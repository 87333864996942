import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Drawer, TextField } from '@skiwo/components';
import { useApi } from '../../../../providers/ApiProvider';
import { useToast } from '../../../../providers/ToastProvider/ToastProvider';
import translationKeys from '../../../../translations/translationKeys';
import { ManagerOrderSubtaskDetail } from '../../../../types';
import styles from './CustomerDescriptionDrawer.module.scss';

interface Props {
  show?: boolean;
  onClose: () => void;
  subtask: ManagerOrderSubtaskDetail;
}

const CustomerDescriptionDrawer = (props: Props) => {
  const { show, onClose, subtask } = props;
  const [description, setDescription] = useState(subtask.instructionsFromProjectManagerToSeller);
  const [updateSubtaskLoading, setUpdateSubtaskLoading] = useState(false);
  const api = useApi();
  const intl = useIntl();
  const { showErrorToast } = useToast();

  const handleSubmit = async () => {
    const body = {
      instructionsFromProjectManagerToSeller: description,
    };

    const { data, error } = await api.updateOrderSubtask(
      subtask.id.toString(),
      body,
      setUpdateSubtaskLoading,
    );
    if (data) {
      onClose();
    }
    if (error) {
      showErrorToast(error);
    }
  };

  return (
    <Drawer
      show={show}
      onClose={onClose}
      title={intl.formatMessage({
        id: translationKeys.translation_subtask_salita_instructions_title,
      })}
      data-testid="customer-description-drawer"
    >
      <Form className={styles.customerDescription}>
        <TextField
          label={intl.formatMessage({
            id: translationKeys.translation_subtask_salita_instructions_description,
          })}
          placeholder={intl.formatMessage({
            id: translationKeys.translation_subtask_salita_instructions_description_placeholder,
          })}
          size="large"
          textArea
          value={description}
          onChange={(event) => setDescription(event.target.value)}
        />

        <div className={styles.actions}>
          <Button variant="gray" onClick={onClose} size="large">
            <FormattedMessage
              id={translationKeys.translation_subtask_salita_instructions_cancel_button}
            />
          </Button>
          <Button isLoading={updateSubtaskLoading} onClick={handleSubmit} size="large">
            <FormattedMessage
              id={translationKeys.translation_subtask_salita_instructions_save_button}
            />
          </Button>
        </div>
      </Form>
    </Drawer>
  );
};

export default CustomerDescriptionDrawer;
