import { IntlShape } from 'react-intl';
import translationKeys from '../../translations/translationKeys';
import { InterpretationSkillsAccountStatus } from '../../types';

const getAccountStatusName = (
  status: InterpretationSkillsAccountStatus,
  intl: IntlShape,
): string => {
  if (status === InterpretationSkillsAccountStatus.Active) {
    return intl.formatMessage({ id: translationKeys.suppliers_page_active_status });
  }

  if (status === InterpretationSkillsAccountStatus.Banned) {
    return intl.formatMessage({ id: translationKeys.suppliers_page_banned_status });
  }

  if (status === InterpretationSkillsAccountStatus.Blocked) {
    return intl.formatMessage({ id: translationKeys.suppliers_page_blocked_status });
  }

  if (status === InterpretationSkillsAccountStatus.Deceased) {
    return intl.formatMessage({ id: translationKeys.suppliers_page_deceased_status });
  }

  if (status === InterpretationSkillsAccountStatus.Deleted) {
    return intl.formatMessage({ id: translationKeys.suppliers_page_deleted_status });
  }

  if (status === InterpretationSkillsAccountStatus.Paused) {
    return intl.formatMessage({ id: translationKeys.suppliers_page_paused_status });
  }

  if (status === InterpretationSkillsAccountStatus.Retired) {
    return intl.formatMessage({ id: translationKeys.suppliers_page_retired_status });
  }

  return '-';
};

export default getAccountStatusName;
