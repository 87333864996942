import React from 'react';
import { faBuilding, faUser, faUsers } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ManagerOrderAccessLevel } from '../../types/ManagerOrder';

const getOrderAccessLevelIcon = (status: ManagerOrderAccessLevel) => {
  switch (status) {
    case ManagerOrderAccessLevel.Owner:
      return <FontAwesomeIcon icon={faUser} />;
    case ManagerOrderAccessLevel.Department:
      return <FontAwesomeIcon icon={faUsers} />;
    case ManagerOrderAccessLevel.Enterprise:
      return <FontAwesomeIcon icon={faBuilding} />;
  }
};

export default getOrderAccessLevelIcon;
