import React from 'react';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './SuccessBanner.module.scss';

const SuccessBanner = () => {
  return (
    <div className={styles.successBannerContainer}>
      <div className={styles.innerBlockBackground}>
        <div className={styles.avatar}></div>
        <div className={styles.simulatedNames}>
          <span className={styles.simulatedFirstName}></span>
          <span className={styles.simulatedLastName}></span>
        </div>
        <FontAwesomeIcon className={styles.checkIcon} icon={faCircleCheck} />
      </div>
    </div>
  );
};

export default SuccessBanner;
