import { IntlShape } from 'react-intl';
import translationKeys from '../../translations/translationKeys';
import { ManagerOrderSourceType } from '../../types/ManagerOrder';

const getOrderSourceName = (status: ManagerOrderSourceType, intl: IntlShape): string => {
  if (status === ManagerOrderSourceType.Platform) {
    return intl.formatMessage({
      id: translationKeys.translation_order_source_platform,
    });
  }
  if (status === ManagerOrderSourceType.CopiedFromXtrf) {
    return intl.formatMessage({
      id: translationKeys.translation_order_source_copied_from_xtrf,
    });
  }
  if (status === ManagerOrderSourceType.Logout) {
    return intl.formatMessage({
      id: translationKeys.translation_order_source_logout,
    });
  }
  if (status === ManagerOrderSourceType.OversettelseEmail) {
    return intl.formatMessage({
      id: translationKeys.translation_order_source_translators_email,
    });
  }
  if (status === ManagerOrderSourceType.PersonalEmail) {
    return intl.formatMessage({
      id: translationKeys.translation_order_source_personal_email,
    });
  }
  if (status === ManagerOrderSourceType.Post) {
    return intl.formatMessage({
      id: translationKeys.translation_order_source_post,
    });
  }
  if (status === ManagerOrderSourceType.PostEmail) {
    return intl.formatMessage({
      id: translationKeys.translation_order_source_post_email,
    });
  }
  if (status === ManagerOrderSourceType.ProjectEmail) {
    return intl.formatMessage({
      id: translationKeys.translation_order_source_project_email,
    });
  }

  return '-';
};

export default getOrderSourceName;
