const formatOrgNumber = (orgNumber: string | undefined) => {
  if (!orgNumber) {
    return;
  }

  const prefix = orgNumber.slice(0, 3);
  const numbers = orgNumber.slice(3);

  const groups = [prefix];
  groups.push(numbers.slice(0, 3));
  groups.push(numbers.slice(3, 6));

  return groups.join(' ');
};

export default formatOrgNumber;
