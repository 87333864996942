import React from 'react';
import { Skeleton } from '@skiwo/components';
import styles from './TranslationSubtaskItemSkeleton.module.scss';

const TranslationSubtaskItemSkeleton = () => {
  return (
    <tr className={styles.skeleton} data-testid="skeleton">
      <td>
        <Skeleton variant="circles" />
      </td>
      <td>
        <Skeleton variant="circles" circleCount={1} />
      </td>
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton variant="details" />
      </td>
      <td>
        <Skeleton variant="details" />
      </td>
      <td>
        <Skeleton variant="details" />
      </td>
      <td>
        <Skeleton variant="details" />
      </td>
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton variant="details" />
      </td>
      <td>
        <Skeleton />
      </td>
    </tr>
  );
};

export default TranslationSubtaskItemSkeleton;
