import { AdminNoteResponse } from '../../types/AdminNote';
import fetchApi from '../fetchApi';

export default {
  deletePerson: (userUid: string, body: any, loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi<AdminNoteResponse>({
      method: 'DELETE',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/people/${userUid}`,
      body,
      loadingCallback,
    });
  },
  getNotes: (userUid: string, loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi<AdminNoteResponse>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/people/${userUid}/notes`,
      loadingCallback,
    });
  },
  sendVerificationEmail: (userUid: string, loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi({
      method: 'POST',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/people/${userUid}/send_verification_email`,
      loadingCallback,
    });
  },
  createNote: (userUid: string, note: string, loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi({
      method: 'POST',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/people/${userUid}/notes`,
      body: { note },
      loadingCallback,
    });
  },
  updateNote: (
    userUid: string,
    noteId: string,
    note: string,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi({
      method: 'PUT',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/people/${userUid}/notes/${noteId}`,
      body: { note },
      loadingCallback,
    });
  },
  deleteNote: (userUid: string, noteId: string, loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi({
      method: 'DELETE',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/people/${userUid}/notes/${noteId}`,
      loadingCallback,
    });
  },
  mergeCustomerList: (
    customerUid: string,
    duplicatePersonUids: string[],
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi({
      method: 'POST',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/people/merge`,
      body: {
        correct_person_uid: customerUid,
        duplicate_person_uids: duplicatePersonUids,
      },
      loadingCallback,
    });
  },
  removeEmailFromSuppressedList: (
    peopleUid: string,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi<any>({
      method: 'POST',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/people/${peopleUid}/remove_email_suppression`,
      loadingCallback,
    });
  },
};
