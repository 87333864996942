import { IntlShape } from 'react-intl';
import translationKeys from '../../translations/translationKeys';
import { ManagerJobStatus } from '../../types';

const getStatusName = (status: ManagerJobStatus, intl: IntlShape): string => {
  if (status === ManagerJobStatus.Accepted) {
    return intl.formatMessage({
      id: translationKeys.jobs_page_status_accepted,
    });
  }

  if (status === ManagerJobStatus.Cancelled) {
    return intl.formatMessage({
      id: translationKeys.jobs_page_status_cancelled,
    });
  }

  if (status === ManagerJobStatus.Closed) {
    return intl.formatMessage({
      id: translationKeys.jobs_page_status_closed,
    });
  }

  if (status === ManagerJobStatus.Finished) {
    return intl.formatMessage({
      id: translationKeys.jobs_page_status_finished,
    });
  }

  if (status === ManagerJobStatus.InProgress) {
    return intl.formatMessage({
      id: translationKeys.jobs_page_status_in_progress,
    });
  }

  if (status === ManagerJobStatus.Published) {
    return intl.formatMessage({
      id: translationKeys.jobs_page_status_published,
    });
  }

  return '';
};

export default getStatusName;
