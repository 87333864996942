import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { faFile, faLanguage, faPhone } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, Tooltip } from '@skiwo/components';
import { getFormattedAddress } from '@skiwo/utils';
import { Form, Formik } from 'formik';
import { InformationSection } from '../../../../CreateInterpretationOrder/InformationSection/InformationSection';
import {
  CreateInterpretationOrderFormValues,
  createInterpretationOrderSchema,
} from '../../../../CreateInterpretationOrder/schema';
import SettingsSection from '../../../../CreateInterpretationOrder/SettingsSection/SettingsSection';
import {
  getCreateInterpretationFormInitialValues,
  getInformationOrderData,
} from '../../../../CreateInterpretationOrder/utils';
import appendFormAttachmentsBlob from '../../../../helpers/appendFormAttachmentsBlob';
import appendNestedFormDataObject from '../../../../helpers/appendNestedFormDataObject';
import { useApi } from '../../../../providers/ApiProvider';
import { useLanguages } from '../../../../providers/LanguagesProvider';
import { useQualifications } from '../../../../providers/QualificationsProvider';
import { useToast } from '../../../../providers/ToastProvider/ToastProvider';
import translationKeys from '../../../../translations/translationKeys';
import { ManagerJobDetails } from '../../../../types/ManagerJob';
import Card from '../Card/Card';
import styles from './InformationCard.module.scss';

interface InformationCardProps {
  job: ManagerJobDetails;
}

const InformationCard = ({ job }: InformationCardProps) => {
  const [showModal, setShowModal] = useState(false);
  const [isCategoryRequired, setIsCategoryRequired] = useState(false);
  const [isCategorySubjectRequired, setIsCategorySubjectRequired] = useState(false);
  const { languages } = useLanguages();
  const intl = useIntl();
  const api = useApi();
  const [isLoading, setIsLoading] = useState(false);
  const { showToast, showErrorToast } = useToast();
  const { qualifications } = useQualifications();
  const navigate = useNavigate();

  const filteredAttachments =
    job.attachments?.filter(
      (attachment): attachment is { fileName: string; fileUrl: string } =>
        attachment.fileName !== null && attachment.fileUrl !== null,
    ) || [];

  const handleToggleDrawer = () => setShowModal((isActive) => !isActive);

  const schema = createInterpretationOrderSchema({
    intl,
    isCategoryRequired,
    isCategorySubjectRequired,
  });

  return (
    <>
      <Formik<CreateInterpretationOrderFormValues>
        validationSchema={schema}
        initialValues={getCreateInterpretationFormInitialValues(languages, job)}
        onSubmit={async (values) => {
          const createOrderData = getInformationOrderData(values, qualifications);
          const createOrderFormData = new FormData();

          appendNestedFormDataObject(createOrderFormData, createOrderData);

          values.uploadFilesActive &&
            values.attachments &&
            appendFormAttachmentsBlob(createOrderFormData, values.attachments);

          const { error } = await api.updateJob(job.id, createOrderFormData, setIsLoading);

          if (error) {
            showErrorToast(error);
          } else {
            navigate(window.location.pathname, { replace: true }); // We don't have refetch.
            showToast({
              variant: 'success',
              message: intl.formatMessage({
                id: translationKeys.job_edit_information_updated_successfully,
              }),
            });
          }
        }}
        enableReinitialize
      >
        {(formikProps) => {
          return (
            <Modal
              title="Edit requirements"
              show={showModal}
              onHide={handleToggleDrawer}
              onCancel={handleToggleDrawer}
              onSubmit={formikProps.handleSubmit}
              submitButtonText="Submit"
              size="large"
              isSubmitting={isLoading}
            >
              <Form className={styles.form}>
                <InformationSection
                  formikProps={formikProps}
                  enterpriseId={job.enterprise?.id}
                  setIsCategoryRequired={setIsCategoryRequired}
                  setIsCategorySubjectRequired={setIsCategorySubjectRequired}
                  isEditForm
                />
                <SettingsSection
                  formikProps={formikProps}
                  customer={{ emailVerified: false }}
                  isEditForm
                />
              </Form>
            </Modal>
          );
        }}
      </Formik>
      <Card
        titleIcon={<FontAwesomeIcon icon={faLanguage} />}
        title={intl.formatMessage({ id: translationKeys.job_information_label })}
        emptyText={intl.formatMessage({ id: translationKeys.job_information_empty_text })}
        handleEdit={handleToggleDrawer}
      >
        {job.contact && (
          <Card.BodyItem
            title={intl.formatMessage({ id: translationKeys.job_information_address_label })}
          >
            {getFormattedAddress(job.contact)}
          </Card.BodyItem>
        )}

        {job.contact?.extra && (
          <Card.BodyItem
            title={intl.formatMessage({ id: translationKeys.job_information_address_desc_label })}
          >
            {job.contact.extra}
          </Card.BodyItem>
        )}

        {job.contact && (
          <Card.BodyItem
            title={intl.formatMessage({ id: translationKeys.job_information_contact_person_label })}
          >
            <Card.ItemRow>
              {job.contact.phone && (
                <Tooltip title={job.contact.phone} copyable>
                  <FontAwesomeIcon icon={faPhone} />
                </Tooltip>
              )}
              {job.contact.name}
            </Card.ItemRow>
          </Card.BodyItem>
        )}
        {job.info.description && (
          <Card.BodyItem
            title={intl.formatMessage({ id: translationKeys.job_information_message_label })}
          >
            {job.info.description}
          </Card.BodyItem>
        )}
        {filteredAttachments.length > 0 && (
          <Card.BodyItem
            title={
              intl.formatMessage({ id: translationKeys.job_information_files_label }) +
              ` (${filteredAttachments.length})`
            }
          >
            {filteredAttachments.map((attachment) => (
              <Card.Button
                key={attachment.fileUrl}
                icon={<FontAwesomeIcon icon={faFile} />}
                onClick={async () => {
                  const { data } = await api.downloadFile(attachment.fileUrl);

                  if (data) {
                    window.open(data, '_blank');
                  }
                }}
              >
                {attachment.fileName}
              </Card.Button>
            ))}
          </Card.BodyItem>
        )}
        {job.blockedInterpreters && job.blockedInterpreters.length > 0 && (
          <Card.BodyItem
            title={intl.formatMessage({
              id: translationKeys.job_information_blocked_interpreters_label,
            })}
          >
            {job.blockedInterpreters.map(({ name }) => name).join(', ')}
          </Card.BodyItem>
        )}
        {job.blockedCities && job.blockedCities.length > 0 && (
          <Card.BodyItem
            title={intl.formatMessage({ id: translationKeys.job_information_blocked_cities_label })}
          >
            {job.blockedCities.map((city) => `${city.name}, ${city.country}`).join(' • ')}
          </Card.BodyItem>
        )}
      </Card>
    </>
  );
};

export default InformationCard;
