export enum CustomerPaymentMethodInvoiceGroupStrategy {
  PerJob = 'per_job',
  PerDepartment = 'per_department',
  PerCustomer = 'per_customer',
  PerOrderReference = 'per_order_reference',
  PerBuyerReference = 'per_buyer_reference',
  PerBuyerOrderAndReference = 'per_buyer_order_and_reference',
  PerBooker = 'per_booker',
}

export enum CustomerPaymentMethodInvoiceFrequency {
  Daily = 'daily',
  Weekly = 'weekly',
  Fortnightly = 'fortnightly',
}
export enum CustomerPaymentMethodInvoiceDeliveryMechanizm {
  EHF = 'ehf',
  Email = 'email',
}

export enum CustomerPaymentMethodType {
  Organization = 'organization',
  Individual = 'individual',
}

interface CustomerPaymentMethod {
  id: number;
  archived: boolean;
  orgNumber?: string;
  orgName?: string;
  email?: string;
  invoiceGroupStrategy: CustomerPaymentMethodInvoiceGroupStrategy;
  invoiceFrequency?: CustomerPaymentMethodInvoiceFrequency;
  invoiceDeliveryMechanizm?: CustomerPaymentMethodInvoiceDeliveryMechanizm;
  createdAt: string;
  updatedAt: string;
  methodType: CustomerPaymentMethodType;
  address?: {
    addressLine1?: string;
    addressLine2?: string;
    city?: string;
    county?: string;
    country?: string;
  };
  isBlacklisted: boolean;
  blacklistMessage?: string;
  default: boolean;
  bookingReferenceRegex?: string;
  paymentBookingReferenceRegex?: string;
  bookingReferenceRegexHint?: string;
  paymentBookingReferenceRegexHint?: string;
  allowCaseNumber: boolean;
  allowPaymentBookingReference: boolean;
  allowBookingReference: boolean;
  labelForBookingReference: string;
  labelForCaseNumber: string;
  labelForPaymentBookingReference: string;
  defaultBookingReference: string;
  defaultPaymentBookingReference: string;
  requireBookingReference: boolean;
  requirePaymentBookingReference: boolean;
  requireCaseNumber: boolean;
}

export default CustomerPaymentMethod;
