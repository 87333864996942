import { FileState } from '@skiwo/components';

export default function appendFormAttachmentsBlob(formData: FormData, files: FileState[]) {
  files.forEach((file) => {
    const fileBlob = new Blob([file.data], {
      type: file.type,
    });

    formData.append(`attachments[][file]`, fileBlob, file.name);
    formData.append(`attachments[][comment]`, file.name);
  });
}
