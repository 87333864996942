import React from 'react';
import { Skeleton } from '@skiwo/components';
import styles from './InterpretationSkillsItemSkeleton.module.scss';

const InterpretationSkillsItemSkeleton = () => {
  return (
    <tr className={styles.skeleton} data-testid="interpretation-skill-skeleton">
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton variant="details" />
      </td>
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton variant="details" />
      </td>
      <td>
        <Skeleton variant="circles" circleCount={3} />
      </td>
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton variant="details" />
      </td>
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton variant="circles" />
      </td>
    </tr>
  );
};

export default InterpretationSkillsItemSkeleton;
