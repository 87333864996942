import { IntlShape } from 'react-intl';
import translationKeys from '../../../../../translations/translationKeys';

export const getDeliveryMethodTranslation = ({
  value,
  intl,
}: {
  value?: string;
  intl: IntlShape;
}) => {
  if (value === 'email') {
    return intl.formatMessage({
      id: translationKeys.translation_order_system_logs_order_updated_delivery_method_email,
    });
  }
  if (value === 'post') {
    return intl.formatMessage({
      id: translationKeys.translation_order_system_logs_order_updated_delivery_method_post,
    });
  }
  return '-';
};
