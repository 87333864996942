import React, { useEffect, useState } from 'react';
import { NavDropdown } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import classnames from 'classnames';
import MenuSection from './MenuSection/MenuSection';
import styles from './MegaMenu.module.scss';
export interface MegaMenuItem {
  name: string;
  description?: string;
  link: string;
  icon?: IconDefinition;
  disabled?: boolean;
}

export interface MegaMenuSection {
  name: string;
  items: MegaMenuItem[];
}

export interface MegaMenuTab {
  name: string;
  sections: MegaMenuSection[];
}

interface Props {
  menuTabs: Record<string, MegaMenuTab>;
  logo: React.ReactNode;
  isAuthenticated?: boolean;
  actions?: JSX.Element;
  rightMenu?: JSX.Element;
  onClick?: (link: string) => void;
}

// NB: The gap between the menu and the left of the viewport
const MENU_MARGIN = 32;

function MegaMenu(props: Props) {
  const { menuTabs, logo, onClick, actions, rightMenu, isAuthenticated = false } = props;
  const [tabOffset, setTabOffset] = useState<Record<string, number>>({});
  const [activeTab, setActiveTab] = useState('');

  const getActiveTab = () => {
    const locationUrl = location.href;

    for (const menuTab in menuTabs) {
      for (const section of menuTabs[menuTab].sections) {
        const sectionItemLinks = section.items
          .map((item) => item.link)
          .filter((link) => link !== '#');

        for (const link of sectionItemLinks) {
          if (locationUrl.includes(link)) {
            setActiveTab(menuTab);
          }
        }
      }
    }
  };

  useEffect(() => {
    getActiveTab();
  }, [location.href]);

  return (
    <Navbar
      bg={isAuthenticated ? 'white' : 'transparent'}
      expand="lg"
      className={classnames(styles.megaMenu, { [styles.showBorder]: isAuthenticated })}
      fixed="top"
    >
      <Container fluid className={styles.navContainer}>
        <Navbar.Brand
          href="/"
          className={classnames(styles.brand, { [styles.loggedOutBrand]: !isAuthenticated })}
        >
          {logo}
        </Navbar.Brand>
        {isAuthenticated && (
          <>
            <Navbar.Toggle />
            <Navbar.Collapse>
              <Nav>
                {Object.keys(menuTabs).map((tab) => {
                  return (
                    <NavDropdown
                      key={tab}
                      title={menuTabs[tab].name}
                      className={classnames(styles.navItem, { [styles.active]: tab === activeTab })}
                      data-testid="menu-tab"
                    >
                      <div
                        className={styles.tabContent}
                        style={{
                          marginLeft:
                            window.innerWidth > 1000
                              ? `-${tabOffset[tab] - MENU_MARGIN || 0}px`
                              : `-${tabOffset[tab] || 0}px`,
                        }}
                        ref={(el: HTMLDivElement) => {
                          if (!el) return;

                          const { x } = el.getBoundingClientRect();
                          if (x === 0) return;

                          if (!tabOffset[tab] || x > tabOffset[tab]) {
                            setTabOffset({ ...tabOffset, [tab]: x });
                          }
                        }}
                      >
                        {menuTabs[tab].sections.map((section) => (
                          <MenuSection section={section} key={section.name} onClick={onClick} />
                        ))}
                      </div>
                    </NavDropdown>
                  );
                })}
              </Nav>

              {actions && <Nav>{actions}</Nav>}

              <Nav>{rightMenu}</Nav>
            </Navbar.Collapse>
          </>
        )}
      </Container>
    </Navbar>
  );
}

export default MegaMenu;
