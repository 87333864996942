import { IntlShape } from 'react-intl';
import { faBuilding, faUsers } from '@fortawesome/pro-light-svg-icons';
import translationKeys from '../translations/translationKeys';

const getCustomers = (intl: IntlShape) => {
  const customerAccountsSection = {
    name: intl.formatMessage({ id: translationKeys.menu_customers_customer_accounts_section }),
    items: [
      {
        name: intl.formatMessage({
          id: translationKeys.menu_customers_customers,
        }),
        description: intl.formatMessage({
          id: translationKeys.menu_customers_customers_description,
        }),
        link: '/customers',
        icon: faUsers,
      },
      {
        name: intl.formatMessage({
          id: translationKeys.menu_customers_enterprises,
        }),
        description: intl.formatMessage({
          id: translationKeys.menu_customers_enterprises_description,
        }),
        link: '/enterprises',
        icon: faBuilding,
      },
    ],
  };

  const statisticsSection = {
    name: intl.formatMessage({ id: translationKeys.menu_customers_statistics_section }),
    items: [
      {
        name: intl.formatMessage({
          id: translationKeys.menu_customers_customer_statistics,
        }),
        link: '#',
        icon: faBuilding,
        disabled: true,
      },
    ],
  };

  return {
    name: intl.formatMessage({ id: translationKeys.menu_customers_item }),
    sections: [customerAccountsSection, statisticsSection],
  };
};

export default getCustomers;
