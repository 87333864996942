import { IntlShape } from 'react-intl';
import translationKeys from '../../translations/translationKeys';
import { ManagerOrderTaskStatus } from '../../types/ManagerOrder';

const getTaskStatusName = (status: ManagerOrderTaskStatus, intl: IntlShape): string => {
  if (status === ManagerOrderTaskStatus.Draft) {
    return intl.formatMessage({
      id: translationKeys.translation_task_status_draft,
    });
  }

  if (status === ManagerOrderTaskStatus.Published) {
    return intl.formatMessage({
      id: translationKeys.translation_task_status_published,
    });
  }

  if (status === ManagerOrderTaskStatus.Finished) {
    return intl.formatMessage({
      id: translationKeys.translation_task_status_finished,
    });
  }

  if (status === ManagerOrderTaskStatus.Cancelled) {
    return intl.formatMessage({
      id: translationKeys.translation_task_status_cancelled,
    });
  }

  return '-';
};

export default getTaskStatusName;
