import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@skiwo/components';
import SuccessBanner from '../../../components/SuccessBanner/SuccessBanner';
import translationKeys from '../../../translations/translationKeys';
import styles from './CreateSupplierSuccess.module.scss';

interface Props {
  onClose: () => void;
  resetShowSuccess: () => void;
  onOpenNewSkillDrawer: () => void;
  fullName?: string;
  godmodeUrl?: string;
  onComplete: () => void;
}

const CreateDepartmentSuccess = (props: Props) => {
  const { onClose, resetShowSuccess, fullName, godmodeUrl, onOpenNewSkillDrawer, onComplete } =
    props;

  return (
    <div className={styles.successContainer} data-testid="create-supplier-success">
      <SuccessBanner />
      {fullName && (
        <p className={styles.message}>
          <FormattedMessage
            id={translationKeys.create_supplier_drawer_success_message}
            values={{
              fullName: <b>{fullName}</b>,
            }}
          />
        </p>
      )}
      <div className={styles.actions}>
        <Button
          size="large"
          variant="gray"
          data-testid="go-to-enterprise-button"
          onClick={() => {
            onOpenNewSkillDrawer();
          }}
        >
          <FormattedMessage id={translationKeys.create_supplier_drawer_success_add_skill_button} />
        </Button>

        <Button
          size="large"
          data-testid="go-to-profile-button"
          onClick={() => {
            resetShowSuccess();
            window.open(godmodeUrl, '_blank');
            onClose();
            onComplete();
          }}
        >
          <FormattedMessage
            id={translationKeys.create_supplier_drawer_success_go_to_profile_button}
          />
        </Button>
      </div>
    </div>
  );
};

export default CreateDepartmentSuccess;
