import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { faBuilding, faEnvelope, faPhone } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Banner, Button, DetailsCard, Modal, TextField } from '@skiwo/components';
import { formatPhoneNumber } from '@skiwo/utils';
import { useApi } from '../../../providers/ApiProvider';
import { useToast } from '../../../providers/ToastProvider/ToastProvider';
import translationKeys from '../../../translations/translationKeys';
import { ManagerCustomer } from '../../../types';
import styles from './DeleteCustomerModal.module.scss';

interface DeleteCustomerModalProps {
  show: boolean;
  customer: ManagerCustomer;
  onClose: () => void;
  onComplete: (hardReload?: boolean) => void;
}

export const DeleteCustomerModal = ({
  show,
  customer,
  onClose,
  onComplete,
}: DeleteCustomerModalProps) => {
  const intl = useIntl();
  const api = useApi();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const { showErrorToast } = useToast();
  const [reason, setReason] = useState('');
  const [otherReason, setOtherReason] = useState('');
  const [confirmDelete, setConfirmDelete] = useState(false);

  const reasonOptions = {
    duplicate: intl.formatMessage({
      id: translationKeys.customers_page_delete_modal_reason_duplicate,
    }),
    doesNotWorkAnymore: intl.formatMessage({
      id: translationKeys.customers_page_delete_modal_reason_does_not_work,
    }),
    mistake: intl.formatMessage({ id: translationKeys.customers_page_delete_modal_reason_mistake }),
    other: intl.formatMessage({ id: translationKeys.customers_page_delete_modal_reason_other }),
  };

  const handleDelete = async () => {
    const reasonOrOtherReason = reason === reasonOptions.other ? otherReason : reason;
    const { error } = await api.deletePerson(
      customer.uid,
      { reason: reasonOrOtherReason },
      setDeleteLoading,
    );

    if (error) {
      showErrorToast(error);
    } else {
      onClose();
      onComplete();
    }
  };

  const isSubmitDisabled = () => {
    const hasValidReason = (reason && reason !== reasonOptions.other) || otherReason.length;

    if (customer.hasFutureAssignments) {
      return !hasValidReason || !confirmDelete;
    } else {
      return !hasValidReason;
    }
  };

  return (
    <Modal
      show={show}
      size="large"
      title={intl.formatMessage({ id: translationKeys.customers_page_delete_modal_label })}
      description={intl.formatMessage({ id: translationKeys.customers_page_delete_modal_subtitle })}
      onHide={onClose}
      data-testid="delete-customer-modal"
    >
      <div className={styles.deleteCustomerModal}>
        <Banner
          variant="error"
          text={
            customer.hasFutureAssignments
              ? intl.formatMessage({
                  id: translationKeys.customers_page_delete_modal_banner_attached_jobs,
                })
              : intl.formatMessage({ id: translationKeys.customers_page_delete_modal_banner })
          }
        />
        <DetailsCard
          avatarUrl={customer.enterprise?.logoUrl}
          title={customer.name}
          subtitleItems={[
            { icon: <FontAwesomeIcon icon={faEnvelope} />, value: customer.email },
            { icon: <FontAwesomeIcon icon={faPhone} />, value: formatPhoneNumber(customer.phone) },
          ]}
          mainItems={[
            {
              label: intl.formatMessage({
                id: translationKeys.customers_page_delete_modal_enterprise_label,
              }),
              icon: <FontAwesomeIcon icon={faBuilding} />,
              value: customer.enterprise?.name,
            },
            {
              label: intl.formatMessage({
                id: translationKeys.customers_page_delete_modal_departments_label,
              }),
              icon: <FontAwesomeIcon icon={faBuilding} />,
              value: customer.departments?.map((department) => department.name).join(', '),
            },
          ]}
        />
        <hr />
        <div className={styles.reason}>
          <span className={styles.reasonTitle}>
            <FormattedMessage id={translationKeys.customers_page_delete_modal_reason_label} />
          </span>

          <div className={styles.reasonOptions}>
            <Form.Check
              type="radio"
              label={reasonOptions.duplicate}
              name="reason"
              onChange={() => {
                setReason(reasonOptions.duplicate);
                setOtherReason('');
              }}
            />
            <Form.Check
              type="radio"
              label={reasonOptions.doesNotWorkAnymore}
              name="reason"
              onChange={() => {
                setReason(reasonOptions.doesNotWorkAnymore);
                setOtherReason('');
              }}
            />
            <Form.Check
              type="radio"
              label={reasonOptions.mistake}
              name="reason"
              onChange={() => {
                setReason(reasonOptions.mistake);
                setOtherReason('');
              }}
            />
            <Form.Check
              type="radio"
              label={reasonOptions.other}
              name="reason"
              onChange={() => setReason(reasonOptions.other)}
            />
          </div>

          {reason === reasonOptions.other && (
            <div className={styles.otherReason}>
              <TextField
                textArea
                label={intl.formatMessage({
                  id: translationKeys.customers_page_delete_modal_reason_other_input_label,
                })}
                placeholder={intl.formatMessage({
                  id: translationKeys.customers_page_delete_modal_reason_other_input_placeholder,
                })}
                onChange={(e) => setOtherReason(e.target.value)}
                size="large"
              />
            </div>
          )}
        </div>

        {customer.hasFutureAssignments && (
          <Form.Check
            type="checkbox"
            label={intl.formatMessage({
              id: translationKeys.customers_page_delete_modal_confirm_checkbox,
            })}
            onChange={(e) => setConfirmDelete(e.target.checked)}
          />
        )}

        <div className={styles.actionButtons}>
          <Button
            onClick={onClose}
            variant="gray"
            size="large"
            className={styles.cancelButton}
            data-testid="cancel-button"
          >
            <FormattedMessage id={translationKeys.customers_page_delete_modal_cancel_button} />
          </Button>

          <Button
            iconPosition="left"
            size="x-large"
            variant="danger"
            className={styles.renameFileButton}
            onClick={() => handleDelete()}
            disabled={isSubmitDisabled()}
            isLoading={deleteLoading}
          >
            <FormattedMessage id={translationKeys.customers_page_delete_modal_delete_button} />
          </Button>
        </div>
      </div>
    </Modal>
  );
};
