import React from 'react';
import classnames from 'classnames';
import avatarPlaceholder from '../assets/avatar-placeholder.svg';
import styles from './Avatar.module.scss';

export interface AvatarProps {
  altText: string;
  url?: string;
  active?: boolean;
  size?: 'small' | 'medium' | 'large';
}

const Avatar = (props: AvatarProps) => {
  const { altText, url, active, size = 'small' } = props;
  const src = url ? url : avatarPlaceholder;

  return (
    <div className={classnames(styles.avatar, { [styles.active]: active })} data-testid="avatar">
      <img src={src} alt={altText} className={styles[size]} />
    </div>
  );
};

export default Avatar;
