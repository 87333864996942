import React from 'react';
import { useIntl } from 'react-intl';
import { faCircle } from '@fortawesome/pro-light-svg-icons';
import { faChevronDown, faCircle as faCircleSolid } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, Tag } from '@skiwo/components';
import { ManagerOrder, ManagerOrderStatus } from '../../../types';
import getOrderStatusColour from '../../utils/getOrderStatusColour';
import getOrderStatusName from '../../utils/getOrderStatusName';
import styles from './StatusDropdown.module.scss';

interface StatusDropdownProps {
  order: ManagerOrder;
  onSelect: (status: ManagerOrderStatus) => void;
}

export const StatusDropdown = ({ order, onSelect }: StatusDropdownProps) => {
  const intl = useIntl();
  return (
    <Dropdown
      toggle={
        <Tag color={getOrderStatusColour(order.status)} data-testId="dropdown-status-action-button">
          <div className={styles.toggle}>
            {getOrderStatusName(order.status, intl)}
            <FontAwesomeIcon className={styles.chevron} icon={faChevronDown} />
          </div>
        </Tag>
      }
      items={Object.values(ManagerOrderStatus).map((status) => {
        return {
          id: status,
          text: getOrderStatusName(status, intl),
          icon:
            status === ManagerOrderStatus.Draft || status === ManagerOrderStatus.Completed
              ? faCircle
              : faCircleSolid,
          iconClassName: styles[getOrderStatusColour(status)],
          'data-testId': status,
          disabled:
            status === ManagerOrderStatus.Draft || status === ManagerOrderStatus.Cancelled
              ? false
              : !order.ownerId,
        };
      })}
      onSelect={(status) => onSelect(status as ManagerOrderStatus)}
      data-testid="status-dropdown"
    />
  );
};
