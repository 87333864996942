import React from 'react';
import { useIntl } from 'react-intl';
import { Tooltip } from '@skiwo/components';
import { format } from 'date-fns';
import gradCapArrow from '../../../assets/icons/graduation-cap-arrow.svg';
import gradCapCrossed from '../../../assets/icons/graduation-cap-crossed.svg';
import gradCapPencil from '../../../assets/icons/graduation-cap-pencil.svg';
import gradCapTransparent from '../../../assets/icons/graduation-cap-transparent.svg';
import translationKeys from '../../../translations/translationKeys';
import { ManagerJobQualificationProcessing } from '../../../types';
import styles from './Hint.module.scss';

interface Props {
  specificQualificationRequested?: boolean;
  qualificationProcessing?: ManagerJobQualificationProcessing;
  qualificationPriority?: number;
  qualificationName?: string;
  autoUpdateTime?: string;
}

const QualificationHint = (props: Props) => {
  const {
    specificQualificationRequested,
    qualificationProcessing,
    qualificationPriority,
    qualificationName,
    autoUpdateTime,
  } = props;
  const intl = useIntl();

  const autoUpdateTimeStamp = new Date(autoUpdateTime || '');

  if (!specificQualificationRequested) {
    return (
      <Tooltip
        title={intl.formatMessage({
          id: translationKeys.jobs_page_no_qualification_tooltip,
        })}
      >
        <img
          data-testid="qualification-icon-no-preference"
          src={gradCapTransparent}
          className={styles.icon}
        />
      </Tooltip>
    );
  }

  switch (qualificationProcessing) {
    case ManagerJobQualificationProcessing.qualification_auto_update:
      if (qualificationPriority === 8) {
        return (
          <Tooltip
            title={intl.formatMessage({
              id: translationKeys.jobs_page_qualification_reset_tooltip,
            })}
            subtitle={format(autoUpdateTimeStamp, 'dd.MM.yyyy HH:mm')}
          >
            <img
              data-testid="qualification-reset-hint"
              src={gradCapArrow}
              className={styles.icon}
            />
          </Tooltip>
        );
      }

      return (
        <Tooltip
          title={intl.formatMessage(
            {
              id: translationKeys.jobs_page_qualification_change_level_tooltip,
            },
            {
              level: qualificationName,
            },
          )}
          subtitle={format(autoUpdateTimeStamp, 'dd.MM.yyyy HH:mm')}
        >
          <img
            data-testid="qualification-auto-update-hint"
            src={gradCapPencil}
            className={styles.icon}
          />
        </Tooltip>
      );

    case ManagerJobQualificationProcessing.qualification_cancel_not_found:
      return (
        <Tooltip
          title={intl.formatMessage(
            {
              id: translationKeys.jobs_page_qualification_cancel_tooltip,
            },
            {
              level: qualificationName,
            },
          )}
          subtitle={format(autoUpdateTimeStamp, 'dd.MM.yyyy HH:mm')}
        >
          <img
            data-testid="qualification-not-found-hint"
            src={gradCapCrossed}
            className={styles.icon}
          />
        </Tooltip>
      );

    case ManagerJobQualificationProcessing.qualification_no_action:
      return (
        <img data-testid="qualification-ok-hint" src={gradCapTransparent} className={styles.icon} />
      );
  }

  return (
    <img data-testid="qualification-ok-hint" src={gradCapTransparent} className={styles.icon} />
  );
};

export default QualificationHint;
