import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Modal } from '@skiwo/components';
import { useApi } from '../../../providers/ApiProvider';
import { useLanguages } from '../../../providers/LanguagesProvider';
import { useToast } from '../../../providers/ToastProvider/ToastProvider';
import translationKeys from '../../../translations/translationKeys';
import styles from './ConfirmDeleteSkillModal.module.scss';

interface ConfirmDeleteSkillModalProps {
  show: boolean;
  skill: any;
  onClose: () => void;
  onComplete: (hardReload?: boolean) => void;
  type: 'interpretation' | 'translation';
}

export const ConfirmDeleteSkillModal = ({
  show,
  skill,
  onClose,
  onComplete,
  type,
}: ConfirmDeleteSkillModalProps) => {
  const intl = useIntl();
  const { getLanguageById } = useLanguages();
  const api = useApi();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const { showErrorToast } = useToast();

  const handleDelete = async () => {
    if (type === 'interpretation') {
      const { error } = await api.deleteInterpretationSkill(skill.id, setDeleteLoading);

      if (error) {
        showErrorToast(error);
      } else {
        onComplete(true);
        onClose();
      }
    }
    if (type === 'translation') {
      const { error } = await api.deleteTranslationSkill(skill.id, setDeleteLoading);

      if (error) {
        showErrorToast(error);
      } else {
        onComplete(true);
        onClose();
      }
    }
  };

  return (
    <Modal
      show={show}
      title={intl.formatMessage({ id: translationKeys.skills_delete_skill_modal_title })}
      description={intl.formatMessage(
        {
          id: translationKeys.skills_delete_skill_modal_description,
        },
        {
          languages: (
            <span className={styles.bold}>
              {getLanguageById(skill.langFromId)} - {getLanguageById(skill.langToId)}
            </span>
          ),
          name: <span className={styles.bold}>{skill.name || skill.person.name || '-'}</span>,
        },
      )}
      onHide={onClose}
    >
      <div className={styles.actionButtons}>
        <Button
          onClick={onClose}
          variant="gray"
          size="large"
          className={styles.cancelButton}
          data-testid="cancel-button"
        >
          <FormattedMessage id={translationKeys.skills_delete_skill_modal_back_button} />
        </Button>

        <Button
          iconPosition="left"
          size="large"
          variant="danger"
          className={styles.renameFileButton}
          onClick={() => handleDelete()}
          isLoading={deleteLoading}
        >
          <FormattedMessage id={translationKeys.skills_delete_skill_modal_delete_button} />
        </Button>
      </div>
    </Modal>
  );
};
