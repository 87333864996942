import { IntlShape } from 'react-intl';
import { getInvoiceStrategyName } from '../CreateTranslationOrder/PaymentSection/getInvoiceStrategyName';
import translationKeys from '../translations/translationKeys';
import { CustomerPaymentMethodInvoiceGroupStrategy } from '../types';

interface PaymentMethodData {
  orgName?: string;
  orgNumber?: string;
  email?: string | null;
  invoiceGroupStrategy: CustomerPaymentMethodInvoiceGroupStrategy;
}

export const getPaymentMethodName = (paymentMethodData: PaymentMethodData, intl: IntlShape) => {
  const { orgName, orgNumber, email, invoiceGroupStrategy } = paymentMethodData;

  return orgNumber
    ? `${orgName || '-'} / ${orgNumber} (${getInvoiceStrategyName(invoiceGroupStrategy, intl)})`
    : `${email || '-'} / ${intl.formatMessage({
        id: translationKeys.translation_order_edit_invoicing_payment_methods_email_label,
      })} (${getInvoiceStrategyName(invoiceGroupStrategy, intl)})`;
};
