import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Button,
  Drawer,
  SearchDropdown,
  SearchDropdownMenuOption,
  TextField,
} from '@skiwo/components';
import { useApi } from '../../../../providers/ApiProvider';
import { useToast } from '../../../../providers/ToastProvider/ToastProvider';
import translationKeys from '../../../../translations/translationKeys';
import { ManagerOrder } from '../../../../types';
import { translationOrderErrorKeys } from '../../../utils/translationOrderErrorKeys';
import styles from './EditDetailsDrawer.module.scss';

interface Props {
  show?: boolean;
  order: ManagerOrder;
  onClose: () => void;
}

const EditDetailsDrawer = (props: Props) => {
  const { show, order, onClose } = props;
  const intl = useIntl();
  const [translationMethodOptions, setTranslationMethodOptions] = useState<
    SearchDropdownMenuOption[]
  >([]);
  const [selectedTranslationMethodOption, setSelectedTranslationMethodOption] = useState<
    SearchDropdownMenuOption | undefined
  >();
  const [updateOrderLoading, setUpdateOrderLoading] = useState(false);
  const [caseNumber, setCaseNumber] = useState<string>(order.caseNumber || '');
  const [description, setDescription] = useState<string>(order.instructionsFromDemander || '');
  const { showErrorToast } = useToast();

  const api = useApi();

  const getTranslationMethods = async () => {
    const { data, error } = await api.getTranslationMethods();

    if (data?.translationMethods) {
      const options =
        data?.translationMethods.map((method) => {
          return { id: method.id, label: method.name, key: method.id.toString() };
        }) || [];

      setTranslationMethodOptions(options);

      const selectedOption = options.find((option) => option.id === order.translationMethodId);
      setSelectedTranslationMethodOption(selectedOption);
    }

    if (error) {
      showErrorToast(error);
    }
  };

  const handleSubmit = async () => {
    const body = {
      requirementIdempotencyKey: order.requirementIdempotencyKey,
      translationMethodId: selectedTranslationMethodOption?.id,
      caseNumber: caseNumber,
      instructionsFromDemander: description,
    } as Partial<ManagerOrder>;

    const { data, error } = await api.updateOrder(order.id.toString(), body, setUpdateOrderLoading);

    if (data) {
      onClose();
    }

    if (error) {
      showErrorToast(error, translationOrderErrorKeys);
    }
  };

  useEffect(() => {
    if (!show) return;

    getTranslationMethods();
  }, [show]);

  return (
    <Drawer
      show={show}
      onClose={onClose}
      title={intl.formatMessage({ id: translationKeys.translation_order_order_details_title })}
      data-testid="edit-order-drawer"
    >
      <Form className={styles.editDetails}>
        <SearchDropdown
          options={translationMethodOptions.filter(
            (option) => option.id !== selectedTranslationMethodOption?.id,
          )}
          size="large"
          selected={selectedTranslationMethodOption ? [selectedTranslationMethodOption] : []}
          placeholder={intl.formatMessage({
            id: translationKeys.translation_order_order_details_subject_placeholder,
          })}
          label={intl.formatMessage({
            id: translationKeys.translation_order_order_details_subject_label,
          })}
          onChange={(items: SearchDropdownMenuOption[] | null) => {
            if (items && items.length > 0) {
              setSelectedTranslationMethodOption(items[0]);
            }
          }}
        />

        <TextField
          label={intl.formatMessage({
            id: translationKeys.translation_order_order_details_case_number_label,
          })}
          placeholder={intl.formatMessage({
            id: translationKeys.translation_order_order_details_case_number_placeholder,
          })}
          value={caseNumber}
          onChange={(e) => setCaseNumber(e.target.value)}
          size="large"
        />

        <TextField
          label={intl.formatMessage({
            id: translationKeys.translation_order_order_details_description_label,
          })}
          placeholder={intl.formatMessage({
            id: translationKeys.translation_order_order_details_description_placeholder,
          })}
          size="large"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          textArea
        />

        <div className={styles.actions}>
          <Button variant="gray" onClick={onClose} size="large">
            <FormattedMessage id={translationKeys.translation_order_order_details_cancel_button} />
          </Button>
          <Button onClick={handleSubmit} size="large" isLoading={updateOrderLoading}>
            <FormattedMessage id={translationKeys.translation_order_order_details_save_button} />
          </Button>
        </div>
      </Form>
    </Drawer>
  );
};

export default EditDetailsDrawer;
