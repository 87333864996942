import { IntlShape } from 'react-intl';
import * as yup from 'yup';
import getRequiredMessage from '../helpers/getRequiredFieldMessage';
import translationKeys from '../translations/translationKeys';
import { CustomerPaymentMethod, ManagerCustomer, ManagerOrderDeliveryMethod } from '../types';
import { PostageType } from './PostageSection/PostageSection';

interface CreateTranslationOrderSchemaProps {
  intl: IntlShape;
  customer: ManagerCustomer | undefined;
  paymentMethod: CustomerPaymentMethod | undefined;
  postageType: PostageType;
  postageAddressRequired: boolean;
  noSourceLanguage: boolean;
}

export default function createTranslationOrderSchema({
  intl,
  customer,
  paymentMethod,
  postageType,
  postageAddressRequired,
  noSourceLanguage,
}: CreateTranslationOrderSchemaProps) {
  return yup.object().shape({
    customerId: yup.string().required(),
    bookerName: customer?.isShared
      ? yup.string().required(
          getRequiredMessage(
            intl,
            intl.formatMessage({
              id: translationKeys.create_translation_order_customer_booker_name_placeholder,
            }),
          ),
        )
      : yup.string().notRequired(),
    departmentId: yup.string().required(),
    paymentMethodId: yup.string().required(),
    bookingReference:
      paymentMethod?.allowBookingReference && paymentMethod?.requireBookingReference
        ? yup
            .string()
            .matches(
              /^[0-9a-zA-ZÆØÅæøå\\/:\- ]*$/,
              intl.formatMessage({ id: translationKeys.wrong_reference }),
            )
            .required(getRequiredMessage(intl, paymentMethod.labelForBookingReference))
        : yup
            .string()
            .matches(
              /^[0-9a-zA-ZÆØÅæøå\\/:\- ]*$/,
              intl.formatMessage({ id: translationKeys.wrong_reference }),
            )
            .notRequired(),
    paymentBookingReference:
      paymentMethod?.allowPaymentBookingReference && paymentMethod?.requirePaymentBookingReference
        ? yup
            .string()
            .matches(
              /^[0-9a-zA-ZÆØÅæøå\\/:\- ]*$/,
              intl.formatMessage({ id: translationKeys.wrong_reference }),
            )
            .required(getRequiredMessage(intl, paymentMethod.labelForPaymentBookingReference))
        : yup
            .string()
            .matches(
              /^[0-9a-zA-ZÆØÅæøå\\/:\- ]*$/,
              intl.formatMessage({ id: translationKeys.wrong_reference }),
            )
            .notRequired(),
    caseNumber:
      paymentMethod?.allowCaseNumber && paymentMethod?.requireCaseNumber
        ? yup.string().required(getRequiredMessage(intl, paymentMethod.labelForCaseNumber))
        : yup.string().notRequired(),
    deliveryMethod: yup.string().required(),
    coverLetters: yup
      .array()
      .when('deliveryMethod', ([deliveryMethod]: ManagerOrderDeliveryMethod[], sch) =>
        deliveryMethod === ManagerOrderDeliveryMethod.Post &&
        postageType === PostageType.CoverLetter
          ? sch.min(
              1,
              getRequiredMessage(
                intl,
                intl.formatMessage({
                  id: translationKeys.create_translation_order_postage_cover_letter_label,
                }),
              ),
            )
          : sch.notRequired(),
      ),
    sendByPostAddressIncludedInCoverLetter: yup.boolean(),
    sendByPostName: yup
      .string()
      .when('deliveryMethod', ([deliveryMethod]: ManagerOrderDeliveryMethod[], sch) =>
        deliveryMethod === ManagerOrderDeliveryMethod.Post && postageAddressRequired
          ? sch.required(
              getRequiredMessage(
                intl,
                intl.formatMessage({
                  id: translationKeys.create_translation_order_postage_add_return_address_name_label,
                }),
              ),
            )
          : sch.notRequired(),
      ),
    sendByPostEnterpriseName: yup
      .string()
      .when('deliveryMethod', ([deliveryMethod]: ManagerOrderDeliveryMethod[], sch) =>
        deliveryMethod === ManagerOrderDeliveryMethod.Post && postageAddressRequired
          ? sch.required(
              getRequiredMessage(
                intl,
                intl.formatMessage({
                  id: translationKeys.create_translation_order_postage_add_return_address_company_label,
                }),
              ),
            )
          : sch.notRequired(),
      ),
    sendByPostAddress: yup
      .string()
      .when('deliveryMethod', ([deliveryMethod]: ManagerOrderDeliveryMethod[], sch) =>
        deliveryMethod === ManagerOrderDeliveryMethod.Post && postageAddressRequired
          ? sch.required(
              getRequiredMessage(
                intl,
                intl.formatMessage({
                  id: translationKeys.create_translation_order_postage_add_return_address_address_label,
                }),
              ),
            )
          : sch.notRequired(),
      ),
    bookedSubtaskType: yup.string().required(
      getRequiredMessage(
        intl,
        intl.formatMessage({
          id: translationKeys.create_translation_order_information_type_label,
        }),
      ),
    ),
    sourceLanguageId: noSourceLanguage
      ? yup.string().notRequired()
      : yup.string().required(
          getRequiredMessage(
            intl,
            intl.formatMessage({
              id: translationKeys.create_translation_order_information_from_label,
            }),
          ),
        ),
    targetLanguageIds: yup.array().min(
      1,
      getRequiredMessage(
        intl,
        intl.formatMessage({
          id: translationKeys.create_translation_order_information_to_label,
        }),
      ),
    ),
    alternativeLanguageId: yup.string().notRequired(),
    attachments: yup.array().notRequired(),
    filePasswords: yup.object().notRequired(),
    countOfWords: yup.number().notRequired(),
    externalDeadline: yup.string().notRequired(),
    translationMethodId: yup
      .string()
      .required(
        getRequiredMessage(
          intl,
          intl.formatMessage({
            id: translationKeys.create_translation_order_information_subject_label,
          }),
        ),
      )
      .nullable(),
    otherSubject: yup
      .string()
      .when('translationMethodId', ([translationMethodId]: string[] | null[], sch) =>
        translationMethodId === null
          ? sch.required(
              getRequiredMessage(
                intl,
                intl.formatMessage({
                  id: translationKeys.create_translation_order_information_other_subject_label,
                }),
              ),
            )
          : sch.notRequired(),
      ),
    instructionsFromDemander: yup.string().notRequired(),
    ccEmails: yup.array().notRequired(),
    accessLevel: yup.string().notRequired(),
  });
}
