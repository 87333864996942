import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, TextField } from '@skiwo/components';
import classnames from 'classnames';
import translationKeys from '../../translations/translationKeys';
import styles from './AssignmentSearch.module.scss';

const AssignmentSearch = () => {
  const intl = useIntl();
  const [searchExpanded, setSearchExpanded] = useState(false);
  const [query, setQuery] = useState('');

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    const railsGodmodeLink =
      process.env.REACT_APP_API_BASE_URL + `/radmin/job_search?term=${query.trim()}`;

    window.open(railsGodmodeLink, '_blank');
  };

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.code === 'Escape') {
        setSearchExpanded(false);
      }
    };

    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  return (
    <div className={styles.assignmentSearch} data-testid="assignment-search">
      <IconButton
        onClick={() => setSearchExpanded(true)}
        icon={<FontAwesomeIcon icon={faSearch} />}
        className={classnames(styles.searchIcon, { [styles.expanded]: searchExpanded })}
      />

      <form
        onSubmit={handleSubmit}
        className={classnames(styles.searchForm, { [styles.expanded]: searchExpanded })}
      >
        <TextField
          placeholder={intl.formatMessage({
            id: translationKeys.menu_assignment_search_placeholder,
          })}
          type="text"
          icon={
            searchExpanded ? (
              <FontAwesomeIcon icon={faSearch} data-testid="expanded-search-icon" />
            ) : null
          }
          size="medium"
          name="username"
          onChange={(e) => setQuery(e.currentTarget.value)}
          value={query}
          data-testid="assignment-search-input"
        />
      </form>
    </div>
  );
};

export default AssignmentSearch;
