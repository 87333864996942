import React from 'react';
import { useIntl } from 'react-intl';
import {
  faCircle2,
  faCircle5,
  faCircleCheck,
  faCirclePause,
  faCirclePlay,
  faCircleStop,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@skiwo/components';
import circleCheckInfinite from '../../../assets/icons/circle-check-infinite.svg';
import circlePauseInfinite from '../../../assets/icons/circle-pause-infinite.svg';
import circlePlayInfinite from '../../../assets/icons/circle-play-infinite.svg';
import circleStopInfinite from '../../../assets/icons/circle-stop-infinite.svg';
import number1h from '../../../assets/icons/number1h.svg';
import number2h from '../../../assets/icons/number2h.svg';
import number10 from '../../../assets/icons/number10.svg';
import number20 from '../../../assets/icons/number20.svg';
import translationKeys from '../../../translations/translationKeys';
import { ManagerJobInviteStatus } from '../../../types';
import styles from './Hint.module.scss';

interface Props {
  inviteStatus?: ManagerJobInviteStatus;
  invitationDelay?: number;
  infiniteAutoInvite: boolean;
}

const InviteHint = (props: Props) => {
  const { inviteStatus, invitationDelay, infiniteAutoInvite } = props;
  const intl = useIntl();

  if (!invitationDelay) {
    switch (inviteStatus) {
      case ManagerJobInviteStatus.Finished:
        if (infiniteAutoInvite) {
          return (
            <Tooltip
              title={intl.formatMessage({
                id: translationKeys.jobs_page_invite_status_finished_infinite_tooltip,
              })}
            >
              <img
                data-testid="infinite-invite-finished-hint"
                src={circleCheckInfinite}
                className={styles.icon}
              />
            </Tooltip>
          );
        }

        return (
          <Tooltip
            title={intl.formatMessage({
              id: translationKeys.jobs_page_invite_status_finished_tooltip,
            })}
          >
            <FontAwesomeIcon
              icon={faCircleCheck}
              className={styles.icon}
              data-testid="invite-finished-hint"
            />
          </Tooltip>
        );
      case ManagerJobInviteStatus.Disabled:
      case ManagerJobInviteStatus.NoSuitableInterpreters:
        if (infiniteAutoInvite) {
          return (
            <Tooltip
              title={intl.formatMessage({
                id: translationKeys.jobs_page_invite_status_stopped_infinite_tooltip,
              })}
            >
              <img
                data-testid="infinite-invite-stopped-hint"
                src={circleStopInfinite}
                className={styles.icon}
              />
            </Tooltip>
          );
        }

        return (
          <Tooltip
            title={intl.formatMessage({
              id: translationKeys.jobs_page_invite_status_stopped_tooltip,
            })}
          >
            <FontAwesomeIcon
              icon={faCircleStop}
              className={styles.icon}
              data-testid="invite-stopped-hint"
            />
          </Tooltip>
        );
      case ManagerJobInviteStatus.Ongoing:
        if (infiniteAutoInvite) {
          return (
            <Tooltip
              title={intl.formatMessage({
                id: translationKeys.jobs_page_invite_status_ongoing_infinite_tooltip,
              })}
            >
              <img
                data-testid="infinite-invite-ongoing-hint"
                src={circlePlayInfinite}
                className={styles.icon}
              />
            </Tooltip>
          );
        }

        return (
          <Tooltip
            title={intl.formatMessage({
              id: translationKeys.jobs_page_invite_status_ongoing_tooltip,
            })}
          >
            <FontAwesomeIcon
              icon={faCirclePlay}
              className={styles.icon}
              data-testid="invite-ongoing-hint"
            />
          </Tooltip>
        );

      case ManagerJobInviteStatus.Paused:
        if (infiniteAutoInvite) {
          return (
            <Tooltip
              title={intl.formatMessage({
                id: translationKeys.jobs_page_invite_status_paused_infinite_tooltip,
              })}
            >
              <img
                src={circlePauseInfinite}
                data-testid="infinite-invite-paused-hint"
                className={styles.icon}
              />
            </Tooltip>
          );
        }

        return (
          <Tooltip
            title={intl.formatMessage({
              id: translationKeys.jobs_page_invite_status_paused_infinite_tooltip,
            })}
          >
            <FontAwesomeIcon
              icon={faCirclePause}
              className={styles.icon}
              data-testid="invite-paused-hint"
            />
          </Tooltip>
        );
    }
  }

  let inviteDelay = (
    <FontAwesomeIcon data-testid="invite-delay-2-hint" icon={faCircle2} className={styles.icon} />
  );

  if (invitationDelay === 2) {
    inviteDelay = (
      <FontAwesomeIcon data-testid="invite-delay-2-hint" icon={faCircle2} className={styles.icon} />
    );
  }

  if (invitationDelay === 5) {
    inviteDelay = (
      <FontAwesomeIcon data-testid="invite-delay-5-hint" icon={faCircle5} className={styles.icon} />
    );
  }

  if (invitationDelay === 10) {
    inviteDelay = <img data-testid="invite-delay-10-hint" src={number10} className={styles.icon} />;
  }

  if (invitationDelay === 20) {
    inviteDelay = <img data-testid="invite-delay-20-hint" src={number20} className={styles.icon} />;
  }

  if (invitationDelay === 60) {
    inviteDelay = <img data-testid="invite-delay-60-hint" src={number1h} className={styles.icon} />;
  }

  if (invitationDelay === 120) {
    inviteDelay = (
      <img data-testid="invite-delay-120-hint" src={number2h} className={styles.icon} />
    );
  }

  return (
    <Tooltip
      title={intl.formatMessage(
        {
          id: translationKeys.jobs_page_invite_status_ongoing_interval_tooltip,
        },
        {
          interval: invitationDelay,
        },
      )}
    >
      {inviteDelay}
    </Tooltip>
  );
};

export default InviteHint;
