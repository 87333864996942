import React from 'react';
import { Skeleton } from '@skiwo/components';
import styles from './EnterpriseItemSkeleton.module.scss';

const EnterpriseItemSkeleton = () => {
  return (
    <tr className={styles.skeleton} data-testid="customer-item-skeleton">
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton />
      </td>
    </tr>
  );
};

export default EnterpriseItemSkeleton;
