import { IntlShape } from 'react-intl';
import translationKeys from '../../../../../translations/translationKeys';

export const getBooleanTranslation = ({ value, intl }: { value?: string; intl: IntlShape }) => {
  if (value === 'true') {
    return intl.formatMessage({
      id: translationKeys.translation_order_system_logs_on_value,
    });
  }
  if (value === 'false') {
    return intl.formatMessage({
      id: translationKeys.translation_order_system_logs_off_value,
    });
  }
  return '-';
};
