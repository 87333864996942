import React from 'react';
import { Table as B_Table, TableProps } from 'react-bootstrap';
import styles from './Table.module.scss';

interface Props {
  children: React.ReactNode;
}

const Table = (props: Props & TableProps) => {
  const { children, ...tableProps } = props;

  return (
    <B_Table {...tableProps} className={styles.table}>
      {children}
    </B_Table>
  );
};

export default Table;
