enum CustomersFilterField {
  Id = 'ID',
  Type = 'TYPE',
  Status = 'STATUS',
  NameEmailPhone = 'NAME_EMAIL_PHONE',
  OrgNumberName = 'ORG_NUMBER_NAME',
  Departments = 'DEPARTMENTS',
}

export default CustomersFilterField;
