import { IntlShape } from 'react-intl';
import translationKeys from '../../translations/translationKeys';
import { ManagerOrderAccessLevel } from '../../types';

const getAccessLevelName = (sourceType: ManagerOrderAccessLevel, intl: IntlShape): string => {
  if (sourceType === ManagerOrderAccessLevel.Owner) {
    return intl.formatMessage({
      id: translationKeys.edit_order_drawer_access_level_owner_option,
    });
  }

  if (sourceType === ManagerOrderAccessLevel.Department) {
    return intl.formatMessage({
      id: translationKeys.edit_order_drawer_access_level_department_option,
    });
  }

  if (sourceType === ManagerOrderAccessLevel.Enterprise) {
    return intl.formatMessage({
      id: translationKeys.edit_order_drawer_access_level_enterprise_option,
    });
  }

  return '-';
};

export default getAccessLevelName;
