import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  faCheck,
  faCircleCheck,
  faEllipsis,
  faFileContract,
  faLanguage,
  faTrash,
  faUser,
  faXmark,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Button, Dropdown, IconButton, Tag } from '@skiwo/components';
import classnames from 'classnames';
import { differenceInDays, format } from 'date-fns';
import translationKeys from '../../../translations/translationKeys';
import { ManagerOrderSubtaskStatus } from '../../../types';
import { ManagerOrderSubtaskDeadlineRequestStatus } from '../../../types/ManagerOrderSubtaskDetail';
import { ManagerRejectedSellerReason } from '../../../types/ManagerRejectedSeller';
import getSubtaskStatusColour from '../../utils/getSubtaskStatusColour';
import getSubtaskStatusName from '../../utils/getSubtaskStatusName';
import styles from './SellerItem.module.scss';

interface Props {
  avatarUrl?: string;
  name: string;
  qualificationName?: string | null;
  assignedSubtasksCount?: number;
  isProactive?: boolean;
  hasContract?: boolean;
  status?: ManagerOrderSubtaskStatus;
  rejectionReason?: ManagerRejectedSellerReason | null;
  deadlineChangeRequestedAt?: string;
  deadlineChangeRequestStatus?: ManagerOrderSubtaskDeadlineRequestStatus;
  originalInternalDeadline?: string;
  requestedInternalDeadline?: string;
  changeDeadlineRequestStatusLoading?: boolean;
  variant?: 'primary' | 'selected';
  onClick?: () => void;
  onDelete?: () => void;
  changeDeadlineRequestStatus?: (status: ManagerOrderSubtaskDeadlineRequestStatus) => void;
}

const SellerItem = (props: Props) => {
  const {
    avatarUrl,
    name,
    qualificationName,
    assignedSubtasksCount,
    isProactive,
    hasContract,
    status,
    onDelete,
    onClick,
    rejectionReason,
    deadlineChangeRequestedAt,
    originalInternalDeadline,
    requestedInternalDeadline,
    deadlineChangeRequestStatus,
    changeDeadlineRequestStatus,
    changeDeadlineRequestStatusLoading,
    variant = 'primary',
  } = props;
  const intl = useIntl();

  const getReasonMessage = (reason: ManagerRejectedSellerReason) => {
    switch (reason) {
      case ManagerRejectedSellerReason.NotAvailable:
        return (
          <FormattedMessage
            id={
              translationKeys.translation_subtask_details_assign_translator_drawer_reason_not_available
            }
          />
        );
      case ManagerRejectedSellerReason.LanguagePair:
        return (
          <FormattedMessage
            id={
              translationKeys.translation_subtask_details_assign_translator_drawer_reason_language_pair
            }
          />
        );
      case ManagerRejectedSellerReason.Deadline:
        return (
          <FormattedMessage
            id={
              translationKeys.translation_subtask_details_assign_translator_drawer_reason_deadline
            }
          />
        );

      default:
        return '-';
    }
  };

  return (
    <div
      className={classnames(styles.sellerItem, styles[variant], {
        [styles.clickable]: !!onClick,
      })}
      onClick={onClick}
    >
      <div className={classnames(styles.topSection)}>
        <div data-testid="detail-item-preview">
          <Avatar size="medium" altText="" url={avatarUrl} />
        </div>
        <div className={styles.titleContainer}>
          <span data-testid="detail-item-title" className={styles.title}>
            <span>{name}</span>
          </span>
          <span className={styles.subtitle} data-testid="detail-item-subtitle">
            {qualificationName && (
              <span className={styles.subtitleItem}>
                <FontAwesomeIcon icon={faUser} />
                {qualificationName}
              </span>
            )}
            <span className={styles.subtitleItem}>
              <FontAwesomeIcon icon={faLanguage} />
              {assignedSubtasksCount}{' '}
              <FormattedMessage
                id={
                  translationKeys.translation_subtask_details_assign_translator_drawer_subtasks_label
                }
              />
            </span>
            {isProactive && (
              <span className={styles.subtitleItem}>
                <FontAwesomeIcon icon={faCircleCheck} />
                <FormattedMessage
                  id={
                    translationKeys.translation_subtask_details_assign_translator_drawer_proactive_label
                  }
                />
              </span>
            )}
            {hasContract && (
              <span className={styles.subtitleItem}>
                <FontAwesomeIcon icon={faFileContract} />
                <FormattedMessage
                  id={
                    translationKeys.translation_subtask_details_assign_translator_drawer_has_contract_label
                  }
                />
              </span>
            )}
          </span>
        </div>

        <div className={styles.rightSection}>
          {status && (
            <div data-testid="detail-item-auxiliary-view">
              <Tag color={getSubtaskStatusColour(status)}>{getSubtaskStatusName(status, intl)}</Tag>
            </div>
          )}

          {onDelete && (
            <Dropdown
              data-testid="customer-action-more"
              toggle={
                <IconButton
                  size="medium"
                  data-testid="detail-item-actions-button"
                  icon={<FontAwesomeIcon icon={faEllipsis} />}
                />
              }
              onSelect={(selection) => selection === 'remove-from-rejected' && onDelete()}
              items={[
                {
                  id: 'remove-from-rejected',
                  text: intl.formatMessage({
                    id: translationKeys.translation_subtask_details_assign_translator_drawer_remove_from_rejected_button,
                  }),
                  icon: faTrash,
                  variant: 'critical',
                },
              ]}
            />
          )}

          {onClick && (
            <div
              className={classnames(styles.selectedIndicator, {
                [styles.selectedCheckBox]: variant === 'selected',
              })}
            >
              <FontAwesomeIcon icon={faCheck} className={styles.selectedIcon} />
            </div>
          )}
        </div>
      </div>

      {(rejectionReason || (originalInternalDeadline && requestedInternalDeadline)) && (
        <div className={classnames(styles.bottomSection)}>
          {rejectionReason && (
            <span className={styles.reasonLabel}>
              <FormattedMessage
                id={
                  translationKeys.translation_subtask_details_assign_translator_drawer_reason_label
                }
                values={{
                  reason: (
                    <span className={styles.reason}>{getReasonMessage(rejectionReason)}</span>
                  ),
                }}
              />
            </span>
          )}
          {originalInternalDeadline && requestedInternalDeadline && (
            <>
              <div className={styles.deadlinesWrapper}>
                <div className={styles.deadline}>
                  <span className={styles.deadlineTitle}>
                    <FormattedMessage
                      id={
                        translationKeys.translation_subtask_details_assign_translator_drawer_original_internal_deadline
                      }
                    />
                  </span>
                  <span className={styles.deadlineDate}>
                    {format(new Date(originalInternalDeadline), 'dd.MM.yyyy')}
                  </span>
                </div>
                <div className={styles.deadline}>
                  <span className={styles.deadlineTitle}>
                    <FormattedMessage
                      id={
                        translationKeys.translation_subtask_details_assign_translator_drawer_requested_internal_deadline
                      }
                    />
                  </span>
                  <span className={styles.deadlineDate}>
                    {format(new Date(requestedInternalDeadline), 'dd.MM.yyyy')}{' '}
                    <FormattedMessage
                      id={
                        translationKeys.translation_subtask_details_assign_translator_drawer_days_difference
                      }
                      values={{
                        days_count: differenceInDays(
                          new Date(requestedInternalDeadline),
                          new Date(originalInternalDeadline),
                        ),
                      }}
                    />
                  </span>
                </div>
              </div>
              <div className={styles.bottomActions}>
                {deadlineChangeRequestStatus === ManagerOrderSubtaskDeadlineRequestStatus.Pending &&
                  changeDeadlineRequestStatus &&
                  status === ManagerOrderSubtaskStatus.Invited && (
                    <div className={styles.approveActions}>
                      <Button
                        icon={<FontAwesomeIcon icon={faCheck} />}
                        isLoading={changeDeadlineRequestStatusLoading}
                        onClick={() =>
                          changeDeadlineRequestStatus(
                            ManagerOrderSubtaskDeadlineRequestStatus.Approved,
                          )
                        }
                      >
                        <FormattedMessage
                          id={
                            translationKeys.translation_subtask_details_assign_translator_drawer_accept_deadline_button
                          }
                        />
                      </Button>
                      <Button
                        variant="secondary"
                        icon={<FontAwesomeIcon icon={faXmark} />}
                        isLoading={changeDeadlineRequestStatusLoading}
                        onClick={() =>
                          changeDeadlineRequestStatus(
                            ManagerOrderSubtaskDeadlineRequestStatus.Rejected,
                          )
                        }
                      >
                        <FormattedMessage
                          id={
                            translationKeys.translation_subtask_details_assign_translator_drawer_reject_deadline_button
                          }
                        />
                      </Button>
                    </div>
                  )}

                {deadlineChangeRequestedAt && (
                  <span className={styles.requested}>
                    <FormattedMessage
                      id={
                        translationKeys.translation_subtask_details_assign_translator_drawer_requested_label
                      }
                      values={{
                        requested_at: format(
                          new Date(deadlineChangeRequestedAt),
                          'dd.MM.yyyy HH:mm',
                        ),
                      }}
                    />
                  </span>
                )}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default SellerItem;
