import React, { useState } from 'react';
import { faCircleCheck, faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { CustomerPaymentMethod } from '../../types';

export default function usePaymentRegexHint(selectedPaymentMethod?: CustomerPaymentMethod) {
  const [regexHintIcon, setRegexHintIcon] = useState({
    bookingRefIcon: faInfoCircle,
    paymentBookingRefIcon: faInfoCircle,
  });

  const matchRegex = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field?: string,
  ) => {
    if (!selectedPaymentMethod) return;
    const innerField = e.target.name ?? field;

    const pattern =
      innerField === 'bookingReference'
        ? selectedPaymentMethod.bookingReferenceRegex
        : selectedPaymentMethod.paymentBookingReferenceRegex;

    if (!pattern) return;

    const regex = new RegExp(pattern);
    const isValidValue = regex.test(e.target.value);

    const iconName = innerField === 'bookingReference' ? 'bookingRefIcon' : 'paymentBookingRefIcon';
    const iconValue = isValidValue ? faCircleCheck : faInfoCircle;

    setRegexHintIcon((prevIcons) => ({
      ...prevIcons,
      [iconName]: iconValue,
    }));
  };

  return { regexHintIcon, matchRegex };
}
