import React from 'react';
import { MegaMenuSection } from '../MegaMenu';
import MenuItem from '../MenuItem/MenuItem';
import styles from './MenuSection.module.scss';

interface Props {
  section: MegaMenuSection;
  onClick?: (link: string) => void;
}

function MenuSection(props: Props) {
  const { section, onClick } = props;

  return (
    <div data-testid="menu-section" className={styles.sectionContainer}>
      <div className={styles.sectionName}>
        <span data-testid="menu-section-name">{section.name}</span>
      </div>

      <div className={styles.itemsContainer}>
        {section.items.map((item) => (
          <MenuItem item={item} key={item.name} onClick={onClick} />
        ))}
      </div>
    </div>
  );
}

export default MenuSection;
