import React, { PropsWithChildren } from 'react';
import styles from './SettingsItem.module.scss';

interface SettingsItemProps {
  title: string;
  description?: string;
  action?: React.ReactNode;
}

const SettingsItem = ({
  title,
  description,
  action,
  children,
}: PropsWithChildren<SettingsItemProps>) => {
  return (
    <div className={styles.settingsItem}>
      <div className={styles.headerWrapper}>
        <div className={styles.header}>
          <div className={styles.iconTitleWrapper}>
            <span className={styles.title}>{title}</span>
          </div>
          <div className={styles.actions}>{action}</div>
        </div>
        <span className={styles.description}>{description}</span>
      </div>
      {children}
    </div>
  );
};

export default SettingsItem;
