import React from 'react';
import { createIntl } from 'react-intl';
import { getLanguage } from '@skiwo/utils';
import Button from '../Button/Button';
import languages from '../translations/languages';
import translationKeys from '../translations/translationKeys';
import styles from './EmptyState.module.scss';

interface Props {
  image?: string;
  text: React.ReactNode;
  description: React.ReactNode;
  refreshable?: boolean;
  className?: string;
}

const EmptyState = (props: Props) => {
  const { text, description, image, refreshable = false } = props;
  const userLanguage = getLanguage();
  const intl = createIntl({
    locale: userLanguage,
    messages: languages[userLanguage],
  });

  const handleOnClick = () => {
    location.reload();
  };

  return (
    <div className={styles.emptyTable} data-testid="empty-state">
      <img src={image} data-testid="empty-table-image" />
      <span data-testid="empty-table-text">{text}</span>
      <span data-testid="empty-table-description" className={styles.description}>
        {description}
      </span>
      {refreshable && (
        <Button
          onClick={handleOnClick}
          size="large"
          variant="secondary"
          data-testid="empty-table-error-button"
        >
          {intl.formatMessage({ id: translationKeys.empty_state_refresh_button })}
        </Button>
      )}
    </div>
  );
};

export default EmptyState;
