export enum ManagerRole {
  Translation = 'translation',
  Interpretation = 'interpretation',
  Admin = 'admin',
  Finance = 'finance',
  Talent = 'talent',
  Compliance = 'compliance',
}

export type Manager = {
  uid: string;
  name: string;
  email: string;
  avatarPath: string;
};

export type ManagersResponse = {
  managers: Manager[];
};
