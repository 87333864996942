import { IntlShape } from 'react-intl';
import translationKeys from '../../translations/translationKeys';
import { InterpretationSkillsStatus } from '../../types';

const getStatusName = (status: InterpretationSkillsStatus, intl: IntlShape): string => {
  if (status === InterpretationSkillsStatus.Approved) {
    return intl.formatMessage({
      id: translationKeys.interpretation_skills_page_approved_status,
    });
  }

  if (status === InterpretationSkillsStatus.Declined) {
    return intl.formatMessage({
      id: translationKeys.interpretation_skills_page_declined_status,
    });
  }

  if (status === InterpretationSkillsStatus.Learning) {
    return intl.formatMessage({
      id: translationKeys.interpretation_skills_page_learning_status,
    });
  }

  if (status === InterpretationSkillsStatus.Pending) {
    return intl.formatMessage({
      id: translationKeys.interpretation_skills_page_pending_status,
    });
  }

  return '-';
};

export default getStatusName;
