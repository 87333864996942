import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@skiwo/components';
import { stripObject } from '@skiwo/utils';
import { ApiError } from '../Api';
import { useApi } from '../providers/ApiProvider';
import translationKeys from '../translations/translationKeys';
import { ManagerEnterprise } from '../types';
import EnterprisesTable from './EnterprisesTable/EnterprisesTable';
import getEnterpriseFilters from './utils/getEnterpriseFilters';
import EnterprisesFilterField from './EnterprisesFilterField';
import styles from './Enterprises.module.scss';

const Enterprises = () => {
  const [enterprises, setEnterprises] = useState<ManagerEnterprise[]>([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [enterprisesLoading, setEnterprisesLoading] = useState(false);
  const [enterprisesError, setEnterprisesError] = useState<ApiError | null>(null);
  const [filters, setFilters] = useState<Record<string, string>>({});
  const api = useApi();
  const handleFilterChange = (field: EnterprisesFilterField, value: string) => {
    setFilters(getEnterpriseFilters(filters, field, value));
  };

  const fetchEnterprises = async (page: number, clearCache = false) => {
    const { data, error } = await api.getManagerEnterprises(
      { page, items: 20, 's[s]': 'id desc', ...stripObject(filters) },
      setEnterprisesLoading,
    );

    setEnterprisesError(error);

    if (data?.enterprises) {
      let allEnterprises = enterprises;

      if (!clearCache) {
        for (const newEnterprise of data.enterprises) {
          const newEnterpriseIndex = allEnterprises.findIndex(
            (enterprise) => enterprise.id === newEnterprise.id,
          );

          if (newEnterpriseIndex === -1) {
            allEnterprises.push(newEnterprise);
          } else {
            allEnterprises[newEnterpriseIndex] = newEnterprise;
          }
        }
      } else {
        allEnterprises = data.enterprises;
      }

      setEnterprises(allEnterprises);
      setPage(data.page);
      setPages(data.pages);
    }
  };

  const handleLoadMore = () => {
    fetchEnterprises(page + 1);
  };

  const handleReloadData = (hardReload = false) => {
    fetchEnterprises(page, hardReload);
  };

  useEffect(() => {
    if (Object.keys(filters).length === 0) {
      return;
    }

    setEnterprises([]);
    fetchEnterprises(1, true);
  }, [filters]);

  useEffect(() => {
    fetchEnterprises(1);
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 data-testid="enterprises-header">
          <FormattedMessage id={translationKeys.enterprises_page_title} />
        </h1>
      </div>

      <div className={styles.tableContainer}>
        <EnterprisesTable
          enterprises={enterprises}
          error={enterprisesError}
          isLoading={enterprisesLoading}
          onFilterChange={handleFilterChange}
          onCompleteAction={handleReloadData}
        />
      </div>

      {!enterprisesLoading && enterprises && page < pages && (
        <div className={styles.loadMoreButton}>
          <Button
            variant="secondary"
            size="large"
            onClick={handleLoadMore}
            data-testid="load-more-button"
          >
            <FormattedMessage id={translationKeys.enterprises_page_load_more_button} />
          </Button>
        </div>
      )}
    </div>
  );
};

export default Enterprises;
