import { SearchDropdownMenuOption } from '@skiwo/components';
import { CustomerPaymentMethod, EnterpriseDepartment, ManagerCustomer } from '../types';
import { ManagerJobBookingReferenceFrom } from '../types/ManagerJob';

interface DefaultReferencesProps {
  departmentList?: EnterpriseDepartment[];
  selectedDepartment?: SearchDropdownMenuOption;
  customer?: ManagerCustomer;
  paymentMethod?: CustomerPaymentMethod;
}

interface DefaultReferencesReturn {
  defaultBookingReference: string;
  defaultBookingReferenceFrom?: ManagerJobBookingReferenceFrom;
  defaultPaymentBookingReference: string;
  defaultPaymentBookingReferenceFrom?: ManagerJobBookingReferenceFrom;
}

export default function getDefaultReferences({
  departmentList,
  selectedDepartment,
  customer,
  paymentMethod,
}: DefaultReferencesProps): DefaultReferencesReturn {
  const department = departmentList?.find((item) => item.id === selectedDepartment?.id);
  let defaultBookingReference = '';
  let defaultBookingReferenceFrom;

  if (paymentMethod?.allowBookingReference) {
    if (customer?.defaultBookingReference) {
      defaultBookingReference = customer.defaultBookingReference;
      defaultBookingReferenceFrom = ManagerJobBookingReferenceFrom.Owner;
    } else if (department?.defaultBookingReference) {
      defaultBookingReference = department.defaultBookingReference;
      defaultBookingReferenceFrom = ManagerJobBookingReferenceFrom.Department;
    } else if (customer?.enterprise?.defaultBookingReference) {
      defaultBookingReference = customer.enterprise.defaultBookingReference;
      defaultBookingReferenceFrom = ManagerJobBookingReferenceFrom.Enterprise;
    }
  }

  let defaultPaymentBookingReference = '';
  let defaultPaymentBookingReferenceFrom;

  if (paymentMethod?.allowPaymentBookingReference) {
    if (customer?.defaultPaymentBookingReference) {
      defaultPaymentBookingReference = customer.defaultPaymentBookingReference;
      defaultPaymentBookingReferenceFrom = ManagerJobBookingReferenceFrom.Owner;
    } else if (department?.defaultPaymentBookingReference) {
      defaultPaymentBookingReference = department.defaultPaymentBookingReference;
      defaultPaymentBookingReferenceFrom = ManagerJobBookingReferenceFrom.Department;
    } else if (customer?.enterprise?.defaultPaymentBookingReference) {
      defaultPaymentBookingReference = customer.enterprise.defaultPaymentBookingReference;
      defaultPaymentBookingReferenceFrom = ManagerJobBookingReferenceFrom.Enterprise;
    }
  }

  return {
    defaultBookingReference,
    defaultBookingReferenceFrom,
    defaultPaymentBookingReference,
    defaultPaymentBookingReferenceFrom,
  };
}
