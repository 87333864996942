import { IntlShape } from 'react-intl';
import translationKeys from '../../translations/translationKeys';
import { ManagerOrderAccessLevel } from '../../types/ManagerOrder';

const getOrderAccessLevelName = (status: ManagerOrderAccessLevel, intl: IntlShape): string => {
  if (status === ManagerOrderAccessLevel.Owner) {
    return intl.formatMessage({
      id: translationKeys.translation_order_access_level_owner,
    });
  }

  if (status === ManagerOrderAccessLevel.Department) {
    return intl.formatMessage({
      id: translationKeys.translation_order_access_level_department,
    });
  }

  if (status === ManagerOrderAccessLevel.Enterprise) {
    return intl.formatMessage({
      id: translationKeys.translation_order_access_level_enterprise,
    });
  }

  return '-';
};

export default getOrderAccessLevelName;
