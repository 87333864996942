import {
  EnterpriseDepartmentsResponse,
  EnterpriseResponse,
  EnterpriseSearchResultResponse,
  ManagerEnterprisesResponse,
} from '../../types';
import CreateDepartmentResponse from '../../types/CreateDepartmentResponse';
import GetEnterpriseJobCategoriesResponse from '../../types/EnterpriseJobCategories';
import { GetEnterpriseAddressesResponse } from '../../types/EnterpriseSearchResult';
import fetchApi, { UrlParams } from '../fetchApi';

export default {
  searchEnterprises: (urlParams: UrlParams, loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi<EnterpriseSearchResultResponse>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/enterprises/search`,
      urlParams,
      loadingCallback,
    });
  },
  publicSearchEnterprises: (loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi<EnterpriseSearchResultResponse>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/enterprises/public_search`,
      loadingCallback,
    });
  },
  getManagerEnterprises: (urlParams: UrlParams, loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi<ManagerEnterprisesResponse>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/enterprises/managers_list`,
      urlParams,
      loadingCallback,
    });
  },
  getDepartments: (
    enterpriseId: string,
    verbose?: boolean,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi<EnterpriseDepartmentsResponse>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/enterprises/${enterpriseId}/departments`,
      urlParams: { verbose },
      loadingCallback,
    });
  },
  updateCustomer: (
    customerEnterpriseId: string,
    customerUid: string,
    body: any,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi({
      method: 'PUT',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/enterprises/${customerEnterpriseId}/members/${customerUid}`,
      body,
      loadingCallback,
    });
  },
  moveCustomer: (
    enterpriseId: string,
    memberId: string,
    body: any,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi({
      method: 'POST',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/enterprises/${enterpriseId}/members/${memberId}/move`,
      body,
      loadingCallback,
    });
  },
  getEnterpriseDetails: (enterpriseId: string, loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi<EnterpriseResponse>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/enterprises/${enterpriseId}`,
      loadingCallback,
    });
  },
  getEnterpriseDepartments: (
    enterpriseId: string,
    verbose?: boolean,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi<EnterpriseDepartmentsResponse>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/enterprises/${enterpriseId}/departments`,
      urlParams: { verbose },
      loadingCallback,
    });
  },
  createEnterpriseDepartment: (
    enterpriseId: string,
    body: any,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi<CreateDepartmentResponse>({
      method: 'POST',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/enterprises/${enterpriseId}/departments`,
      body,
      loadingCallback,
    });
  },
  getEnterpriseAddresses: (
    enterpriseId: string,
    departmentId?: string,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi<GetEnterpriseAddressesResponse>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/enterprises/${enterpriseId}/contact_addresses`,
      urlParams: { departmentId },
      loadingCallback,
    });
  },
  getEnterpriseMembers: (enterpriseId: string, loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi<any>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/enterprises/${enterpriseId}/members`,
      loadingCallback,
    });
  },
  getEnterpriseCategories: (
    enterpriseId: string,
    department_id: string,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi<GetEnterpriseJobCategoriesResponse>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/enterprises/categories/logout_index?enterprise_id=${enterpriseId}&department_id=${department_id}`,
      loadingCallback,
    });
  },
};
