import { IntlShape } from 'react-intl';
import translationKeys from '../../translations/translationKeys';
import { ManagerOrderSourceType } from '../../types';

const getSourceTypeName = (sourceType: ManagerOrderSourceType, intl: IntlShape): string => {
  if (sourceType === ManagerOrderSourceType.Platform) {
    return intl.formatMessage({
      id: translationKeys.edit_order_drawer_source_platform_option,
    });
  }

  if (sourceType === ManagerOrderSourceType.ProjectEmail) {
    return intl.formatMessage({
      id: translationKeys.edit_order_drawer_source_project_email_option,
    });
  }

  if (sourceType === ManagerOrderSourceType.OversettelseEmail) {
    return intl.formatMessage({
      id: translationKeys.edit_order_drawer_source_translators_email_option,
    });
  }
  if (sourceType === ManagerOrderSourceType.PostEmail) {
    return intl.formatMessage({
      id: translationKeys.edit_order_drawer_source_post_email_option,
    });
  }
  if (sourceType === ManagerOrderSourceType.Post) {
    return intl.formatMessage({
      id: translationKeys.edit_order_drawer_source_post_option,
    });
  }
  if (sourceType === ManagerOrderSourceType.PersonalEmail) {
    return intl.formatMessage({
      id: translationKeys.edit_order_drawer_source_personal_email_option,
    });
  }

  return intl.formatMessage({
    id: translationKeys.edit_order_drawer_source_logout_option,
  });
};

export default getSourceTypeName;
