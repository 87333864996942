import { ManagerJobSessionType } from './ManagerJob';

export enum InterpreterAvailabilityEventType {
  Default = 'default',
  BlockTime = 'block_time',
  FreeTime = 'free_time',
  StandByTime = 'stand_by_time',
}

export enum InterpreterAvailabilityEventSessionType {
  OnSite = 'on_site',
  Remote = 'remote',
}

export enum InterpreterAvailabilityEventTravelType {
  Default = 'default',
  PublicTransport = 'public_transport',
  Car = 'car',
  Flight = 'flight',
}

export enum InterpreterAvailabilityTravelEventType {
  TravelFromTime = 'travel_from_time',
  TravelToTime = 'travel_to_time',
}

interface Job {
  id: number;
  subject: string;
  startTime: Date;
  finishTime: Date;
  deadline: Date;
  languageFromId: number;
  languageToId: number;
  alternateLanguageToId: number;
  dialect: string;
  sessionType: ManagerJobSessionType;
  customerName: string;
  seriesOrderId: number;
  seriesSequenceNumber: number;
  status: string;
  videoPlatformName: string;
  categoryName: string;
  address: string;
}

interface Event {
  id: number;
  startTime: Date;
  finishTime: Date;
  title: string;
  description: string;
  address: string;
  eventType: InterpreterAvailabilityEventType;
  sessionType: InterpreterAvailabilityEventSessionType;
  travelType: InterpreterAvailabilityEventTravelType;
  allDay: boolean;
  distance: number;
}

interface OffTime {
  id: number;
  startTime: Date;
  finishTime: Date;
}

interface TravelTime {
  startTime: Date;
  finishTime: Date;
  eventType: InterpreterAvailabilityTravelEventType;
}

export interface InterpreterAvailability {
  jobs: Job[];
  events: Event[];
  offTimes: OffTime[];
  travelTimes: TravelTime[];
}
