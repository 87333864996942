import { ManagerOrderSubtaskStatus } from './ManagerOrderSubtaskDetail';

export enum ManagerRejectionType {
  DeadlineChangeRequest = 'deadline_change_request',
  RejectionLog = 'rejection_log',
}

export enum ManagerRejectedSellerReason {
  NotAvailable = 'not_available',
  Deadline = 'deadline',
  LanguagePair = 'language_pair',
}

export type ManagerRejectedSellerResponse = {
  rejections: ManagerRejectedSeller[];
};

export type ManagerRejectedSeller = {
  id: number;
  type: ManagerRejectionType;
  message?: string | null;
  reason?: ManagerRejectedSellerReason | null;
  status: ManagerOrderSubtaskStatus;
  seller: {
    id: number;
    isProactive: boolean;
    assignedSubtasksCount: number;
    person: {
      uid: string;
      name: string;
      avatar: string;
      email: string;
      phone: string;
      phoneCode?: string | null;
      phoneNumber?: string | null;
    };
    suitableSkill?: {
      activeFromYear?: string | null;
      approvedAt?: Date;
      contractMaxAutoAssignedSubtasksNumber?: string;
      contractMinDeadlineDaysNumber?: string;
      createdAt: Date;
      declinedAt?: Date;
      hasContract: boolean;
      languageFromCode: string;
      languageFromId: string;
      languageToCode: string;
      languageToId: string;
      nativeFrom: boolean;
      nativeTo: boolean;
      qualification: {
        name?: string | null;
        priority: number;
        shortName?: string;
      };
    } | null;
  };
  createdAt: string;
  originalInternalDeadline?: string;
  requestedInternalDeadline?: string;
};
