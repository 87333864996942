import {
  JobCategoriesResponse,
  JobNoteResponse,
  ManagerJobsResponse,
  ManagerJobStats,
} from '../../types';
import { ManagerJobDetails,ManagerJobResponse } from '../../types/ManagerJob';
import fetchApi, { UrlParams } from '../fetchApi';

export default {
  createJob: (body: any, loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi<ManagerJobResponse>({
      method: 'POST',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs`,
      body,
      loadingCallback,
    });
  },
  updateJob: (id: number, body: any, loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi<ManagerJobResponse>({
      method: 'PUT',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs/${id}`,
      body,
      loadingCallback,
    });
  },
  getJobs: (urlParams: UrlParams, loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi<ManagerJobsResponse>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/dashboards/manager_jobs`,
      urlParams,
      loadingCallback,
    });
  },
  getJob: (id: string, loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi<ManagerJobDetails>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs/${id}`,
      loadingCallback,
    });
  },
  getJobsStats: (loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi<ManagerJobStats>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/dashboards/manager_job_stats`,
      loadingCallback,
    });
  },
  getCategories: (loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi<JobCategoriesResponse>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/job_categories`,
      loadingCallback,
    });
  },
  getJobNotes: (jobId: string, loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi<JobNoteResponse>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs/${jobId}/manager_chat/messages`,
      loadingCallback,
    });
  },
  postJobNote: (jobId: string, note: string, loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi({
      method: 'POST',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs/${jobId}/manager_chat/messages`,
      body: { message: { text: note } },
      loadingCallback,
    });
  },
  updateJobNote: (
    jobId: string,
    noteId: string,
    note: string,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi({
      method: 'PATCH',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs/${jobId}/manager_chat/messages/${noteId}`,
      body: { message: { text: note } },
      loadingCallback,
    });
  },
  deleteJobNote: (
    jobId: string,
    noteId: string,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi({
      method: 'DELETE',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs/${jobId}/manager_chat/messages/${noteId}`,
      loadingCallback,
    });
  },
};
