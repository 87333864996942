import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { EmptyState, SearchDropdown, Table, TextField } from '@skiwo/components';
import { ApiError } from '../../Api';
import errorStateIllustration from '../../assets/empty-table-error.svg';
import useDebounce from '../../hooks/useDebounce';
import translationKeys from '../../translations/translationKeys';
import { ManagerCustomer, ManagerCustomerType } from '../../types';
import { ManagerCustomerAccountStatus } from '../../types/ManagerCustomer';
import CustomerItem from '../CustomerItem/CustomerItem';
import CustomerItemSkeleton from '../CustomerItemSkeleton/CustomerItemSkeleton';
import CustomersFilterField from '../CustomersFilterField';
import getAccountStatusName from '../utils/getAccountStatusName';
import getTypeName from '../utils/getTypeName';
import styles from './CustomersTable.module.scss';

interface Props {
  customers: ManagerCustomer[];
  isLoading: boolean;
  error: ApiError | null;
  onFilterChange: (field: CustomersFilterField, value: string) => void;
  onCompleteAction: (hardReload: boolean) => void;
}

const CustomersTable = (props: Props) => {
  const { customers, isLoading, error, onFilterChange, onCompleteAction } = props;
  const intl = useIntl();

  const accountStatusValues: Record<string, string> = {
    [ManagerCustomerAccountStatus.Active]: '0',
    [ManagerCustomerAccountStatus.Paused]: '1',
    [ManagerCustomerAccountStatus.Blocked]: '2',
    [ManagerCustomerAccountStatus.Retired]: '3',
    [ManagerCustomerAccountStatus.Banned]: '4',
    [ManagerCustomerAccountStatus.Deleted]: '5',
    [ManagerCustomerAccountStatus.Deceased]: '6',
    [ManagerCustomerAccountStatus.Inactive]: '7',
  };

  const debounceFilterChange = useDebounce(300);

  const handleFilterChange = (field: CustomersFilterField, value: string) => {
    debounceFilterChange(() => {
      onFilterChange(field, value);
    });
  };

  const handleCompleteAction = (hardReload = false) => {
    onCompleteAction(hardReload);
  };

  const typeOptions =
    Object.values(ManagerCustomerType).map((type, index) => {
      return { id: index, label: getTypeName(type, intl), key: type };
    }) || [];

  const accountStatusOptions =
    Object.values(ManagerCustomerAccountStatus).map((status, index) => {
      return { id: index, label: getAccountStatusName(status, intl), key: status };
    }) || [];

  if (error) {
    return (
      <div className={styles.emptyStateContainer}>
        <EmptyState
          image={errorStateIllustration}
          text={intl.formatMessage({
            id: translationKeys.customers_page_error_state_title,
          })}
          description={intl.formatMessage({
            id: translationKeys.customers_page_error_state_subtitle,
          })}
          refreshable
        />
      </div>
    );
  }

  return (
    <>
      <Table striped data-testid="customers-table">
        <thead className={styles.tableHeader}>
          <tr className={styles.filters}>
            <th className={styles.idFilter}>
              <TextField
                placeholder={intl.formatMessage({
                  id: translationKeys.customers_page_id_filter_placeholder,
                })}
                data-testid="id-filter"
                onChange={(e) => handleFilterChange(CustomersFilterField.Id, e.currentTarget.value)}
              />
            </th>
            <th className={styles.typeFilter} data-testid="type-filter">
              <SearchDropdown
                options={typeOptions}
                placeholder={intl.formatMessage({
                  id: translationKeys.customers_page_type_filter_placeholder,
                })}
                onChange={(type) => {
                  if (type && type.length > 0 && type[0].key) {
                    handleFilterChange(CustomersFilterField.Type, type[0].key.toString());
                  } else {
                    handleFilterChange(CustomersFilterField.Type, '');
                  }
                }}
              />
            </th>
            <th className={styles.statusFilter} data-testid="status-filter">
              <SearchDropdown
                options={accountStatusOptions}
                placeholder={intl.formatMessage({
                  id: translationKeys.customers_page_status_filter_placeholder,
                })}
                onChange={(statuses) => {
                  if (statuses && statuses.length > 0 && statuses[0].key) {
                    handleFilterChange(
                      CustomersFilterField.Status,
                      accountStatusValues[statuses[0].key],
                    );
                  } else {
                    handleFilterChange(CustomersFilterField.Status, '');
                  }
                }}
              />
            </th>
            <th className={styles.nameFilter}>
              <TextField
                placeholder={intl.formatMessage({
                  id: translationKeys.customers_page_name_filter_placeholder,
                })}
                data-testid="name-filter"
                onChange={(e) =>
                  handleFilterChange(CustomersFilterField.NameEmailPhone, e.currentTarget.value)
                }
              />
            </th>
            <th className={styles.organisationFilter}>
              <TextField
                placeholder={intl.formatMessage({
                  id: translationKeys.customers_page_org_filter_placeholder,
                })}
                data-testid="org-filter"
                onChange={(e) =>
                  handleFilterChange(CustomersFilterField.OrgNumberName, e.currentTarget.value)
                }
              />
            </th>
            <th className={styles.departmentFilter}>
              <TextField
                placeholder={intl.formatMessage({
                  id: translationKeys.customers_page_departments_filter_placeholder,
                })}
                data-testid="department-filter"
                onChange={(e) =>
                  handleFilterChange(CustomersFilterField.Departments, e.currentTarget.value)
                }
              />
            </th>
            <th></th>
          </tr>

          <tr className={styles.headers}>
            <th>
              <FormattedMessage id={translationKeys.customers_page_id_header} />
            </th>
            <th>
              <FormattedMessage id={translationKeys.customers_page_type_header} />
            </th>
            <th>
              <FormattedMessage id={translationKeys.customers_page_status_header} />
            </th>
            <th>
              <FormattedMessage id={translationKeys.customers_page_name_header} />
            </th>
            <th>
              <FormattedMessage id={translationKeys.customers_page_org_header} />
            </th>
            <th>
              <FormattedMessage id={translationKeys.customers_page_departments_header} />
            </th>
            <th>
              <FormattedMessage id={translationKeys.customers_page_actions_header} />
            </th>
          </tr>
        </thead>

        <tbody>
          {customers.map((customer) => (
            <CustomerItem
              customer={customer}
              key={customer.id}
              onCompleteAction={handleCompleteAction}
            />
          ))}

          {isLoading && [...Array(3)].map((_, index) => <CustomerItemSkeleton key={index} />)}
        </tbody>
      </Table>

      {!isLoading && customers.length === 0 && (
        <div className={styles.emptyStateContainer}>
          <EmptyState
            image={errorStateIllustration}
            text={intl.formatMessage({
              id: translationKeys.customers_page_empty_state_title,
            })}
            description={intl.formatMessage({
              id: translationKeys.customers_page_empty_state_subtitle,
            })}
          />
        </div>
      )}
    </>
  );
};

export default CustomersTable;
