import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, SearchDropdownMenuOption, Tabs } from '@skiwo/components';
import { stripObject } from '@skiwo/utils';
import { ApiError } from '../Api';
import { useApi } from '../providers/ApiProvider';
import translationKeys from '../translations/translationKeys';
import { TranslationSubtask, TranslationSubtaskStatus } from '../types';
import TranslationSubtasksTable from './TranslationSubtasksTable/TranslationSubtasksTable';
import getStatusName from './utils/getStatusName';
import getTranslationSubtasksFilters, {
  customerInputIds,
  statusCodes,
} from './utils/getTranslationSubtasksFilters';
import TranslationSubtasksFilterField from './TranslationSubtasksFilterField';
import TranslationSubtasksTab from './TranslationSubtasksTab';
import styles from './TranslationSubtasks.module.scss';

interface TabItem {
  active?: boolean;
  count?: number;
  id: TranslationSubtasksTab;
  title: string;
  filters: Record<string, string>;
}

type Filters = Record<string, string | string[]>;

const TranslationSubtasks = () => {
  const intl = useIntl();
  const api = useApi();
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [selectedStatuses, setSelectedStatuses] = useState<SearchDropdownMenuOption[]>([]);
  const [subtasks, setSubtasks] = useState<TranslationSubtask[]>([]);
  const [subtasksLoading, setSubtasksLoading] = useState(false);
  const [subtasksError, setSubtasksError] = useState<ApiError | null>(null);
  const [filters, setFilters] = useState<Filters>({});
  const [tabItems, setTabItems] = useState<TabItem[]>([
    {
      id: TranslationSubtasksTab.Draft,
      title: getStatusName(TranslationSubtaskStatus.Draft, intl),
      filters: {
        's[subtask_status_in]': statusCodes[TranslationSubtaskStatus.Draft].toString(),
      },
    },
    {
      id: TranslationSubtasksTab.Invited,
      title: getStatusName(TranslationSubtaskStatus.Invited, intl),
      filters: {
        's[subtask_status_in]': statusCodes[TranslationSubtaskStatus.Invited].toString(),
      },
    },
    {
      id: TranslationSubtasksTab.Rejected,
      title: getStatusName(TranslationSubtaskStatus.Rejected, intl),
      filters: {
        's[subtask_status_in]': statusCodes[TranslationSubtaskStatus.Rejected].toString(),
      },
    },
    {
      id: TranslationSubtasksTab.ReviewRequested,
      title: getStatusName(TranslationSubtaskStatus.ReviewRequested, intl),
      filters: {
        's[subtask_status_in]': statusCodes[TranslationSubtaskStatus.ReviewRequested].toString(),
      },
    },
    {
      active: true,
      id: TranslationSubtasksTab.All,
      title: intl.formatMessage({ id: translationKeys.translation_subtasks_all_tab }),
      filters: {},
    },
  ]);

  const fetchTranslationsWithNewFilters = (newFilters: Filters) => {
    setSubtasks([]);
    setFilters(newFilters);
    fetchTranslationSubtasks(newFilters, 1, true);
  };

  const handleTabSelect = (tab: string) => {
    const newFilters = getTranslationSubtasksFilters(
      filters,
      TranslationSubtasksFilterField.Status,
      tab,
    );

    setSelectedStatuses(
      tab === TranslationSubtasksTab.All
        ? []
        : [
            {
              id: statusCodes[tab] ?? customerInputIds[tab],
              label: getStatusName(tab, intl),
              key: tab,
            },
          ],
    );
    fetchTranslationsWithNewFilters(newFilters);
  };

  const syncTabsWithFilters = (field: TranslationSubtasksFilterField, value: string) => {
    if (field !== TranslationSubtasksFilterField.Status) return;

    const hasMultipleSelected = value.includes(',');
    const isAllActive =
      hasMultipleSelected || !value || !tabItems.find((item) => item.id === value);

    setTabItems(
      tabItems.map((item) => ({
        ...item,
        active: isAllActive ? item.id === TranslationSubtasksTab.All : item.id === value,
      })),
    );
  };

  const handleFilterChange = (field: TranslationSubtasksFilterField, value: string) => {
    const newFilters = getTranslationSubtasksFilters(filters, field, value);

    syncTabsWithFilters(field, value);
    fetchTranslationsWithNewFilters(newFilters);
  };

  const fetchTranslationSubtasks = async (filters: Filters, page: number, clearCache = false) => {
    const { data, error } = await api.getTranslationSubtasks(
      {
        page,
        items: 20,
        ...stripObject(filters),
      },
      setSubtasksLoading,
    );

    setSubtasksError(error);

    if (data?.subtasks) {
      let allSubtasks = subtasks;
      if (!clearCache) {
        for (const newSubtask of data.subtasks) {
          const newSubtaskIndex = allSubtasks.findIndex((subtask) => subtask.id === newSubtask.id);

          if (newSubtaskIndex === -1) {
            allSubtasks.push(newSubtask);
          } else {
            allSubtasks[newSubtaskIndex] = newSubtask;
          }
        }
      } else {
        allSubtasks = data.subtasks;
      }

      setSubtasks(allSubtasks);
      setPage(data.page);
      setPages(data.pages);
    }
  };

  const handleLoadMore = () => {
    fetchTranslationSubtasks(filters, page + 1);
  };

  const handleReloadData = (hardReload = false) => {
    fetchTranslationSubtasks(filters, page, hardReload);
  };

  const getSubtaskStats = async () => {
    const { data } = await api.getTranslationSubtasksStats();

    if (data) {
      setTabItems((prev) =>
        prev.map((item) => ({
          ...item,
          count:
            item.id === TranslationSubtasksTab.All ? data.totalCount : data.statusCounts[item.id],
        })),
      );
    }
  };

  useEffect(() => {
    getSubtaskStats();
  }, []);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <h1 data-testid="translation-subtasks-header">
            <FormattedMessage id={translationKeys.translation_subtasks_title} />
          </h1>
        </div>

        <div className={styles.tabsContainer}>
          <Tabs items={tabItems} onSelect={handleTabSelect} />
        </div>

        <div className={styles.tableContainer}>
          <TranslationSubtasksTable
            subtasks={subtasks}
            error={subtasksError}
            isLoading={subtasksLoading}
            onFilterChange={handleFilterChange}
            onCompleteAction={handleReloadData}
            selectedStatuses={selectedStatuses}
          />
        </div>

        {!subtasksLoading && subtasks && page < pages && (
          <div className={styles.loadMoreButton}>
            <Button
              variant="secondary"
              size="large"
              onClick={handleLoadMore}
              data-testid="load-more-button"
            >
              <FormattedMessage id={translationKeys.translation_subtasks_load_more_button} />
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default TranslationSubtasks;
