import React, { useEffect, useState } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { IconButton } from '@skiwo/components';
import styles from './LanguagesDropdown.module.scss';

const LanguagesDropdown = () => {
  const [currentLanguage, setCurrentLanguage] = useState('');

  const supportedLanguage = [
    {
      id: 'nb-NO',
      text: 'Norsk',
      flag: '🇳🇴',
    },
    {
      id: 'en-GB',
      text: 'English',
      flag: '🇬🇧',
    },
    {
      id: 'uk-UA',
      text: 'Українська',
      flag: '🇺🇦',
    },
  ];

  const updateCurrentLanguage = (selectedLanguage?: string) => {
    if (selectedLanguage) {
      localStorage.setItem('locale', selectedLanguage);
      location.reload();
    }

    const locale = localStorage.getItem('locale');
    const supportedLocales = supportedLanguage.find((language) => language.id);

    if (locale && supportedLocales) {
      setCurrentLanguage(locale);
    } else {
      const defaultLanguage = 'nb-NO';
      updateCurrentLanguage(defaultLanguage);
    }
  };

  const getCurrentLanguageFlag = () => {
    if (!currentLanguage) return null;

    const language = supportedLanguage.find((language) => language.id === currentLanguage);
    if (language) {
      return language.flag;
    }
  };

  useEffect(() => {
    updateCurrentLanguage();
  }, []);

  return (
    <NavDropdown
      title={<IconButton size="medium" icon={<span>{getCurrentLanguageFlag()}</span>} />}
      align="end"
      className={styles.languagesMenu}
      data-testid="languages-dropdown"
    >
      {supportedLanguage.map((language) => {
        return (
          <NavDropdown.Item
            key={language.id}
            onClick={() => updateCurrentLanguage(language.id)}
            data-testid="languages-menu-item"
          >
            <div className={styles.languageLabel}>
              <span>{language.flag}</span>
              <span>{language.text}</span>
            </div>
          </NavDropdown.Item>
        );
      })}
    </NavDropdown>
  );
};

export default LanguagesDropdown;
