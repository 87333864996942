const getFormattedAddress = (address?: {
  line1?: string | null;
  line2?: string | null;
  postcode?: string | null;
  city?: string | null;
  country?: string | null;
  county?: string | null;
}): string | null => {
  if (!address) return '-';

  const postalAddress = [address.postcode, address.city].filter((component) => !!component);
  const addressComponents = [address.line1, address.line2, postalAddress.join(' '), address.country].filter(
    (component) => !!component,
  );

  if (addressComponents.length === 0) return null;

  return addressComponents.join(', ');
};

export default getFormattedAddress;
