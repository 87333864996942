import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import {
  faArrowRight,
  faCalendar,
  faDiagramSubtask,
  faPen,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Banner, Breadcrumbs, Button, DetailItem, Tag } from '@skiwo/components';
import { differenceInDays, format } from 'date-fns';
import { useLanguages } from '../../providers/LanguagesProvider';
import translationKeys from '../../translations/translationKeys';
import { ManagerOrderTask } from '../../types';
import getSubtaskStatusColour from '../utils/getSubtaskStatusColour';
import getSubtaskStatusName from '../utils/getSubtaskStatusName';
import getSubtaskTypeName from '../utils/getSubtaskTypeName';
import getTaskStatusColour from '../utils/getTaskStatusColour';
import getTaskStatusName from '../utils/getTaskStatusName';
import EditTaskDrawer from './Drawers/EditTaskDrawer/EditTaskDrawer';
import styles from './TaskDetails.module.scss';

interface Props {
  task?: ManagerOrderTask;
  onUpdate: () => void;
  editable: boolean;
}

const TaskDetails = (props: Props) => {
  const { task, onUpdate, editable } = props;
  const intl = useIntl();
  const [, setSearchParams] = useSearchParams();
  const { getLanguageById } = useLanguages();
  const [showEditTask, setShowEditTask] = useState(false);

  if (!task) {
    // TODO: Deal with undefined value
    return null;
  }

  const getCreatedDate = (createdAt: string) => {
    const date = new Date(createdAt);

    return format(date, 'dd.MM.yy');
  };

  const getTimeAgo = (timeStamp: string) => {
    const createdAt = new Date(timeStamp);
    const now = new Date();

    const daysAgo = differenceInDays(now, createdAt);

    if (daysAgo === 0) {
      return intl.formatMessage({
        id: translationKeys.translation_order_details_time_today,
      });
    }

    return `${daysAgo}d ${intl.formatMessage({
      id: translationKeys.translation_order_details_time_ago,
    })}`;
  };

  return (
    <section className={styles.taskSection}>
      <Breadcrumbs
        items={[
          {
            id: 'translation_orders',
            title: intl.formatMessage({
              id: translationKeys.translation_order_details_translation_order_label,
            }),
            href: '/translation-subtasks',
          },
          {
            id: task.orderId.toString(),
            title: `${intl.formatMessage({
              id: translationKeys.translation_order_details_order_label,
            })} #${task.orderId}`,
            active: true,
          },
        ]}
      />

      {!editable && (
        <Banner
          variant="error"
          text={intl.formatMessage({ id: translationKeys.translation_order_no_owner_error_text })}
        />
      )}

      <div className={styles.taskHeader}>
        <h2 className={styles.taskTitle}>
          <span data-testid="task-details-source-language">
            {getLanguageById(task.sourceLanguageId)}
          </span>
          <span>
            <FontAwesomeIcon icon={faArrowRight} />
          </span>
          <span data-testid="task-details-target-languages">
            {getLanguageById(task.targetLanguageId)}
          </span>
        </h2>

        <div className={styles.taskDetails} data-testid="task-details-status-info">
          <Tag color={getTaskStatusColour(task.status)}>{getTaskStatusName(task.status, intl)}</Tag>
          <span className={styles.secondaryValue} data-testid="task-details-time-ago">
            {getTimeAgo(task.createdAt)}
          </span>
        </div>
      </div>

      <div className={styles.taskId}>
        <span>
          <FormattedMessage id={translationKeys.translation_task_details_task_label} />
        </span>
        <span>•</span>
        <span>{task.publicId}</span>
      </div>

      <div className={styles.taskInfo}>
        <span className={styles.label}>
          <FormattedMessage id={translationKeys.translation_task_details_create_label} />
        </span>
        <div className={styles.value}>
          <FontAwesomeIcon icon={faCalendar} />
          <span>{getCreatedDate(task.createdAt)}</span>
        </div>

        <Button
          className={styles.createSubtask}
          icon={<FontAwesomeIcon icon={faPen} />}
          variant="gray"
          size="large"
          onClick={() => setShowEditTask(true)}
          data-testid="task-details-edit-button"
          disabled={!editable}
        >
          <FormattedMessage id={translationKeys.translation_order_details_edit_button} />
        </Button>
      </div>

      <div className={styles.subtasks}>
        <div className={styles.header}>
          <FontAwesomeIcon icon={faDiagramSubtask} />
          <span>
            <FormattedMessage id={translationKeys.translation_task_details_subtasks_header} />
          </span>
        </div>

        <div className={styles.subtaskList}>
          {!task.subtasks?.length ? (
            <div className={styles.subtaskListEmptyState}>
              <FormattedMessage id={translationKeys.translation_task_details_subtasks_empty_state} />
            </div>
          ) : (
            task.subtasks?.map((subtask) => (
              <DetailItem
                key={subtask.publicId}
                title={<span>{getSubtaskTypeName(subtask.subtaskType, intl)}</span>}
                subtitle={<span>{`#${subtask.publicId}`}</span>}
                onClick={() => setSearchParams({ active: subtask.publicId })}
                size="large"
                data-testid="task-details-subtask"
              >
                <div className={styles.subtaskItemInfo}>
                  <Tag color={getSubtaskStatusColour(subtask.status)}>
                    {getSubtaskStatusName(subtask.status, intl)}
                  </Tag>
                  {subtask.seller?.avatar && (
                    <Avatar url={subtask.seller.avatar} altText={subtask.seller.name}></Avatar>
                  )}
                </div>
              </DetailItem>
            ))
          )}
        </div>
      </div>

      <EditTaskDrawer
        show={showEditTask}
        onClose={() => {
          onUpdate();
          setShowEditTask(false);
        }}
        task={task}
      />
    </section>
  );
};

export default TaskDetails;
