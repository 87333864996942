import { ManagerSupplierSex, ManagerSupplierStandBy, ManagerSupplierType } from '../../types';
import SuppliersFilterField from '../SuppliersFilterField';

const getSuppliersFilters = (
  currentFilters: Record<string, string>,
  field: SuppliersFilterField,
  value: string,
): Record<string, string> => {
  if (field === SuppliersFilterField.Id) {
    return { ...currentFilters, ...{ 's[id_eq]': value } };
  }

  if (field === SuppliersFilterField.Type) {
    if (value === ManagerSupplierType.Interpreter) {
      return { ...currentFilters, ...{ 's[interpreter_id_not_null]': '1' } };
    }

    if (value === ManagerSupplierType.Translator) {
      return {
        ...currentFilters,
        ...{ 's[interpreter_id_not_null]': '0', 's[seller_id_not_null]': '1' },
      };
    }

    if (value === ManagerSupplierType.Both) {
      return {
        ...currentFilters,
        ...{ 's[interpreter_id_not_null]': '1', 's[seller_id_not_null]': '1' },
      };
    }

    if (value === ManagerSupplierType.Undefined) {
      return {
        ...currentFilters,
        ...{ 's[interpreter_id_not_null]': '0', 's[seller_id_not_null]': '0' },
      };
    }

    return {
      ...currentFilters,
      ...{ 's[interpreter_id_not_null]': '', 's[seller_id_not_null]': '' },
    };
  }

  if (field === SuppliersFilterField.NameEmailPhone) {
    let filterValue = value;
    const isPhone = value.startsWith('+47');

    if (isPhone) {
      filterValue = value.replaceAll(' ', '');
    }

    return { ...currentFilters, ...{ 's[lookup_fields_cont]': filterValue } };
  }

  if (field === SuppliersFilterField.Gender) {
    let sexFilter = '';

    if (value === ManagerSupplierSex.Male) {
      sexFilter = '0';
    }

    if (value === ManagerSupplierSex.Female) {
      sexFilter = '1';
    }

    return {
      ...currentFilters,
      ...{ 's[sex_in][]': sexFilter },
    };
  }

  if (field === SuppliersFilterField.Standby) {
    let standByFilter = '';

    if (value === ManagerSupplierStandBy.Yes) {
      standByFilter = '1';
    }

    if (value === ManagerSupplierStandBy.No) {
      standByFilter = '0';
    }

    return {
      ...currentFilters,
      ...{ 's[interpreter_can_standby_in][]': standByFilter },
    };
  }

  return currentFilters;
};

export default getSuppliersFilters;
