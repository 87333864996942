import { IntlShape } from 'react-intl';
import { getInvoiceStrategyName } from '../../CreateTranslationOrder/PaymentSection/getInvoiceStrategyName';
import { CustomerPaymentMethodInvoiceGroupStrategy } from '../../types/CustomerPaymentMethod';

export const getInvoiceStrategies = (intl: IntlShape) => [
  {
    id: 1,
    label: getInvoiceStrategyName(CustomerPaymentMethodInvoiceGroupStrategy.PerJob, intl),
    key: CustomerPaymentMethodInvoiceGroupStrategy.PerJob,
  },
  {
    id: 2,
    label: getInvoiceStrategyName(
      CustomerPaymentMethodInvoiceGroupStrategy.PerBuyerReference,
      intl,
    ),
    key: CustomerPaymentMethodInvoiceGroupStrategy.PerBuyerReference,
  },
  {
    id: 3,
    label: getInvoiceStrategyName(CustomerPaymentMethodInvoiceGroupStrategy.PerCustomer, intl),
    key: CustomerPaymentMethodInvoiceGroupStrategy.PerCustomer,
  },
  {
    id: 4,
    label: getInvoiceStrategyName(CustomerPaymentMethodInvoiceGroupStrategy.PerDepartment, intl),
    key: CustomerPaymentMethodInvoiceGroupStrategy.PerDepartment,
  },
  {
    id: 5,
    label: getInvoiceStrategyName(CustomerPaymentMethodInvoiceGroupStrategy.PerBooker, intl),
    key: CustomerPaymentMethodInvoiceGroupStrategy.PerBooker,
  },
  {
    id: 6,
    label: getInvoiceStrategyName(
      CustomerPaymentMethodInvoiceGroupStrategy.PerBuyerOrderAndReference,
      intl,
    ),
    key: CustomerPaymentMethodInvoiceGroupStrategy.PerBuyerOrderAndReference,
  },
  {
    id: 7,
    label: getInvoiceStrategyName(
      CustomerPaymentMethodInvoiceGroupStrategy.PerOrderReference,
      intl,
    ),
    key: CustomerPaymentMethodInvoiceGroupStrategy.PerOrderReference,
  },
];
