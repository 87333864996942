import React, { PropsWithChildren } from 'react';
import styles from './SummaryItem.module.scss';

interface SummaryItemProps {
  label: string;
}

const SummaryItem = ({ label, children }: PropsWithChildren<SummaryItemProps>) => {
  return (
    <div className={styles.summaryItem}>
      <span className={styles.label}>{label}</span>
      {children}
    </div>
  );
};

export default SummaryItem;
