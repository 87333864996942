import { IntlShape } from 'react-intl';
import translationKeys from '../../translations/translationKeys';
import { ManagerJobAutoUpdateToSessionType } from '../../types';

const getAlternativeSessionTypeName = (
  type: ManagerJobAutoUpdateToSessionType,
  intl: IntlShape,
) => {
  switch (type) {
    case ManagerJobAutoUpdateToSessionType.Phone:
      return intl.formatMessage({
        id: translationKeys.create_interpretation_order_assignment_type_phone_label,
      });
    case ManagerJobAutoUpdateToSessionType.Video:
      return intl.formatMessage({
        id: translationKeys.create_interpretation_order_assignment_type_video_label,
      });
    case ManagerJobAutoUpdateToSessionType.InPerson:
      return intl.formatMessage({
        id: translationKeys.create_interpretation_order_assignment_type_in_person_label,
      });
    default:
      return '-';
  }
};

export default getAlternativeSessionTypeName;
