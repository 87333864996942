import { ManagerOrderTaskStatus } from '../../types';

const getTaskStatusColour = (
  status: ManagerOrderTaskStatus,
): 'success' | 'error' | 'info' | 'warning' | 'primary' | 'neutral' => {
  if (status === ManagerOrderTaskStatus.Published) {
    return 'success';
  }

  if (status === ManagerOrderTaskStatus.Cancelled) {
    return 'error';
  }

  if (status === ManagerOrderTaskStatus.Finished) {
    return 'info';
  }

  return 'neutral';
};

export default getTaskStatusColour;
