import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DatePicker,
  EmptyState,
  SearchDropdown,
  SearchDropdownMenuOption,
  Table,
  TextField,
} from '@skiwo/components';
import { format } from 'date-fns';
import { ApiError } from '../../Api';
import errorStateIllustration from '../../assets/empty-table-error.svg';
import useDebounce from '../../hooks/useDebounce';
import { useLanguages } from '../../providers/LanguagesProvider';
import { useQualifications } from '../../providers/QualificationsProvider';
import translationKeys from '../../translations/translationKeys';
import { TranslationSkill, TranslationSkillAccountStatus } from '../../types';
import TranslationSkillItem from '../TranslationSkillItem/TranslationSkillItem';
import TranslationSkillsFilterField from '../TranslationSkillsFilterField';
import TranslationSkillsItemSkeleton from '../TranslationSkillsItemSkeleton/TranslationSkillsItemSkeleton';
import getAccountStatusName from '../utils/getAccountStatusName';
import styles from './TranslationSkillsTable.module.scss';

interface Props {
  skills: TranslationSkill[];
  isLoading: boolean;
  error: ApiError | null;
  onFilterChange: (field: TranslationSkillsFilterField, value: string) => void;
  onCompleteAction: (hardReload: boolean) => void;
  className?: string;
}

const TranslationSkillsTable = (props: Props) => {
  const { skills, isLoading, error, onFilterChange, onCompleteAction } = props;
  const intl = useIntl();
  const { languages } = useLanguages();
  const { qualifications } = useQualifications();
  const [selectedLanguages, setSelectedLanguages] = useState<SearchDropdownMenuOption[]>();
  const [selectedQualifications, setSelectedQualifications] =
    useState<SearchDropdownMenuOption[]>();
  const [selectedAccountStatuses, setSelectedAccountStatuses] =
    useState<SearchDropdownMenuOption[]>();
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [formattedCreatedDate, setFormattedCreatedDate] = useState('');

  const debounceFilterChange = useDebounce(300);

  const handleFilterChange = (field: TranslationSkillsFilterField, value: string) => {
    debounceFilterChange(() => {
      onFilterChange(field, value);
    });
  };

  const handleCompleteAction = (hardReload = false) => {
    onCompleteAction(hardReload);
  };

  const handleDateChange = (field: TranslationSkillsFilterField, e: React.ChangeEvent<any>) => {
    const newValue = e.currentTarget.value;
    if (!newValue) {
      if (field === TranslationSkillsFilterField.CreatedAt) {
        setFormattedCreatedDate('');
      }

      onFilterChange(field, '');
      setDatePickerOpen(false);
    }
  };

  const handleDatePickerChange = (field: TranslationSkillsFilterField, start: Date, end?: Date) => {
    const formattedStartDate = format(start, 'dd.MM.yy');
    const formattedEndDate = end ? format(end, 'dd.MM.yy') : null;

    if (field === TranslationSkillsFilterField.CreatedAt && end) {
      onFilterChange(
        TranslationSkillsFilterField.CreatedAt,
        `${start.toISOString()},${end.toISOString()}`,
      );
      setFormattedCreatedDate(`${formattedStartDate} - ${formattedEndDate}`);
    }

    setDatePickerOpen(false);
  };

  const languageOptions =
    languages.map((language) => {
      return { id: language.id, label: language.name || '', key: language.id.toString() };
    }) || [];

  const accountStatusOptions =
    Object.values(TranslationSkillAccountStatus)
      .filter((status) => status !== TranslationSkillAccountStatus.Inactive)
      .map((status, index) => {
        return { id: index, label: getAccountStatusName(status, intl), key: status };
      }) || [];

  const qualificationOptions =
    qualifications
      .filter((qualification) => qualification.industry === 'translation')
      .map((qualification) => {
        return {
          id: qualification.id,
          label: qualification.name || '',
          key: qualification.id.toString(),
        };
      }) || [];

  useEffect(() => {
    if (!selectedLanguages) return;
    if (selectedLanguages.length) {
      const languageIds = selectedLanguages.map((language) => language.id);
      handleFilterChange(TranslationSkillsFilterField.Languages, languageIds.join(','));
    } else {
      handleFilterChange(TranslationSkillsFilterField.Languages, '');
    }
  }, [selectedLanguages]);

  useEffect(() => {
    if (!selectedQualifications) return;
    if (selectedQualifications.length) {
      const qualificationIds = selectedQualifications.map((qualification) => qualification.id);
      handleFilterChange(TranslationSkillsFilterField.Qualifications, qualificationIds.join(','));
    } else {
      handleFilterChange(TranslationSkillsFilterField.Qualifications, '');
    }
  }, [selectedQualifications]);

  useEffect(() => {
    if (!selectedAccountStatuses) return;

    const accountStatusValues: Record<string, number> = {
      [TranslationSkillAccountStatus.Active]: 0,
      [TranslationSkillAccountStatus.Paused]: 1,
      [TranslationSkillAccountStatus.Blocked]: 2,
      [TranslationSkillAccountStatus.Retired]: 3,
      [TranslationSkillAccountStatus.Banned]: 4,
      [TranslationSkillAccountStatus.Deleted]: 5,
      [TranslationSkillAccountStatus.Deceased]: 6,
    };

    const selectedStatusValues = selectedAccountStatuses.map(
      (status) => accountStatusValues[status.key as string],
    );

    if (selectedStatusValues.length) {
      handleFilterChange(
        TranslationSkillsFilterField.AccountStatuses,
        selectedStatusValues.join(','),
      );
    } else {
      handleFilterChange(TranslationSkillsFilterField.AccountStatuses, '');
    }
  }, [selectedAccountStatuses]);

  if (error) {
    return (
      <div className={styles.emptyStateContainer} data-testid="page-error-empty-state">
        <EmptyState
          image={errorStateIllustration}
          text={intl.formatMessage({ id: translationKeys.translation_skills_error_state_title })}
          description={intl.formatMessage({
            id: translationKeys.translation_skills_error_state_subtitle,
          })}
          refreshable
        />
      </div>
    );
  }

  return (
    <>
      <Table striped data-testid="translation-skills-table">
        <thead className={styles.tableHeader}>
          <tr className={styles.filters}>
            <th className={styles.idFilter}>
              <TextField
                placeholder={intl.formatMessage({
                  id: translationKeys.translation_skills_id_filter_placeholder,
                })}
                data-testid="id-filter"
                onChange={(e) =>
                  handleFilterChange(TranslationSkillsFilterField.Id, e.currentTarget.value)
                }
              />
            </th>
            <th className={styles.nameFilter}>
              <TextField
                placeholder={intl.formatMessage({
                  id: translationKeys.translation_skills_name_filter_placeholder,
                })}
                data-testid="name-filter"
                onChange={(e) =>
                  handleFilterChange(TranslationSkillsFilterField.Name, e.currentTarget.value)
                }
              />
            </th>
            <th className={styles.statusFilter}></th>
            <th
              className={styles.languageQualificationFilter}
              data-testid="language-and-qualification-filter"
            >
              <div className={styles.multipleColumnHeader}>
                <SearchDropdown
                  maxResults={languageOptions.length}
                  options={languageOptions}
                  placeholder={intl.formatMessage({
                    id: translationKeys.translation_skills_language_filter_placeholder,
                  })}
                  multiple
                  selected={selectedLanguages}
                  onChange={(languages) => {
                    setSelectedLanguages([...(languages || [])]);
                  }}
                />
                <SearchDropdown
                  options={qualificationOptions}
                  placeholder={intl.formatMessage({
                    id: translationKeys.interpretation_skills_page_qualifications_filter_placeholder,
                  })}
                  multiple
                  selected={selectedQualifications}
                  onChange={(qualifications) => {
                    setSelectedQualifications([...(qualifications || [])]);
                  }}
                />
              </div>
            </th>
            <th className={styles.statusFilter}>
              <SearchDropdown
                options={accountStatusOptions}
                placeholder={intl.formatMessage({
                  id: translationKeys.translation_skills_account_status_filter_placeholder,
                })}
                multiple
                selected={selectedAccountStatuses}
                onChange={(statuses) => {
                  setSelectedAccountStatuses([...(statuses || [])]);
                }}
              />
            </th>
            <th className={styles.dateFilter}>
              <TextField
                type="search"
                placeholder={intl.formatMessage({
                  id: translationKeys.translation_skills_created_at_filter_placeholder,
                })}
                icon={<FontAwesomeIcon icon={faCalendar} />}
                onFocus={() => setDatePickerOpen(true)}
                value={formattedCreatedDate}
                data-testid="created-date-filter"
                onChange={(e) => handleDateChange(TranslationSkillsFilterField.CreatedAt, e)}
              />
              {datePickerOpen && (
                <DatePicker
                  className={styles.datePicker}
                  onClose={() => setDatePickerOpen(false)}
                  monthCount={1}
                  onChange={(start: Date, end?: Date) =>
                    handleDatePickerChange(TranslationSkillsFilterField.CreatedAt, start, end)
                  }
                />
              )}
            </th>
            <th></th>
            <th></th>
          </tr>

          <tr className={styles.headers}>
            <th>
              <FormattedMessage id={translationKeys.translation_skills_id_header} />
            </th>
            <th>
              <FormattedMessage id={translationKeys.translation_skills_name_header} />
            </th>
            <th>
              <FormattedMessage id={translationKeys.translation_skills_status_header} />
            </th>
            <th>
              <FormattedMessage id={translationKeys.translation_skills_language_header} />
            </th>
            <th>
              <FormattedMessage id={translationKeys.translation_skills_account_status_header} />
            </th>
            <th>
              <FormattedMessage id={translationKeys.translation_skills_created_at_header} />
            </th>
            <th>
              <FormattedMessage id={translationKeys.translation_skills_notes_header} />
            </th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          {skills.map((skill) => (
            <TranslationSkillItem
              skill={skill}
              key={skill.id}
              onCompleteAction={handleCompleteAction}
            />
          ))}

          {isLoading &&
            [...Array(3)].map((_, index) => <TranslationSkillsItemSkeleton key={index} />)}
        </tbody>
      </Table>

      {!isLoading && skills.length === 0 && (
        <EmptyState
          className={styles.emptyStateContainer}
          image={errorStateIllustration}
          text={intl.formatMessage({
            id: translationKeys.translation_skills_empty_state_title,
          })}
          description={intl.formatMessage({
            id: translationKeys.translation_skills_empty_state_subtitle,
          })}
        />
      )}
    </>
  );
};

export default TranslationSkillsTable;
