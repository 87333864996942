import React from 'react';
import styles from './Separator.module.scss';

interface Props {
  icon: React.ReactNode;
  text: string;
}

const Separator = (props: Props) => {
  const { icon, text } = props;

  return (
    <div className={styles.separator}>
      {icon}
      <span>{text}</span>
    </div>
  );
};

export default Separator;
