import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { ApiError } from '../Api';
import { Qualification } from '../types';
import { useApi } from './ApiProvider';
import { useAuth } from './AuthProvider';

interface Props {
  children: JSX.Element | JSX.Element[];
}

interface QualificationsContext {
  qualifications: Qualification[];
  isLoading: boolean;
  error: ApiError | null;
  getQualificationById: (qualificationId: number) => Qualification | undefined;
}

export const initialState: QualificationsContext = {
  qualifications: [],
  isLoading: false,
  error: null,
  getQualificationById: () => undefined,
};

export const QualificationsContext = createContext(initialState);

const QualificationsProvider: React.FC<Props> = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const api = useApi();
  const [qualifications, setQualifications] = useState<Qualification[]>([]);
  const [qualificationsLoading, setQualificationsLoading] = useState(false);
  const [qualificationsError, setQualificationsError] = useState<ApiError | null>(null);

  const getQualifications = async () => {
    const { data, error } = await api.getQualifications(setQualificationsLoading);

    if (data) {
      setQualifications(data);
    }
    if (error) {
      setQualificationsError(error);
    }
  };

  useEffect(() => {
    if (!isAuthenticated() || qualifications.length > 0) return;
    getQualifications();
  }, []);

  const getQualificationById = (qualificationId: number) => {
    if (qualifications) {
      const qualificationObject = qualifications.find(
        (qualification: any) => qualification.id === qualificationId,
      );

      if (qualificationObject) {
        return qualificationObject;
      }
    }
  };

  const QualificationsContextValue = useMemo(
    () => ({
      qualifications,
      isLoading: qualificationsLoading,
      error: qualificationsError,
      getQualificationById,
    }),
    [qualifications, qualificationsLoading, qualificationsError],
  );

  return (
    <QualificationsContext.Provider value={QualificationsContextValue}>
      {children}
    </QualificationsContext.Provider>
  );
};

export const useQualifications = () => useContext(QualificationsContext);

export default QualificationsProvider;
