import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { faSearch, faUser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextField } from '@skiwo/components';
import useDebounce from '../../../../hooks/useDebounce';
import translationKeys from '../../../../translations/translationKeys';
import { ManagerSuitableSeller } from '../../../../types/ManagerSuitableSeller';
import SellerItem from '../../SellerItem/SellerItem';
import styles from './AssignSellerDrawer.module.scss';

interface AvailableSellersListProps {
  availableSellers: ManagerSuitableSeller[];
  onSearch: (query: string) => void;
  selectedSellerId: string | null;
  setSelectedSellerId: (id: string) => void;
}

const AvailableSellersList = ({
  availableSellers,
  onSearch,
  selectedSellerId,
  setSelectedSellerId,
}: AvailableSellersListProps) => {
  const intl = useIntl();
  const debounceSearch = useDebounce(300);

  return (
    <>
      <TextField
        size="large"
        icon={<FontAwesomeIcon icon={faSearch} />}
        placeholder={intl.formatMessage({
          id: translationKeys.translation_subtask_details_assign_translator_drawer_search_placeholder,
        })}
        onChange={(e) =>
          debounceSearch(() => {
            onSearch(e.target.value);
          })
        }
      />

      {!!availableSellers.length && (
        <div className={styles.detailItemList}>
          {availableSellers.map((item) => {
            return (
              <SellerItem
                key={item.id}
                avatarUrl={item.person?.avatar}
                name={item.person?.name}
                qualificationName={item.suitableSkill?.qualification.name}
                assignedSubtasksCount={item.assignedSubtasksCount}
                isProactive={item.isProactive}
                hasContract={item.suitableSkill?.hasContract}
                variant={selectedSellerId === item.id ? 'selected' : 'primary'}
                onClick={() => setSelectedSellerId(item.id)}
              />
            );
          })}
        </div>
      )}

      {!availableSellers.length && (
        <div className={styles.emptyState}>
          <FontAwesomeIcon className={styles.emptyStateIcon} icon={faUser} />
          <span>
            <FormattedMessage
              id={translationKeys.translation_subtask_details_assign_translator_drawer_empty_state}
            />
          </span>
        </div>
      )}
    </>
  );
};

export default AvailableSellersList;
