import { IntlShape } from 'react-intl';
import translationKeys from '../../translations/translationKeys';
import { ManagerSupplierStandBy } from '../../types';
import { InterpretationSkillStandBy } from '../../types/InterpretationSkill';

const getStandByName = (
  standby: ManagerSupplierStandBy | InterpretationSkillStandBy,
  intl: IntlShape,
): string => {
  if (standby === ManagerSupplierStandBy.Yes || standby === InterpretationSkillStandBy.Yes) {
    return 'Standby';
  }

  if (standby === ManagerSupplierStandBy.Plus || standby === InterpretationSkillStandBy.Plus) {
    return '';
  }

  return intl.formatMessage({ id: translationKeys.suppliers_table_not_standby_filter });
};

export default getStandByName;
