import { IntlShape } from 'react-intl';
import translationKeys from '../../translations/translationKeys';
import { ManagerOrderSubtaskStatus } from '../../types';

const getSubtaskStatusName = (status: ManagerOrderSubtaskStatus, intl: IntlShape): string => {
  if (status === ManagerOrderSubtaskStatus.Draft) {
    return intl.formatMessage({
      id: translationKeys.translation_subtasks_status_draft,
    });
  }

  if (status === ManagerOrderSubtaskStatus.ProactiveAccess) {
    return intl.formatMessage({
      id: translationKeys.translation_subtasks_status_proactive,
    });
  }

  if (status === ManagerOrderSubtaskStatus.Invited) {
    return intl.formatMessage({
      id: translationKeys.translation_subtasks_status_invited,
    });
  }

  if (status === ManagerOrderSubtaskStatus.Accepted) {
    return intl.formatMessage({
      id: translationKeys.translation_subtasks_status_accepted,
    });
  }

  if (status === ManagerOrderSubtaskStatus.Rejected) {
    return intl.formatMessage({
      id: translationKeys.translation_subtasks_status_rejected,
    });
  }

  if (status === ManagerOrderSubtaskStatus.InProgress) {
    return intl.formatMessage({
      id: translationKeys.translation_subtasks_status_in_progress,
    });
  }

  if (status === ManagerOrderSubtaskStatus.ReviewRequested) {
    return intl.formatMessage({
      id: translationKeys.translation_subtasks_status_review_requested,
    });
  }

  if (status === ManagerOrderSubtaskStatus.ChangesRequired) {
    return intl.formatMessage({
      id: translationKeys.translation_subtasks_status_changes_requested,
    });
  }

  if (status === ManagerOrderSubtaskStatus.Finished) {
    return intl.formatMessage({
      id: translationKeys.translation_subtasks_status_finished,
    });
  }

  if (status === ManagerOrderSubtaskStatus.Cancelled) {
    return intl.formatMessage({
      id: translationKeys.translation_subtasks_status_cancelled,
    });
  }

  return '-';
};

export default getSubtaskStatusName;
