import React from 'react';
import { faEllipsis, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropdown, { DropdownItemVariant } from '../Dropdown/Dropdown';
import IconButton from '../IconButton/IconButton';
import Tooltip from '../Tooltip/Tooltip';
import styles from './ActionsSection.module.scss';

export interface ActionsSectionProps {
  actionItems: {
    title: string;
    disabled?: boolean;
    icon: IconDefinition;
    key: string;
    variant?: DropdownItemVariant;
    'data-testid'?: string;
  }[];
  size?: 'small' | 'medium' | 'large' | 'x-large';
  onActionSelected: (key: string) => void;
  visibleItems?: number;
}

const ActionsSection = ({
  actionItems,
  size,
  onActionSelected,
  visibleItems = 3,
}: ActionsSectionProps) => {
  const calculatedVisibleItems =
    actionItems.length > visibleItems ? visibleItems - 1 : visibleItems;

  return (
    <div className={styles.actionsSection}>
      {actionItems.slice(0, calculatedVisibleItems).map((actionItem) => {
        return (
          <Tooltip title={actionItem.title} key={actionItem.key}>
            <IconButton
              size={size}
              disabled={actionItem.disabled}
              icon={<FontAwesomeIcon icon={actionItem.icon} />}
              data-testid={actionItem['data-testid']}
              onClick={() => onActionSelected(actionItem.key)}
            />
          </Tooltip>
        );
      })}

      {calculatedVisibleItems !== visibleItems && (
        <Dropdown
          data-testid="action-more"
          toggle={
            <IconButton
              size="medium"
              data-testid="detail-item-actions-button"
              icon={<FontAwesomeIcon icon={faEllipsis} />}
            />
          }
          onSelect={(selection) => onActionSelected(selection || '')}
          items={actionItems.slice(calculatedVisibleItems, actionItems.length).map((actionItem) => {
            return {
              id: actionItem.key,
              text: actionItem.title,
              icon: actionItem.icon,
              disabled: actionItem.disabled,
              'data-testId': actionItem['data-testid'],
              variant: actionItem.variant,
            };
          })}
        />
      )}
    </div>
  );
};

export default ActionsSection;
