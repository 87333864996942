import { TranslationSubtaskStatus } from '../../types';
import { TranslationSubtaskCustomerInput } from '../../types/TranslationSubtask';
import TranslationSubtasksFilterField from '../TranslationSubtasksFilterField';

export const customerInputIds: Record<string, number> = {
  [TranslationSubtaskCustomerInput.Requested]: 0,
  [TranslationSubtaskCustomerInput.Updated]: 1,
};

export const statusCodes: Record<string, number> = {
  [TranslationSubtaskStatus.Draft]: 10,
  [TranslationSubtaskStatus.ProactiveAccess]: 20,
  [TranslationSubtaskStatus.Invited]: 30,
  [TranslationSubtaskStatus.Accepted]: 50,
  [TranslationSubtaskStatus.Rejected]: 60,
  [TranslationSubtaskStatus.InProgress]: 65,
  [TranslationSubtaskStatus.ReviewRequested]: 70,
  [TranslationSubtaskStatus.ChangesRequired]: 75,
  [TranslationSubtaskStatus.Finished]: 80,
  [TranslationSubtaskStatus.Cancelled]: 90,
};

const getTranslationSubtasksFilters = (
  currentFilters: Record<string, string | string[]>,
  field: TranslationSubtasksFilterField,
  value: string,
): Record<string, string | string[]> => {
  if (field === TranslationSubtasksFilterField.Id) {
    return { ...currentFilters, 's[public_id_cont]': value, 's[order_id_eq]': '' };
  }

  if (field === TranslationSubtasksFilterField.OrderId) {
    return { ...currentFilters, 's[order_id_eq]': value, 's[public_id_cont]': '' };
  }

  if (field === TranslationSubtasksFilterField.Document) {
    return { ...currentFilters, 's[initial_files_attached_eq]': value };
  }

  if (field === TranslationSubtasksFilterField.Status) {
    const subtaskStautses = value
      .split(',')
      .filter((status) =>
        Object.values(TranslationSubtaskStatus).includes(status as TranslationSubtaskStatus),
      );

    const otherStatuses = value
      .split(',')
      .filter((status) =>
        Object.values(TranslationSubtaskCustomerInput).includes(
          status as TranslationSubtaskCustomerInput,
        ),
      );

    const statuses = subtaskStautses.map((status) => statusCodes[status].toString());

    return {
      ...currentFilters,
      's[subtask_status_in]': statuses,
      's[order_customer_input_status_in]': otherStatuses,
    };
  }

  if (field === TranslationSubtasksFilterField.Owner) {
    let filterValue = value;
    const isPhone = value.startsWith('+47');

    if (isPhone) {
      filterValue = value.replaceAll(' ', '');
    }

    return { ...currentFilters, ...{ 's[owner_cont]': filterValue } };
  }

  if (field === TranslationSubtasksFilterField.Languages) {
    const languages = value.split(',');
    if (languages.length > 0) {
      return { ...currentFilters, 's[lang_id_in]': languages };
    }

    return { ...currentFilters };
  }

  if (field === TranslationSubtasksFilterField.ExternalDeadline) {
    const dates = value.split(',');
    return {
      ...currentFilters,
      's[external_deadline_gteq]': dates[0],
      's[external_deadline_lteq]': dates[1],
      's[s]': 'external_deadline',
    };
  }

  if (field === TranslationSubtasksFilterField.InternalDeadline) {
    const dates = value.split(',');
    return {
      ...currentFilters,
      's[internal_deadline_gteq]': dates[0],
      's[internal_deadline_lteq]': dates[1],
    };
  }

  if (field === TranslationSubtasksFilterField.CreatedAt) {
    const dates = value.split(',');

    return {
      ...currentFilters,
      's[order_created_at_gteq]': dates[0],
      's[order_created_at_lteq]': dates[1],
    };
  }

  if (field === TranslationSubtasksFilterField.Translator) {
    let filterValue = value;
    const isPhone = value.startsWith('+47');

    if (isPhone) {
      filterValue = value.replaceAll(' ', '');
    }

    return { ...currentFilters, ...{ 's[translator_cont]': filterValue } };
  }

  if (field === TranslationSubtasksFilterField.WordCount) {
    return { ...currentFilters, 's[wordcount_eq]': value };
  }

  return { ...currentFilters };
};

export default getTranslationSubtasksFilters;
