export enum TranslationSubtaskCustomerInput {
  Requested = 'requested',
  Updated = 'updated',
}

export enum TranslationSubtaskStatus {
  Draft = 'draft',
  ProactiveAccess = 'proactive_access',
  Invited = 'invited',
  Accepted = 'accepted',
  Rejected = 'rejected',
  InProgress = 'in_progress',
  ReviewRequested = 'review_requested',
  ChangesRequired = 'changes_required',
  Finished = 'finished',
  Cancelled = 'cancelled',
}

enum TranslationSubtaskOrderStatus {
  Draft = 'draft',
  QuoteRequested = 'quote_requested',
  QuoteSent = 'quote_sent',
  QuoteAccepted = 'quote_accepted',
  QuoteRejected = 'quote_rejected',
  InProgress = 'in_progress',
  Finished = 'finished',
  Cancelled = 'cancelled',
  Completed = 'completed',
}

interface TranslationSubtask {
  id: number;
  publicId: string;
  godmodeSupplierUrl?: string;
  godmodeDemanderUrl?: string;
  orderId: number;
  orderStatus: TranslationSubtaskOrderStatus;
  departmentName?: string;
  accessLevel: 'owner' | 'department' | 'enterprise';
  initialAttachmentsPresent: boolean;
  showOcrAttachmentsToSeller: boolean;
  deliveryMethod: 'email' | 'post';
  sendByPostAddressStatus?: 'pending' | 'approved' | 'rejected';
  sendByPostAddress?: string;
  sendByPostEnterpriseName?: string;
  sendByPostName?: string;
  sentByPost: boolean;
  ccEmails?: string[];
  orderCreatedAt: string;
  externalDeadline?: string;
  internalDeadline?: string;
  owner?: {
    uid: string;
    name: string;
    email: string;
    phone?: string;
    enterpriseName?: string;
    enterpriseId?: number;
  };
  subtaskStatus: TranslationSubtaskStatus;
  subtaskType: 'translation' | 'correction' | 'other';
  archived?: boolean;
  sourceLanguageId: number;
  targetLanguageId: number;
  alternativeLanguageAllowed: boolean;
  alternativeLanguageToId?: number;
  originalLanguageToId?: number;
  alternativeLanguageActivated: boolean;
  wordcount?: number;
  translator?: {
    uid: string;
    name: string;
    email: string;
    phone?: string;
    qualificationId?: number;
  };
  inviteStatus: {
    name: 'disabled' | 'accepted' | 'rejected' | 'proactive_access' | 'invited' | 'finished';
    translatorName?: string;
    actionPerformedAt?: string;
  };
  dangerLevel: 1 | 2 | 3 | 4 | 5 | 6 | 100;
  panicPercentage: number;
  externalDeadlinePanicDaysTerm: number;
  internalDeadlinePanicDaysTerm: number;
  wipAttachmentsPresent: boolean;
  finalAttachments: boolean;
  orderCustomerInputStatus?: TranslationSubtaskCustomerInput;
  orderCustomerInputUpdatedAt?: string;
  assignedManager?: {
    uid?: string;
    name?: string;
    avatar?: string;
  };
  note: {
    note?: string;
  };
}

export interface TranslationSubtasksResponse {
  subtasks: TranslationSubtask[];
  count: number;
  page: number;
  pages: number;
}

export interface TranslationSubtaskStats {
  totalCount: number;
  statusCounts: {
    draft: number;
    transferred: number;
    proactive_access: number;
    invited: number;
    applied: number;
    accepted: number;
    rejected: number;
    in_progress: number;
    review_requested: number;
    changes_required: number;
    finished: number;
    cancelled: number;
  };
}

export default TranslationSubtask;
