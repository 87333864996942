import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  faCircleCheck,
  faCircleXmark,
  faEnvelope,
  faFile,
  faLocationDot,
  faMars,
  faPen,
  faPhone,
  faVenus,
  faVideo,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActionsSection, Avatar, Tag, Tooltip } from '@skiwo/components';
import { formatPhoneNumber } from '@skiwo/utils';
import classnames from 'classnames';
import { format } from 'date-fns';
import translationKeys from '../../translations/translationKeys';
import {
  ManagerSupplier,
  ManagerSupplierAccountStatus,
  ManagerSupplierSex,
  ManagerSupplierStandBy,
  ManagerSupplierType,
} from '../../types';
import EditSupplierDrawers from '../Drawers/EditSupplierDrawers/EditSupplierDrawers';
import getGenderName from '../utils/getGenderName';
import getStandByName from '../utils/getStandByName';
import getStatusName from '../utils/getStatusName';
import getTypeName from '../utils/getTypeName';
import styles from './SupplierItem.module.scss';

enum SupplierPageDrawers {
  EditSupplier = 'EDIT_SUPPLIER',
}

interface Props {
  supplier: ManagerSupplier;
  onCompleteAction: (hardReload?: boolean) => void;
}

const SupplierItem = (props: Props) => {
  const { supplier, onCompleteAction } = props;
  const [openDrawer, setOpenDrawer] = useState<SupplierPageDrawers | null>();
  const intl = useIntl();
  const getTypeTagColor = () => {
    if (supplier.type === ManagerSupplierType.Interpreter) {
      return 'success';
    }

    if (supplier.type === ManagerSupplierType.Translator) {
      return 'info';
    }

    if (supplier.type === ManagerSupplierType.Both) {
      return 'warning';
    }

    return 'neutral';
  };

  const handleActionComplete = () => {
    onCompleteAction();
  };

  const getSupplierStatus = () => {
    if (supplier.accountStatus.name === ManagerSupplierAccountStatus.Paused) {
      return (
        <Tooltip title={supplier.accountStatus.comment}>
          <div>
            <div className={styles.accountStatus}>
              <FontAwesomeIcon icon={faCircleXmark} />
              <span className={styles.tableTitleText} data-testid="supplier-paused-label">
                <FormattedMessage id={translationKeys.suppliers_page_status_paused_until} />
              </span>
            </div>
            <span className={styles.details} data-testid="supplier-paused-expiry">
              {supplier.accountStatus.expiresAt
                ? format(new Date(supplier.accountStatus.expiresAt), 'dd.MM.yyyy HH:mm')
                : intl.formatMessage({
                    id: translationKeys.suppliers_page_status_paused_invalid_date,
                  })}
            </span>
          </div>
        </Tooltip>
      );
    }

    if (supplier.accountStatus.name === ManagerSupplierAccountStatus.Active) {
      return (
        <div className={styles.hints}>
          <Tooltip
            title={intl.formatMessage({
              id: translationKeys.supplier_page_phone_icon_tooltip,
            })}
          >
            <FontAwesomeIcon
              data-testid="phone-session-icon"
              className={classnames(styles.icon, {
                [styles.active]:
                  supplier.interpretation.sessionTypesStatuses?.phone.name === 'active',
              })}
              icon={faPhone}
            />
          </Tooltip>
          <Tooltip
            title={intl.formatMessage({
              id: translationKeys.supplier_page_video_icon_tooltip,
            })}
          >
            <FontAwesomeIcon
              data-testid="video-session-icon"
              className={classnames(styles.icon, {
                [styles.active]:
                  supplier.interpretation.sessionTypesStatuses?.video.name === 'active',
              })}
              icon={faVideo}
            />
          </Tooltip>
          <Tooltip
            title={intl.formatMessage({
              id: translationKeys.supplier_page_in_person_icon_tooltip,
            })}
          >
            <FontAwesomeIcon
              data-testid="in-person-session-icon"
              className={classnames(styles.icon, {
                [styles.active]:
                  supplier.interpretation.sessionTypesStatuses?.in_person.name === 'active',
              })}
              icon={faLocationDot}
            />
          </Tooltip>
          <Tooltip
            title={intl.formatMessage({
              id: translationKeys.supplier_page_translation_icon_tooltip,
            })}
          >
            <FontAwesomeIcon
              data-testid="translation-icon"
              className={classnames(styles.icon, {
                [styles.active]:
                  supplier.type === ManagerSupplierType.Translator ||
                  supplier.type === ManagerSupplierType.Both,
              })}
              icon={faFile}
            />
          </Tooltip>
        </div>
      );
    }

    return (
      <div className={styles.accountStatus}>
        <FontAwesomeIcon icon={faCircleXmark} />
        <span className={styles.tableTitleText} data-testid="supplier-status-label">
          {getStatusName(supplier.accountStatus.name, intl)}
        </span>
      </div>
    );
  };

  const getSupplierStatistic = () => {
    const { invited, withdrawn, showedUp, late, noShow } = supplier.interpretation.statistics;
    return (
      <div className={styles.statistic}>
        <div>
          <span className={styles.itemValue}>{invited || 0}</span>
          <span className={styles.itemLabel}>
            <FormattedMessage id={translationKeys.suppliers_page_statistic_invited_cell} />
          </span>
        </div>
        <div>
          <span className={styles.itemValue}>{withdrawn || 0}</span>
          <span className={styles.itemLabel}>
            <FormattedMessage id={translationKeys.suppliers_page_statistic_withdrawn_cell} />
          </span>
        </div>
        <div>
          <span className={styles.itemValue}>{showedUp || 0}</span>
          <span className={styles.itemLabel}>
            <FormattedMessage id={translationKeys.suppliers_page_statistic_showedup_cell} />
          </span>
        </div>
        <div>
          <span className={styles.itemValue}>{late || 0}</span>
          <span className={styles.itemLabel}>
            <FormattedMessage id={translationKeys.suppliers_page_statistic_late_cell} />
          </span>
        </div>
        <div>
          <span className={styles.itemValue}>{noShow || 0}</span>
          <span className={styles.itemLabel}>
            <FormattedMessage id={translationKeys.suppliers_page_statistic_noshow_cell} />
          </span>
        </div>
      </div>
    );
  };

  return (
    <tr className={styles.itemRow} data-testid="supplier-item">
      <td className={styles.tableTitleText} data-testid="supplier-id">
        <a target="_blank" href={supplier.godmodeSupplierUrl} rel="noreferrer">
          {supplier.id}
        </a>
      </td>
      <td>
        <Tag color={getTypeTagColor()}>{getTypeName(supplier.type, intl)}</Tag>
      </td>
      <td>
        <span className={styles.indicatorIcons}>
          <Tooltip title={supplier.email} copyable>
            <FontAwesomeIcon
              data-testid="email-indicator"
              icon={faEnvelope}
              className={classnames(styles.icon, { [styles.active]: !!supplier.email })}
            />
          </Tooltip>

          <Tooltip title={formatPhoneNumber(supplier.phone)} copyable>
            <FontAwesomeIcon
              data-testid="phone-indicator"
              icon={faPhone}
              className={classnames(styles.icon, { [styles.active]: !!supplier.phone })}
            />
          </Tooltip>

          <Avatar
            altText={supplier.name || supplier.email}
            url={supplier.avatarUrl}
            active={supplier.accountStatus.name === ManagerSupplierAccountStatus.Active}
          />

          <Tooltip title={supplier.name || supplier.email} copyable>
            <span className={styles.tableTitleText} data-testid="supplier-name">
              {supplier.name || supplier.email}
            </span>
          </Tooltip>
        </span>
      </td>
      <td>{getSupplierStatus()}</td>
      <td>
        <span className={styles.indicatorIcons}>
          {supplier.sex === ManagerSupplierSex.Male && (
            <FontAwesomeIcon
              icon={faMars}
              className={classnames(styles.icon, styles.active)}
              data-testid="male-icon"
            />
          )}
          {supplier.sex === ManagerSupplierSex.Female && (
            <FontAwesomeIcon
              icon={faVenus}
              className={classnames(styles.icon, styles.active)}
              data-testid="female-icon"
            />
          )}
        </span>

        <span className={styles.tableTitleText} data-testid="supplier-gender">
          {supplier.sex && getGenderName(supplier.sex, intl)}
        </span>
      </td>

      <td data-testid="supplier-standby">
        <span className={styles.indicatorIcons}>
          {supplier.interpretation.standby === ManagerSupplierStandBy.Yes ? (
            <FontAwesomeIcon
              icon={faCircleCheck}
              className={classnames(styles.icon, styles.active)}
              data-testid="standby-icon"
            />
          ) : (
            '-'
          )}
        </span>

        <span className={styles.tableTitleText} data-testid="supplier-standby-text">
          {supplier.interpretation.standby === ManagerSupplierStandBy.Yes &&
            getStandByName(supplier.interpretation.standby, intl)}
        </span>
      </td>

      <td data-testid="supplier-statistic">{getSupplierStatistic()}</td>
      <td>
        <ActionsSection
          actionItems={[
            {
              title: intl.formatMessage({
                id: translationKeys.supplier_page_edit_supplier_tooltip,
              }),
              icon: faPen,
              key: SupplierPageDrawers.EditSupplier,
              ['data-testid']: 'supplier-edit-action',
            },
          ]}
          onActionSelected={(key) => setOpenDrawer(key as SupplierPageDrawers)}
        />

        <EditSupplierDrawers
          show={openDrawer === SupplierPageDrawers.EditSupplier}
          onClose={() => setOpenDrawer(null)}
          supplier={supplier}
          onComplete={handleActionComplete}
        />
      </td>
    </tr>
  );
};

export default SupplierItem;
