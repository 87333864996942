import { IntlShape } from 'react-intl';
import translationKeys from '../../translations/translationKeys';
import { ManagerSupplierType } from '../../types';

const getTypeName = (type: ManagerSupplierType, intl: IntlShape): string => {
  if (type === ManagerSupplierType.Interpreter) {
    return intl.formatMessage({ id: translationKeys.suppliers_page_supplier_type_interpreter });
  }

  if (type === ManagerSupplierType.Translator) {
    return intl.formatMessage({ id: translationKeys.suppliers_page_supplier_type_translator });
  }

  if (type === ManagerSupplierType.Both) {
    return intl.formatMessage({ id: translationKeys.suppliers_page_supplier_type_both });
  }

  return intl.formatMessage({ id: translationKeys.suppliers_page_supplier_type_undefined });
};

export default getTypeName;
