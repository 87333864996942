interface Qualification {
  id: number;
  enabled: boolean;
  industry: QualificationIndustry;
  isInTolkeregister: boolean;
  justicePrice: number;
  name: string;
  norwegianOnly: boolean;
  priority: number;
  shortName: string;
  tolkeregisterName: string;
}

export enum QualificationIndustry {
  Interpretation = 'interpretation',
  Translation = 'translation',
}

export default Qualification;
