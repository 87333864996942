import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  faArrowDownToLine,
  faBoxArchive,
  faCalendar,
  faFile,
  faLock,
  faTrash,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DetailItem, DropdownItem, Separator, Tooltip } from '@skiwo/components';
import { format } from 'date-fns';
import defaultFilePreview from '../../assets/default-file-preview.svg';
import unarchive from '../../assets/icons/unarchive.svg';
import translationKeys from '../../translations/translationKeys';
import { ManagerOrderAttachment } from '../../types';
import styles from './GroupedFileList.module.scss';

interface Props {
  files?: ManagerOrderAttachment[];
  onDownload?: (file: ManagerOrderAttachment) => void;
  onDelete?: (file: ManagerOrderAttachment) => void;
  onArchive?: (file: ManagerOrderAttachment) => void;
  onUnarchive?: (file: ManagerOrderAttachment) => void;
}

const GroupedFileList = (props: Props) => {
  const { files, onDownload, onDelete, onArchive, onUnarchive } = props;
  const intl = useIntl();

  const availableFiles = files?.filter((file) => !file.archived) || [];
  const archivedFiles = files?.filter((file) => file.archived) || [];

  if (!(!!availableFiles.length || !!archivedFiles.length)) {
    return (
      <div className={styles.noFiles} data-testid="grouped-file-list-no-files">
        <FontAwesomeIcon className={styles.icon} icon={faFile} />
        <span>
          <FormattedMessage id={translationKeys.translation_order_no_files} />
        </span>
      </div>
    );
  }

  const getGroupedFiles = () => {
    return availableFiles.reduce((fileList: any, file) => {
      const createdAt = new Date(file.createdAt);
      const createdDate = format(createdAt, 'yyyy-MM-dd');

      if (!fileList[createdDate]) {
        fileList[createdDate] = [];
      }

      fileList[createdDate].push(file);

      return fileList;
    }, {});
  };

  const getActions = () => {
    const actions: DropdownItem[] = [];

    if (onDownload)
      actions.push({
        id: 'Download',
        text: intl.formatMessage({ id: translationKeys.file_uploader_actions_download }),
        icon: faArrowDownToLine,
      });

    if (onDelete)
      actions.push({
        id: 'Delete',
        text: intl.formatMessage({ id: translationKeys.file_uploader_actions_delete }),
        icon: faTrash,
        variant: 'critical',
      });

    if (onArchive)
      actions.push({
        id: 'Archive',
        text: intl.formatMessage({ id: translationKeys.translation_order_archive_file }),
        icon: faBoxArchive,
      });

    return actions;
  };

  return (
    <div className={styles.filesContainer} data-testid="grouped-file-list">
      {Object.keys(getGroupedFiles()).map((date) => {
        return (
          <div className={styles.fileList} key={date}>
            <Separator
              icon={<FontAwesomeIcon icon={faCalendar} />}
              text={format(new Date(date), 'dd.MM.yyyy')}
            />

            {getGroupedFiles()[date].map((file: ManagerOrderAttachment) => (
              <DetailItem
                key={file.id}
                subtitle={
                  <div className={styles.fileSubtitle}>
                    {file.byteSize && (
                      <span>{parseFloat((file.byteSize / (1024 * 1024)).toFixed(2))}Mb</span>
                    )}
                    {!!file.countOfWords && (
                      <>
                        <span>•</span>
                        <span data-testid="word-count">{file.countOfWords}</span>
                        <span>w</span>
                      </>
                    )}
                  </div>
                }
                title={
                  <div className={styles.titleContainer}>
                    {file.filePassword && (
                      <Tooltip title={file.filePassword} copyable hideTitle={true}>
                        <FontAwesomeIcon icon={faLock} className={styles.passwordProtected} />
                      </Tooltip>
                    )}
                    <Tooltip title={file.name}>
                      <span className={styles.title}>{file.name}</span>
                    </Tooltip>
                    {file.ocr && (
                      <span className={styles.ocrBadge} data-testid="ocr-badge">
                        OCR
                      </span>
                    )}
                  </div>
                }
                size="small"
                // TODO: Figure out the thumbnail (works only for images right now)
                preview={
                  file.base64 ? (
                    <img src={`data:${file.contentType};base64,${file.base64}`} height="40" />
                  ) : (
                    <img src={defaultFilePreview} height="40" />
                  )
                }
                actions={getActions()}
                onActionSelect={(id) => {
                  if (id === 'Download' && onDownload) {
                    onDownload(file);
                  }
                  if (id === 'Delete' && onDelete) {
                    onDelete(file);
                  }
                  if (id === 'Archive' && onArchive) {
                    onArchive(file);
                  }
                }}
                data-testid="file-item"
              />
            ))}
          </div>
        );
      })}
      {!!archivedFiles.length && (
        <Separator
          icon={<FontAwesomeIcon icon={faBoxArchive} />}
          text={intl.formatMessage({
            id: translationKeys.translation_order_files_list_archived_label,
          })}
        />
      )}
      <div className={styles.fileList}>
        {archivedFiles.map((file: ManagerOrderAttachment) => (
          <div key={file.id} className={styles.archivedItem}>
            <DetailItem
              variant="archived"
              subtitle={
                <div className={styles.fileSubtitle}>
                  {file.byteSize && (
                    <span>{parseFloat((file.byteSize / (1024 * 1024)).toFixed(2))}Mb</span>
                  )}
                  {!!file.countOfWords && (
                    <>
                      <span>•</span>
                      <span data-testid="word-count">{file.countOfWords}</span>
                      <span>w</span>
                    </>
                  )}
                </div>
              }
              title={
                <div className={styles.titleContainer}>
                  {file.filePassword && (
                    <Tooltip title={file.filePassword} copyable hideTitle={true}>
                      <FontAwesomeIcon icon={faLock} className={styles.passwordProtected} />
                    </Tooltip>
                  )}
                  <span className={styles.title}>{file.name}</span>
                  {file.ocr && (
                    <span className={styles.ocrBadge} data-testid="ocr-badge">
                      OCR
                    </span>
                  )}
                </div>
              }
              size="small"
              // TODO: Figure out the thumbnail (works only for images right now)
              preview={
                file.base64 ? (
                  <img src={`data:${file.contentType};base64,${file.base64}`} height="40" />
                ) : (
                  <img src={defaultFilePreview} height="40" />
                )
              }
              actions={[
                {
                  id: 'Download',
                  text: intl.formatMessage({ id: translationKeys.file_uploader_actions_download }),
                  icon: faArrowDownToLine,
                },
                {
                  id: 'Unarchive',
                  text: intl.formatMessage({
                    id: translationKeys.translation_order_unarchive_file,
                  }),
                  customIcon: <img src={unarchive} className={styles.icon} />,
                },
              ]}
              onActionSelect={(id) => {
                if (id === 'Download' && onDownload) {
                  onDownload(file);
                }
                if (id === 'Unarchive' && onUnarchive) {
                  onUnarchive(file);
                }
              }}
              data-testid="file-item"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default GroupedFileList;
