import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form } from '@skiwo/components';
import padScreenWithFinger from '../../assets/illustration-pad-screen-with-finger.svg';
import { AuthLayout } from '../../layouts';
import { useApi } from '../../providers/ApiProvider';
import { useAuth } from '../../providers/AuthProvider';
import translationKeys from '../../translations/translationKeys';
import styles from './CheckEmail.module.scss';

const CheckEmail = () => {
  const navigate = useNavigate();
  const { username } = useAuth();
  const intl = useIntl();
  const api = useApi();
  const [isLoading, setIsLoading] = useState(false);

  const resetPassword = async (username: string) => {
    await api.resetPassword(username, setIsLoading);
  };

  useEffect(() => {
    if (!username) {
      navigate('/forgot-password');
    }
  }, []);

  const handleSubmit = () => {
    resetPassword(username);
  };

  function handleBackToLogin() {
    navigate('/login');
  }

  return (
    <AuthLayout>
      <Form
        title={intl.formatMessage({ id: translationKeys.check_email_header_title })}
        description={
          <>
            <span className={styles.headerDescription}>
              <FormattedMessage id={translationKeys.check_email_header_description} />
            </span>
            <span className={styles.email} data-testid="username">
              {username}
            </span>
          </>
        }
        image={padScreenWithFinger}
        onSubmit={handleSubmit}
      >
        <Button size="x-large" type="submit" fullWidth data-testid="resend-email-button">
          <FormattedMessage id={translationKeys.check_email_resend_button} />
        </Button>
        <Button
          icon={<FontAwesomeIcon icon={faArrowLeft} />}
          fullWidth
          iconPosition="left"
          size="large"
          variant="link"
          onClick={handleBackToLogin}
          data-testid="back-to-login-button"
          isLoading={isLoading}
        >
          <FormattedMessage id={translationKeys.check_email_back_to_login_button} />
        </Button>
      </Form>
    </AuthLayout>
  );
};

export default CheckEmail;
