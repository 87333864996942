import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Tabs } from '@skiwo/components';
import { stripObject } from '@skiwo/utils';
import { ApiError } from '../Api';
import { useApi } from '../providers/ApiProvider';
import translationKeys from '../translations/translationKeys';
import { InterpretationSkill, InterpretationSkillsStatus } from '../types';
import { InterpretationSkillsStatistics } from '../types/InterpretationSkill';
import NewSkillDrawer from './Drawers/NewSkillDrawer/NewSkillDrawer';
import InterpretationSkillsTable from './InterpretationSkillsTable/InterpretationSkillsTable';
import getInterpretationSkillsFilters from './utils/getInterpretationSkillsFilters';
import getStatusName from './utils/getStatusName';
import InterpretationSkillsFilterField from './InterpretationSkillsFilterField';
import InterpretationSkillsTab from './InterpretationSkillsTab';
import styles from './InterpretationSkills.module.scss';

const InterpretationSkills = () => {
  const [skills, setSkills] = useState<InterpretationSkill[]>([]);
  const [statistics, setStatistics] = useState<InterpretationSkillsStatistics>();
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [newSkillDrawerOpen, setNewSkillDrawerOpen] = useState(false);
  const intl = useIntl();
  const [activeTab, setActiveTab] = useState('');
  const [filters, setFilters] = useState<Record<string, string | string[]>>({});
  const api = useApi();
  const [skillsLoading, setSkillsLoading] = useState<boolean>(false);
  const [skillsError, setSkillsError] = useState<ApiError | null>(null);

  const tabItems = [
    {
      active: true,
      count: statistics?.approved ? statistics.approved : undefined,
      id: InterpretationSkillsTab.Approved,
      title: getStatusName(InterpretationSkillsStatus.Approved, intl),
      filters: {
        's[status_eq]': 30,
      },
    },
    {
      count: statistics?.learning ? statistics.learning : undefined,
      id: InterpretationSkillsTab.Learning,
      title: getStatusName(InterpretationSkillsStatus.Learning, intl),
      filters: {
        's[status_eq]': 20,
      },
    },
    {
      count: statistics?.pending ? statistics.pending : undefined,
      id: InterpretationSkillsTab.Pending,
      title: getStatusName(InterpretationSkillsStatus.Pending, intl),
      filters: {
        's[status_eq]': 10,
      },
    },
    {
      count: statistics?.declined ? statistics.declined : undefined,
      id: InterpretationSkillsTab.Declined,
      title: getStatusName(InterpretationSkillsStatus.Declined, intl),
      filters: {
        's[status_eq]': 40,
      },
    },
    {
      count: statistics?.all ? statistics.all : undefined,
      id: InterpretationSkillsTab.All,
      title: intl.formatMessage({
        id: translationKeys.interpretation_skills_page_all_tab,
      }),
      filters: {},
    },
  ];

  const getTabFilters = () => {
    const activeTabItem = tabItems.find((item) => item.id === activeTab);

    if (activeTabItem) {
      return activeTabItem.filters;
    }
  };

  const handleFilterChange = (field: InterpretationSkillsFilterField, value: string) => {
    setFilters(getInterpretationSkillsFilters(filters, field, value));
  };

  const fetchInterpretationSkill = async (page: number, clearCache = false) => {
    const { data, error } = await api.getInterpretationSkills(
      {
        page,
        items: 20,
        's[s]': 'id desc',
        ...getTabFilters(),
        ...stripObject(filters),
      },
      setSkillsLoading,
    );

    if (data?.skills) {
      let allSkills = skills;
      if (!clearCache) {
        for (const newSkill of data.skills) {
          const newSkillIndex = allSkills.findIndex((skill) => skill.id === newSkill.id);

          if (newSkillIndex === -1) {
            allSkills.push(newSkill);
          } else {
            allSkills[newSkillIndex] = newSkill;
          }
        }
      } else {
        allSkills = data.skills;
      }

      setStatistics(data.statistics);
      setSkills(allSkills);
      setPage(data.page);
      setPages(data.pages);
    }

    if (error) setSkillsError(error);
  };

  const handleLoadMore = () => {
    fetchInterpretationSkill(page + 1);
  };

  const handleReloadData = (hardReload = false) => {
    fetchInterpretationSkill(page, hardReload);
  };

  const handleTabSelect = (newActiveTab: string) => {
    if (newActiveTab !== activeTab) {
      setSkills([]);
      setPage(1);
      setActiveTab(newActiveTab);
    }
  };

  useEffect(() => {
    if (Object.keys(filters).length === 0) {
      return;
    }

    setSkills([]);
    fetchInterpretationSkill(1, true);
  }, [filters]);

  useEffect(() => {
    if (activeTab) {
      fetchInterpretationSkill(page);
    }
  }, [activeTab]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <h1 data-testid="interpretation-skills-header">
            <FormattedMessage id={translationKeys.interpretation_skills_page_title} />
          </h1>

          <div>
            <Button
              icon={<FontAwesomeIcon icon={faPlus} />}
              data-testid="new-skill-button"
              onClick={() => setNewSkillDrawerOpen(true)}
            >
              <FormattedMessage id={translationKeys.interpretation_skills_page_new_skill_button} />
            </Button>
          </div>
        </div>

        <div className={styles.tabsContainer}>
          <Tabs items={tabItems} onSelect={handleTabSelect} />
        </div>

        <div className={styles.tableContainer}>
          <InterpretationSkillsTable
            skills={skills}
            error={skillsError}
            isLoading={skillsLoading}
            onFilterChange={handleFilterChange}
            onCompleteAction={handleReloadData}
          />
        </div>

        {!skillsLoading && skills && page < pages && (
          <div className={styles.loadMoreButton}>
            <Button
              variant="secondary"
              size="large"
              onClick={handleLoadMore}
              data-testid="load-more-button"
            >
              <FormattedMessage id={translationKeys.interpretation_skills_page_load_more_button} />
            </Button>
          </div>
        )}
      </div>

      <NewSkillDrawer
        show={newSkillDrawerOpen}
        onClose={() => setNewSkillDrawerOpen(false)}
        onComplete={() => handleReloadData(true)}
      />
    </>
  );
};

export default InterpretationSkills;
