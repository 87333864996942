import React, { Fragment } from 'react';
import classNames from 'classnames';
import styles from './Stepper.module.scss';

interface StepperProps {
  steps: string[];
  currentStep: number;
}

const Stepper = ({ steps, currentStep }: StepperProps) => {
  const isCompleted = (i: number) => {
    return i + 1 <= currentStep;
  };

  return (
    <div className={styles.stepper}>
      {steps.map((step, i) => (
        <Fragment key={step}>
          <div className={classNames(styles.step, isCompleted(i) && styles['step--completed'])}>
            <span
              className={classNames(
                styles.stepNumber,
                isCompleted(i) && styles['stepNumber--completed'],
              )}
            >
              {i + 1}
            </span>
            <span className={classNames(isCompleted(i) && styles['stepName--completed'])}>
              {step}
            </span>
          </div>
          {i !== steps.length - 1 && <div className={styles.stepLine} />}
        </Fragment>
      ))}
    </div>
  );
};

export default Stepper;
