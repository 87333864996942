import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Drawer, SearchDropdown, SearchDropdownMenuOption } from '@skiwo/components';
import { useApi } from '../../../../providers/ApiProvider';
import { useLanguages } from '../../../../providers/LanguagesProvider';
import { useToast } from '../../../../providers/ToastProvider/ToastProvider';
import translationKeys from '../../../../translations/translationKeys';
import { ManagerOrder } from '../../../../types';
import styles from './CreateTaskDrawer.module.scss';

interface Props {
  show?: boolean;
  order: ManagerOrder;
  onClose: () => void;
}

const CreateTaskDrawer = (props: Props) => {
  const { show, onClose, order } = props;
  const { languages } = useLanguages();
  const [selectedLanguage, setSelectedLanguage] = useState<SearchDropdownMenuOption>();
  const [createTaskLoading, setCreateTaskLoading] = useState(false);
  const api = useApi();
  const intl = useIntl();
  const { showErrorToast } = useToast();

  const languageOptions =
    languages
      .filter((language) => language.id !== order.sourceLanguageId)
      .map((language) => {
        return { id: language.id, label: language.name || '', key: language.id.toString() };
      }) || [];

  const handleSubmit = async () => {
    if (!selectedLanguage) return;

    const { data, error } = await api.createTask(
      order.id.toString(),
      selectedLanguage.id.toString(),
      setCreateTaskLoading,
    );

    if (data) {
      onClose();
    }

    if (error) {
      showErrorToast(error);
    }
  };

  return (
    <Drawer
      show={show}
      onClose={onClose}
      title={intl.formatMessage({ id: translationKeys.translation_order_create_task_title })}
      data-testid="new-task-drawer"
    >
      <Form className={styles.createTask}>
        <SearchDropdown
          options={languageOptions}
          placeholder={intl.formatMessage({
            id: translationKeys.translation_order_create_task_target_language_placeholder,
          })}
          label={intl.formatMessage({
            id: translationKeys.translation_order_create_task_target_language_label,
          })}
          size="large"
          onChange={(language) => {
            if (language && language.length > 0 && language[0].key) {
              setSelectedLanguage(language[0]);
            }
          }}
        />

        <div className={styles.actions}>
          <Button size="large" variant="gray" onClick={onClose} data-testid="cancel-button">
            <FormattedMessage id={translationKeys.translation_order_create_task_cancel_button} />
          </Button>
          <Button
            size="large"
            onClick={handleSubmit}
            isLoading={createTaskLoading}
            disabled={!selectedLanguage}
          >
            <FormattedMessage id={translationKeys.translation_order_create_task_create_button} />
          </Button>
        </div>
      </Form>
    </Drawer>
  );
};

export default CreateTaskDrawer;
