import React from 'react';
import { useIntl } from 'react-intl';
import { faLocationDot, faPhone, faVideo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@skiwo/components';
import { format } from 'date-fns';
import locationCrossed from '../../../assets/icons/location-crossed.svg';
import locationWithPencil from '../../../assets/icons/location-with-pencil.svg';
import phoneWithArrow from '../../../assets/icons/phone-with-arrow.svg';
import starVideo from '../../../assets/icons/star-video.svg';
import starVideoCrossed from '../../../assets/icons/star-video-crossed.svg';
import starVideoPencil from '../../../assets/icons/star-video-pencil.svg';
import starVideoArrow from '../../../assets/icons/star-video-with-arrow.svg';
import videoCrossed from '../../../assets/icons/video-crossed.svg';
import videoWithArrow from '../../../assets/icons/video-with-arrow.svg';
import videoWithPencil from '../../../assets/icons/video-with-pencil.svg';
import translationKeys from '../../../translations/translationKeys';
import {
  ManagerJobAutoUpdateToSessionType,
  ManagerJobSessionType,
  ManagerJobSessionTypeProcessing,
  ManagerJobVideoPlatformName,
} from '../../../types';
import styles from './Hint.module.scss';

interface Props {
  sessionType: ManagerJobSessionType;
  videoPlatformName?: ManagerJobVideoPlatformName;
  autoUpdateToSessionType?: ManagerJobAutoUpdateToSessionType;
  sessionTypeProcessing?: ManagerJobSessionTypeProcessing;
  autoUpdateTime?: string;
}

const SessionTypeHint = (props: Props) => {
  const {
    sessionType,
    videoPlatformName,
    autoUpdateToSessionType,
    sessionTypeProcessing,
    autoUpdateTime,
  } = props;
  const autoUpdateTimestamp = new Date(autoUpdateTime || '');
  const intl = useIntl();

  if (sessionType === ManagerJobSessionType.Phone) {
    if (autoUpdateToSessionType === ManagerJobAutoUpdateToSessionType.Phone) {
      return (
        <Tooltip
          title={intl.formatMessage({
            id: videoPlatformName
              ? translationKeys.jobs_page_session_type_phone_from_video_tooltip
              : translationKeys.jobs_page_session_type_phone_from_in_person_tooltip,
          })}
          subtitle={format(autoUpdateTimestamp, 'dd.MM.yyyy HH:mm')}
        >
          <img
            data-testid="session-auto-update-phone"
            src={phoneWithArrow}
            className={styles.icon}
          />
        </Tooltip>
      );
    }

    return (
      <Tooltip
        title={intl.formatMessage({
          id: translationKeys.jobs_page_phone_assignment_tooltip,
        })}
      >
        <FontAwesomeIcon data-testid="session-phone" icon={faPhone} className={styles.icon} />
      </Tooltip>
    );
  }

  // video
  if (
    sessionType === ManagerJobSessionType.Video ||
    sessionType === ManagerJobSessionType.VideoRoom
  ) {
    const isSalita = videoPlatformName === ManagerJobVideoPlatformName.Salita;

    switch (sessionTypeProcessing) {
      case ManagerJobSessionTypeProcessing.SessionTypeNoAction:
        if (isSalita) {
          return (
            <Tooltip
              title={intl.formatMessage({
                id: translationKeys.jobs_page_video_assignment_tooltip,
              })}
            >
              <FontAwesomeIcon data-testid="session-video" icon={faVideo} className={styles.icon} />
            </Tooltip>
          );
        }

        return (
          <Tooltip
            title={intl.formatMessage({
              id: translationKeys.jobs_page_video_assignment_not_salita_tooltip,
            })}
          >
            <img data-testid="session-video-not-salita" src={starVideo} className={styles.icon} />
          </Tooltip>
        );

      case ManagerJobSessionTypeProcessing.SessionTypeCancelNotFound:
        return (
          <Tooltip
            title={intl.formatMessage(
              {
                id: translationKeys.jobs_page_session_type_cancel_video_tooltip,
              },
              {
                videoPlatform: videoPlatformName,
              },
            )}
            subtitle={format(autoUpdateTimestamp, 'dd.MM.yyyy HH:mm')}
          >
            {isSalita ? (
              <img
                data-testid="session-video-not-found"
                src={videoCrossed}
                className={styles.icon}
              />
            ) : (
              <img
                data-testid="session-video--not-salita-not-found"
                src={starVideoCrossed}
                className={styles.icon}
              />
            )}
          </Tooltip>
        );

      case ManagerJobSessionTypeProcessing.SessionTypeAutoUpdate:
        if (autoUpdateToSessionType === ManagerJobAutoUpdateToSessionType.Video) {
          return (
            <Tooltip
              title={intl.formatMessage(
                { id: translationKeys.jobs_page_session_type_video_from_in_person_tooltip },
                {
                  videoPlatform: videoPlatformName,
                },
              )}
              subtitle={format(autoUpdateTimestamp, 'dd.MM.yyyy HH:mm')}
            >
              {isSalita ? (
                <img
                  data-testid="session-auto-update-video"
                  src={videoWithArrow}
                  className={styles.icon}
                />
              ) : (
                <img
                  data-testid="session-not-salita-auto-update-video"
                  src={starVideoArrow}
                  className={styles.icon}
                />
              )}
            </Tooltip>
          );
        }

        return (
          <Tooltip
            title={intl.formatMessage(
              { id: translationKeys.jobs_page_session_type_video_to_phone_tooltip },
              {
                videoPlatform: videoPlatformName,
              },
            )}
            subtitle={format(autoUpdateTimestamp, 'dd.MM.yyyy HH:mm')}
          >
            {isSalita ? (
              <img
                data-testid="session-downgrade-video"
                src={isSalita ? videoWithPencil : starVideoPencil}
                className={styles.icon}
              />
            ) : (
              <img
                data-testid="session-not-salita-downgrade-video"
                src={isSalita ? videoWithPencil : starVideoPencil}
                className={styles.icon}
              />
            )}
          </Tooltip>
        );
    }
  }

  // In person
  if (sessionType === ManagerJobSessionType.InPerson) {
    switch (sessionTypeProcessing) {
      case ManagerJobSessionTypeProcessing.SessionTypeNoAction:
        return (
          <Tooltip
            title={intl.formatMessage({
              id: translationKeys.jobs_page_in_person_tooltip,
            })}
          >
            <FontAwesomeIcon
              data-testid="session-in-person"
              icon={faLocationDot}
              className={styles.icon}
            />
          </Tooltip>
        );

      case ManagerJobSessionTypeProcessing.SessionTypeCancelNotFound:
        return (
          <Tooltip
            title={intl.formatMessage({
              id: translationKeys.jobs_page_session_type_cancel_in_person_tooltip,
            })}
            subtitle={format(autoUpdateTimestamp, 'dd.MM.yyyy HH:mm')}
          >
            <img
              data-testid="session-in-person-not-found"
              src={locationCrossed}
              className={styles.icon}
            />
          </Tooltip>
        );

      case ManagerJobSessionTypeProcessing.SessionTypeAutoUpdate:
        return (
          <Tooltip
            title={intl.formatMessage(
              {
                id: translationKeys.jobs_page_session_type_in_person_to_video_tooltip,
              },
              {
                sessionType: autoUpdateToSessionType,
              },
            )}
            subtitle={format(autoUpdateTimestamp, 'dd.MM.yyyy HH:mm')}
          >
            <img
              data-testid="session-downgrade-in-person"
              src={locationWithPencil}
              className={styles.icon}
            />
          </Tooltip>
        );
    }
  }

  return <></>;
};

export default SessionTypeHint;
