import React from 'react';
import Badge from 'react-bootstrap/Badge';
import classnames from 'classnames';
import styles from './SystemLogTag.module.scss';

export type SystemLogTagColor = 'success' | 'error' | 'info' | 'warning' | 'primary' | 'neutral';

interface Props {
  children: React.ReactNode;
  color?: SystemLogTagColor;
  'data-testId'?: string;
  className?: string;
}

const SystemLogTag = (props: Props) => {
  const { children, color = 'success', className } = props;

  return (
    <Badge
      bg=""
      className={classnames(styles.systemLogTag, styles[color], className)}
      data-testid={props['data-testId'] || 'systemLogTag'}
    >
      <span data-testid="systemLogTag-text">{children}</span>
    </Badge>
  );
};

export default SystemLogTag;
