import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { faEllipsis, faPen, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dropdown, TextField } from '@skiwo/components';
import { format } from 'date-fns';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { useApi } from '../../../providers/ApiProvider';
import { useAuth } from '../../../providers/AuthProvider';
import translationKeys from '../../../translations/translationKeys';
import { ManagerJob, Message } from '../../../types';
import styles from './JobNotesList.module.scss';

interface JobNotesListProps {
  jobNotesList: Message[];
  job: ManagerJob;
  onDeleteNote?: () => void;
}

const JobNotesList: React.FC<JobNotesListProps> = ({ jobNotesList, job, onDeleteNote }) => {
  const [jobNotesListData, setJobNotesListData] = useState<Message[]>(jobNotesList);
  const [selectedNoteId, setSelectedNoteId] = useState<number | null>(null);
  const [showEditForm, setShowEditForm] = useState<boolean>(false);
  const intl = useIntl();
  const api = useApi();
  const { userProfile } = useAuth();

  const updateNote = (updatedNote: string, noteId: number) => {
    api.updateJobNote(job.id.toString(), noteId.toString(), updatedNote);
  };

  const handleUpdateNote = (updatedNote: string, noteId: number, index: number) => {
    updateNote(updatedNote, noteId);

    const updatedNotesList = [...jobNotesListData];
    updatedNotesList[index].text = updatedNote;
    setJobNotesListData(updatedNotesList);
  };

  const deleteNote = (noteId: number) => {
    api.deleteJobNote(job.id.toString(), noteId.toString()).then(() => {
      const updatedNotesList = jobNotesListData.filter((note) => note.id !== noteId);
      setJobNotesListData(updatedNotesList);
    });
  };

  const handleDeleteNote = (noteId: number) => {
    deleteNote(noteId);
    setSelectedNoteId(null);

    if (onDeleteNote) {
      onDeleteNote();
    }
  };

  const schema = yup.object().shape({
    note: yup
      .string()
      .required(
        intl.formatMessage({ id: translationKeys.job_notes_edit_textfield_required_error_message }),
      ),
  });

  const handleSubmit = () => {
    setSelectedNoteId(null);
    setShowEditForm(false);
  };

  useEffect(() => {
    setJobNotesListData(jobNotesList);
  }, [jobNotesList]);

  return (
    <div className={styles.container} data-testid="notes-drawer-list">
      {jobNotesListData?.map((note: Message, index: number) => {
        if (note?.text) {
          return (
            <div key={note.id} className={styles.noteContainer} data-testid="note-item">
              {(selectedNoteId !== note.id || !showEditForm) && (
                <div className={styles.noteCard}>
                  <div className={styles.noteContent}>
                    <span>{note.text}</span>
                    {userProfile?.uid === note.author.uid && (
                      <span data-testid="edit-options-dropdown">
                        <Dropdown
                          toggle={
                            <FontAwesomeIcon icon={faEllipsis} className={styles.noteOptions} />
                          }
                          items={[
                            {
                              id: 'Edit',
                              text: intl.formatMessage({
                                id: translationKeys.job_notes_drawer_edit_dropdown_item,
                              }),
                              icon: faPen,
                            },
                            {
                              id: 'Delete',
                              text: intl.formatMessage({
                                id: translationKeys.job_notes_drawer_delete_dropdown_item,
                              }),
                              icon: faTrash,
                              className: styles.deleteBtn,
                            },
                          ]}
                          onSelect={(selection) => {
                            if (selection === 'Edit') {
                              setSelectedNoteId(note.id);
                              setShowEditForm(true);
                            } else if (selection === 'Delete') {
                              handleDeleteNote(note.id);
                            }
                          }}
                        />
                      </span>
                    )}
                  </div>
                  <div className={styles.authorInfo}>
                    {note?.author && (
                      <span>
                        {note.author.firstName} {note.author.lastName}
                      </span>
                    )}
                    {note?.createdAt && (
                      <span data-testid="created-at-text">
                        {format(new Date(note.createdAt), 'dd.MM.yy, HH:mm')}
                      </span>
                    )}
                  </div>
                </div>
              )}

              {selectedNoteId === note.id && showEditForm && (
                <div className={styles.editNoteTextfield}>
                  <Formik
                    onSubmit={handleSubmit}
                    initialValues={{
                      note: note.text as string,
                    }}
                    validationSchema={schema}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      errors,
                      isValid,
                      dirty,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <TextField
                          as="textarea"
                          type="text"
                          placeholder={intl.formatMessage({
                            id: translationKeys.job_notes_drawer_edit_input_placeholder,
                          })}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="note"
                          value={values.note}
                          errorText={touched.note ? errors.note : undefined}
                          data-testid="edit-textfield"
                        />

                        <div className={styles.editButtons}>
                          <Button
                            onClick={() => setSelectedNoteId(null)}
                            variant="gray"
                            size="large"
                            className={styles.cancelButton}
                            data-testid="cancel-button"
                          >
                            <FormattedMessage
                              id={translationKeys.job_notes_drawer_edit_cancel_button}
                            />
                          </Button>

                          <Button
                            iconPosition="left"
                            size="large"
                            variant="primary"
                            disabled={!(isValid && dirty)}
                            onClick={() => handleUpdateNote(values.note, note.id, index)}
                            type="submit"
                            data-testid="edit-submit-button"
                          >
                            <FormattedMessage
                              id={translationKeys.job_notes_drawer_edit_save_button}
                            />
                          </Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              )}
            </div>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};

export default JobNotesList;
