import { IntlShape } from 'react-intl';
import translationKeys from '../../translations/translationKeys';
import { TranslationSubtaskStatus } from '../../types';

const getStatusName = (status: string, intl: IntlShape): string => {
  if (status === TranslationSubtaskStatus.Accepted) {
    return intl.formatMessage({
      id: translationKeys.translation_subtasks_status_accepted,
    });
  }

  if (status === TranslationSubtaskStatus.ReviewRequested) {
    return intl.formatMessage({
      id: translationKeys.translation_subtasks_status_review_requested,
    });
  }

  if (status === TranslationSubtaskStatus.Draft) {
    return intl.formatMessage({
      id: translationKeys.translation_subtasks_status_draft,
    });
  }

  if (status === TranslationSubtaskStatus.Finished) {
    return intl.formatMessage({
      id: translationKeys.translation_subtasks_status_finished,
    });
  }

  if (status === TranslationSubtaskStatus.Cancelled) {
    return intl.formatMessage({
      id: translationKeys.translation_subtasks_status_cancelled,
    });
  }

  if (status === TranslationSubtaskStatus.Invited) {
    return intl.formatMessage({
      id: translationKeys.translation_subtasks_status_invited,
    });
  }

  if (status === TranslationSubtaskStatus.ChangesRequired) {
    return intl.formatMessage({
      id: translationKeys.translation_subtasks_status_changes_requested,
    });
  }

  if (status === TranslationSubtaskStatus.InProgress) {
    return intl.formatMessage({
      id: translationKeys.translation_subtasks_status_in_progress,
    });
  }

  if (status === TranslationSubtaskStatus.ProactiveAccess) {
    return intl.formatMessage({
      id: translationKeys.translation_subtasks_status_proactive,
    });
  }

  if (status === TranslationSubtaskStatus.Rejected) {
    return intl.formatMessage({
      id: translationKeys.translation_subtasks_status_rejected,
    });
  }

  return '-';
};

export default getStatusName;
