import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Button,
  Drawer,
  PhoneCodeDropdown,
  SearchDropdown,
  SearchDropdownMenuOption,
  TextField,
} from '@skiwo/components';
import { Formik } from 'formik';
import * as yup from 'yup';
import { emailRegex, phoneRegex } from '../../../helpers/regexPatterns';
import useDebounce from '../../../hooks/useDebounce';
import { useApi } from '../../../providers/ApiProvider';
import translationKeys from '../../../translations/translationKeys';
import { ManagerSupplier, ManagerSupplierSex } from '../../../types';
import styles from './EditSupplierDrawers.module.scss';

interface Props {
  show?: boolean;
  onClose: () => void;
  onComplete: (hardReload?: boolean) => void;
  supplier: ManagerSupplier;
}

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  birthDay: string;
  birthMonth: string;
  birthYear: string;
}

const EditSupplierDrawers = (props: Props) => {
  const { show, supplier, onClose, onComplete } = props;
  const [selectedPhoneCode, setSelectedPhoneCode] = useState<SearchDropdownMenuOption>();
  const [updateSupplierLoading, setUpdateSupplierLoading] = useState(false);
  const [selectedGender, setSelectedGender] = useState<ManagerSupplierSex | undefined>(
    supplier.sex,
  );
  const [addressResponse, setAddressResponse] = useState<any[]>([]);

  const [addressesResults, setAddressesResults] = useState<SearchDropdownMenuOption[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<SearchDropdownMenuOption>();
  const debounceAddressSearch = useDebounce(300);
  const intl = useIntl();
  const api = useApi();

  const getAddressList = async (query: string) => {
    const { data } = await api.getAddressList({
      query,
    });

    if (data) {
      setAddressResponse(data);

      const addressOptions =
        data.map((address: any, index: any) => {
          return {
            id: index,
            label: address.data.formatted_address,
            key: address.data.place_id,
          };
        }) || [];

      setAddressesResults(addressOptions);
    }
  };

  const schema = yup.object().shape({
    firstName: yup.string().required(
      intl.formatMessage(
        { id: translationKeys.form_error_required },
        {
          fieldName: intl.formatMessage({
            id: translationKeys.suppliers_page_edit_supplier_first_name_label,
          }),
        },
      ),
    ),
    lastName: yup.string().required(
      intl.formatMessage(
        { id: translationKeys.form_error_required },
        {
          fieldName: intl.formatMessage({
            id: translationKeys.suppliers_page_edit_supplier_last_name_label,
          }),
        },
      ),
    ),
    email: yup
      .string()
      .matches(
        emailRegex,
        intl.formatMessage(
          { id: translationKeys.form_error_not_valid },
          {
            fieldName: intl.formatMessage({
              id: translationKeys.suppliers_page_edit_supplier_email_label,
            }),
          },
        ),
      )
      .required(
        intl.formatMessage(
          { id: translationKeys.form_error_required },
          {
            fieldName: intl.formatMessage({
              id: translationKeys.suppliers_page_edit_supplier_email_label,
            }),
          },
        ),
      ),
    phoneNumber: yup
      .string()
      .matches(
        phoneRegex,
        intl.formatMessage(
          { id: translationKeys.form_error_not_valid },
          {
            fieldName: intl.formatMessage({
              id: translationKeys.suppliers_page_edit_supplier_phone_label,
            }),
          },
        ),
      )
      .notRequired(),
    birthDay: yup
      .string()
      .matches(
        /^(?:[1-9]|[12][0-9]|3[01])$/,
        intl.formatMessage(
          { id: translationKeys.create_supplier_drawer_dob_day_error_message },
          {
            fieldName: intl.formatMessage({
              id: translationKeys.create_supplier_drawer_dob_day_error_message,
            }),
          },
        ),
      )
      .min(1)
      .max(31),
    birthMonth: yup
      .string()
      .matches(
        /^(?:[1-9]|1[0-2])$/,
        intl.formatMessage(
          { id: translationKeys.create_supplier_drawer_dob_month_error_message },
          {
            fieldName: intl.formatMessage({
              id: translationKeys.create_supplier_drawer_dob_month_error_message,
            }),
          },
        ),
      )
      .min(1)
      .max(12),
    birthYear: yup
      .number()
      .min(
        1923,
        intl.formatMessage(
          { id: translationKeys.create_supplier_drawer_dob_year_error_message },
          {
            fieldName: intl.formatMessage({
              id: translationKeys.create_supplier_drawer_dob_year_error_message,
            }),
          },
        ),
      )
      .max(
        new Date().getFullYear(),
        intl.formatMessage(
          { id: translationKeys.create_supplier_drawer_dob_max_year_error_message },
          {
            fieldName: intl.formatMessage({
              id: translationKeys.create_supplier_drawer_dob_max_year_error_message,
            }),
            year: new Date().getFullYear(),
          },
        ),
      ),
  });

  const handleSubmit = async (values: FormValues) => {
    const birthday =
      values.birthDay && values.birthMonth && values.birthYear
        ? `${values.birthDay}.${values.birthMonth}.${values.birthYear}`
        : '';

    const sexValue: Record<ManagerSupplierSex, string> = {
      [ManagerSupplierSex.Male]: 'male',
      [ManagerSupplierSex.Female]: 'female',
      [ManagerSupplierSex.Transgender]: 'transgender',
    };

    const payload = {
      supplier: {
        firstName: values.firstName,
        lastName: values.lastName,
        phoneCode: selectedPhoneCode?.key || '',
        phoneNumber: values.phoneNumber,
        email: values.email,
        birth: birthday,
        sex: selectedGender ? sexValue[selectedGender] : null,
      },
      address: undefined as Record<string, string> | undefined,
    };

    if (selectedAddress) {
      const address = addressResponse.find(
        (address) => address.data.place_id == selectedAddress.key,
      );

      if (address) {
        const addressValue = {
          line1: address.data.formatted_address.split(',')[0],
          city:
            address.data.address_components.find((component: any) =>
              component.types.includes('postal_town'),
            )?.long_name || undefined,
          county:
            address.data.address_components.find((component: any) =>
              component.types.includes('political'),
            )?.long_name || undefined,
          country:
            address.data.address_components.find((component: any) =>
              component.types.includes('country'),
            )?.long_name || undefined,
          postcode:
            address.data.address_components.find((component: any) =>
              component.types.includes('postal_code'),
            )?.long_name || undefined,
          latitude: address.data.geometry.location.lat,
          longitude: address.data.geometry.location.lng,
        };
        payload.address = addressValue;
      }
    }

    const { data } = await api.updateSupplier(
      supplier.id.toString(),
      payload,
      setUpdateSupplierLoading,
    );

    if (data) {
      onComplete();
      onClose();
    }
  };

  const getNameComponents = () => {
    if (!supplier.name) {
      return { firstName: '', lastName: '' };
    }

    const nameComponents = supplier.name.split(' ');
    const lastName = nameComponents.pop() || '';
    const firstName = nameComponents.join(' ') || '';

    return { firstName, lastName };
  };

  useEffect(() => {
    if (supplier.phoneCode) {
      setSelectedPhoneCode({
        id: Number(supplier.phoneCode.replace('+', '')),
        label: supplier.phoneCode,
        key: supplier.phoneCode,
      });
    }
  }, []);

  const getFormattedAddress = () => {
    const address = supplier.address;
    if (!address) return null;

    const cityAndPostCode = [address.city, address.postcode].filter((item) => item);
    const formattedCityAndPostCode = cityAndPostCode ? cityAndPostCode.join(' ') : undefined;
    const addressComponents = [address.line1, formattedCityAndPostCode, address.country].filter(
      (item) => item,
    );

    return addressComponents ? addressComponents.join(', ') : undefined;
  };

  const getDateComponents = (date?: string) => {
    if (!date) return null;

    const supplierBirthday = new Date(date);

    const day = supplierBirthday?.getDate().toString();
    const month = (supplierBirthday?.getMonth() + 1).toString();
    const year = supplierBirthday?.getFullYear().toString();
    return {
      day,
      month,
      year,
    };
  };

  return (
    <Drawer
      show={show}
      data-testid="edit-supplier-drawer"
      onClose={onClose}
      title={intl.formatMessage({ id: translationKeys.suppliers_page_edit_supplier_title })}
    >
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={{
          firstName: getNameComponents().firstName,
          lastName: getNameComponents().lastName,
          email: supplier.email,
          phoneNumber: supplier.phoneNumber || '',
          birthDay: getDateComponents(supplier.anniversary?.birth)?.day || '',
          birthMonth: getDateComponents(supplier.anniversary?.birth)?.month || '',
          birthYear: getDateComponents(supplier.anniversary?.birth)?.year || '',
        }}
      >
        {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
          <Form onSubmit={handleSubmit} className={styles.container}>
            <div className={styles.nameInputs}>
              <TextField
                placeholder={intl.formatMessage({
                  id: translationKeys.suppliers_page_edit_supplier_first_name_placeholder,
                })}
                type="text"
                label={intl.formatMessage({
                  id: translationKeys.suppliers_page_edit_supplier_first_name_label,
                })}
                size="large"
                name="firstName"
                required
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.firstName}
                errorText={touched.firstName ? errors.firstName : undefined}
                data-testid="first-name-input"
              />

              <TextField
                placeholder={intl.formatMessage({
                  id: translationKeys.suppliers_page_edit_supplier_last_name_placeholder,
                })}
                type="text"
                required
                label={intl.formatMessage({
                  id: translationKeys.suppliers_page_edit_supplier_last_name_label,
                })}
                size="large"
                name="lastName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.lastName}
                errorText={touched.lastName ? errors.lastName : undefined}
                data-testid="last-name-input"
              />
            </div>

            <div>
              <p className={styles.dateOfBirthLabel}>
                <FormattedMessage id={translationKeys.create_supplier_drawer_dob_label} />
              </p>
              <div className={styles.dateOfBirthInputs}>
                <TextField
                  placeholder="DD"
                  type="text"
                  size="large"
                  name="birthDay"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.birthDay}
                  maxLength={2}
                  errorText={touched.birthDay ? errors.birthDay : undefined}
                  data-testid="birth-day-input"
                />
                <TextField
                  placeholder="MM"
                  type="text"
                  size="large"
                  name="birthMonth"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.birthMonth}
                  maxLength={2}
                  errorText={touched.birthMonth ? errors.birthMonth : undefined}
                  data-testid="birth-month-input"
                />
                <TextField
                  placeholder={intl.formatMessage({
                    id: translationKeys.create_supplier_drawer_dob_year_placeholder,
                  })}
                  type="text"
                  required
                  size="large"
                  name="birthYear"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.birthYear}
                  maxLength={4}
                  errorText={touched.birthYear ? errors.birthYear : undefined}
                  data-testid="birth-year-input"
                />
              </div>
            </div>

            <div>
              <p className={styles.genderLabel}>
                <FormattedMessage id={translationKeys.create_supplier_drawer_gender_label} />
              </p>
              <div className={styles.genderRadioButtons}>
                <Form.Check
                  type="radio"
                  label={intl.formatMessage({
                    id: translationKeys.create_supplier_drawer_gender_male_label,
                  })}
                  name="gender"
                  checked={selectedGender === ManagerSupplierSex.Male}
                  onChange={() => {
                    setSelectedGender(ManagerSupplierSex.Male);
                  }}
                  data-testid="male-radio-option"
                />
                <Form.Check
                  type="radio"
                  label={intl.formatMessage({
                    id: translationKeys.create_supplier_drawer_gender_female_label,
                  })}
                  name="gender"
                  checked={selectedGender === ManagerSupplierSex.Female}
                  onChange={() => {
                    setSelectedGender(ManagerSupplierSex.Female);
                  }}
                  data-testid="female-radio-option"
                />
              </div>
            </div>

            <SearchDropdown
              options={addressesResults}
              search
              searchAddress
              size="large"
              label={intl.formatMessage({
                id: translationKeys.create_supplier_drawer_address_label,
              })}
              placeholder={
                getFormattedAddress() ||
                intl.formatMessage({
                  id: translationKeys.create_supplier_drawer_address_placeholder,
                })
              }
              onSearch={(query: string) => {
                debounceAddressSearch(() => {
                  getAddressList(query);
                });
              }}
              onChange={(address) => {
                if (address && address.length > 0 && address[0].key) {
                  setSelectedAddress(address[0]);
                } else {
                  setSelectedAddress(undefined);
                }
              }}
            />

            <TextField
              placeholder={intl.formatMessage({
                id: translationKeys.suppliers_page_edit_supplier_email_placeholder,
              })}
              type="email"
              label={intl.formatMessage({
                id: translationKeys.suppliers_page_edit_supplier_email_label,
              })}
              size="large"
              required
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              errorText={touched.email ? errors.email : undefined}
              data-testid="email-input"
            />

            <div className={styles.phoneInputs}>
              <div data-testid="phone-code-dropdown">
                <PhoneCodeDropdown
                  selected={selectedPhoneCode}
                  placeholder="+47"
                  label={intl.formatMessage({
                    id: translationKeys.suppliers_page_edit_supplier_country_code_label,
                  })}
                  onChange={(phoneCodes) => {
                    if (phoneCodes && phoneCodes.length > 0 && phoneCodes[0].key) {
                      setSelectedPhoneCode(phoneCodes[0]);
                    } else {
                      setSelectedPhoneCode(undefined);
                    }
                  }}
                />
              </div>

              <TextField
                placeholder={intl.formatMessage({
                  id: translationKeys.suppliers_page_edit_supplier_phone_placeholder,
                })}
                type="text"
                label={intl.formatMessage({
                  id: translationKeys.suppliers_page_edit_supplier_phone_label,
                })}
                size="large"
                name="phoneNumber"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phoneNumber}
                errorText={touched.phoneNumber ? errors.phoneNumber : undefined}
                data-testid="phone-number-input"
              />
            </div>

            <div className={styles.actions}>
              <Button size="large" variant="gray" onClick={onClose} data-testid="cancel-button">
                <FormattedMessage id={translationKeys.suppliers_page_edit_supplier_cancel_button} />
              </Button>

              <Button
                size="large"
                type="submit"
                disabled={!isValid}
                data-testid="submit-button"
                isLoading={updateSupplierLoading}
              >
                <FormattedMessage id={translationKeys.suppliers_page_edit_supplier_edit_button} />
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Drawer>
  );
};

export default EditSupplierDrawers;
