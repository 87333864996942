import { ManagerJobInviteStatus } from '../../types';
import JobsFilterField from '../JobsFilterField';

const statusValues: Record<string, string> = {
  [ManagerJobInviteStatus.Ongoing]: '10',
  [ManagerJobInviteStatus.Paused]: '20',
  [ManagerJobInviteStatus.Finished]: '30',
  [ManagerJobInviteStatus.Disabled]: '40',
  [ManagerJobInviteStatus.NoSuitableInterpreters]: '40',
};

const getJobFilters = (
  currentFilters: Record<string, string | string[]>,
  field: JobsFilterField,
  values: string[],
): Record<string, string | string[]> => {
  if (field === JobsFilterField.Id) {
    return { ...currentFilters, ...{ 's[id_eq]': values[0] } };
  }

  if (field === JobsFilterField.OwnerAndParticipants) {
    let filterValue = values[0];
    const isPhone = values[0].startsWith('+47');

    if (isPhone) {
      filterValue = values[0].replaceAll(' ', '');
    }

    return { ...currentFilters, ...{ 's[owner_cont]': filterValue } };
  }

  if (field === JobsFilterField.StartDate) {
    return {
      ...currentFilters,
      ...{ 's[start_time_gteq]': values[0], 's[start_time_lteq]': values[1] },
    };
  }

  if (field === JobsFilterField.CreatedDate) {
    return {
      ...currentFilters,
      ...{ 's[created_at_gteq]': values[0], 's[created_at_lteq]': values[1] },
    };
  }

  if (field === JobsFilterField.BuyerAndOrderRef) {
    return {
      ...currentFilters,
      ...{ 's[references_cont]': values[0] },
    };
  }

  if (field === JobsFilterField.Language) {
    const languageIds = values[0].split(',');
    if (languageIds.length > 0) {
      return {
        ...currentFilters,
        ...{ 's[lang_in]': languageIds },
      };
    }

    return {
      ...currentFilters,
    };
  }

  if (field === JobsFilterField.CategoryAndSubject) {
    return {
      ...currentFilters,
      ...{ 's[category_item_id_eq]': values[0] },
    };
  }

  if (field === JobsFilterField.SessionType) {
    return {
      ...currentFilters,
      ...{ 's[session_type_eq]': values[0] },
    };
  }

  if (field === JobsFilterField.Withdrawal) {
    return {
      ...currentFilters,
      ...{ 's[had_withdrawal_eq]': values[0] },
    };
  }

  if (field === JobsFilterField.QualificationRequested) {
    return {
      ...currentFilters,
      ...{ 's[specific_qualification_requested_eq]': values[0] },
    };
  }

  if (field === JobsFilterField.NeedsManualApproval) {
    if (values[0] === 'true') {
      return {
        ...currentFilters,
        ...{ 's[manual_approval_needed]': 'true' },
        ...{ 's[auto_award_without_applications]': '' },
      };
    }

    if (values[0] === 'false') {
      return {
        ...currentFilters,
        ...{ 's[auto_award_without_applications]': 'true' },
        ...{ 's[manual_approval_needed]': '' },
      };
    }

    if (!values[0]) {
      return {
        ...currentFilters,
        ...{ 's[auto_award_without_applications]': '' },
        ...{ 's[manual_approval_needed]': '' },
      };
    }
  }

  if (field === JobsFilterField.InviteStatus) {
    return {
      ...currentFilters,
      ...{ 's[check_invite_status_eq]': statusValues[values[0]] },
    };
  }

  if (field === JobsFilterField.Status) {
    return {
      ...currentFilters,
      ...{ 's[status_in][]': values[0] },
    };
  }

  if (field === JobsFilterField.Interpreter) {
    let filterValue = values[0];
    const isPhone = values[0].startsWith('+47');

    if (isPhone) {
      filterValue = values[0].replaceAll(' ', '');
    }

    return { ...currentFilters, ...{ 's[interpreter_cont]': filterValue } };
  }

  return currentFilters;
};

export default getJobFilters;
