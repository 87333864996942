import React from 'react';
import { Skeleton } from '@skiwo/components';
import styles from './CustomerItemSkeleton.module.scss';

const CustomerItemSkeleton = () => {
  return (
    <tr className={styles.skeleton} data-testid="customer-item-skeleton">
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton variant="details" />
      </td>
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton variant="details" />
      </td>
      <td>
        <Skeleton variant="circles" circleCount={3} />
      </td>
    </tr>
  );
};

export default CustomerItemSkeleton;
