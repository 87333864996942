import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@skiwo/components';
import { stripObject } from '@skiwo/utils';
import { ApiError } from '../Api';
import { useApi } from '../providers/ApiProvider';
import translationKeys from '../translations/translationKeys';
import { ManagerCustomer } from '../types';
import CustomersTable from './CustomersTable/CustomersTable';
import getCustomerFilters from './utils/getCustomerFilters';
import CustomersFilterField from './CustomersFilterField';
import styles from './Customers.module.scss';

const Customers = () => {
  const [customers, setCustomers] = useState<ManagerCustomer[]>([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [customersLoading, setCustomersLoading] = useState(false);
  const [customersError, setCustomersError] = useState<ApiError | null>(null);
  const api = useApi();
  const [filters, setFilters] = useState<Record<string, string>>({});

  const handleFilterChange = (field: CustomersFilterField, value: string) => {
    setFilters(getCustomerFilters(filters, field, value));
  };

  const fetchCustomers = async (page: number, clearCache = false) => {
    const { data, error } = await api.getCustomers(
      { page, 's[s]': 'id desc', ...stripObject(filters) },
      setCustomersLoading,
    );

    setCustomersError(error);
    setPages(data?.pages || 0);

    if (data?.customers) {
      let allCustomers = customers;

      if (!clearCache) {
        for (const newCustomer of data.customers) {
          const newCustomerIndex = allCustomers.findIndex(
            (customer) => customer.id === newCustomer.id,
          );

          if (newCustomerIndex === -1) {
            allCustomers.push(newCustomer);
          } else {
            allCustomers[newCustomerIndex] = newCustomer;
          }
        }
      } else {
        allCustomers = data.customers;
      }

      setCustomers(allCustomers);
      setPage(data.page);
    }
  };

  const handleLoadMore = () => {
    fetchCustomers(page + 1);
  };

  const handleReloadData = (hardReload = false) => {
    fetchCustomers(page, hardReload);
  };

  useEffect(() => {
    if (Object.keys(filters).length === 0) {
      return;
    }

    setCustomers([]);
    fetchCustomers(1, true);
  }, [filters]);

  useEffect(() => {
    fetchCustomers(1);
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 data-testid="customers-header">
          <FormattedMessage id={translationKeys.customers_page_title} />
        </h1>
      </div>

      <div className={styles.tableContainer}>
        <CustomersTable
          customers={customers}
          error={customersError}
          isLoading={customersLoading}
          onFilterChange={handleFilterChange}
          onCompleteAction={handleReloadData}
        />
      </div>

      {!customersLoading && customers && page < pages && (
        <div className={styles.loadMoreButton}>
          <Button
            variant="secondary"
            size="large"
            onClick={handleLoadMore}
            data-testid="load-more-button"
          >
            <FormattedMessage id={translationKeys.jobs_page_load_more_button} />
          </Button>
        </div>
      )}
    </div>
  );
};

export default Customers;
