import React from 'react';
import { Offcanvas } from 'react-bootstrap';
import { createIntl } from 'react-intl';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getLanguage } from '@skiwo/utils';
import Button from '../Button/Button';
import Tag from '../Tag/Tag';
import languages from '../translations/languages';
import translationKeys from '../translations/translationKeys';
import styles from './Drawer.module.scss';

interface Props {
  show?: boolean;
  children: React.ReactNode;
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  tagTitle?: React.ReactNode;
  tagColor?: 'success' | 'error' | 'info' | 'warning' | 'primary' | 'neutral';
  backText?: string;
  onBack?: () => void;
  onClose: () => void;
  'data-testid'?: string;
}

const Badge = (props: Props) => {
  const {
    children,
    onClose,
    title,
    subtitle,
    show = false,
    tagTitle,
    tagColor,
    onBack,
    backText,
  } = props;
  const userLanguage = getLanguage();
  const intl = createIntl({
    locale: userLanguage,
    messages: languages[userLanguage],
  });

  const isMobile = window.innerWidth < 768;

  return (
    <Offcanvas
      show={show}
      onHide={onClose}
      placement={isMobile ? 'bottom' : 'end'}
      data-testid={show ? props['data-testid'] : ''}
    >
      <Offcanvas.Header closeButton>
        {onBack && (
          <Button
            onClick={onBack}
            className={styles.goBack}
            variant="link"
            icon={<FontAwesomeIcon icon={faChevronLeft} />}
            data-testid="drawer-back-button"
          >
            {backText || intl.formatMessage({ id: translationKeys.drawer_back_button })}
          </Button>
        )}
        <Offcanvas.Title>
          <span className={styles.title} data-testid="drawer-title">
            {title}
          </span>
          {subtitle && (
            <span className={styles.subtitle} data-testid="drawer-subtitle">
              {subtitle}
            </span>
          )}
          {tagTitle && (
            <span className={styles.drawerTitleTag}>
              <Tag color={tagColor}>{tagTitle}</Tag>
            </span>
          )}
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>{children}</Offcanvas.Body>
    </Offcanvas>
  );
};

export default Badge;
