import fetchApi from '../fetchApi';

type QualificationsPresenceResultResponse = {
  code: string;
  qualifications: {
    qualificationId: number;
    interpretersCount: number;
  }[];
}[] | [];

export default {
  getQualificationsPresence: (
    languageId: string,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi<QualificationsPresenceResultResponse>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/languages/${languageId}/qualifications_presence`,
      loadingCallback,
    });
  },
};
