import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  faArrowDownToLine,
  faArrowRight,
  faCalendar,
  faCircleDollar,
  faEnvelope,
  faFile,
  faFileInvoice,
  faLanguage,
  faMailbox,
  faMemoPad,
  faPaperPlaneTop,
  faPen,
  faPlus,
  faRotateRight,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Banner, Breadcrumbs, Button, IconButton, Tag } from '@skiwo/components';
import ActionCable from 'actioncable';
import classnames from 'classnames';
import { format } from 'date-fns';
import { useApi } from '../../providers/ApiProvider';
import { useLanguages } from '../../providers/LanguagesProvider';
import { useToast } from '../../providers/ToastProvider/ToastProvider';
import translationKeys from '../../translations/translationKeys';
import {
  ManagerOrder,
  ManagerOrderAttachment,
  ManagerOrderAttachmentStatus,
  ManagerOrderCalledBy,
  ManagerOrderDeliveryMethod,
  ManagerOrderSourceType,
  ManagerOrderStatus,
  TranslationMethod,
} from '../../types';
import GroupedFileList from '../GroupedFileList/GroupedFileList';
import UploadFileDrawer from '../UploadFileDrawer/UploadFileDrawer';
import getOrderAccessLevelIcon from '../utils/getOrderAccessLevelIcon';
import getOrderAccessLevelName from '../utils/getOrderAccessLevelName';
import getOrderSourceIcon from '../utils/getOrderSourceIcon';
import getOrderSourceName from '../utils/getOrderSourceName';
import { translationOrderErrorKeys } from '../utils/translationOrderErrorKeys';
import EditBookerDrawer from './Drawers/EditBookerDrawer/EditBookerDrawer';
import EditDetailsDrawer from './Drawers/EditDetailsDrawer/EditDetailsDrawer';
import EditInvoicing from './Drawers/EditInvoicing/EditInvoicing';
import EditOrderDrawer from './Drawers/EditOrderDrawer/EditOrderDrawer';
import NewResponseDrawer from './Drawers/NewResponseDrawer/NewResponseDrawer';
import OrderEmailsDrawer from './Drawers/OrderEmailsDrawer/OrderEmailsDrawer';
import SendByPostDrawer from './Drawers/SendByPostDrawer/SendByPostDrawer';
import SendSavedResponseDrawer from './Drawers/SendSavedResponseDrawer/SendSavedResponseDrawer';
import SystemLogDrawer from './Drawers/SystemLogDrawer/SystemLogDrawer';
import { CancelOrderModal } from './Modals/CancelOrderModal/CancelOrderModal';
import { StatusDropdown } from './StatusDropdown/StatusDropdown';
import { BookerCard } from './BookerCard';
import LogoutDetailsCard from './LogoutDetailsCard';
import styles from './OrderDetails.module.scss';

enum OrderDrawer {
  EditOrder = 'EDIT_ORDER',
  SendSavedResponse = 'SEND_SAVED_RESPONSE',
  SystemLog = 'SYSTEM_LOG',
  SendByPost = 'SEND_BY_POST',
  EditDetails = 'EDIT_DETAILS',
  EditBooker = 'EDIT_BOOKER',
  EditInvoicing = 'EDIT_INVOICING',
  OrderEmails = 'ORDER_EMAILS',
  UploadFiles = 'UPLOAD_FILES',
  NewResponse = 'NEW_RESPONSE',
}

interface Props {
  order: ManagerOrder;
  onOrderUpdate: () => void;
  editable: boolean;
}

const OrderDetails = (props: Props) => {
  const { order, onOrderUpdate, editable } = props;
  const intl = useIntl();
  const [ocrFilesLoading, setOcrFilesLoading] = useState<boolean>(false);
  const [orderAttachments, setOrderAttachments] = useState<ManagerOrderAttachment[]>();
  const [coverLetters, setCoverLetters] = useState<ManagerOrderAttachment[]>();
  const [activeModal, setActiveModal] = useState<boolean>(false);
  const [activeDrawer, setActiveDrawer] = useState<OrderDrawer | undefined>(undefined);
  const { getLanguageById } = useLanguages();
  const [translationMethods, setTranslationMethods] = useState<TranslationMethod[]>([]);
  const api = useApi();
  const token = localStorage.getItem('token');
  const { showToast, showErrorToast } = useToast();

  const getToLanguages = () => {
    return order.tasks.map((task) => getLanguageById(task.targetLanguageId));
  };

  const getOrderAttachments = async () => {
    const { data, error } = await api.getOrderAttachments(order.id.toString(), {
      status: ManagerOrderAttachmentStatus.Initial,
    });

    if (data?.orderAttachments) {
      setOrderAttachments(data.orderAttachments);
    }

    if (error) {
      showErrorToast(error);
    }
  };

  const getCoverLetters = async () => {
    const { data, error } = await api.getOrderAttachments(order.id.toString(), {
      status: ManagerOrderAttachmentStatus.CoverLetter,
    });

    if (data?.orderAttachments) {
      const currentCoverLetters = data.orderAttachments.filter(
        (attachment) => !attachment.archived,
      );
      setCoverLetters(currentCoverLetters);
    }

    if (error) {
      showErrorToast(error);
    }
  };

  const getOcrAttachments = async () => {
    setOcrFilesLoading(true);

    const { data, error } = await api.getOcrAttachments(order.id.toString());

    if (data && token) {
      const cable = ActionCable.createConsumer(
        `${process.env.REACT_APP_WEB_SOCKET_URL}?jwt=${token}`,
      );

      const ocrResultChannel = cable.subscriptions.create(
        {
          channel: 'OcrResultChannel',
          jwt: token,
          orderId: order.id.toString(),
        },
        {
          received: (message: any) => {
            if (message.ocrStatus === 'failure') {
              showToast({
                variant: 'error',
                message: intl.formatMessage({
                  id: translationKeys.global_generic_error,
                }),
              });
              ocrResultChannel.unsubscribe();
              setOcrFilesLoading(false);
            }
            if (message.ocrStatus === 'success') {
              getOrderAttachments();
              ocrResultChannel.unsubscribe();
              setOcrFilesLoading(false);
            }
          },
        },
      );
    }

    if (error) {
      showErrorToast(error);
      setOcrFilesLoading(false);
    }
  };

  const downloadAttachment = async (attachment: ManagerOrderAttachment) => {
    const downloadUrl = `${process.env.REACT_APP_API_BASE_URL}${attachment.link}?disposition=attachment`;

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = attachment.name;
    link.click();
  };

  const downloadAllAttachments = async () => {
    const { data, headers, error } = await api.downloadOrderAttachments(order.id.toString(), {
      status: ManagerOrderAttachmentStatus.Initial,
    });

    if (data) {
      let fileName = `Order #${order.id}.zip`;

      const disposition = headers['content-disposition'];
      if (disposition && disposition.includes('filename=')) {
        const filenameMatch = disposition.match(/filename="?(.+?)"?(;|$)/);
        if (filenameMatch && filenameMatch[1]) {
          fileName = filenameMatch[1];
        }
      }

      const dataBlob = new Blob([data], { type: headers['content-type'] });
      const url = window.URL.createObjectURL(dataBlob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    }

    if (error) {
      showErrorToast(error);
    }
  };

  const archiveAttachment = async (attachment: ManagerOrderAttachment) => {
    const { error } = await api.updateOrderAttachments(
      order.id.toString(),
      attachment.id.toString(),
      {
        orderAttachment: {
          archived: true,
        },
      },
    );

    if (error) {
      showErrorToast(error);
    } else {
      getOrderAttachments();
    }
  };

  const unarchiveAttachment = async (attachment: ManagerOrderAttachment) => {
    const { error } = await api.updateOrderAttachments(
      order.id.toString(),
      attachment.id.toString(),
      {
        orderAttachment: {
          archived: false,
        },
      },
    );

    if (error) {
      showErrorToast(error);
    } else {
      getOrderAttachments();
    }
  };

  const getTranslationMethods = async () => {
    const { data, error } = await api.getTranslationMethods();

    if (data?.translationMethods) {
      setTranslationMethods(data.translationMethods);
    }

    if (error) {
      showErrorToast(error);
    }
  };

  const onDrawerClose = () => {
    setActiveDrawer(undefined);
  };

  const handleChangeStatus = async (
    status: ManagerOrderStatus,
    cancellationReason?: string,
    cancelledBy?: ManagerOrderCalledBy,
  ) => {
    let body: Partial<ManagerOrder> = {
      requirementIdempotencyKey: order.requirementIdempotencyKey,
      status,
    };
    if (status === ManagerOrderStatus.Cancelled && cancellationReason && cancelledBy) {
      body = { ...body, cancellationReasonText: cancellationReason, cancelledBy };
    }

    const { data, error } = await api.updateOrder(order.id.toString(), body);

    if (data) {
      onOrderUpdate();
    }
    if (error) {
      showErrorToast(error, translationOrderErrorKeys);
    }
  };

  const handleWaitingForCustomerChange = async (value: boolean) => {
    const { error } = await api.updateOrder(order.id.toString(), {
      customerInputRequested: value,
      requirementIdempotencyKey: order.requirementIdempotencyKey,
    });

    if (error) {
      showErrorToast(error, translationOrderErrorKeys);
    } else {
      onOrderUpdate();
    }
  };

  useEffect(() => {
    getOrderAttachments();
    getCoverLetters();
    getTranslationMethods();
  }, []);

  return (
    <section className={styles.orderSection}>
      <Breadcrumbs
        items={[
          {
            id: 'translation_orders',
            title: intl.formatMessage({
              id: translationKeys.translation_order_details_translation_order_label,
            }),
            href: '/translation-subtasks',
          },
          {
            id: order.id.toString(),
            title: `${intl.formatMessage({
              id: translationKeys.translation_order_details_order_label,
            })} #${order.id}`,
            active: true,
          },
        ]}
      />

      {!editable && order.status !== ManagerOrderStatus.Cancelled && (
        <Banner
          variant="error"
          text={intl.formatMessage({ id: translationKeys.translation_order_no_owner_error_text })}
        />
      )}

      {order.status === ManagerOrderStatus.Cancelled && (
        <Banner
          data-testid="cancelled-banner"
          variant="error"
          text={intl.formatMessage({
            id:
              order.cancelledBy === ManagerOrderCalledBy.Platform
                ? translationKeys.translation_order_details_cancelled_banner_platform
                : translationKeys.translation_order_details_cancelled_banner_customer,
          })}
        />
      )}

      <div className={styles.orderHeader}>
        <h2 className={styles.orderTitle} data-testid="order-details-language-info">
          <span data-testid="order-details-source-language">
            {getLanguageById(order.sourceLanguageId)}
          </span>
          <span>
            <FontAwesomeIcon icon={faArrowRight} />
          </span>
          <span data-testid="order-details-target-languages">{getToLanguages().join(', ')}</span>
        </h2>

        <div className={styles.orderDetails} data-testid="order-details-status-info">
          <StatusDropdown
            order={order}
            onSelect={(status) => {
              if (status === ManagerOrderStatus.Cancelled) {
                setActiveModal(true);
              } else {
                handleChangeStatus(status);
              }
            }}
          />
        </div>
      </div>

      <div className={styles.orderId} data-testid="order-details-order-id-info">
        <span data-testid="order-details-order-id-label">
          <FormattedMessage id={translationKeys.translation_order_details_order_label} />
        </span>
        <span>•</span>
        <span data-testid="order-details-order-id">#{order.id}</span>
      </div>

      <div className={styles.orderInfo}>
        <div className={styles.infoItem}>
          <span className={styles.label}>
            <FormattedMessage id={translationKeys.translation_order_details_created_label} />
          </span>
          <span className={styles.value} data-testid="order-details-created-at">
            <FontAwesomeIcon icon={faCalendar} />
            {format(new Date(order.createdAt), 'dd.MM.yyyy')}
          </span>
        </div>
        {order.externalDeadline && (
          <div className={styles.infoItem}>
            <span className={styles.label}>
              <FormattedMessage
                id={translationKeys.translation_order_details_external_deadline_label}
              />
            </span>
            <span className={styles.value} data-testid="order-details-external-deadline">
              <FontAwesomeIcon icon={faCalendar} />
              {format(new Date(order.externalDeadline), 'dd.MM.yyyy')}
            </span>
          </div>
        )}
        {!!order.countOfWords && (
          <div className={styles.infoItem}>
            <span className={styles.label}>
              <FormattedMessage id={translationKeys.translation_order_details_word_count_label} />
            </span>
            <span className={styles.value} data-testid="order-details-word-count">
              <FontAwesomeIcon icon={faLanguage} />
              {order.countOfWords}
            </span>
          </div>
        )}

        <div className={styles.infoItem}>
          <span className={styles.label}>
            <FormattedMessage id={translationKeys.translation_order_alternative_language_label} />
          </span>
          <span className={styles.value} data-testid="order-details-alternative-language">
            <FontAwesomeIcon icon={faLanguage} />
            {order.alternativeTargetLanguageId == null ? (
              <FormattedMessage
                id={translationKeys.translation_order_alternative_language_no_preference}
              />
            ) : (
              getLanguageById(order.alternativeTargetLanguageId)
            )}
          </span>
        </div>
        <div className={styles.infoItem}>
          <span className={styles.label}>
            <FormattedMessage id={translationKeys.translation_order_details_access_level_label} />
          </span>
          <span className={styles.value} data-testid="order-details-access-level">
            {getOrderAccessLevelIcon(order.accessLevel)}
            {getOrderAccessLevelName(order.accessLevel, intl)}
          </span>
        </div>
        <div className={styles.infoItem}>
          <span className={styles.label}>
            <FormattedMessage id={translationKeys.translation_order_details_source_label} />
          </span>
          <span className={styles.value} data-testid="order-details-source-type">
            {order.isLoggedOut
              ? getOrderSourceIcon(ManagerOrderSourceType.Logout)
              : getOrderSourceIcon(order.sourceType)}
            {order.isLoggedOut
              ? getOrderSourceName(ManagerOrderSourceType.Logout, intl)
              : getOrderSourceName(order.sourceType, intl)}
          </span>
        </div>
      </div>

      <div className={styles.actions}>
        <div className={styles.buttons}>
          <Button
            size="large"
            variant="gray"
            icon={<FontAwesomeIcon icon={faPen} />}
            onClick={() => setActiveDrawer(OrderDrawer.EditOrder)}
            data-testid="order-details-edit-action"
            disabled={!editable}
          >
            <FormattedMessage id={translationKeys.translation_order_details_edit_button} />
          </Button>
          <Button
            size="large"
            variant="gray"
            icon={<FontAwesomeIcon icon={faPaperPlaneTop} />}
            onClick={() => setActiveDrawer(OrderDrawer.SendSavedResponse)}
            data-testid="order-details-send-saved-responses-action"
            disabled={!editable}
          >
            <FormattedMessage
              id={translationKeys.translation_order_details_send_saved_responses_button}
            />
          </Button>
          <Button
            size="large"
            icon={<FontAwesomeIcon icon={faEnvelope} />}
            variant="gray"
            onClick={() => setActiveDrawer(OrderDrawer.OrderEmails)}
            data-testid="order-details-order-emails-action"
            disabled={!editable}
          >
            <FormattedMessage id={translationKeys.translation_order_details_order_emails_button} />
          </Button>

          <Button
            size="large"
            variant="gray"
            icon={<FontAwesomeIcon icon={faMemoPad} />}
            onClick={() => setActiveDrawer(OrderDrawer.SystemLog)}
            data-testid="order-details-system-log-action"
          >
            <FormattedMessage id={translationKeys.translation_order_details_system_log_button} />
          </Button>
        </div>

        <Form.Check
          type="switch"
          label={intl.formatMessage({
            id: translationKeys.translation_order_waiting_for_the_customer_toggle,
          })}
          data-testid="order-details-waiting-for-customer-toggle"
          checked={order.customerInputStatus != null}
          onChange={(e) => {
            handleWaitingForCustomerChange(e.target.checked);
          }}
          disabled={!editable}
        />
      </div>

      <Container fluid>
        <Row>
          <Col lg={7}>
            <div className={classnames(styles.section, styles.table)}>
              <div className={styles.title}>
                <FontAwesomeIcon className={styles.icon} icon={faFile} />
                <FormattedMessage
                  id={translationKeys.translation_order_details_source_files_header}
                />

                <div className={styles.action}>
                  <IconButton
                    icon={<FontAwesomeIcon className={styles.icon} icon={faRotateRight} />}
                    onClick={() => getOcrAttachments()}
                    hoverText={intl.formatMessage({
                      id: translationKeys.translation_order_files_get_ocr_tooltip,
                    })}
                    isLoading={ocrFilesLoading}
                  />
                  <IconButton
                    icon={<FontAwesomeIcon className={styles.icon} icon={faArrowDownToLine} />}
                    onClick={() => downloadAllAttachments()}
                    hoverText={intl.formatMessage({
                      id: translationKeys.translation_order_files_download_all_tooltip,
                    })}
                    disabled={!(orderAttachments?.length !== 0 && editable)}
                  />
                  <IconButton
                    icon={<FontAwesomeIcon className={styles.icon} icon={faPlus} />}
                    onClick={() => setActiveDrawer(OrderDrawer.UploadFiles)}
                    hoverText={intl.formatMessage({
                      id: translationKeys.translation_order_files_upload_tooltip,
                    })}
                    disabled={!editable}
                  />
                </div>
              </div>

              <div className={styles.container}>
                <GroupedFileList
                  files={orderAttachments}
                  onDownload={downloadAttachment}
                  onArchive={archiveAttachment}
                  onUnarchive={unarchiveAttachment}
                />
              </div>
            </div>
            <div
              className={classnames(styles.section, styles.table)}
              data-testid="order-details-details-section"
            >
              <span className={styles.title}>
                <FontAwesomeIcon className={styles.icon} icon={faMemoPad} />
                <span data-testid="details-header">
                  <FormattedMessage
                    id={translationKeys.translation_order_details_order_details_header}
                  />
                </span>

                <Button
                  variant="link"
                  size="large"
                  icon={<FontAwesomeIcon className={styles.icon} icon={faPen} />}
                  className={styles.action}
                  onClick={() => setActiveDrawer(OrderDrawer.EditDetails)}
                  data-testid="details-edit-button"
                  disabled={!editable}
                >
                  <FormattedMessage
                    id={translationKeys.translation_order_details_section_edit_button}
                  />
                </Button>
              </span>

              <div className={styles.row}>
                <span className={styles.label}>
                  <FormattedMessage id={translationKeys.translation_order_details_subject_label} />
                </span>
                <span className={styles.value} data-testid="subject">
                  {translationMethods.find((method) => method.id === order.translationMethodId)
                    ?.name || '-'}
                </span>
              </div>
              <div className={styles.row}>
                <span className={styles.label}>
                  <FormattedMessage
                    id={translationKeys.translation_order_details_case_number_label}
                  />
                </span>
                <span className={styles.value} data-testid="case-number">
                  {order.caseNumber || '-'}
                </span>
              </div>
              <div className={styles.row}>
                <span className={styles.label}>
                  <FormattedMessage
                    id={translationKeys.translation_order_details_description_label}
                  />
                </span>
                <span className={styles.value} data-testid="description">
                  {order.instructionsFromDemander || '-'}
                </span>
              </div>
            </div>

            <div
              className={classnames(styles.section, styles.table)}
              data-testid="order-details-quote-section"
            >
              <span className={styles.title}>
                <FontAwesomeIcon className={styles.icon} icon={faCircleDollar} />
                <span data-testid="quote-header">
                  <FormattedMessage id={translationKeys.translation_order_details_quote_header} />
                </span>
              </span>

              <div className={styles.row}>
                <span className={styles.label}>
                  <FormattedMessage
                    id={translationKeys.translation_order_details_quote_logout_estimate}
                  />
                </span>
                <div>
                  <span className={styles.value}>
                    {order.logoutEstimatedQuote?.amount
                      ? `${order.logoutEstimatedQuote?.amount} NOK`
                      : '-'}
                  </span>
                  {!order.logoutEstimatedQuote?.visibleForCustomer && (
                    <span className={styles.quoteNotShown}>
                      <FormattedMessage
                        id={
                          translationKeys.translation_order_details_quote_logout_estimate_not_shown
                        }
                      />
                    </span>
                  )}
                </div>
              </div>
              <div className={styles.row}>
                <span className={styles.label}>
                  <FormattedMessage id={translationKeys.translation_order_details_quote_label} />
                </span>
                <span className={styles.value} data-testid="quote">
                  {order.demanderQuoteAmount ? `${order.demanderQuoteAmount} NOK` : '-'}
                </span>
              </div>
              <div className={styles.row}>
                <span className={styles.label}>
                  <FormattedMessage
                    id={translationKeys.translation_order_details_word_count_label}
                  />
                </span>
                <span className={styles.value} data-testid="word-count">
                  {order.countOfWords || '-'}
                </span>
              </div>
              <div className={styles.row}>
                <span className={styles.label}>
                  <FormattedMessage
                    id={translationKeys.translation_order_details_price_offer_email_label}
                  />
                </span>
                <div className={styles.value}>
                  <span data-testid="price-offer-email">
                    {order.quoteSentEmailSent
                      ? intl.formatMessage({
                          id: translationKeys.translation_order_details_quote_price_offer_sent,
                        })
                      : intl.formatMessage({
                          id: translationKeys.translation_order_details_quote_price_offer_not_sent,
                        })}
                  </span>
                </div>
              </div>
            </div>
            <div
              className={classnames(styles.section, styles.table)}
              data-testid="order-details-invoicing-section"
            >
              <span className={styles.title}>
                <FontAwesomeIcon className={styles.icon} icon={faFileInvoice} />
                <span data-testid="invoicing-header">
                  <FormattedMessage
                    id={translationKeys.translation_order_details_invoicing_header}
                  />
                </span>

                <Button
                  variant="link"
                  size="large"
                  icon={<FontAwesomeIcon className={styles.icon} icon={faPen} />}
                  className={styles.action}
                  onClick={() => setActiveDrawer(OrderDrawer.EditInvoicing)}
                  data-testid="invoicing-edit-button"
                  disabled={!editable}
                >
                  <FormattedMessage
                    id={translationKeys.translation_order_details_section_edit_button}
                  />
                </Button>
              </span>

              <div className={styles.row}>
                <span className={styles.label}>
                  <FormattedMessage
                    id={translationKeys.translation_order_details_payment_method_label}
                  />
                </span>
                <div>
                  <div className={styles.value} data-testid="payment-method">
                    {!order.paymentMethod && '-'}
                    <span>
                      {order.paymentMethod?.orgName || order.paymentMethod?.email || '-'}
                      {order.paymentMethod?.orgNumber && (
                        <span data-testid="payment-method-org-number">
                          {' '}
                          / {order.paymentMethod?.orgNumber}
                        </span>
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className={styles.row}>
                <span className={styles.label}>
                  {order.referenceSettings.labelForBookingReference}
                </span>
                <span className={styles.value} data-testid="booking-reference">
                  {order.bookingReference || '-'}
                </span>
              </div>
              <div className={styles.row}>
                <span className={styles.label}>
                  {order.referenceSettings.labelForPaymentBookingReference}
                </span>
                <span className={styles.value} data-testid="payment-booking-reference">
                  {order.paymentBookingReference || '-'}
                </span>
              </div>
              <div className={styles.row}>
                <span className={styles.label}>{order.referenceSettings.labelForCaseNumber}</span>
                <span className={styles.value} data-testid="case-number">
                  {order.caseNumber || '-'}
                </span>
              </div>
              {order.clientId && (
                <div className={styles.row}>
                  <span className={styles.label}>
                    <FormattedMessage
                      id={translationKeys.translation_order_details_client_number_label}
                    />
                  </span>
                  <span className={styles.value} data-testid="client-id">
                    {order.clientId}
                  </span>
                </div>
              )}
              {order.clientName && (
                <div className={styles.row}>
                  <span className={styles.label}>
                    <FormattedMessage
                      id={translationKeys.translation_order_details_client_name_label}
                    />
                  </span>
                  <span className={styles.value} data-testid="client-name">
                    {order.clientName}
                  </span>
                </div>
              )}
            </div>
          </Col>
          <Col lg={5}>
            <div className={styles.section} data-testid="order-details-send-by-post-section">
              <span className={styles.title}>
                <FontAwesomeIcon className={styles.icon} icon={faMailbox} />
                <span data-testid="send-by-post-header">
                  <FormattedMessage
                    id={translationKeys.translation_order_details_send_by_post_header}
                  />
                </span>

                <Button
                  variant="link"
                  size="large"
                  icon={<FontAwesomeIcon className={styles.icon} icon={faPen} />}
                  className={styles.action}
                  onClick={() => setActiveDrawer(OrderDrawer.SendByPost)}
                  data-testid="send-by-post-edit-button"
                  disabled={!editable}
                >
                  <FormattedMessage
                    id={translationKeys.translation_order_details_section_edit_button}
                  />
                </Button>
              </span>

              <div className={styles.card}>
                <div className={styles.row}>
                  {order.deliveryMethod === ManagerOrderDeliveryMethod.Post ? (
                    <div className={styles.sendByPostBlock}>
                      {order.sendByPostAddress && (
                        <div>
                          <span className={styles.label}>
                            <FormattedMessage
                              id={translationKeys.translation_order_details_address_label}
                            />
                          </span>
                          <span className={styles.value} data-testid="address">
                            {order.sendByPostAddress || '-'}
                          </span>
                        </div>
                      )}
                      {!!coverLetters?.length && (
                        <div>
                          <span className={styles.label}>
                            <FormattedMessage
                              id={
                                translationKeys.translation_order_details_send_by_post_cover_letter_label
                              }
                            />
                          </span>
                          {coverLetters.map((letter) => (
                            <a
                              key={letter.id}
                              className={classnames(styles.value, styles.coverLetter)}
                              onClick={() => downloadAttachment(letter)}
                            >
                              {letter.name}
                            </a>
                          ))}
                        </div>
                      )}

                      <div className={styles.actions}>
                        <Tag variant="default" color={order.sentByPost ? 'success' : 'neutral'}>
                          {order.sentByPost
                            ? intl.formatMessage({
                                id: translationKeys.translation_order_send_by_post_summary_sent,
                              })
                            : intl.formatMessage({
                                id: translationKeys.translation_order_send_by_post_summary_not_sent,
                              })}
                        </Tag>
                      </div>
                    </div>
                  ) : (
                    <div className={styles.notSentByPost}>
                      <FontAwesomeIcon icon={faMailbox} className={styles.notSentByPostIcon} />
                      <FormattedMessage
                        id={translationKeys.translation_order_details_send_by_post_not_requested}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            {order.isLoggedOut && !order.owner?.uid ? (
              <LogoutDetailsCard
                order={order}
                onAssignSeller={() => setActiveDrawer(OrderDrawer.EditBooker)}
              />
            ) : (
              <BookerCard
                order={order}
                onEditBooker={() => setActiveDrawer(OrderDrawer.EditBooker)}
              />
            )}
          </Col>
        </Row>
      </Container>

      <EditOrderDrawer
        order={order}
        show={activeDrawer === OrderDrawer.EditOrder}
        onClose={onDrawerClose}
        onFormSubmit={onOrderUpdate}
      />
      <SendSavedResponseDrawer
        show={activeDrawer === OrderDrawer.SendSavedResponse}
        onNewResponse={() => setActiveDrawer(OrderDrawer.NewResponse)}
        onClose={onDrawerClose}
        order={order}
      />
      <SystemLogDrawer
        show={activeDrawer === OrderDrawer.SystemLog}
        orderId={order.id}
        onClose={onDrawerClose}
      />
      <SendByPostDrawer
        show={activeDrawer === OrderDrawer.SendByPost}
        onClose={() => {
          onDrawerClose();
          onOrderUpdate();
          getCoverLetters();
        }}
        order={order}
      />
      <EditDetailsDrawer
        show={activeDrawer === OrderDrawer.EditDetails}
        onClose={() => {
          onDrawerClose();
          onOrderUpdate();
        }}
        order={order}
      />
      <EditBookerDrawer
        show={activeDrawer === OrderDrawer.EditBooker}
        onClose={onDrawerClose}
        order={order}
        onFormSubmit={onOrderUpdate}
      />
      <EditInvoicing
        show={activeDrawer === OrderDrawer.EditInvoicing}
        order={order}
        onClose={() => {
          onDrawerClose();
          onOrderUpdate();
        }}
      />

      <OrderEmailsDrawer
        show={activeDrawer === OrderDrawer.OrderEmails}
        onClose={onDrawerClose}
        order={order}
        onUpdate={onOrderUpdate}
      />

      <CancelOrderModal
        show={activeModal}
        onClose={() => setActiveModal(false)}
        onSubmit={(values) => {
          handleChangeStatus(
            ManagerOrderStatus.Cancelled,
            values.cancellationReasonText,
            values.cancelledBy,
          );
        }}
      />
      <UploadFileDrawer
        show={activeDrawer === OrderDrawer.UploadFiles}
        orderId={order.id.toString()}
        attachmentStatus={ManagerOrderAttachmentStatus.Initial}
        onClose={() => {
          onDrawerClose();
          onOrderUpdate();
          getOrderAttachments();
        }}
      />
      <NewResponseDrawer
        show={activeDrawer === OrderDrawer.NewResponse}
        onClose={() => onDrawerClose()}
        onBack={() => {
          setActiveDrawer(OrderDrawer.SendSavedResponse);
        }}
      />
    </section>
  );
};

export default OrderDetails;
