import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { MegaMenuItem } from '../MegaMenu';
import styles from './MenuItem.module.scss';

interface Props {
  item: MegaMenuItem;
  onClick?: (link: string) => void;
}

function MenuItem(props: Props) {
  const { item, onClick } = props;

  const handleOnClick = () => {
    if (!item.disabled && onClick) {
      onClick(item.link);
    }
  };

  return (
    <NavDropdown.Item
      className={classnames(styles.menuItem, { [styles.disabled]: item.disabled })}
      data-testid="menu-item"
      onClick={handleOnClick}
      disabled={item.disabled}
    >
      {item.icon && (
        <div data-testid="menu-item-icon">
          <FontAwesomeIcon icon={item.icon} className={styles.icon} />
        </div>
      )}

      <div>
        <span className={styles.name} data-testid="menu-item-name">
          {item.name}
        </span>

        {item.description && (
          <span className={styles.description} data-testid="menu-item-description">
            {item.description}
          </span>
        )}
      </div>
    </NavDropdown.Item>
  );
}

export default MenuItem;
