import React, { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';
import { Tag } from '@skiwo/components';
import translationKeys from '../../translations/translationKeys';
import styles from './SectionContainer.module.scss';

interface SectionContainerProps {
  icon?: React.ReactNode;
  title?: string;
  description?: string;
  action?: React.ReactNode;
  optional?: boolean;
  'data-testid'?: string;
}

const SectionContainer = ({
  icon,
  title,
  description,
  action,
  children,
  optional = false,
  'data-testid': dataTestId,
}: PropsWithChildren<SectionContainerProps>) => {
  const hasHeaderWrapper = icon && title && description && action && optional;
  
  return (
    <div data-testid={dataTestId}>
      {hasHeaderWrapper && (
        <div className={styles.headerWrapper}>
          <div className={styles.header}>
            <div className={styles.iconTitleWrapper}>
              {icon}
              {title && <span className={styles.title}>{title}</span>}
              {optional && (
                <Tag color="neutral" variant="square" size="small">
                  <FormattedMessage id={translationKeys.create_translation_order_optional_tag} />
                </Tag>
              )}
            </div>
            <div className={styles.actions}>{action}</div>
          </div>
          <span className={styles.description}>{description}</span>
        </div>
      )}
      {children}
    </div>
  );
};

export default SectionContainer;
