import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Drawer, TextField } from '@skiwo/components';
import { useApi } from '../../../../providers/ApiProvider';
import { useToast } from '../../../../providers/ToastProvider/ToastProvider';
import translationKeys from '../../../../translations/translationKeys';
import { ManagerOrderSubtaskDetail } from '../../../../types';
import styles from './InternalReviewDrawer.module.scss';

interface Props {
  show?: boolean;
  onClose: () => void;
  subtask: ManagerOrderSubtaskDetail;
}

const InternalReviewDrawer = (props: Props) => {
  const { show, onClose, subtask } = props;
  const [description, setDescription] = useState(subtask.reviewCommentFromProjectManager);
  const [updateSubtaskLoading, setUpdateSubtaskLoading] = useState(false);
  const api = useApi();
  const intl = useIntl();
  const { showErrorToast } = useToast();

  const handleSubmit = async () => {
    const body = {
      reviewCommentFromProjectManager: description,
    };

    const { data, error } = await api.updateOrderSubtask(
      subtask.id.toString(),
      body,
      setUpdateSubtaskLoading,
    );

    if (data) {
      onClose();
    }
    if (error) {
      showErrorToast(error);
    }
  };

  return (
    <Drawer
      show={show}
      onClose={onClose}
      title={intl.formatMessage({ id: translationKeys.translation_subtask_salita_review_title })}
      data-testid="internal-review-drawer"
    >
      <div className={styles.internalReview}>
        <TextField
          label={intl.formatMessage({
            id: translationKeys.translation_subtask_salita_review_description,
          })}
          placeholder={intl.formatMessage({
            id: translationKeys.translation_subtask_salita_review_description_placeholder,
          })}
          size="large"
          textArea
          value={description}
          onChange={(event) => setDescription(event.target.value)}
        />

        <div className={styles.actions}>
          <Button size="large" variant="gray" onClick={onClose}>
            <FormattedMessage
              id={translationKeys.translation_subtask_salita_review_cancel_button}
            />
          </Button>
          <Button size="large" onClick={handleSubmit} isLoading={updateSubtaskLoading}>
            <FormattedMessage id={translationKeys.translation_subtask_salita_review_save_button} />
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default InternalReviewDrawer;
