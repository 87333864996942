import React from 'react';
import { useIntl } from 'react-intl';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { faPen } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@skiwo/components';
import { ButtonProps } from '@skiwo/components/src/Button/Button';
import classNames from 'classnames';
import translationKeys from '../../../../translations/translationKeys';
import styles from './Card.module.scss';

interface CardProps {
  title: string;
  titleIcon: JSX.Element;
  emptyText?: string;
  handleEdit?: ButtonProps['onClick'];
}

const Card = ({ titleIcon, title, children, emptyText, handleEdit }: React.PropsWithChildren<CardProps>) => {
  const intl = useIntl();

  const shouldDisplayEmptyState =
    emptyText &&
    (React.Children.count(children) === 0 ||
      React.Children.toArray(children).every((child) => !child));

  return (
    <div className={styles.card}>
      <header className={styles.cardHeader}>
        <h4 className={styles.cardTitle}>
          {titleIcon}
          {title}
        </h4>
        <Button
          icon={<FontAwesomeIcon icon={faPen} />}
          variant="link"
          size="large"
          className={styles.linkButton}
          onClick={handleEdit}
          disabled={!handleEdit}
        >
          {intl.formatMessage({ id: translationKeys.job_information_edit_label })}
        </Button>
      </header>
      <div className={styles.cardBody}>
        {shouldDisplayEmptyState ? <EmptyCard icon={titleIcon} body={emptyText} /> : children}
      </div>
    </div>
  );
};

const EmptyCard = ({ icon, body }: { icon: JSX.Element; body: string }) => {
  return (
    <div className={styles.emptyCard}>
      {icon}
      {body}
    </div>
  );
};

const CardButton = (props: Omit<ButtonProps, 'variant' | 'size' | 'className'>) => (
  <Button
    {...props}
    variant="link"
    size="large"
    className={classNames(styles.linkButton, styles['linkButton--underline'])}
  />
);

const CardBodyItem = ({
  title,
  variant,
  children,
}: React.PropsWithChildren<{ title: string; variant?: 'warning' | 'error' }>) => (
  <div className={styles[`cardBodyItem--${variant}`]}>
    <p className={classNames(styles.itemText, styles.itemTitle)}>{title}</p>
    <div className={styles.itemRow}>
      {variant && (
        <FontAwesomeIcon
          className={styles[`cardBodyIcon--${variant}`]}
          icon={faExclamationCircle}
        />
      )}
      <div className={styles.itemText}>{children}</div>
    </div>
  </div>
);

const CardItemRow = ({ children }: React.PropsWithChildren) => (
  <div className={styles.itemRow}>{children}</div>
);

const CardGridRow = ({ children }: React.PropsWithChildren) => (
  <div className={styles.gridRow}>{children}</div>
);

const CardSeparator = () => <hr className={styles.separator} />;

export default Card;

Card.Button = CardButton;
Card.BodyItem = CardBodyItem;
Card.ItemRow = CardItemRow;
Card.Separator = CardSeparator;
Card.GridRow = CardGridRow;
