import React from 'react';
import { useIntl } from 'react-intl';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip, TrafficLightIndicator } from '@skiwo/components';
import classnames from 'classnames';
import { format } from 'date-fns';
import translationKeys from '../../../translations/translationKeys';
import styles from './Hint.module.scss';

interface Props {
  invitationsCount: number;
  dangerLevel: 1 | 2 | 3 | 4 | 5 | 6 | 100;
  applicationsCount: number;
  lastWithdrawalAt?: string;
}

const DangerLevelHint = (props: Props) => {
  const { invitationsCount, dangerLevel, applicationsCount, lastWithdrawalAt } = props;
  const intl = useIntl();

  switch (dangerLevel) {
    case 1:
      return (
        <Tooltip
          title={intl.formatMessage(
            { id: translationKeys.jobs_page_danger_level_a_tooltip_title },
            { invitationCount: invitationsCount },
          )}
          subtitle={intl.formatMessage(
            { id: translationKeys.jobs_page_danger_level_a_tooltip_subtitle },
            { applicationCount: applicationsCount },
          )}
        >
          <div>
            <TrafficLightIndicator variant="green" data-testid="danger-level-A" />
          </div>
        </Tooltip>
      );

    case 2:
      return (
        <Tooltip
          title={intl.formatMessage({ id: translationKeys.jobs_page_danger_level_b_tooltip_title })}
          subtitle={intl.formatMessage({
            id: translationKeys.jobs_page_danger_level_b_tooltip_subtitle,
          })}
        >
          <div>
            <TrafficLightIndicator variant="green" data-testid="danger-level-B" />
          </div>
        </Tooltip>
      );

    case 3:
      return (
        <Tooltip
          title={intl.formatMessage(
            { id: translationKeys.jobs_page_danger_level_c_tooltip_title },
            { invitationCount: invitationsCount },
          )}
          subtitle={intl.formatMessage({
            id: translationKeys.jobs_page_danger_level_c_tooltip_subtitle,
          })}
        >
          <div>
            <TrafficLightIndicator variant="yellow" data-testid="danger-level-C" />
          </div>
        </Tooltip>
      );

    case 4:
      return (
        <Tooltip
          title={intl.formatMessage(
            { id: translationKeys.jobs_page_danger_level_d_tooltip_title },
            { invitationCount: invitationsCount },
          )}
          subtitle={intl.formatMessage({
            id: translationKeys.jobs_page_danger_level_d_tooltip_subtitle,
          })}
        >
          <div>
            <TrafficLightIndicator variant="yellow" data-testid="danger-level-D" />
          </div>
        </Tooltip>
      );

    case 5:
      return (
        <Tooltip
          title={intl.formatMessage(
            { id: translationKeys.jobs_page_danger_level_e_tooltip_title },
            { invitationCount: invitationsCount },
          )}
          subtitle={intl.formatMessage({
            id: translationKeys.jobs_page_danger_level_e_tooltip_subtitle,
          })}
        >
          <div>
            <TrafficLightIndicator variant="yellow" data-testid="danger-level-E" />
          </div>
        </Tooltip>
      );

    case 6:
      return (
        <Tooltip
          title={intl.formatMessage(
            { id: translationKeys.jobs_page_danger_level_f_tooltip_title },
            { invitationCount: invitationsCount },
          )}
          subtitle={intl.formatMessage({
            id: translationKeys.jobs_page_danger_level_f_tooltip_subtitle,
          })}
        >
          <div>
            <TrafficLightIndicator variant="red" data-testid="danger-level-F" />
          </div>
        </Tooltip>
      );

    case 100:
      return (
        <Tooltip
          title={intl.formatMessage({
            id: translationKeys.jobs_page_danger_level_warning_tooltip_title,
          })}
          subtitle={
            lastWithdrawalAt ? format(new Date(lastWithdrawalAt), 'dd.MM.yyyy HH:mm') : undefined
          }
        >
          <FontAwesomeIcon
            data-testid="danger-level-triangle-exclamation"
            icon={faTriangleExclamation}
            className={classnames(styles.icon, styles.triangleExclamation)}
          />
        </Tooltip>
      );
  }
};

export default DangerLevelHint;
