import React from 'react';
import { Skeleton } from '@skiwo/components';
import styles from './SupplierItemSkeleton.module.scss';

const SupplierItemSkeleton = () => {
  return (
    <tr className={styles.skeleton} data-testid="supplier-item-skeleton">
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton variant="circles" circleCount={4} />
      </td>
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton variant="details" />
      </td>
      <td>
        <Skeleton variant="circles" />
      </td>
    </tr>
  );
};

export default SupplierItemSkeleton;
