import React, { useEffect, useState } from 'react';
import { Form as B_Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { faFileInvoice, faPlus, faRotateLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  RadioOption,
  RadioOptions,
  SearchDropdown,
  SearchDropdownMenuOption,
  TextField,
} from '@skiwo/components';
import classNames from 'classnames';
import { FormikProps } from 'formik';
import CreatePaymentDrawer from '../../components/CreatePaymentDrawer/CreatePaymentDrawer';
import PaymentRegexHint from '../../components/PaymentRegexHint/PaymentRegexHint';
import usePaymentRegexHint from '../../components/PaymentRegexHint/usePaymentRegexHint';
import getDefaultReferences from '../../helpers/getDefaultReferences';
import { getPaymentMethodName } from '../../helpers/getPaymentMethodName';
import { useApi } from '../../providers/ApiProvider';
import { useToast } from '../../providers/ToastProvider/ToastProvider';
import translationKeys from '../../translations/translationKeys';
import { CustomerPaymentMethod, EnterpriseDepartment, ManagerCustomer } from '../../types';
import { CreateInterpretationOrderFormValues } from '../schema';
import SectionContainer from '../SectionContainer/SectionContainer';
import { getInvoiceStrategyName } from './getInvoiceStrategyName';
import { PaymentSectionSkeleton } from './PaymentSectionSkeleton';
import styles from './PaymentSection.module.scss';

interface PaymentSectionProps {
  customer: ManagerCustomer;
  selectedPaymentMethod?: CustomerPaymentMethod;
  setSelectedPaymentMethod: (paymentMethod?: CustomerPaymentMethod) => void;
  selectedDepartment?: SearchDropdownMenuOption;
  departmentList?: EnterpriseDepartment[];
  formikProps: FormikProps<CreateInterpretationOrderFormValues>;
}

export const PaymentSection = ({
  customer,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  selectedDepartment,
  departmentList,
  formikProps,
}: PaymentSectionProps) => {
  const intl = useIntl();
  const [showCreateDrawer, setShowCreateDrawer] = useState(false);
  const [paymentMethodOptions, setPaymentMethodOptions] = useState<RadioOption[]>([]);
  const [paymentMethodDropdownOptions, setPaymentMethodDropdownOptions] = useState<
    SearchDropdownMenuOption[]
  >([]);
  const [paymentMethods, setPaymentMethods] = useState<CustomerPaymentMethod[]>([]);
  const [paymentMethodsLoading, setPaymentMethodsLoading] = useState(false);
  const api = useApi();
  const { showErrorToast } = useToast();
  const { regexHintIcon, matchRegex } = usePaymentRegexHint(selectedPaymentMethod);

  const handleSelectPaymentMethod = (selectedId: string) => {
    const selectedMethod = paymentMethods.find((method) => method.id.toString() === selectedId);

    if (selectedMethod) {
      formikProps.setFieldValue('paymentMethodId', selectedMethod.id.toString());
      setSelectedPaymentMethod(selectedMethod);
    }
  };

  const getDefaultPaymentMethod = () => {
    const department = departmentList?.find((item) => item.id === selectedDepartment?.id);

    if (department?.paymentMethod) {
      setSelectedPaymentMethod(
        paymentMethods.find(
          (method) => method.id.toString() === department.paymentMethod?.id.toString(),
        ),
      );
      formikProps.setFieldValue('paymentMethodId', department.paymentMethod.id.toString());
    } else if (paymentMethods.length) {
      setSelectedPaymentMethod(paymentMethods[0]);
      formikProps.setFieldValue('paymentMethodId', paymentMethods[0].id.toString());
    }
  };

  const { defaultBookingReference, defaultPaymentBookingReference } = getDefaultReferences({
    customer,
    departmentList,
    paymentMethod: selectedPaymentMethod,
    selectedDepartment,
  });

  const getPaymentMethods = async () => {
    const { data, error } = await api.getPaymentMethods(
      {
        customerUid: customer.uid,
      },
      setPaymentMethodsLoading,
    );

    if (data) {
      const activePaymentMethods = data.filter((item) => !item.archived);
      setPaymentMethods(activePaymentMethods);

      const paymentOptions: RadioOption[] = activePaymentMethods
        .map((option) => {
          const title = option.orgNumber ? option.orgName : option.email;
          return {
            id: option.id.toString(),
            title: (
              <div className={styles.paymentMethodTitle}>
                <span>{title}</span>
                <span className={styles.paymentMethodOrgNumber}>
                  {option.orgNumber ||
                    intl.formatMessage({
                      id: translationKeys.translation_order_edit_invoicing_payment_methods_email_label,
                    })}
                </span>
              </div>
            ),
            subtitle: (
              <span className={styles.invoiceStrategy}>
                {getInvoiceStrategyName(option.invoiceGroupStrategy, intl)}
              </span>
            ),
            default: option.default,
          };
        })
        .sort((a, b) => {
          if (b.default) {
            return 1;
          } else {
            return a.default ? -1 : 0;
          }
        });

      const paymentDropdownOptions: SearchDropdownMenuOption[] = activePaymentMethods.map(
        (option) => {
          return {
            id: option.id,
            label: getPaymentMethodName(
              {
                orgName: option.orgName,
                orgNumber: option.orgNumber,
                email: option.email,
                invoiceGroupStrategy: option.invoiceGroupStrategy,
              },
              intl,
            ),
            key: option.id.toString(),
          };
        },
      );

      setPaymentMethodOptions(paymentOptions);
      setPaymentMethodDropdownOptions(paymentDropdownOptions);

      if (!formikProps.values.paymentMethodId && paymentOptions[0]) {
        formikProps.setFieldValue('paymentMethodId', paymentOptions[0].id);
        setSelectedPaymentMethod(
          activePaymentMethods.find((method) => method.id.toString() === paymentOptions[0].id),
        );
      }
    }

    if (error) {
      showErrorToast(error);
    }
  };

  const getSelectedPaymentMethod = (id: string) => {
    const option = paymentMethodDropdownOptions.find((option) => {
      return option.id.toString() === id;
    });

    return option ? [option] : [];
  };

  const getBookerRefDecoration = () => {
    if (!defaultBookingReference) return;

    if (formikProps.values.bookingReference === defaultBookingReference) {
      return (
        <span className={styles.defaultIndicator}>
          <FormattedMessage id={translationKeys.translation_order_edit_invoicing_default_label} />
        </span>
      );
    }

    return (
      <FontAwesomeIcon
        icon={faRotateLeft}
        onClick={() => formikProps.setFieldValue('bookingReference', defaultBookingReference)}
      />
    );
  };

  const getInvoiceRefDecoration = () => {
    if (!defaultPaymentBookingReference) return;

    if (formikProps.values.paymentBookingReference === defaultPaymentBookingReference) {
      return (
        <span className={styles.defaultIndicator}>
          <FormattedMessage id={translationKeys.translation_order_edit_invoicing_default_label} />
        </span>
      );
    }

    return (
      <FontAwesomeIcon
        icon={faRotateLeft}
        onClick={() =>
          formikProps.setFieldValue('paymentBookingReference', defaultPaymentBookingReference)
        }
      />
    );
  };

  useEffect(() => {
    getPaymentMethods();
  }, []);

  useEffect(() => {
    if (selectedDepartment && selectedPaymentMethod) {
      formikProps.setFieldValue('bookingReference', defaultBookingReference);
      formikProps.setFieldValue('paymentBookingReference', defaultPaymentBookingReference);
    }
  }, [selectedDepartment, selectedPaymentMethod]);

  useEffect(() => {
    if (selectedDepartment) {
      getDefaultPaymentMethod();
    }
  }, [selectedDepartment]);

  return (
    <>
      <SectionContainer
        title={intl.formatMessage({
          id: translationKeys.create_interpretation_order_payment_label,
        })}
        icon={<FontAwesomeIcon icon={faFileInvoice} />}
        data-testid="payments-section"
      >
        {paymentMethodsLoading ? (
          <PaymentSectionSkeleton />
        ) : !paymentMethodOptions.length ? (
          <div>
            <span className={styles.label}>
              <FormattedMessage
                id={translationKeys.create_interpretation_order_payment_method_label}
              />
            </span>
            <button
              className={classNames(styles.addNewButton)}
              onClick={() => setShowCreateDrawer(true)}
              data-testid="add-payment-button"
              type="button"
            >
              <FontAwesomeIcon icon={faPlus} />
              <FormattedMessage
                id={translationKeys.create_translation_order_invoicing_add_new_button}
              />
            </button>
          </div>
        ) : (
          <>
            {paymentMethodOptions.length <= 4 && (
              <div className={styles.paymentMethods} data-testid="radio-options">
                <RadioOptions
                  label={intl.formatMessage({
                    id: translationKeys.create_interpretation_order_payment_method_label,
                  })}
                  options={paymentMethodOptions}
                  onSelect={(selectedId) => handleSelectPaymentMethod(selectedId)}
                  block
                  selected={formikProps.values.paymentMethodId}
                />
                <button
                  className={styles.newOption}
                  onClick={() => setShowCreateDrawer(true)}
                  data-testid="add-payment-button"
                  type="button"
                >
                  <FontAwesomeIcon icon={faPlus} />
                  <FormattedMessage
                    id={translationKeys.create_translation_order_invoicing_add_new_button}
                  />
                </button>
              </div>
            )}
            {paymentMethodOptions.length > 4 && (
              <div className={styles.dropdownPaymentMethods} data-testid="dropdown-options">
                <B_Form.Label>
                  <FormattedMessage
                    id={translationKeys.translation_order_edit_invoicing_payment_methods_label}
                  />
                </B_Form.Label>
                <div className={styles.selectorWithButton}>
                  <div className={styles.selector}>
                    <SearchDropdown
                      options={paymentMethodDropdownOptions}
                      selected={getSelectedPaymentMethod(formikProps.values.paymentMethodId)}
                      onChange={(option) => {
                        if (option && option.length > 0 && option[0].key) {
                          formikProps.setFieldValue('paymentMethodId', option[0].id.toString());
                          handleSelectPaymentMethod(option[0].id.toString());
                        }
                      }}
                      size="large"
                    />
                  </div>
                  <Button
                    variant="gray"
                    icon={<FontAwesomeIcon icon={faPlus} />}
                    onClick={() => setShowCreateDrawer(true)}
                    size="x-large"
                    className={styles.button}
                    data-testid="add-payment-button"
                    type='button'
                  >
                    <FormattedMessage
                      id={translationKeys.create_translation_order_invoicing_add_new_button}
                    />
                  </Button>
                </div>
              </div>
            )}
            <hr />
            {selectedPaymentMethod && (
              <div className={styles.inputRow}>
                {selectedPaymentMethod.allowBookingReference && (
                  <div>
                    <TextField
                      label={selectedPaymentMethod.labelForBookingReference}
                      placeholder={`${selectedPaymentMethod.labelForBookingReference}...`}
                      size="large"
                      decorationView={getBookerRefDecoration()}
                      onBlur={formikProps.handleBlur}
                      onChange={(e) => {
                        formikProps.handleChange(e);
                        matchRegex(e);
                      }}
                      errorText={
                        formikProps.touched.bookingReference
                          ? formikProps.errors.bookingReference
                          : undefined
                      }
                      value={formikProps.values.bookingReference}
                      name="bookingReference"
                      data-testid="booking-reference"
                    />
                    <PaymentRegexHint
                      reference={formikProps.values.bookingReference}
                      referenceRegex={selectedPaymentMethod.bookingReferenceRegex}
                      referenceRegexHint={selectedPaymentMethod.bookingReferenceRegexHint}
                      icon={regexHintIcon.bookingRefIcon}
                    />
                  </div>
                )}
                {selectedPaymentMethod.allowPaymentBookingReference && (
                  <div>
                    <TextField
                      label={selectedPaymentMethod.labelForPaymentBookingReference}
                      placeholder={`${selectedPaymentMethod.labelForPaymentBookingReference}...`}
                      size="large"
                      decorationView={getInvoiceRefDecoration()}
                      onBlur={formikProps.handleBlur}
                      onChange={(e) => {
                        formikProps.handleChange(e);
                        matchRegex(e);
                      }}
                      errorText={
                        formikProps.touched.paymentBookingReference
                          ? formikProps.errors.paymentBookingReference
                          : undefined
                      }
                      value={formikProps.values.paymentBookingReference}
                      name="paymentBookingReference"
                      data-testid="payment-booking-reference"
                    />
                    <PaymentRegexHint
                      reference={formikProps.values.paymentBookingReference}
                      referenceRegex={selectedPaymentMethod.paymentBookingReferenceRegex}
                      referenceRegexHint={selectedPaymentMethod.paymentBookingReferenceRegexHint}
                      icon={regexHintIcon.paymentBookingRefIcon}
                    />
                  </div>
                )}
                {selectedPaymentMethod?.allowCaseNumber && (
                  <TextField
                    label={selectedPaymentMethod.labelForCaseNumber}
                    placeholder={`${selectedPaymentMethod.labelForCaseNumber}...`}
                    size="large"
                    onBlur={formikProps.handleBlur}
                    onChange={formikProps.handleChange}
                    errorText={
                      formikProps.touched.caseNumber ? formikProps.errors.caseNumber : undefined
                    }
                    value={formikProps.values.caseNumber}
                    name="caseNumber"
                    data-testid="case-number"
                  />
                )}
              </div>
            )}
          </>
        )}
      </SectionContainer>

      <CreatePaymentDrawer
        show={showCreateDrawer}
        onClose={() => {
          setShowCreateDrawer(false);
          getPaymentMethods();
        }}
        customerName={customer.name}
        enterpriseName={customer.enterprise?.name}
        enterpriseId={customer.enterprise?.id?.toString()}
      />
    </>
  );
};
