import React, { PropsWithChildren } from 'react';
import styles from './InfoItem.module.scss';

interface InfoItemProps {
  label: string;
  icons?: React.ReactNode;
}

export const InfoItem = ({ label, icons, children }: PropsWithChildren<InfoItemProps>) => {
  return (
    <div className={styles.infoItem}>
      <span className={styles.label}>{label}</span>
      <span className={styles.value} data-testid="order-details-created-at">
        {icons}
        {children || '-'}
      </span>
    </div>
  );
};

export default InfoItem;
