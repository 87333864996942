import TranslationSkillsFilterField from '../TranslationSkillsFilterField';

const getTranslationSkillsFilters = (
  currentFilters: Record<string, string | string[]>,
  field: TranslationSkillsFilterField,
  value: string,
): Record<string, string | string[]> => {
  if (field === TranslationSkillsFilterField.Id) {
    return { ...currentFilters, ...{ 's[id_eq]': value } };
  }

  if (field === TranslationSkillsFilterField.Name) {
    let filterValue = value;
    const isPhone = value.startsWith('+47');

    if (isPhone) {
      filterValue = value.replaceAll(' ', '');
    }

    return { ...currentFilters, ...{ 's[lookup_fields_cont]': filterValue } };
  }

  if (field === TranslationSkillsFilterField.Languages) {
    const languages = value.split(',');
    if (languages.length > 0) {
      return { ...currentFilters, ...{ 's[lang_id_in]': languages } };
    }

    return currentFilters;
  }

  if (field === TranslationSkillsFilterField.Qualifications) {
    const qualifications = value.split(',');
    if (qualifications.length > 0) {
      return { ...currentFilters, ...{ 's[qualification_id_in': qualifications } };
    }

    return currentFilters;
  }

  if (field === TranslationSkillsFilterField.CreatedAt) {
    const dates = value.split(',');

    return {
      ...currentFilters,
      ...{ 's[created_at_gteq]': dates[0], 's[created_at_lteq]': dates[1] },
    };
  }

  if (field === TranslationSkillsFilterField.AccountStatuses) {
    return {
      ...currentFilters,
      ...{ 's[account_status_name_in][]': value.split(',') },
    };
  }

  return currentFilters;
};

export default getTranslationSkillsFilters;
