import fetchApi from '../fetchApi';

export default {
  signIn: (username: string, password: string, loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi<any>({
      method: 'POST',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/accounts/sign_in`,
      body: {
        username,
        password,
      },
      loadingCallback,
    });
  },
  resetPassword: (username: string, loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi({
      method: 'POST',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/accounts/reset_passwords`,
      body: {
        username,
      },
      loadingCallback,
    });
  },
  getProfile: (userUid: string, loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi<any>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/profiles/${userUid}`,
      loadingCallback,
    });
  },
  setNewPassword: (
    token: string,
    password: string,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi<any>({
      method: 'PUT',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/accounts/reset_passwords/${token}`,
      body: {
        identity: { password },
      },
      loadingCallback,
    });
  },
};
