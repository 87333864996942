// Add feature flags here.
export enum Feature {
  CreateInterpretationOrder = 'create_interpretation_order',
  CreateTranslationOrder = 'create_translation_order',
  Interpretation = 'interpretation_page',
}

interface FeatureToggle {
  features: Record<Feature, boolean>;
}

export default FeatureToggle;
