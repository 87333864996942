import React from 'react';
import { ManagerJobDetails } from '../../../types/ManagerJob';
import InformationCard from './InformationCard/InformationCard';
import CustomerCard from './CustomerCard';
import InvoicingCard from './InvoicingCard';
import styles from './JobFooter.module.scss';

interface JobFooterProps {
  job: ManagerJobDetails;
}

const JobFooter = ({ job }: JobFooterProps) => {
  return (
    <footer className={styles.jobFooter}>
      <InformationCard job={job} />
      <CustomerCard job={job} />
      <InvoicingCard job={job} />
    </footer>
  );
};

export default JobFooter;
