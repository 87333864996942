import { IntlShape } from 'react-intl';
import {
  faChartLineUp,
  faClipboardListCheck,
  faDisplayChartUp,
  faFileInvoice,
  faMoneyCheckDollarPen,
} from '@fortawesome/pro-light-svg-icons';
import translationKeys from '../translations/translationKeys';

const getFinance = (intl: IntlShape) => {
  const interpretationSection = {
    name: intl.formatMessage({ id: translationKeys.menu_finance_interpretation_section }),
    items: [
      {
        name: intl.formatMessage({
          id: translationKeys.menu_finance_interpretation_assignments,
        }),
        description: intl.formatMessage({
          id: translationKeys.menu_finance_interpretation_assignments_description,
        }),
        link: '#',
        icon: faDisplayChartUp,
        disabled: true,
      },
      {
        name: intl.formatMessage({
          id: translationKeys.menu_finance_interpretation_customer_invoices,
        }),
        description: intl.formatMessage({
          id: translationKeys.menu_finance_interpretation_customer_invoices_description,
        }),
        link: '#',
        icon: faFileInvoice,
        disabled: true,
      },
      {
        name: intl.formatMessage({
          id: translationKeys.menu_finance_interpreter_payouts,
        }),
        description: intl.formatMessage({
          id: translationKeys.menu_finance_interpreter_payouts_description,
        }),
        link: '#',
        icon: faMoneyCheckDollarPen,
        disabled: true,
      },
    ],
  };

  const translationSection = {
    name: intl.formatMessage({ id: translationKeys.menu_finance_translation_section }),
    items: [
      {
        name: intl.formatMessage({
          id: translationKeys.menu_finance_translation_assignments,
        }),
        description: intl.formatMessage({
          id: translationKeys.menu_finance_translation_assignments_description,
        }),
        link: '#',
        icon: faDisplayChartUp,
        disabled: true,
      },
      {
        name: intl.formatMessage({
          id: translationKeys.menu_finance_translation_customer_invoices,
        }),
        description: intl.formatMessage({
          id: translationKeys.menu_finance_translation_customer_invoices_description,
        }),
        link: '#',
        icon: faFileInvoice,
        disabled: true,
      },
      {
        name: intl.formatMessage({
          id: translationKeys.menu_finance_translator_payouts,
        }),
        description: intl.formatMessage({
          id: translationKeys.menu_finance_translator_payouts_description,
        }),
        link: '#',
        icon: faMoneyCheckDollarPen,
        disabled: true,
      },
    ],
  };

  const pricesAndStatisticsSection = {
    name: intl.formatMessage({ id: translationKeys.menu_finance_prices_and_statistics_section }),
    items: [
      {
        name: intl.formatMessage({
          id: translationKeys.menu_finance_statistics,
        }),
        description: intl.formatMessage({
          id: translationKeys.menu_finance_statistics_description,
        }),
        link: '#',
        icon: faChartLineUp,
        disabled: true,
      },
      {
        name: intl.formatMessage({
          id: translationKeys.menu_finance_price_templates,
        }),
        description: intl.formatMessage({
          id: translationKeys.menu_finance_price_templates_description,
        }),
        link: '#',
        icon: faClipboardListCheck,
        disabled: true,
      },
    ],
  };

  return {
    name: intl.formatMessage({
      id: translationKeys.menu_finance_item,
    }),
    sections: [interpretationSection, translationSection, pricesAndStatisticsSection],
  };
};

export default getFinance;
