import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import {
  faCircleExclamation,
  faClock,
  faDiagramSubtask,
  faEnvelope,
  faPage,
  faPhone,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Skeleton, Tag, Tooltip } from '@skiwo/components';
import { formatPhoneNumber } from '@skiwo/utils';
import classnames from 'classnames';
import { differenceInDays, differenceInMonths, differenceInWeeks, format } from 'date-fns';
import { useLanguages } from '../../providers/LanguagesProvider';
import { useQualifications } from '../../providers/QualificationsProvider';
import translationKeys from '../../translations/translationKeys';
import { TranslationSubtask, TranslationSubtaskStatus } from '../../types';
import { TranslationSubtaskCustomerInput } from '../../types/TranslationSubtask';
import getStatusName from '../utils/getStatusName';
import styles from './TranslationSubtaskItem.module.scss';

interface Props {
  subtask: TranslationSubtask;
  onCompleteAction: (hardReload?: boolean) => void;
  onIdFilter: (value: string) => void;
}

const TranslationSubtaskItem = (props: Props) => {
  const { subtask, onIdFilter } = props;
  const intl = useIntl();
  const { getLanguageById, isLoading: languagesLoading } = useLanguages();
  const { getQualificationById, isLoading: QualificationsLoading } = useQualifications();
  const externalDeadlineTimeStamp = new Date(subtask.externalDeadline || '');
  const internalDeadlineTimeStamp = new Date(subtask.internalDeadline || '');
  const createdAlTimeStamp = new Date(subtask.orderCreatedAt);

  function getTimeUntilDeadline(deadline: Date) {
    const now = new Date();

    if (now > deadline) {
      return (
        <span
          data-testid="deadlined-passed-text"
          className={classnames(styles.details, styles.critical)}
        >
          <FormattedMessage id={translationKeys.translation_subtasks_deadline_passed_text} />
        </span>
      );
    }

    const months = differenceInMonths(deadline, now);
    const weeks = differenceInWeeks(deadline, new Date(now.setMonth(now.getMonth() + months)));
    const days = differenceInDays(deadline, new Date(now.setDate(now.getDate() + 7 * weeks)));

    return (
      <span className={styles.details}>
        <FormattedMessage
          values={{ months, weeks, days }}
          id={translationKeys.translation_subtasks_deadline_time_left}
        />{' '}
      </span>
    );
  }

  const getStatusTagColor = () => {
    if (subtask.subtaskStatus === TranslationSubtaskStatus.ChangesRequired) {
      return 'primary';
    }

    if (
      subtask.subtaskStatus === TranslationSubtaskStatus.Accepted ||
      subtask.subtaskStatus === TranslationSubtaskStatus.Finished
    ) {
      return 'success';
    }

    if (
      subtask.subtaskStatus === TranslationSubtaskStatus.ReviewRequested ||
      subtask.subtaskStatus === TranslationSubtaskStatus.ProactiveAccess
    ) {
      return 'info';
    }

    if (
      subtask.subtaskStatus === TranslationSubtaskStatus.Invited ||
      subtask.subtaskStatus === TranslationSubtaskStatus.InProgress
    ) {
      return 'warning';
    }

    if (
      subtask.subtaskStatus === TranslationSubtaskStatus.Cancelled ||
      subtask.subtaskStatus === TranslationSubtaskStatus.Rejected
    ) {
      return 'error';
    }

    return 'neutral';
  };

  return (
    <tr className={styles.itemRow} data-testid="translation-subtask-item">
      <td>
        <div className={styles.subtaskId}>
          <Link
            to={`/translation-subtasks/${subtask.orderId}`}
            className={styles.tableTitleText}
            data-testid="subtask-id"
          >
            {subtask.publicId}
          </Link>

          {subtask.godmodeSupplierUrl && (
            <div>
              [
              <a
                data-testid="god-mode-supplier-url"
                target="_blank"
                href={subtask.godmodeSupplierUrl}
                rel="noreferrer"
              >
                T
              </a>
              ]
            </div>
          )}

          <span>
            <IconButton
              size="medium"
              icon={<FontAwesomeIcon icon={faDiagramSubtask} />}
              onClick={() => onIdFilter(subtask.orderId.toString())}
              hoverText={intl.formatMessage({
                id: translationKeys.translation_subtasks_order_filter_tooltip,
              })}
              data-testid="order-id-icon"
            />
          </span>
        </div>
      </td>
      <td className={styles.hintsFilter}>
        <div className={styles.hints}>
          <Tooltip
            title={
              subtask.initialAttachmentsPresent
                ? intl.formatMessage({
                    id: translationKeys.translation_subtasks_document_attached,
                  })
                : intl.formatMessage({
                    id: translationKeys.translation_subtasks_no_document_attached,
                  })
            }
          >
            <FontAwesomeIcon
              className={classnames(styles.icon, {
                [styles.active]: subtask.initialAttachmentsPresent,
              })}
              icon={faPage}
            />
          </Tooltip>
        </div>
      </td>
      <td data-testid="status-tag">
        <div className={styles.status}>
          <Tag color={getStatusTagColor()}>{getStatusName(subtask.subtaskStatus, intl)}</Tag>

          {subtask.orderCustomerInputStatus === TranslationSubtaskCustomerInput.Requested && (
            <Tooltip
              title={intl.formatMessage({
                id: translationKeys.translation_subtasks_waiting_for_customer_tooltip,
              })}
            >
              <span className={styles.statusTooltip}>
                <FontAwesomeIcon icon={faClock} />
                <FormattedMessage id={translationKeys.translation_subtasks_waiting_label} />
              </span>
            </Tooltip>
          )}
          {subtask.orderCustomerInputStatus === TranslationSubtaskCustomerInput.Updated &&
            subtask.orderCustomerInputUpdatedAt && (
              <Tooltip
                title={intl.formatMessage(
                  { id: translationKeys.translation_subtasks_updated_by_customer_tooltip },
                  {
                    updatedAt: format(
                      new Date(subtask.orderCustomerInputUpdatedAt),
                      'dd.MM.yyyy, HH:mm',
                    ),
                  },
                )}
              >
                <span className={styles.statusTooltip}>
                  <FontAwesomeIcon icon={faCircleExclamation} />
                  <FormattedMessage id={translationKeys.translation_subtasks_updated_label} />
                </span>
              </Tooltip>
            )}
        </div>
      </td>
      <td>
        {subtask.owner?.email ? (
          <>
            <div>
              <span className={styles.indicatorIcons}>
                <Tooltip title={subtask.owner?.email} copyable>
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className={classnames(styles.icon, { [styles.active]: !!subtask.owner?.email })}
                  />
                </Tooltip>

                <Tooltip title={formatPhoneNumber(subtask.owner?.phone)} copyable>
                  <FontAwesomeIcon
                    icon={faPhone}
                    className={classnames(styles.icon, { [styles.active]: !!subtask.owner?.phone })}
                  />
                </Tooltip>
                <Tooltip title={subtask.owner?.name || subtask.owner?.email} copyable>
                  <span className={styles.tableTitleText}>
                    {subtask.owner?.name || subtask.owner?.email}
                  </span>
                </Tooltip>
              </span>
            </div>
            <span className={styles.details}>{subtask.owner?.enterpriseName}</span>
          </>
        ) : (
          '-'
        )}
      </td>
      <td>
        {languagesLoading ? (
          <Skeleton variant="details" />
        ) : (
          <>
            <div>
              <span className={styles.tableTitleText}>
                {getLanguageById(subtask.sourceLanguageId)}
              </span>
            </div>
            <span>{getLanguageById(subtask.targetLanguageId)}</span>
          </>
        )}
      </td>
      <td>
        {subtask.externalDeadline ? (
          <div data-testid="external-deadline-time">
            <span className={styles.tableTitleText}>
              {format(externalDeadlineTimeStamp, 'dd.MM.yyyy')}
            </span>
            {getTimeUntilDeadline(externalDeadlineTimeStamp)}
          </div>
        ) : (
          <span>-</span>
        )}
      </td>
      <td>
        {subtask.internalDeadline ? (
          <div data-testid="internal-deadline-time">
            <span className={styles.tableTitleText}>
              {format(internalDeadlineTimeStamp, 'dd.MM.yyyy')}
            </span>
            <span className={styles.details}>
              {getTimeUntilDeadline(internalDeadlineTimeStamp)}
            </span>
          </div>
        ) : (
          <span>-</span>
        )}
      </td>
      <td>
        <span className={styles.tableTitleText}>{format(createdAlTimeStamp, 'dd.MM.yyyy')}</span>
      </td>
      <td>
        {subtask.translator?.email ? (
          <>
            <div>
              <span className={styles.indicatorIcons}>
                <Tooltip title={subtask.translator?.email} copyable>
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className={classnames(styles.icon, {
                      [styles.active]: !!subtask.translator?.email,
                    })}
                  />
                </Tooltip>

                <Tooltip title={formatPhoneNumber(subtask.translator?.phone)} copyable>
                  <FontAwesomeIcon
                    icon={faPhone}
                    className={classnames(styles.icon, {
                      [styles.active]: !!subtask.translator?.phone,
                    })}
                  />
                </Tooltip>
              </span>
              <span className={styles.tableTitleText}>
                {subtask.translator?.name || subtask.translator?.email}
              </span>
            </div>
            <span className={styles.details}>
              {QualificationsLoading ? (
                <Skeleton />
              ) : (
                <span className={styles.qualification}>
                  {subtask.translator.qualificationId
                    ? getQualificationById(subtask.translator.qualificationId)?.name
                    : '-'}
                </span>
              )}
            </span>
          </>
        ) : (
          '-'
        )}
      </td>
      <td className={styles.tableTitleText}>{subtask.wordcount || '-'}</td>
      <td>{subtask.note?.note || '-'}</td>
    </tr>
  );
};

export default TranslationSubtaskItem;
