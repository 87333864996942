import { ManagerOrderSubtaskStatus } from '../../types';

const getSubtaskStatusColour = (
  status: ManagerOrderSubtaskStatus,
): 'success' | 'error' | 'info' | 'warning' | 'primary' | 'neutral' => {
  if (status === ManagerOrderSubtaskStatus.Accepted) {
    return 'success';
  }

  if (
    status === ManagerOrderSubtaskStatus.Rejected ||
    status === ManagerOrderSubtaskStatus.Cancelled
  ) {
    return 'error';
  }

  if (
    status === ManagerOrderSubtaskStatus.ReviewRequested ||
    status === ManagerOrderSubtaskStatus.Finished ||
    status === ManagerOrderSubtaskStatus.ProactiveAccess
  ) {
    return 'info';
  }

  if (
    status === ManagerOrderSubtaskStatus.Invited ||
    status === ManagerOrderSubtaskStatus.InProgress ||
    status === ManagerOrderSubtaskStatus.ChangesRequired
  ) {
    return 'warning';
  }

  return 'neutral';
};

export default getSubtaskStatusColour;
