import EnterprisesFilterField from '../EnterprisesFilterField';

const getEnterpriseFilters = (
  currentFilters: Record<string, string>,
  field: EnterprisesFilterField,
  value: string,
): Record<string, string> => {
  if (field === EnterprisesFilterField.Id) {
    return { ...currentFilters, ...{ 's[id_eq]': value } };
  }

  if (field === EnterprisesFilterField.OrgName) {
    return { ...currentFilters, ...{ 's[name_cont]': value } };
  }

  if (field === EnterprisesFilterField.OrgNumber) {
    return { ...currentFilters, ...{ 's[org_number_cont]': value } };
  }

  if (field === EnterprisesFilterField.Owner) {
    let filterValue = value;
    const isPhone = value.startsWith('+47');

    if (isPhone) {
      filterValue = value.replaceAll(' ', '');
    }

    return { ...currentFilters, ...{ 's[owner_lookup_fields_cont]': filterValue } };
  }

  return currentFilters;
};

export default getEnterpriseFilters;
