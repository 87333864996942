import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Avatar } from '@skiwo/components';
import { FormikProps, FormikValues } from 'formik';
import useDebounce from '../../hooks/useDebounce';
import { useApi } from '../../providers/ApiProvider';
import translationKeys from '../../translations/translationKeys';
import { ManagerCustomer } from '../../types';
import CustomerSearchDropdown, {
  CustomerSearchDropdownMenuOption,
} from '../CustomerSearchDropdown/CustomerSearchDropdown';
import CustomerSkeleton from './CustomerSkeleton/CustomerSkeleton';
import styles from './SearchCustomerSection.module.scss';

interface SearchCustomerSectionProps<V extends FormikValues> {
  setCustomer: (customer: ManagerCustomer) => void;
  formikProps: FormikProps<V>;
}

const SearchCustomerSection = <V extends FormikValues>({
  setCustomer,
  formikProps,
}: SearchCustomerSectionProps<V>) => {
  const api = useApi();
  const intl = useIntl();
  const [customerResults, setCustomerResults] = useState<ManagerCustomer[]>([]);
  const [customerTopResults, setCustomerTopResults] = useState<ManagerCustomer[]>([]);
  const [customerOptions, setCustomerOptions] = useState<CustomerSearchDropdownMenuOption[]>([]);
  const [customerTopLoading, setCustomerTopLoading] = useState(false);
  const [customerSearchLoading, setCustomerSearchLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [pagination, setPagination] = useState({ page: 1, totalPages: 1 });
  const debounceCustomerSearch = useDebounce(300);

  const getCustomerList = async (query: string, page = 1) => {
    const { data } = await api.getCustomers(
      {
        's[enterprise_members]': true,
        's[lookup_fields_or_enterprise_or_departments_cont]': query,
        page: page > 0 ? page : 1,
      },
      setCustomerSearchLoading,
    );

    if (!data) return;
    const customerOptions =
      data.customers.map((customer) => {
        return {
          id: customer.id,
          uid: customer.uid,
          logoUrl: customer.enterprise?.logoUrl,
          name: customer.name,
          enterpriseName: customer.enterprise?.name,
          departmentName:
            customer.departments?.length === 1
              ? customer.departments[0].name
              : `${customer.departments?.length || 0} departments`,
          email: customer.email,
          phone: customer.phone,
          key: customer.uid,
        };
      }) || [];

    const appendData = page > 1;
    setCustomerResults(appendData ? (prev) => [...prev, ...data.customers] : data.customers);
    setCustomerOptions(appendData ? (prev) => [...prev, ...customerOptions] : customerOptions);
    setPagination({ page: data.page, totalPages: data.pages });
  };

  const getCustomerTopList = async () => {
    const { data } = await api.getCustomers(
      { 's[enterprise_members]': true, 's[s]': 'orders_count desc', items: 4 },
      setCustomerTopLoading,
    );

    if (data?.customers) {
      setCustomerTopResults(data.customers);
    }
  };

  const handleCustomerSelect = (customers: CustomerSearchDropdownMenuOption[] | null) => {
    const selectedCustomer = customerResults.find(
      (customerResult) => customerResult.id === customers?.[0].id,
    );

    if (!selectedCustomer) return;
    setCustomer(selectedCustomer);
    formikProps.setFieldValue('customerId', selectedCustomer.id.toString());
  };

  useEffect(() => {
    getCustomerTopList();
  }, []);

  return (
    <div data-testid="search-customer-section">
      <div data-testid="customer-search-dropdown">
        <CustomerSearchDropdown
          options={customerOptions}
          data-testid="customer-search-input"
          onSearch={(query) => {
            setQuery(query);
            debounceCustomerSearch(() => {
              getCustomerList(query);
            });
          }}
          onLoadMore={() => getCustomerList(query, pagination.page + 1)}
          isLoadingMore={customerSearchLoading}
          pagination={pagination}
          onChange={handleCustomerSelect}
          placeholder={intl.formatMessage({
            id: translationKeys.search_customer_placeholder,
          })}
        />

        <span className={styles.sectionLabel}>
          <FormattedMessage id={translationKeys.search_customer_top_four_label} />
        </span>
        <div className={styles.customers} data-testid="search-customer-top-4-section">
          {customerTopLoading ? (
            <>
              <CustomerSkeleton />
              <CustomerSkeleton />
              <CustomerSkeleton />
              <CustomerSkeleton />
            </>
          ) : (
            customerTopResults.map((customer) => {
              return (
                <button
                  key={customer.id}
                  className={styles.option}
                  data-testid="search-customer-top-4-option"
                  type='button'
                  onClick={() => {
                    setCustomer(customer);
                    formikProps.setFieldValue('customerId', customer.id.toString());
                  }}
                >
                  <Avatar
                    url={customer.enterprise?.logoUrl}
                    altText={customer.name}
                    size="medium"
                  />
                  <div className={styles.infoDetails}>
                    <span className={styles.title}>{customer.name}</span>
                    <span className={styles.subtitle}>{customer.enterprise?.name || '-'}</span>
                  </div>
                </button>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchCustomerSection;
