import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Drawer,
  PhoneCodeDropdown,
  SearchDropdown,
  SearchDropdownMenuOption,
  TextField,
} from '@skiwo/components';
import { Formik } from 'formik';
import useDebounce from '../../../../hooks/useDebounce';
import { useApi } from '../../../../providers/ApiProvider';
import { useToast } from '../../../../providers/ToastProvider/ToastProvider';
import translationKeys from '../../../../translations/translationKeys';
import { ManagerCustomer, ManagerOrder } from '../../../../types';
import styles from './EditBookerDrawer.module.scss';

interface Props {
  show?: boolean;
  onClose: () => void;
  order: ManagerOrder;
  onFormSubmit?: () => void;
}

const EditBookerDrawer = (props: Props) => {
  const { show, onClose, order, onFormSubmit } = props;

  const [changeOwner, setChangeOwner] = useState(false);
  const [customerDropdownResults, setCustomerDropdownResults] = useState<
    SearchDropdownMenuOption[]
  >([]);
  const [customersList, setCustomersList] = useState<ManagerCustomer[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<ManagerCustomer>();
  const [showCustomerInput, setShowCustomerInput] = useState(false);
  const [departmentOptions, setDepartmentOptions] = useState<
    { id: number; name: string; label?: string }[]
  >([]);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState<number>();
  const api = useApi();
  const { showToast, showErrorToast } = useToast();

  const intl = useIntl();

  const debounceCustomerSearch = useDebounce(300);

  const getDepartments = async (enterpriseId: string) => {
    const { data, error } = await api.getDepartments(enterpriseId);

    if (data) {
      setDepartmentOptions(
        data.departments.map((department) => ({
          id: department.id,
          name: department.name,
          label: department.name,
        })),
      );
    }

    if (error) {
      showErrorToast(error);
    }
  };

  const getInitialFormValues = () => {
    if (selectedCustomer) {
      return {
        name: selectedCustomer.name,
        email: selectedCustomer.email,
        phoneCode: selectedCustomer.phoneCode,
        phoneNumber: selectedCustomer.phoneNumber,
        departments: selectedCustomer.departments,
        enterprise: selectedCustomer.enterprise,
      };
    }
    return {
      name: order.owner?.name || '',
      email: order.owner?.email || '',
      phone: order.owner?.phone || '',
      phoneNumber: order.owner?.phoneNumber || '',
      departments: order.department || [],
      enterprise: order.enterprise || {},
    };
  };

  const handleSubmit = async () => {
    if (selectedCustomer?.demanderId) {
      const { error: orderOwnersError } = await api.updateOrderOwner(
        order.id.toString(),
        selectedCustomer?.demanderId || '',
      );

      if (orderOwnersError) {
        const errorText =
          orderOwnersError.text && orderOwnersError.text.length > 0
            ? orderOwnersError.text[0]
            : intl.formatMessage({ id: translationKeys.edit_booker_drawer_unexpected_error_text });
        showToast({ message: errorText, variant: 'error' });
        return;
      }
    }

    const body = {
      departmentId: selectedDepartmentId,
      requirementIdempotencyKey: order.requirementIdempotencyKey,
    } as Partial<ManagerOrder>;

    const { error: updateOrderError } = await api.updateOrder(order.id.toString(), body);

    if (updateOrderError) {
      const errorText =
        updateOrderError.text && updateOrderError.text.length > 0
          ? updateOrderError.text[0]
          : intl.formatMessage({ id: translationKeys.edit_booker_drawer_unexpected_error_text });
      showToast({ message: errorText, variant: 'error' });
      return;
    }

    if (onFormSubmit) {
      onFormSubmit();
    }
    handleOnClose();
  };

  const handleOnClose = () => {
    setSelectedCustomer(undefined);
    setChangeOwner(false);
    setShowCustomerInput(false);
    setSelectedDepartmentId(undefined);
    onClose();
  };

  const handleCustomerSelect = (customerOption: SearchDropdownMenuOption | null) => {
    setShowCustomerInput(true);

    if (customerOption) {
      const fullCustomerDetails = customersList.find(
        (customer) => customer.id === customerOption.id,
      );

      if (fullCustomerDetails) {
        setSelectedCustomer(fullCustomerDetails);
      } else {
        setSelectedCustomer(undefined);
      }
    }
  };

  const getCustomerList = async (query: string) => {
    const { data, error } = await api.getCustomers({
      's[name_cont]': query,
      's[enterprise_membership_id_not_null]': 1,
    });
    if (data?.customers) {
      const customerOptions =
        data.customers.map((customer) => {
          return { id: customer.id, label: customer.name, key: customer.uid };
        }) || [];

      setCustomersList(data.customers);
      setCustomerDropdownResults(customerOptions);
    }

    if (error) {
      showErrorToast(error);
    }
  };

  const convertDepartmentToDropdownOption = () => {
    if (
      selectedCustomer &&
      (!selectedCustomer.departments || selectedCustomer.departments.length === 0)
    ) {
      return [{ id: -1, label: '-', key: 'no-departments' }];
    }

    if (departmentOptions && departmentOptions.length > 0) {
      return departmentOptions.map((department) => ({
        id: department.id,
        label: department.name,
        key: department.id.toString(),
      }));
    }

    if (order.department && order.department.id !== undefined && order.department.name) {
      return [
        {
          id: order.department.id,
          label: order.department.name,
          key: order.department.id.toString(),
        },
      ];
    }

    return [];
  };

  const getSelectedDepartmentOption = () => {
    if (
      selectedCustomer &&
      (!selectedCustomer.departments || selectedCustomer.departments.length === 0)
    ) {
      return [{ id: -1, label: '-', key: 'no-departments' }];
    }

    const selectedDepartment = departmentOptions.find((dept) => dept.id === selectedDepartmentId);

    if (selectedDepartment) {
      return [
        {
          id: selectedDepartment.id,
          label: selectedDepartment.name,
          key: selectedDepartment.id.toString(),
        },
      ];
    }

    if (order.department && order.department.id !== undefined && order.department.name) {
      return [
        {
          id: order.department.id,
          label: order.department.name,
          key: order.department.id.toString(),
        },
      ];
    }

    return [];
  };

  useEffect(() => {
    if (
      selectedCustomer &&
      selectedCustomer.departments &&
      selectedCustomer.departments.length > 0
    ) {
      setSelectedDepartmentId(selectedCustomer.departments[0].id);
    } else {
      if (show && order.enterpriseId) getDepartments(order.enterpriseId.toString());
      setSelectedDepartmentId(undefined);
    }
  }, [selectedCustomer]);

  useEffect(() => {
    if (show && order.enterpriseId) {
      getDepartments(order.enterpriseId.toString());
    }
  }, [show]);

  return (
    <Drawer
      show={show}
      onClose={handleOnClose}
      title={
        changeOwner
          ? intl.formatMessage({ id: translationKeys.edit_booker_drawer_change_owner_title })
          : intl.formatMessage({ id: translationKeys.edit_booker_drawer_title })
      }
      data-testid="booker-drawer"
    >
      <Formik onSubmit={handleSubmit} initialValues={getInitialFormValues()} enableReinitialize>
        {({ handleSubmit }) => (
          <Form className={styles.editBooker} onSubmit={handleSubmit}>
            {(changeOwner || showCustomerInput) && (
              <div data-testid="customer-search-input">
                <SearchDropdown
                  options={customerDropdownResults}
                  placeholder={intl.formatMessage({
                    id: translationKeys.edit_booker_drawer_search_customer_placeholder,
                  })}
                  size="large"
                  search
                  onSearch={(query: string) => {
                    debounceCustomerSearch(() => {
                      getCustomerList(query);
                    });
                  }}
                  onChange={(customer: any) => {
                    handleCustomerSelect(customer[0]);
                  }}
                />
              </div>
            )}

            {!changeOwner && selectedCustomer && <hr className={styles.horizontalLine} />}

            {changeOwner && (
              <div className={styles.changeOwnerActions}>
                <Button
                  size="large"
                  variant="gray"
                  onClick={() => {
                    setChangeOwner(false);
                  }}
                >
                  <FormattedMessage id={translationKeys.edit_booker_drawer_cancel_button} />
                </Button>
                <Button
                  disabled={!selectedCustomer}
                  size="large"
                  type="submit"
                  onClick={() => {
                    setChangeOwner(false);
                  }}
                  data-testid="confirm-changed-owner-button"
                >
                  <FormattedMessage
                    id={translationKeys.edit_booker_drawer_change_owner_button_text}
                  />
                </Button>
              </div>
            )}

            {!changeOwner && (
              <>
                <div>
                  <TextField
                    label={intl.formatMessage({
                      id: translationKeys.edit_booker_drawer_name_label,
                    })}
                    placeholder={intl.formatMessage({
                      id: translationKeys.edit_booker_drawer_title,
                    })}
                    disabled
                    size="large"
                    value={selectedCustomer ? selectedCustomer.name : order.owner?.name || ''}
                  />

                  {!showCustomerInput && (
                    <Button
                      variant="gray"
                      size="large"
                      icon={<FontAwesomeIcon icon={faUser} />}
                      onClick={() => {
                        setChangeOwner(true);
                      }}
                      className={styles.changeOwnerButton}
                      data-testid="change-owner-button"
                    >
                      <FormattedMessage
                        id={translationKeys.edit_booker_drawer_change_owner_button}
                      />
                    </Button>
                  )}
                </div>

                <TextField
                  label={intl.formatMessage({
                    id: translationKeys.edit_booker_drawer_enterprise_label,
                  })}
                  placeholder={intl.formatMessage({
                    id: translationKeys.edit_booker_drawer_enterprise_label,
                  })}
                  disabled
                  size="large"
                  value={
                    selectedCustomer?.enterprise?.name
                      ? selectedCustomer?.enterprise?.name
                      : order.enterprise?.name || ''
                  }
                />

                <SearchDropdown
                  options={convertDepartmentToDropdownOption()}
                  label={intl.formatMessage({
                    id: translationKeys.edit_booker_drawer_department_label,
                  })}
                  placeholder={intl.formatMessage({
                    id: translationKeys.edit_booker_drawer_department_label,
                  })}
                  size="large"
                  selected={getSelectedDepartmentOption()}
                  onChange={(department) => {
                    if (department && department.length > 0 && department[0].key) {
                      setSelectedDepartmentId(department[0].id);
                    }
                  }}
                />

                <TextField
                  label={intl.formatMessage({ id: translationKeys.edit_booker_drawer_email_label })}
                  placeholder={intl.formatMessage({
                    id: translationKeys.edit_booker_drawer_email_label,
                  })}
                  disabled
                  size="large"
                  value={selectedCustomer ? selectedCustomer.email : order.owner?.email || ''}
                />

                <div className={styles.phoneInputs}>
                  <PhoneCodeDropdown
                    placeholder={selectedCustomer?.phoneCode ? selectedCustomer?.phoneCode : '+47'}
                    label={intl.formatMessage({
                      id: translationKeys.edit_booker_drawer_phone_code_label,
                    })}
                    disabled
                  />
                  <TextField
                    label={intl.formatMessage({
                      id: translationKeys.edit_booker_drawer_phone_number_label,
                    })}
                    placeholder={intl.formatMessage({
                      id: translationKeys.edit_booker_drawer_phone_number_label,
                    })}
                    disabled
                    size="large"
                    value={
                      selectedCustomer?.phoneNumber
                        ? selectedCustomer.phoneNumber
                        : order.owner?.phoneNumber || ''
                    }
                  />
                </div>

                <div className={styles.actions}>
                  <Button
                    variant="gray"
                    onClick={() => {
                      setChangeOwner(false);
                      handleOnClose();
                    }}
                    size="large"
                  >
                    <FormattedMessage id={translationKeys.edit_booker_drawer_cancel_button} />
                  </Button>
                  <Button type="submit" size="large" data-testid="change-owner-submit-button">
                    {selectedCustomer ? (
                      <FormattedMessage
                        id={translationKeys.edit_booker_drawer_change_owner_button_text}
                      />
                    ) : (
                      <FormattedMessage id={translationKeys.edit_booker_drawer_save_button} />
                    )}
                  </Button>
                </div>
              </>
            )}
          </Form>
        )}
      </Formik>
    </Drawer>
  );
};

export default EditBookerDrawer;
