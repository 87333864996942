import React, { useState } from 'react';
import { Form as B_Form } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import { faBuilding, faFile, faFilePen } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  DatePicker,
  Drawer,
  RadioOptions,
  SearchDropdown,
  Tag,
  TextField,
} from '@skiwo/components';
import { differenceInHours, format } from 'date-fns';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { useApi } from '../../../../providers/ApiProvider';
import { useLanguages } from '../../../../providers/LanguagesProvider';
import { useToast } from '../../../../providers/ToastProvider/ToastProvider';
import translationKeys from '../../../../translations/translationKeys';
import { ManagerOrder, ManagerOrderSubtaskType } from '../../../../types';
import styles from './CreateSubtaskDrawer.module.scss';

interface FormValues {
  taskId: string;
  type: ManagerOrderSubtaskType;
  internalDeadline?: string;
  externalDeadline?: string;
  platformQuoteAmount?: number;
  sellerQuoteAmount?: number;
  deliveredShortNotice?: boolean;
}

interface Props {
  show?: boolean;
  onClose: () => void;
  order: ManagerOrder;
}

const CreateSubtaskDrawer = (props: Props) => {
  const { show, order, onClose } = props;
  const api = useApi();
  const intl = useIntl();
  const { showErrorToast } = useToast();
  const { getLanguageById } = useLanguages();
  const [showExternalDeadlineDatePicker, setShowExternalDeadlineDatePicker] = useState(false);
  const [showInternalDeadlineDatePicker, setShowInternalDeadlineDatePicker] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);

  const schema = yup.object().shape({
    taskId: yup.string().required(
      intl.formatMessage(
        { id: translationKeys.form_error_required },
        {
          fieldName: intl.formatMessage({
            id: translationKeys.translation_subtask_create_drawer_task_label,
          }),
        },
      ),
    ),
    type: yup.string().required(
      intl.formatMessage(
        { id: translationKeys.form_error_required },
        {
          fieldName: intl.formatMessage({
            id: translationKeys.translation_subtask_create_drawer_type_label,
          }),
        },
      ),
    ),
    internalDeadline: yup.string().nullable(),
    externalDeadline: yup.string().nullable(),
    platformQuoteAmount: yup.string(),
    sellerQuoteAmount: yup.string(),
    deliveredShortNotice: yup.boolean(),
  });

  const showShortNoticeTag = (externalDeadline?: string) => {
    if (!externalDeadline) return false;

    const currentDate = new Date();
    const shortNoticeTimeHours = order.enterprise?.isPolice ? 24 : 48;

    if (differenceInHours(new Date(externalDeadline), currentDate) < shortNoticeTimeHours)
      return true;
    return false;
  };

  const handleSubmit = async (values: FormValues) => {
    const { data, error } = await api.createOrderSubtask(
      {
        taskId: parseFloat(values.taskId),
        subtaskType: values.type,
        internalDeadline: values.internalDeadline,
        externalDeadline: values.externalDeadline,
        platformQuoteAmount: values.platformQuoteAmount,
        sellerQuoteAmount: values.sellerQuoteAmount,
        deliveredShortNotice: values.deliveredShortNotice,
      },
      setUpdateLoading,
    );

    if (data) {
      onClose();
    }

    if (error) {
      showErrorToast(error);
    }
  };

  return (
    <Drawer
      show={show}
      onClose={onClose}
      title={intl.formatMessage({ id: translationKeys.translation_subtask_create_drawer_title })}
      data-testid="new-subtask-drawer"
    >
      <Formik<FormValues>
        validationSchema={schema}
        initialValues={{
          taskId: '',
          type: ManagerOrderSubtaskType.Translation,
          internalDeadline: '',
          externalDeadline: order.externalDeadline,
          platformQuoteAmount: undefined,
          sellerQuoteAmount: undefined,
          deliveredShortNotice: false,
        }}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, handleChange, handleBlur, setFieldValue, values, touched, errors }) => (
          <Form onSubmit={handleSubmit} className={styles.editSubtask}>
            <SearchDropdown
              label={intl.formatMessage({
                id: translationKeys.translation_subtask_create_drawer_task_label,
              })}
              placeholder={intl.formatMessage({
                id: translationKeys.translation_subtask_create_drawer_task_placeholder,
              })}
              options={order.tasks.map((task) => ({
                id: task.id,
                label: `${getLanguageById(task.targetLanguageId)} #${task.publicId}`,
                key: task.id.toString(),
              }))}
              size="large"
              onChange={(option) => {
                if (option && option.length > 0 && option[0].key) {
                  setFieldValue('taskId', option[0].key.toString());
                } else {
                  setFieldValue('taskId', '');
                }
              }}
              errorText={touched.taskId ? errors.taskId : undefined}
            />
            <RadioOptions
              label={intl.formatMessage({
                id: translationKeys.translation_subtask_create_drawer_type_label,
              })}
              selected={values.type}
              options={[
                {
                  id: ManagerOrderSubtaskType.Translation,
                  title: intl.formatMessage({
                    id: translationKeys.translation_subtask_create_drawer_type_translation,
                  }),
                  icon: <FontAwesomeIcon icon={faFile} />,
                },
                {
                  id: ManagerOrderSubtaskType.Correction,
                  title: intl.formatMessage({
                    id: translationKeys.translation_subtask_create_drawer_type_correction,
                  }),
                  icon: <FontAwesomeIcon icon={faFilePen} />,
                },
                {
                  id: ManagerOrderSubtaskType.InPerson,
                  title: intl.formatMessage({
                    id: translationKeys.translation_subtask_create_drawer_type_in_person,
                  }),
                  icon: <FontAwesomeIcon icon={faBuilding} />,
                },
              ]}
              onSelect={(option) => setFieldValue('type', option)}
            />

            <div className={styles.inputRow}>
              <div className={styles.inputRowElement}>
                <TextField
                  label={intl.formatMessage({
                    id: translationKeys.translation_subtask_create_drawer_internal_deadline_label,
                  })}
                  placeholder={intl.formatMessage({
                    id: translationKeys.translation_subtask_create_drawer_internal_deadline_label,
                  })}
                  icon={<FontAwesomeIcon icon={faCalendar} />}
                  value={
                    values.internalDeadline
                      ? format(new Date(values.internalDeadline), 'dd.MM.yy')
                      : undefined
                  }
                  size="large"
                  onFocus={() => setShowInternalDeadlineDatePicker(true)}
                  onChange={() => setFieldValue('internalDeadline', null)}
                  type="search"
                  errorText={touched.internalDeadline ? errors.internalDeadline : undefined}
                />
                {showInternalDeadlineDatePicker && (
                  <DatePicker
                    monthCount={1}
                    onClose={() => setShowInternalDeadlineDatePicker(false)}
                    singleDate
                    selected={
                      values.internalDeadline ? new Date(values.internalDeadline) : undefined
                    }
                    onChange={(date: Date) => {
                      setFieldValue('internalDeadline', date);
                      setShowInternalDeadlineDatePicker(false);
                    }}
                  />
                )}
              </div>

              <div className={styles.inputRowElement}>
                <TextField
                  label={intl.formatMessage({
                    id: translationKeys.translation_subtask_create_drawer_external_deadline_label,
                  })}
                  placeholder={intl.formatMessage({
                    id: translationKeys.translation_subtask_create_drawer_external_deadline_label,
                  })}
                  icon={<FontAwesomeIcon icon={faCalendar} />}
                  value={
                    values.externalDeadline
                      ? format(new Date(values.externalDeadline), 'dd.MM.yy')
                      : undefined
                  }
                  size="large"
                  onFocus={() => setShowExternalDeadlineDatePicker(true)}
                  onChange={() => setFieldValue('externalDeadline', null)}
                  type="search"
                  decorationView={
                    showShortNoticeTag(values.externalDeadline) && (
                      <Tag variant="square" color="neutral">
                        {intl.formatMessage({
                          id: translationKeys.translation_subtask_edit_drawer_short_notice_tag,
                        })}
                      </Tag>
                    )
                  }
                  errorText={touched.externalDeadline ? errors.externalDeadline : undefined}
                  disabled
                />
                {showExternalDeadlineDatePicker && (
                  <DatePicker
                    monthCount={1}
                    onClose={() => setShowExternalDeadlineDatePicker(false)}
                    singleDate
                    selected={
                      values.externalDeadline ? new Date(values.externalDeadline) : undefined
                    }
                    onChange={(date: Date) => {
                      setFieldValue('externalDeadline', date);
                      setShowExternalDeadlineDatePicker(false);
                    }}
                  />
                )}
              </div>
            </div>

            <div className={styles.inputRow}>
              <div className={styles.inputRowElement}>
                <TextField
                  label={intl.formatMessage({
                    id: translationKeys.translation_subtask_create_drawer_salita_quote_label,
                  })}
                  placeholder={intl.formatMessage({
                    id: translationKeys.translation_subtask_create_drawer_salita_quote_label,
                  })}
                  value={values.platformQuoteAmount}
                  size="large"
                  type="number"
                  decorationView="NOK"
                  name="platformQuoteAmount"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errorText={touched.platformQuoteAmount ? errors.platformQuoteAmount : undefined}
                />
              </div>

              <div className={styles.inputRowElement}>
                <TextField
                  label={intl.formatMessage({
                    id: translationKeys.translation_subtask_create_drawer_seller_quote_label,
                  })}
                  placeholder={intl.formatMessage({
                    id: translationKeys.translation_subtask_create_drawer_seller_quote_label,
                  })}
                  value={values.sellerQuoteAmount}
                  size="large"
                  type="number"
                  decorationView="NOK"
                  name="sellerQuoteAmount"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errorText={touched.sellerQuoteAmount ? errors.sellerQuoteAmount : undefined}
                />
              </div>
            </div>

            <B_Form.Check
              type="checkbox"
              label={intl.formatMessage({
                id: translationKeys.translation_subtask_create_drawer_delivered_short_notice_label,
              })}
              name="deliveredShortNotice"
              onChange={handleChange}
              checked={values.deliveredShortNotice}
            />

            <div className={styles.actions}>
              <Button size="large" variant="gray" onClick={onClose} data-testid="cancel-button">
                {intl.formatMessage({
                  id: translationKeys.translation_subtask_create_drawer_cancel_button,
                })}
              </Button>
              <Button type="submit" size="large" isLoading={updateLoading}>
                {intl.formatMessage({
                  id: translationKeys.translation_subtask_create_drawer_create_button,
                })}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Drawer>
  );
};

export default CreateSubtaskDrawer;
