import { IntlShape } from 'react-intl';
import {
  faCalendar,
  faChartLineUp,
  faDisplayChartUp,
  faMessages,
} from '@fortawesome/pro-light-svg-icons';
import translationKeys from '../translations/translationKeys';

const getAssignments = (intl: IntlShape) => {
  const interpretationSection = {
    name: intl.formatMessage({ id: translationKeys.menu_assignments_interpretation_section }),
    items: [
      {
        name: intl.formatMessage({
          id: translationKeys.menu_assignments_interpretation_assignments,
        }),
        description: intl.formatMessage({
          id: translationKeys.menu_assignments_interpretation_assignments_description,
        }),
        link: '/jobs',
        icon: faDisplayChartUp,
      },
      {
        name: intl.formatMessage({
          id: translationKeys.menu_assignments_interpretation_feedback,
        }),
        description: intl.formatMessage({
          id: translationKeys.menu_assignments_interpretation_feedback_description,
        }),
        link: '#',
        icon: faMessages,
        disabled: true,
      },
      {
        name: intl.formatMessage({
          id: translationKeys.menu_assignments_interpretation_calendar,
        }),
        description: intl.formatMessage({
          id: translationKeys.menu_assignments_interpretation_calendar_description,
        }),
        link: '#',
        icon: faCalendar,
        disabled: true,
      },
    ],
  };

  const translationSection = {
    name: intl.formatMessage({ id: translationKeys.menu_assignments_translation_section }),
    items: [
      {
        name: intl.formatMessage({
          id: translationKeys.menu_assignments_translation_orders,
        }),
        description: intl.formatMessage({
          id: translationKeys.menu_assignments_translation_orders_description,
        }),
        link: '/translation-subtasks',
        icon: faDisplayChartUp,
      },
      {
        name: intl.formatMessage({
          id: translationKeys.menu_assignments_translation_feedback,
        }),
        description: intl.formatMessage({
          id: translationKeys.menu_assignments_translation_feedback_description,
        }),
        link: '#',
        icon: faMessages,
        disabled: true,
      },
      {
        name: intl.formatMessage({
          id: translationKeys.menu_assignments_translation_calendar,
        }),
        description: intl.formatMessage({
          id: translationKeys.menu_assignments_translation_calendar_description,
        }),
        link: '#',
        icon: faCalendar,
        disabled: true,
      },
    ],
  };

  const statisticsSection = {
    name: intl.formatMessage({ id: translationKeys.menu_assignments_statistics_section }),
    items: [
      {
        name: intl.formatMessage({
          id: translationKeys.menu_assignments_assignment_statistics,
        }),
        link: '#',
        icon: faChartLineUp,
        disabled: true,
      },
    ],
  };

  return {
    name: intl.formatMessage({ id: translationKeys.menu_assignments_item }),
    sections: [interpretationSection, translationSection, statisticsSection],
  };
};

export default getAssignments;
