import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { EmptyState, SearchDropdown, Table, TextField } from '@skiwo/components';
import { ApiError } from '../../Api';
import errorStateIllustration from '../../assets/empty-table-error.svg';
import useDebounce from '../../hooks/useDebounce';
import translationKeys from '../../translations/translationKeys';
import {
  ManagerSupplier,
  ManagerSupplierSex,
  ManagerSupplierStandBy,
  ManagerSupplierType,
} from '../../types';
import SupplierItem from '../SupplierItem/SupplierItem';
import SupplierItemSkeleton from '../SupplierItemSkeleton/SupplierItemSkeleton';
import SuppliersFilterField from '../SuppliersFilterField';
import getGenderName from '../utils/getGenderName';
import getStandByName from '../utils/getStandByName';
import getTypeName from '../utils/getTypeName';
import styles from './SuppliersTable.module.scss';

interface Props {
  suppliers: ManagerSupplier[];
  isLoading: boolean;
  error: ApiError | null;
  onCompleteAction: (hardReload: boolean) => void;
  onFilterChange: (field: SuppliersFilterField, value: string) => void;
}

const SuppliersTable = (props: Props) => {
  const { suppliers, isLoading, error, onFilterChange, onCompleteAction } = props;
  const intl = useIntl();
  const debounceFilterChange = useDebounce(300);

  const handleFilterChange = (field: SuppliersFilterField, value: string) => {
    debounceFilterChange(() => {
      onFilterChange(field, value);
    });
  };

  const handleCompleteAction = (hardReload = false) => {
    onCompleteAction(hardReload);
  };

  const typeOptions =
    Object.values(ManagerSupplierType).map((type, index) => {
      return { id: index, label: getTypeName(type, intl), key: type };
    }) || [];

  const sexOptions =
    Object.values(ManagerSupplierSex)
      .filter((gender) => gender !== ManagerSupplierSex.Transgender)
      .map((sex, index) => {
        return { id: index, label: getGenderName(sex, intl), key: sex };
      }) || [];

  const standByOptions =
    Object.values(ManagerSupplierStandBy).map((standby, index) => {
      return { id: index, label: getStandByName(standby, intl), key: standby };
    }) || [];

  if (error) {
    return (
      <div className={styles.emptyStateContainer}>
        <EmptyState
          image={errorStateIllustration}
          text={intl.formatMessage({
            id: translationKeys.suppliers_page_error_state_title,
          })}
          description={intl.formatMessage({
            id: translationKeys.suppliers_page_error_state_subtitle,
          })}
          refreshable
        />
      </div>
    );
  }

  return (
    <>
      <Table striped data-testid="suppliers-table">
        <thead className={styles.tableHeader}>
          <tr className={styles.filters}>
            <th className={styles.idFilter}>
              <TextField
                placeholder={intl.formatMessage({
                  id: translationKeys.suppliers_page_id_filter_placeholder,
                })}
                data-testid="id-filter"
                onChange={(e) => handleFilterChange(SuppliersFilterField.Id, e.currentTarget.value)}
              />
            </th>
            <th className={styles.typeFilter} data-testid="type-filter">
              <SearchDropdown
                options={typeOptions}
                placeholder={intl.formatMessage({
                  id: translationKeys.suppliers_page_type_filter_placeholder,
                })}
                onChange={(type) => {
                  if (type && type.length > 0 && type[0].key) {
                    handleFilterChange(SuppliersFilterField.Type, type[0].key.toString());
                  } else {
                    handleFilterChange(SuppliersFilterField.Type, '');
                  }
                }}
              />
            </th>
            <th className={styles.nameFilter}>
              <TextField
                placeholder={intl.formatMessage({
                  id: translationKeys.suppliers_page_name_filter_placeholder,
                })}
                data-testid="name-filter"
                onChange={(e) =>
                  handleFilterChange(SuppliersFilterField.NameEmailPhone, e.currentTarget.value)
                }
              />
            </th>
            <th className={styles.hintsFilter}></th>
            <th data-testid="gender-filter" className={styles.genderFilter}>
              <SearchDropdown
                options={sexOptions}
                placeholder={intl.formatMessage({
                  id: translationKeys.suppliers_page_gender_filter_placeholder,
                })}
                onChange={(gender) => {
                  if (gender && gender.length > 0 && gender[0].key) {
                    handleFilterChange(SuppliersFilterField.Gender, gender[0].key.toString());
                  } else {
                    handleFilterChange(SuppliersFilterField.Gender, '');
                  }
                }}
              />
            </th>

            <th data-testid="standby-filter" className={styles.standByFilter}>
              <SearchDropdown
                options={standByOptions}
                placeholder="Standby"
                onChange={(standby) => {
                  if (standby && standby.length > 0 && standby[0].key) {
                    handleFilterChange(SuppliersFilterField.Standby, standby[0].key.toString());
                  } else {
                    handleFilterChange(SuppliersFilterField.Standby, '');
                  }
                }}
              />
            </th>

            <th className={styles.statsHeader}></th>
            <th></th>
          </tr>

          <tr className={styles.headers}>
            <th>
              <FormattedMessage id={translationKeys.suppliers_page_id_header} />
            </th>
            <th>
              <FormattedMessage id={translationKeys.suppliers_page_type_header} />
            </th>
            <th>
              <FormattedMessage id={translationKeys.suppliers_page_name_header} />
            </th>
            <th>
              <FormattedMessage id={translationKeys.suppliers_page_status_header} />
            </th>
            <th>
              <FormattedMessage id={translationKeys.suppliers_page_gender_header} />
            </th>
            <th>Standby</th>
            <th>
              <FormattedMessage id={translationKeys.suppliers_page_statistic_header} />
            </th>
            <th>
              <FormattedMessage id={translationKeys.suppliers_page_actions_header} />
            </th>
          </tr>
        </thead>

        <tbody>
          {suppliers.map((supplier) => (
            <SupplierItem
              supplier={supplier}
              onCompleteAction={handleCompleteAction}
              key={supplier.id}
            />
          ))}

          {isLoading && [...Array(3)].map((_, index) => <SupplierItemSkeleton key={index} />)}
        </tbody>
      </Table>

      {!isLoading && suppliers.length === 0 && (
        <div className={styles.emptyStateContainer}>
          <EmptyState
            image={errorStateIllustration}
            text={intl.formatMessage({
              id: translationKeys.suppliers_page_empty_state_title,
            })}
            description={intl.formatMessage({
              id: translationKeys.suppliers_page_empty_state_subtitle,
            })}
          />
        </div>
      )}
    </>
  );
};

export default SuppliersTable;
