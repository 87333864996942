export enum TranslationSkillAccountStatus {
  Active = 'active',
  Paused = 'paused',
  Blocked = 'blocked',
  Retired = 'retired',
  Banned = 'banned',
  Deleted = 'deleted',
  Deceased = 'deceased',
  Inactive = 'inactive',
}

export enum TranslationSkillsStatus {
  Pending = 'pending',
  Learning = 'learning',
  Approved = 'approved',
  Declined = 'declined',
}

interface TranslationSkill {
  id: number;
  langFromId: number;
  langToId: number;
  qualificationId: number;
  nativeFrom: boolean;
  nativeTo: boolean;
  activeFrom?: number;
  status: TranslationSkillsStatus;
  createdAt: string;
  godmodeSupplierUrl: string;
  hasSubtaskAssignment: boolean;
  person: {
    id: number;
    uid: string;
    name: string;
    email: string;
    phone?: string;
    alternativePhone?: string;
    avatarUrl?: string;
  };
  accountStatus: {
    name: TranslationSkillAccountStatus;
    expiresAt?: string;
    comment?: string;
  };
  contract: {
    contractMaxAutoAssignedSubtasksNumber?: number;
    contractMinDeadlineDaysNumber?: number;
    hasContract: boolean;
  };
  statistics: {
    finished: number;
  };
  note?: {
    authorName?: string;
    note: string;
    source: 'manual' | 'import' | 'platform';
  };
  anniversary: {
    birth?: string;
    birthAnniversary: boolean;
    accountCreated?: string;
    accountCreatedAnniversary: boolean;
  };
  notesCount: number;
}

export interface TranslationSkillsResponse {
  skills: TranslationSkill[];
  statistics: {
    all: number;
    anniversary: number;
    approved: number;
    declined: number;
    learning: number;
    pending: number;
  };
  count: number;
  page: number;
  pages: number;
}

export default TranslationSkill;
