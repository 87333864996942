import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Navigate, Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import { MegaMenu } from '@skiwo/components';
import connectLogo from './assets/connect-logo.svg';
import salitaLogo from './assets/salita-logo.svg';
import CreateInterpretationOrder from './CreateInterpretationOrder/CreateInterpretationOrder';
import CreateTranslationOrder from './CreateTranslationOrder/CreateTranslationOrder';
import Customers from './Customers/Customers';
import Enterprises from './Enterprises/Enterprises';
import useClarity from './hooks/useClarity';
import useGTM from './hooks/useGoogleTagManager';
import useHotjar from './hooks/useHotjar';
import InterpretationSkills from './InterpretationSkills/InterpretationSkills';
import Job from './Job/Job';
import Jobs from './Jobs/Jobs';
import AssignmentSearch from './MenuContent/AssignmentSearch/AssignmentSearch';
import CreateDropdown from './MenuContent/CreateDropdown/CreateDropdown';
import LanguagesDropdown from './MenuContent/LanguagesDropdown/LanguagesDropdown';
import SettingsDropdown from './MenuContent/SettingsDropdown/SettingsDropdown';
import { useAuth } from './providers/AuthProvider';
import { useFeatureToggle } from './providers/FeatureToggleProvider';
import JobCategoriesProvider from './providers/JobCategoriesProvider';
import LanguagesProvider from './providers/LanguagesProvider';
import QualificationsProvider from './providers/QualificationsProvider';
import ToastProvider from './providers/ToastProvider/ToastProvider';
import Suppliers from './Suppliers/Suppliers';
import TranslationOrder from './TranslationOrder/TranslationOrder';
import TranslationSkills from './TranslationSkills/TranslationSkills';
import TranslationSubtasks from './TranslationSubtasks/TranslationSubtasks';
import { CheckEmail, ForgotPassword, LogIn, SetNewPassword } from './authentication';
import getMenuTabs from './MenuContent';
import { Feature } from './types';
import styles from './App.module.scss';

const LOGIN_PATH = '/login';
const JOBS_PATH = '/jobs';

function App() {
  const { isAuthenticated } = useAuth();
  const intl = useIntl();
  const navigate = useNavigate();
  const { isFeatureActive } = useFeatureToggle();
  useHotjar();
  useClarity();
  useGTM();

  const ProtectedRoute = () => {
    if (!isAuthenticated()) {
      return <Navigate to={LOGIN_PATH} replace />;
    }

    return (
      <div className={styles.content}>
        <Outlet />
      </div>
    );
  };

  const LoggedOutPath = () => {
    if (isAuthenticated()) {
      return <Navigate to={JOBS_PATH} replace />;
    }

    return <Outlet />;
  };

  const menuTabs = getMenuTabs(intl);

  const handleOnclick = (link: string) => {
    navigate(link);
  };

  const getMenuLogo = () => {
    if (process.env.REACT_APP_THEME === 'salita') {
      return salitaLogo;
    }

    return connectLogo;
  };

  useEffect(() => {
    const favicon = document.getElementById('favicon') as HTMLLinkElement;
    favicon.href = '/connect-favicon.ico';

    if (process.env.REACT_APP_THEME === 'salita') {
      favicon.href = '/salita-favicon.ico';
      document.title = 'Manage Salita';
    } else {
      favicon.href = '/connect-favicon.ico';
      document.title = 'Skiwo Connect';
    }
  }, []);

  return (
    <LanguagesProvider>
      <JobCategoriesProvider>
        <ToastProvider>
          <MegaMenu
            menuTabs={menuTabs}
            isAuthenticated={isAuthenticated()}
            logo={<img src={getMenuLogo()} />}
            onClick={handleOnclick}
            actions={<CreateDropdown />}
            rightMenu={
              <>
                <AssignmentSearch />
                <LanguagesDropdown />
                <SettingsDropdown />
              </>
            }
          />
          <Routes>
            <Route element={<LoggedOutPath />}>
              <Route path="/" element={<Navigate to={LOGIN_PATH} replace />} />
              <Route path={LOGIN_PATH} element={<LogIn />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/check-email" element={<CheckEmail />} />
              <Route path="/set-new-password" element={<SetNewPassword />} />
            </Route>

            {/* Protected routes */}
            <Route element={<ProtectedRoute />}>
              <Route path={JOBS_PATH} element={<Jobs />} />
              <Route path="/customers" element={<Customers />} />
              <Route
                path="/suppliers"
                element={
                  <QualificationsProvider>
                    <Suppliers />
                  </QualificationsProvider>
                }
              />
              <Route path="/enterprises" element={<Enterprises />} />
              <Route
                path="/translation-subtasks"
                element={
                  <QualificationsProvider>
                    <TranslationSubtasks />
                  </QualificationsProvider>
                }
              />
              <Route path="/translation-subtasks/:orderId" element={<TranslationOrder />} />
              {isFeatureActive(Feature.CreateInterpretationOrder) && (
                <Route
                  path="/create-interpretation-order"
                  element={
                    <QualificationsProvider>
                      <CreateInterpretationOrder />
                    </QualificationsProvider>
                  }
                />
              )}
              {isFeatureActive(Feature.CreateTranslationOrder) && (
                <Route path="/create-translation-order" element={<CreateTranslationOrder />} />
              )}

              {isFeatureActive(Feature.Interpretation) && (
                <Route
                  path="/jobs/:jobId"
                  element={
                    <QualificationsProvider>
                      <Job />
                    </QualificationsProvider>
                  }
                />
              )}

              <Route
                path="/interpretation-skills"
                element={
                  <QualificationsProvider>
                    <InterpretationSkills />
                  </QualificationsProvider>
                }
              />
              <Route
                path="/translation-skills"
                element={
                  <QualificationsProvider>
                    <TranslationSkills />
                  </QualificationsProvider>
                }
              />
            </Route>
          </Routes>
        </ToastProvider>
      </JobCategoriesProvider>
    </LanguagesProvider>
  );
}

export default App;
