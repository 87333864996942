import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  DatePicker,
  Drawer,
  PhoneCodeDropdown,
  SearchDropdown,
  SearchDropdownMenuOption,
  TextField,
} from '@skiwo/components';
import { format } from 'date-fns';
import { Formik } from 'formik';
import * as yup from 'yup';
import { emailRegex } from '../../../../helpers/regexPatterns';
import useDebounce from '../../../../hooks/useDebounce';
import getAccessLevelName from '../../../../InterpretationSkills/utils/getAccessLevelName';
import getSourceTypeName from '../../../../InterpretationSkills/utils/getSourceTypeName';
import { useApi } from '../../../../providers/ApiProvider';
import { useLanguages } from '../../../../providers/LanguagesProvider';
import { useToast } from '../../../../providers/ToastProvider/ToastProvider';
import translationKeys from '../../../../translations/translationKeys';
import { ManagerOrder, ManagerOrderAccessLevel, ManagerOrderSourceType } from '../../../../types';
import { translationOrderErrorKeys } from '../../../utils/translationOrderErrorKeys';
import styles from './EditOrderDrawer.module.scss';

interface Props {
  show?: boolean;
  onClose: () => void;
  order: ManagerOrder;
  onFormSubmit?: () => void;
}

interface FormValues {
  wordCount?: number;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  email?: string;
}

const EditOrderDrawer = (props: Props) => {
  const { show, onClose, order, onFormSubmit } = props;
  const [showExternalDeadlineDatePicker, setShowExternalDeadlineDatePicker] = useState(false);
  const [externalDeadline, setExternalDeadline] = useState(
    order.externalDeadline ? format(new Date(order.externalDeadline), 'dd.MM.yyyy') : '',
  );
  const [selectedAccessLevel, setSelectedAccessLevel] = useState<string>(order.accessLevel);
  const [selectedAccessLevelText, setSelectedAccessLevelText] = useState<string>();
  const [departmentError, setDepartmentError] = useState<string>();
  const [enterpriseError, setEnterpriseError] = useState<string>();
  const [ownerError, setOwnerError] = useState<string>();
  const [selectedSourceType, setSelectedSourceType] = useState<string>(order.sourceType);
  const [selectedSourceTypeText, setSelectedSourceTypeText] = useState<string>();
  const [isLoggedOut, setIsLoggedOut] = useState(order.isLoggedOut);
  const [selectedAlternativeLanguageId, setSelectedAlternativeLanguageId] = useState<
    number | null
  >();
  const [enterpriseResults, setEnterpriseResults] = useState<SearchDropdownMenuOption[]>([]);
  const [selectedEnterprise, setSelectedEnterprise] = useState<SearchDropdownMenuOption>();
  const [selectedPaymentEnterprise, setSelectedPaymentEnterprise] =
    useState<SearchDropdownMenuOption>();
  const [selectedPhoneCode, setSelectedPhoneCode] = useState<SearchDropdownMenuOption>();
  const [selectedLanguage, setSelectedLanguage] = useState<SearchDropdownMenuOption>();
  const { languages } = useLanguages();

  const intl = useIntl();
  const debounceEnterpriseSearch = useDebounce(300);
  const api = useApi();
  const { showErrorToast } = useToast();

  const schema = yup.object().shape({
    firstName: yup.string().required(
      intl.formatMessage(
        { id: translationKeys.form_error_required },
        {
          fieldName: intl.formatMessage({
            id: translationKeys.suppliers_page_create_supplier_first_name_label,
          }),
        },
      ),
    ),
    lastName: yup.string().required(
      intl.formatMessage(
        { id: translationKeys.form_error_required },
        {
          fieldName: intl.formatMessage({
            id: translationKeys.suppliers_page_create_supplier_last_name_label,
          }),
        },
      ),
    ),
    email: yup
      .string()
      .matches(
        emailRegex,
        intl.formatMessage(
          { id: translationKeys.form_error_not_valid },
          {
            fieldName: intl.formatMessage({
              id: translationKeys.suppliers_page_create_supplier_email_label,
            }),
          },
        ),
      )
      .required(
        intl.formatMessage(
          { id: translationKeys.form_error_required },
          {
            fieldName: intl.formatMessage({
              id: translationKeys.suppliers_page_create_supplier_email_label,
            }),
          },
        ),
      ),
  });

  const handleExternalDeadlineChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setExternalDeadline(e.target.value);
  };

  const handleExternalDeadlineDatePicker = (date: Date) => {
    if (date) {
      setExternalDeadline(format(date, 'dd.MM.yyyy'));
    }
    setShowExternalDeadlineDatePicker(false);
  };

  const handleAccessLevelChange = (levels: SearchDropdownMenuOption[] | null) => {
    if (levels && levels.length > 0 && typeof levels[0].key === 'string') {
      setSelectedAccessLevel(levels[0].key);
    } else {
      setSelectedAccessLevel(order.accessLevel);
    }
  };

  const handleAlternativeLanguageChange = (
    alternativeLanguage: SearchDropdownMenuOption[] | null,
  ) => {
    if (alternativeLanguage && alternativeLanguage.length > 0) {
      const languageId = alternativeLanguage[0].id === 1 ? null : alternativeLanguage[0].id;
      setSelectedAlternativeLanguageId(languageId);
    } else {
      setSelectedAlternativeLanguageId(null);
    }
  };

  const handleSubmit = async (values: FormValues) => {
    if (!selectedLanguage) return;

    if (
      selectedAccessLevel === ManagerOrderAccessLevel.Owner &&
      (!order.owner || Object.keys(order.owner).length === 0)
    ) {
      setOwnerError(intl.formatMessage({ id: translationKeys.edit_order_drawer_owner_error_text }));
      return;
    }
    if (
      selectedAccessLevel === ManagerOrderAccessLevel.Department &&
      (!order.department || Object.keys(order.department).length === 0)
    ) {
      setDepartmentError(
        intl.formatMessage({ id: translationKeys.edit_order_drawer_department_error_text }),
      );
      return;
    }

    if (
      selectedAccessLevel === ManagerOrderAccessLevel.Enterprise &&
      (!order.enterprise || Object.keys(order.enterprise).length === 0)
    ) {
      setEnterpriseError(
        intl.formatMessage({ id: translationKeys.edit_order_drawer_enterprise_error_text }),
      );
      return;
    }

    const payload = {
      countOfWords: values.wordCount,
      accessLevel: selectedAccessLevel,
      requirementIdempotencyKey: order.requirementIdempotencyKey,
      isLoggedOut: isLoggedOut,
      externalDeadline: externalDeadline,
      sourceType: selectedSourceType,
      alternativeTargetLanguageId: selectedAlternativeLanguageId,
      sourceLanguageId: selectedLanguage?.id,
      ...(isLoggedOut && {
        loggedOutBookerFirstName: values.firstName,
        loggedOutBookerLastName: values.lastName,
        loggedOutBookerPhoneCode: selectedPhoneCode?.key,
        loggedOutBookerPhoneNumber: values.phoneNumber,
        loggedOutBookerOrgNumber: selectedEnterprise?.key,
        loggedOutBookerOrgName: selectedEnterprise?.label,
        loggedOutBookerPaymentOrgNumber: selectedPaymentEnterprise?.key,
        loggedOutBookerPaymentOrgName: selectedPaymentEnterprise?.label,
        loggedOutBookerEmail: values.email,
      }),
    } as Partial<ManagerOrder>;

    const { data, error } = await api.updateOrder(order.id.toString(), payload);

    if (error) {
      showErrorToast(error, translationOrderErrorKeys);
    }

    if (data) {
      if (onFormSubmit) {
        onFormSubmit();
      }
      setSelectedSourceType('');
      setDepartmentError('');
      setEnterpriseError('');
      onClose();
    }
  };

  const getEnterpriseList = async (query: string, searchBreeg: boolean) => {
    const { data, error } = await api.searchEnterprises({
      query,
      search_brreg: searchBreeg,
    });

    if (data?.enterprises || data?.brregSearch) {
      const uniqueEnterprise = data?.enterprises.filter((obj, index, array) => {
        return array.findIndex((enterprise) => enterprise.orgNumber === obj.orgNumber) === index;
      });

      const allEnterprises = [...uniqueEnterprise, ...(data.brregSearch || [])];
      const enterpriseOptions = (allEnterprises || [])
        .filter((enterprise) => enterprise.orgNumber !== undefined)
        .map((enterprise) => {
          return {
            id: +enterprise.orgNumber,
            label: enterprise.name,
            key: enterprise.orgNumber,
            subtitle: `Org. nr. ${enterprise.orgNumber}`,
            group: intl.formatMessage({
              id:
                'id' in enterprise && enterprise.id !== undefined
                  ? translationKeys.create_customer_drawer_group_enterprise
                  : translationKeys.create_customer_drawer_group_brreg,
            }),
          };
        });

      setEnterpriseResults(enterpriseOptions);
    }

    if (error) {
      showErrorToast(error);
    }
  };

  const handleOnClose = () => {
    setShowExternalDeadlineDatePicker(false);
    setExternalDeadline('');
    setSelectedAccessLevel('');
    setSelectedSourceType('');
    setSelectedAlternativeLanguageId(null);
    setEnterpriseResults([]);
    setSelectedEnterprise(undefined);
    setSelectedPaymentEnterprise(undefined);
    setSelectedPhoneCode(undefined);
    setIsLoggedOut(false);
    setOwnerError('');
    setDepartmentError('');
    setEnterpriseError('');

    if (onClose) {
      onClose();
    }
  };

  const handleSourceTypeChange = (options: SearchDropdownMenuOption[] | null) => {
    if (options && options.length > 0) {
      if (typeof options[0].key === 'string') {
        if (options[0].key === 'logout') {
          setIsLoggedOut(true);
        } else {
          setSelectedSourceType(options[0].key);
          setIsLoggedOut(false);
        }
      }
    } else {
      setSelectedSourceType(order.accessLevel);
    }
  };

  const languageOptions =
    languages.map((language) => {
      return { id: language.id, label: language.name || '', key: language.id.toString() };
    }) || [];

  useEffect(() => {
    if (order.loggedOutBookerOrgName && order.loggedOutBookerOrgNumber)
      setSelectedEnterprise({
        id: parseInt(order.loggedOutBookerOrgNumber),
        label: order.loggedOutBookerOrgName,
        key: order.loggedOutBookerOrgNumber,
      });

    if (order.loggedOutBookerPaymentOrgName && order.loggedOutBookerPaymentOrgNumber)
      setSelectedPaymentEnterprise({
        id: parseInt(order.loggedOutBookerPaymentOrgNumber),
        label: order.loggedOutBookerPaymentOrgName,
        key: order.loggedOutBookerPaymentOrgNumber,
      });
  }, []);

  useEffect(() => {
    if (show) {
      setSelectedAccessLevel(order.accessLevel);
      setSelectedSourceType(order.sourceType);

      const currentLanguage = languageOptions.find(
        (language) => language.id === order.sourceLanguageId,
      );

      if (currentLanguage) {
        setSelectedLanguage(currentLanguage);
      }
    }
  }, [show, order.externalDeadline, order.accessLevel, order.sourceType]);

  const sourceTypeOptions =
    Object.values(ManagerOrderSourceType)
      .filter((sourceType) => sourceType !== ManagerOrderSourceType.CopiedFromXtrf)
      .map((sourceType, index) => {
        return { id: index, label: getSourceTypeName(sourceType, intl), key: sourceType };
      }) || [];

  const accessLevelOptions =
    Object.values(ManagerOrderAccessLevel).map((accessLevel, index) => {
      return { id: index, label: getAccessLevelName(accessLevel, intl), key: accessLevel };
    }) || [];

  const alternativeLanguageOptions = [
    {
      id: 1,
      label: intl.formatMessage({ id: translationKeys.edit_order_drawer_no_preference_option }),
      key: '1',
    },
    {
      id: 2,
      label: intl.formatMessage({ id: translationKeys.edit_order_drawer_english_option }),
      key: '2',
    },
    {
      id: 3,
      label: intl.formatMessage({ id: translationKeys.edit_order_drawer_norwegian_option }),
      key: '3',
    },
  ];

  const capitalizeFirstLetter = (str: string) => {
    return str.replace(/\b(\w)/g, (s) => s.toUpperCase());
  };

  const getSourceLanguageError = () => {
    if (!selectedLanguage) {
      return intl.formatMessage(
        { id: translationKeys.form_error_required },
        {
          fieldName: intl.formatMessage({
            id: translationKeys.edit_order_drawer_source_language_label,
          }),
        },
      );
    }
  };

  useEffect(() => {
    if (show) {
      setExternalDeadline(
        order.externalDeadline ? format(new Date(order.externalDeadline), 'dd.MM.yyyy') : '',
      );
    }
  }, [show, order.externalDeadline]);

  useEffect(() => {
    setSelectedSourceTypeText(getSourceTypeName(order.sourceType, intl));
    setSelectedAccessLevelText(capitalizeFirstLetter(order.accessLevel));
  }, [order.sourceType, order.accessLevel]);

  return (
    <Drawer
      show={show}
      onClose={handleOnClose}
      title={intl.formatMessage({ id: translationKeys.edit_order_drawer_title })}
      data-testid="edit-order-drawer"
    >
      <Formik
        validationSchema={
          isLoggedOut === true || selectedSourceType === ManagerOrderSourceType.Logout ? schema : ''
        }
        onSubmit={handleSubmit}
        initialValues={{
          wordCount: order.countOfWords,
          firstName: order.loggedOutBookerFirstName,
          lastName: order.loggedOutBookerLastName,
          phoneNumber: order.loggedOutBookerPhoneNumber,
          email: order.loggedOutBookerEmail,
        }}
      >
        {({ handleSubmit, values, handleChange, handleBlur, touched, errors }) => (
          <Form className={styles.editOrder} onSubmit={handleSubmit}>
            <SearchDropdown
              options={languageOptions}
              selected={selectedLanguage && [selectedLanguage]}
              placeholder={intl.formatMessage({
                id: translationKeys.edit_order_drawer_source_language_label,
              })}
              label={intl.formatMessage({
                id: translationKeys.edit_order_drawer_source_language_placeholder,
              })}
              hint={intl.formatMessage({
                id: translationKeys.edit_order_drawer_source_language_hint,
              })}
              errorText={getSourceLanguageError()}
              size="large"
              onChange={(language) => {
                if (language && language.length > 0 && language[0].key) {
                  setSelectedLanguage(language[0]);
                } else {
                  setSelectedLanguage(undefined);
                }
              }}
            />

            <div className={styles.inputRow}>
              <TextField
                label={intl.formatMessage({ id: translationKeys.edit_order_drawer_created_label })}
                icon={<FontAwesomeIcon icon={faCalendar} />}
                value={order.createdAt ? format(new Date(order.createdAt), 'dd.MM.yyyy') : ''}
                size="large"
                disabled={true}
              />

              <div>
                <TextField
                  label={intl.formatMessage({
                    id: translationKeys.edit_order_drawer_external_deadline_label,
                  })}
                  placeholder={intl.formatMessage({
                    id: translationKeys.edit_order_drawer_external_deadline_label,
                  })}
                  icon={<FontAwesomeIcon icon={faCalendar} />}
                  value={externalDeadline}
                  size="large"
                  onFocus={() => setShowExternalDeadlineDatePicker(true)}
                  onChange={handleExternalDeadlineChange}
                  type="search"
                />
                {showExternalDeadlineDatePicker && (
                  <DatePicker
                    monthCount={1}
                    onClose={() => setShowExternalDeadlineDatePicker(false)}
                    singleDate
                    onChange={handleExternalDeadlineDatePicker}
                  />
                )}
              </div>
            </div>

            <div className={styles.wordCountAccessRow}>
              <TextField
                label={intl.formatMessage({
                  id: translationKeys.edit_order_drawer_word_count_label,
                })}
                placeholder="0"
                value={values.wordCount}
                size="large"
                type="number"
                data-testid="word-count-input"
                onChange={handleChange}
                onBlur={handleBlur}
                name="wordCount"
              />

              <div>
                <SearchDropdown
                  label={intl.formatMessage({
                    id: translationKeys.edit_order_drawer_access_level_label,
                  })}
                  options={accessLevelOptions}
                  size="large"
                  placeholder={
                    selectedAccessLevelText ? getAccessLevelName(order.accessLevel, intl) : ''
                  }
                  data-testid="access-level-input"
                  onChange={(level) => {
                    handleAccessLevelChange(level);
                  }}
                />
                {ownerError && (
                  <div className={styles.errorText}>
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                    <p>{ownerError}</p>
                  </div>
                )}
                {departmentError && (
                  <div className={styles.errorText}>
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                    <p>{departmentError}</p>
                  </div>
                )}
                {enterpriseError && (
                  <div className={styles.errorText}>
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                    <p>{enterpriseError}</p>
                  </div>
                )}
              </div>
            </div>

            <div className={styles.inputRow} data-testid="alternative-language-input">
              <SearchDropdown
                label={intl.formatMessage({
                  id: translationKeys.edit_order_drawer_alternative_language_label,
                })}
                options={alternativeLanguageOptions}
                size="large"
                placeholder={
                  order.alternativeTargetLanguageId === 2
                    ? intl.formatMessage({
                        id: translationKeys.edit_order_drawer_english_option,
                      })
                    : order.alternativeTargetLanguageId === 3
                    ? intl.formatMessage({
                        id: translationKeys.edit_order_drawer_norwegian_option,
                      })
                    : intl.formatMessage({
                        id: translationKeys.edit_order_drawer_no_preference_option,
                      })
                }
                onChange={(alternativeLanguage) =>
                  handleAlternativeLanguageChange(alternativeLanguage)
                }
              />
            </div>

            <div className={styles.inputRow}>
              <SearchDropdown
                label={intl.formatMessage({ id: translationKeys.edit_order_drawer_source_label })}
                options={sourceTypeOptions}
                size="large"
                placeholder={
                  selectedSourceTypeText ? getSourceTypeName(order.sourceType, intl) : ''
                }
                onChange={(selection) => handleSourceTypeChange(selection)}
                data-testid="source-input"
              />
            </div>

            {(isLoggedOut === true || selectedSourceType === ManagerOrderSourceType.Logout) && (
              <div data-testid="logged-out-container" className={styles.loggedOutContainer}>
                <hr />
                <p className={styles.logoutTitle}>
                  <FormattedMessage id={translationKeys.edit_order_drawer_logout_title} />
                </p>

                <div className={styles.nameInputs}>
                  <TextField
                    placeholder={intl.formatMessage({
                      id: translationKeys.edit_order_drawer_logout_first_name_placeholder,
                    })}
                    type="text"
                    label={intl.formatMessage({
                      id: translationKeys.edit_order_drawer_logout_first_name_label,
                    })}
                    size="large"
                    name="firstName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstName}
                    data-testid="first-name-input"
                    errorText={touched.firstName ? errors.firstName : undefined}
                  />

                  <TextField
                    placeholder={intl.formatMessage({
                      id: translationKeys.edit_order_drawer_logout_last_name_placeholder,
                    })}
                    type="text"
                    label={intl.formatMessage({
                      id: translationKeys.edit_order_drawer_logout_last_name_label,
                    })}
                    size="large"
                    name="lastName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastName}
                    data-testid="last-name-input"
                    errorText={touched.lastName ? errors.lastName : undefined}
                  />
                </div>

                <div data-testid="enterprise-dropdown">
                  <SearchDropdown
                    options={enterpriseResults}
                    placeholder={intl.formatMessage({
                      id: translationKeys.edit_order_drawer_logout_enterprise_placeholder,
                    })}
                    label={intl.formatMessage({
                      id: translationKeys.edit_order_drawer_logout_enterprise_label,
                    })}
                    size="large"
                    grouped
                    selected={selectedEnterprise ? [selectedEnterprise] : undefined}
                    onSearch={(query: string) => {
                      debounceEnterpriseSearch(() => {
                        getEnterpriseList(query, query !== '');
                      });
                    }}
                    onChange={(enterprise) => {
                      if (enterprise && enterprise.length > 0 && enterprise[0].key) {
                        setSelectedEnterprise(enterprise[0]);
                      } else {
                        setSelectedEnterprise(undefined);
                      }
                    }}
                  />
                </div>

                <div data-testid="payment-enterprise-dropdown">
                  <SearchDropdown
                    options={enterpriseResults}
                    placeholder={intl.formatMessage({
                      id: translationKeys.edit_order_drawer_logout_payment_method_placeholder,
                    })}
                    label={intl.formatMessage({
                      id: translationKeys.edit_order_drawer_logout_payment_method_label,
                    })}
                    size="large"
                    grouped
                    selected={selectedPaymentEnterprise ? [selectedPaymentEnterprise] : undefined}
                    onSearch={(query: string) => {
                      debounceEnterpriseSearch(() => {
                        getEnterpriseList(query, query !== '');
                      });
                    }}
                    onChange={(enterprise) => {
                      if (enterprise && enterprise.length > 0 && enterprise[0].key) {
                        setSelectedPaymentEnterprise(enterprise[0]);
                      } else {
                        setSelectedPaymentEnterprise(undefined);
                      }
                    }}
                  />
                </div>

                <div className={styles.emailInput}>
                  <TextField
                    placeholder={intl.formatMessage({
                      id: translationKeys.edit_order_drawer_logout_email_placeholder,
                    })}
                    type="text"
                    label={intl.formatMessage({
                      id: translationKeys.edit_order_drawer_logout_email_label,
                    })}
                    size="large"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    data-testid="email-input"
                    errorText={touched.email ? errors.email : undefined}
                  />
                </div>

                <div className={styles.phoneInputs} data-testid="phone-code-dropdown">
                  <PhoneCodeDropdown
                    selected={selectedPhoneCode}
                    placeholder={
                      order.loggedOutBookerPhoneCode ? order.loggedOutBookerPhoneCode : '+47'
                    }
                    label={intl.formatMessage({
                      id: translationKeys.edit_order_drawer_logout_phone_code_label,
                    })}
                    onChange={(phoneCodes) => {
                      if (phoneCodes && phoneCodes.length > 0 && phoneCodes[0].key) {
                        setSelectedPhoneCode(phoneCodes[0]);
                      } else {
                        setSelectedPhoneCode(undefined);
                      }
                    }}
                  />

                  <TextField
                    placeholder={intl.formatMessage({
                      id: translationKeys.edit_order_drawer_logout_phone_number_placeholder,
                    })}
                    type="text"
                    label={intl.formatMessage({
                      id: translationKeys.edit_order_drawer_logout_phone_number_label,
                    })}
                    size="large"
                    name="phoneNumber"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phoneNumber}
                    data-testid="phone-number-input"
                  />
                </div>
              </div>
            )}

            <div className={styles.actions}>
              <Button variant="gray" size="large" onClick={onClose} data-testid="cancel-button">
                <FormattedMessage id={translationKeys.edit_order_drawer_cancel_button} />
              </Button>
              <Button
                size="large"
                type="submit"
                disabled={!selectedLanguage}
                data-testid="submit-button"
              >
                <FormattedMessage id={translationKeys.edit_order_drawer_submit_button} />
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Drawer>
  );
};

export default EditOrderDrawer;
