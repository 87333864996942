import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Tabs } from '@skiwo/components';
import { stripObject } from '@skiwo/utils';
import { ApiError } from '../Api';
import NewSkillDrawer from '../InterpretationSkills/Drawers/NewSkillDrawer/NewSkillDrawer';
import { useApi } from '../providers/ApiProvider';
import translationKeys from '../translations/translationKeys';
import { TranslationSkill, TranslationSkillsResponse, TranslationSkillsStatus } from '../types';
import TranslationSkillsTable from './TranslationSkillsTable/TranslationSkillsTable';
import getStatusName from './utils/getStatusName';
import getTranslationSkillsFilters from './utils/getTranslationSkillsFilters';
import TranslationSkillsFilterField from './TranslationSkillsFilterField';
import TranslationSkillsTab from './TranslationSkillsTab';
import styles from './TranslationSkills.module.scss';

const TranslationSkills = () => {
  const [skills, setSkills] = useState<TranslationSkill[]>([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const intl = useIntl();
  const [activeTab, setActiveTab] = useState('');
  const [skillsLoading, setSkillsLoading] = useState(false);
  const [skillsError, setSkillsError] = useState<ApiError | null>(null);
  const [skillsStats, setSkillsStats] = useState<TranslationSkillsResponse['statistics']>();
  const [filters, setFilters] = useState<Record<string, string | string[]>>({});
  const [newSkillDrawerOpen, setNewSkillDrawerOpen] = useState(false);
  const api = useApi();

  const tabItems = [
    {
      active: true,
      count: skillsStats?.approved ? skillsStats.approved : undefined,
      id: TranslationSkillsTab.Approved,
      title: getStatusName(TranslationSkillsStatus.Approved, intl),
      filters: {
        's[status_eq]': 30,
      },
    },
    {
      count: skillsStats?.learning ? skillsStats.learning : undefined,
      id: TranslationSkillsTab.Learning,
      title: getStatusName(TranslationSkillsStatus.Learning, intl),
      filters: {
        's[status_eq]': 20,
      },
    },
    {
      count: skillsStats?.pending ? skillsStats.pending : undefined,
      id: TranslationSkillsTab.Pending,
      title: getStatusName(TranslationSkillsStatus.Pending, intl),
      filters: {
        's[status_eq]': 10,
      },
    },
    {
      count: skillsStats?.declined ? skillsStats.declined : undefined,
      id: TranslationSkillsTab.Declined,
      title: getStatusName(TranslationSkillsStatus.Declined, intl),
      filters: {
        's[status_eq]': 40,
      },
    },
    {
      count: skillsStats?.all ? skillsStats.all : undefined,
      id: TranslationSkillsTab.All,
      title: intl.formatMessage({ id: translationKeys.translation_skills_all_tab }),
      filters: {},
    },
  ];

  const getTabFilters = () => {
    const activeTabItem = tabItems.find((item) => item.id === activeTab);

    if (activeTabItem) {
      return activeTabItem.filters;
    }
  };

  const handleFilterChange = (field: TranslationSkillsFilterField, value: string) => {
    setFilters(getTranslationSkillsFilters(filters, field, value));
  };

  const fetchTranslationSkills = async (page: number, clearCache = false) => {
    const { data, error } = await api.getTranslationSkills(
      {
        page,
        items: 20,
        's[s]': 'id desc',
        ...getTabFilters(),
        ...stripObject(filters),
      },
      setSkillsLoading,
    );

    setSkillsError(error);

    if (data?.skills) {
      let allSkills = skills;
      if (!clearCache) {
        for (const newSkill of data.skills) {
          const newSkillIndex = allSkills.findIndex((skill) => skill.id === newSkill.id);

          if (newSkillIndex === -1) {
            allSkills.push(newSkill);
          } else {
            allSkills[newSkillIndex] = newSkill;
          }
        }
      } else {
        allSkills = data.skills;
      }

      setSkills(allSkills);
      setSkillsStats(data.statistics);
      setPage(data.page);
      setPages(data.pages);
    }
  };

  const handleLoadMore = () => {
    fetchTranslationSkills(page + 1);
  };

  const handleReloadData = (hardReload = false) => {
    fetchTranslationSkills(page, hardReload);
  };

  const handleTabSelect = (newActiveTab: string) => {
    if (newActiveTab !== activeTab) {
      setSkills([]);
      setPage(1);
      setActiveTab(newActiveTab);
    }
  };

  useEffect(() => {
    if (Object.keys(filters).length === 0) {
      return;
    }

    setSkills([]);
    fetchTranslationSkills(1, true);
  }, [filters]);

  useEffect(() => {
    if (activeTab) {
      fetchTranslationSkills(page);
    }
  }, [activeTab]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <h1 data-testid="translation-skills-header">
            <FormattedMessage id={translationKeys.translation_skills_title} />
          </h1>

          <div>
            <Button
              icon={<FontAwesomeIcon icon={faPlus} />}
              data-testid="new-skill-button"
              onClick={() => setNewSkillDrawerOpen(true)}
            >
              <FormattedMessage id={translationKeys.translation_skills_page_new_skill_button} />
            </Button>
          </div>
        </div>

        <div className={styles.tabsContainer}>
          <Tabs items={tabItems} onSelect={handleTabSelect} />
        </div>

        <TranslationSkillsTable
          skills={skills}
          error={skillsError}
          isLoading={skillsLoading}
          onFilterChange={handleFilterChange}
          onCompleteAction={handleReloadData}
          className={styles.tableContainer}
        />

        {!skillsLoading && skills && page < pages && (
          <div className={styles.loadMoreButton}>
            <Button
              variant="secondary"
              size="large"
              onClick={handleLoadMore}
              data-testid="load-more-button"
            >
              <FormattedMessage id={translationKeys.translation_skills_load_more_button} />
            </Button>
          </div>
        )}
      </div>

      <NewSkillDrawer
        show={newSkillDrawerOpen}
        onClose={() => setNewSkillDrawerOpen(false)}
        onComplete={() => handleReloadData(true)}
      />
    </>
  );
};

export default TranslationSkills;
