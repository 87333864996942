import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  faArrowRight,
  faCircleCheck,
  faEnvelope,
  faLocationDot,
  faMars,
  faPen,
  faPhone,
  faTrash,
  faVenus,
  faVideo,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActionsSection, Avatar, Badge, Skeleton, Tag, Tooltip } from '@skiwo/components';
import { formatPhoneNumber } from '@skiwo/utils';
import classnames from 'classnames';
import { format } from 'date-fns';
import imdiIcon from '../../assets/icons/imdi.svg';
import NotesDrawer from '../../Drawers/NotesDrawer/NotesDrawer';
import { useLanguages } from '../../providers/LanguagesProvider';
import { useQualifications } from '../../providers/QualificationsProvider';
import getStandByName from '../../Suppliers/utils/getStandByName';
import translationKeys from '../../translations/translationKeys';
import {
  InterpretationSkill,
  InterpretationSkillsAccountStatus,
  InterpretationSkillSex,
  InterpretationSkillsStatus,
  InterpretationSkillStandBy,
  ManagerSupplierType,
} from '../../types';
import EditSkillDrawer from '../Drawers/EditSkillDrawer/EditSkillDrawer';
import { ConfirmDeleteSkillModal } from '../Modals/ConfirmDeleteSkillModal/ConfirmDeleteSkillModal';
import getAccountStatusName from '../utils/getAccountStatusName';
import getGenderName from '../utils/getGenderName';
import getStatusName from '../utils/getStatusName';
import styles from './InterpretationSkillsItem.module.scss';

enum InterpretationSkillActions {
  ConfirmDeleteSkill = 'CONFIRM_DELETE_SKILL',
  EditInterpretationSkill = 'EDIT_INTERPRETATION_SKILL',
}

interface Props {
  skill: InterpretationSkill;
  onCompleteAction: (hardReload?: boolean) => void;
  onClose?: () => void;
}

const InterpretationSkillsItem = (props: Props) => {
  const { skill, onCompleteAction } = props;
  const intl = useIntl();
  const { getLanguageById, isLoading: languagesLoading } = useLanguages();
  const { getQualificationById, isLoading: QualificationsLoading } = useQualifications();
  const createdAtTimeStamp = new Date(skill.createdAt);
  const [showNotesDrawer, setShowNotesDrawer] = useState(false);
  const [badgeCount, setBadgeCount] = useState(0);
  const [openAction, setOpenAction] = useState<InterpretationSkillActions | null>();

  const onAddNote = () => {
    setBadgeCount(badgeCount + 1);
  };

  const onDeleteNote = () => {
    setBadgeCount(badgeCount - 1);
  };

  useEffect(() => {
    setBadgeCount(skill.notesCount);
  }, []);

  const getStatusTagColor = () => {
    if (skill.status === InterpretationSkillsStatus.Approved) {
      return 'success';
    }

    if (skill.status === InterpretationSkillsStatus.Learning) {
      return 'info';
    }
    if (skill.status === InterpretationSkillsStatus.Declined) {
      return 'error';
    }

    return 'neutral';
  };

  const getLocation = () => {
    if (!skill.location) return '-';

    const components = [
      skill.location.line1,
      skill.location.line2,
      skill.location.postcode,
      skill.location.county,
      skill.location.city,
      skill.location.country,
    ].filter((component) => !!component);

    if (components.length === 0) return '-';

    return components.join(', ');
  };

  const getAccountStatusTagColor = () => {
    if (skill.accountStatus.name === InterpretationSkillsAccountStatus.Active) {
      return 'success';
    }

    if (skill.accountStatus.name === InterpretationSkillsAccountStatus.Paused) {
      return 'warning';
    }

    return 'error';
  };

  const getStatistic = () => {
    const { invited, withdrawn, showedUp, late, noShow } = skill.statistics;
    return (
      <div className={styles.statistic}>
        <div>
          <span className={styles.itemValue}>{invited || 0}</span>
          <span className={styles.itemLabel}>
            <FormattedMessage
              id={translationKeys.interpretation_skills_page_statistic_invited_cell}
            />
          </span>
        </div>
        <div>
          <span className={styles.itemValue}>{withdrawn || 0}</span>
          <span className={styles.itemLabel}>
            <FormattedMessage
              id={translationKeys.interpretation_skills_page_statistic_withdrawn_cell}
            />
          </span>
        </div>
        <div>
          <span className={styles.itemValue}>{showedUp || 0}</span>
          <span className={styles.itemLabel}>
            <FormattedMessage
              id={translationKeys.interpretation_skills_page_statistic_showedup_cell}
            />
          </span>
        </div>
        <div>
          <span className={styles.itemValue}>{late || 0}</span>
          <span className={styles.itemLabel}>
            <FormattedMessage id={translationKeys.interpretation_skills_page_statistic_late_cell} />
          </span>
        </div>
        <div>
          <span className={styles.itemValue}>{noShow || 0}</span>
          <span className={styles.itemLabel}>
            <FormattedMessage
              id={translationKeys.interpretation_skills_page_statistic_noshow_cell}
            />
          </span>
        </div>
      </div>
    );
  };

  return (
    <tr className={styles.itemRow} data-testid="interpretation-skill-item">
      <td className={styles.tableTitleText}>
        <a target="_blank" href={skill.godmodeSupplierUrl} rel="noreferrer" data-testid="skills-id">
          {skill.id}
        </a>
      </td>
      <td>
        <span className={styles.indicatorIcons}>
          <Tooltip title={skill.email} copyable>
            <FontAwesomeIcon
              data-testid="email-indicator"
              icon={faEnvelope}
              className={classnames(styles.icon, { [styles.active]: !!skill.email })}
            />
          </Tooltip>

          <Tooltip title={formatPhoneNumber(skill.phone)} copyable>
            <FontAwesomeIcon
              data-testid="phone-indicator"
              icon={faPhone}
              className={classnames(styles.icon, { [styles.active]: !!skill.phone })}
            />
          </Tooltip>

          <Avatar
            altText={skill.name || skill.email}
            url={skill.avatarUrl}
            active={skill.accountStatus.name === InterpretationSkillsAccountStatus.Active}
          />
          <Tooltip title={skill.name || skill.email} copyable>
            <span className={styles.tableTitleText} data-testid="skills-name-or-email">
              {skill.name || skill.email}
            </span>
          </Tooltip>
        </span>
      </td>
      <td data-testid="skill-status">
        <Tag color={getStatusTagColor()}>{getStatusName(skill.status, intl)}</Tag>
      </td>
      <td>
        <div>
          {languagesLoading ? (
            <Skeleton />
          ) : (
            <>
              <span className={styles.languageInfo}>
                <span className={styles.tableTitleText} data-testid="skills-from-language">
                  {getLanguageById(skill.langFromId)}
                </span>

                <FontAwesomeIcon
                  icon={faArrowRight}
                  className={styles.icon}
                  data-testid="skills-languages-icon"
                />

                <span className={styles.tableTitleText} data-testid="skills-to-language">
                  {getLanguageById(skill.langToId)}
                </span>
              </span>

              <span className={styles.details} data-testid="skills-qualification-id">
                {skill.qualificationIsInTolkeregister && (
                  <Tooltip
                    title={intl.formatMessage({
                      id: translationKeys.interpretation_skills_page_imdi_tooltip,
                    })}
                  >
                    <img src={imdiIcon} alt="IMDI" className={styles.imdiLogo} />
                  </Tooltip>
                )}
                {QualificationsLoading ? (
                  <Skeleton />
                ) : (
                  <span className={styles.qualification}>
                    {getQualificationById(skill.qualificationId)?.name || '-'}
                  </span>
                )}
              </span>
            </>
          )}
        </div>
      </td>
      <td data-testid="account-status">
        <Tag color={getAccountStatusTagColor()}>
          {getAccountStatusName(skill.accountStatus.name, intl)}
        </Tag>
      </td>
      <td>
        <span className={styles.tableTitleText} data-testid="skills-created-at">
          {format(createdAtTimeStamp, 'dd.MM.yyyy')}
        </span>
        <span className={styles.details}>{format(createdAtTimeStamp, 'HH:mm')}</span>
      </td>
      <td>
        <div className={styles.hints} data-testid="skills-assignment-type">
          <Tooltip
            title={intl.formatMessage({
              id: translationKeys.interpretation_skills_phone_assignment_icon_tooltip,
            })}
          >
            <FontAwesomeIcon
              data-testid="phone-session-icon"
              className={classnames(styles.icon, {
                [styles.active]: skill.sessionTypesStatuses?.phone.name === 'active',
              })}
              icon={faPhone}
            />
          </Tooltip>
          <Tooltip
            title={intl.formatMessage({
              id: translationKeys.interpretation_skills_video_assignment_icon_tooltip,
            })}
          >
            <FontAwesomeIcon
              data-testid="video-session-icon"
              className={classnames(styles.icon, {
                [styles.active]: skill.sessionTypesStatuses?.video.name === 'active',
              })}
              icon={faVideo}
            />
          </Tooltip>
          <Tooltip
            title={intl.formatMessage({
              id: translationKeys.interpretation_skills_in_person_assignment_icon_tooltip,
            })}
          >
            <FontAwesomeIcon
              data-testid="in-person-session-icon"
              className={classnames(styles.icon, {
                [styles.active]: skill.sessionTypesStatuses?.in_person.name === 'active',
              })}
              icon={faLocationDot}
            />
          </Tooltip>
        </div>
      </td>
      <td>
        <span className={styles.indicatorIcons} data-testid="skills-gender">
          {skill.sex === InterpretationSkillSex.Male && (
            <FontAwesomeIcon
              icon={faMars}
              className={classnames(styles.icon, styles.active)}
              data-testid="male-icon"
            />
          )}
          {skill.sex === InterpretationSkillSex.Female && (
            <FontAwesomeIcon
              icon={faVenus}
              className={classnames(styles.icon, styles.active)}
              data-testid="female-icon"
            />
          )}
        </span>

        <span className={styles.tableTitleText}>{skill.sex && getGenderName(skill.sex, intl)}</span>
      </td>

      <td data-testid="interpretation-skill-standby">
        <span className={styles.indicatorIcons}>
          {skill.standby === InterpretationSkillStandBy.Yes ? (
            <FontAwesomeIcon
              icon={faCircleCheck}
              className={classnames(styles.icon, styles.active)}
              data-testid="standby-icon"
            />
          ) : (
            '-'
          )}
        </span>

        <span className={styles.tableTitleText} data-testid="interpretation-skill-standby-text">
          {skill.standby === InterpretationSkillStandBy.Yes &&
            getStandByName(InterpretationSkillStandBy.Yes, intl)}
        </span>
      </td>
      <td className={styles.locationText} data-testid="skills-location">
        <Tooltip title={getLocation()}>
          <span>{getLocation()}</span>
        </Tooltip>
      </td>
      <td data-testid="skills-statistic">{getStatistic()}</td>
      <td>
        <div>
          <Badge
            variant={badgeCount > 0 ? 'active' : 'default'}
            data-testid="badge-button"
            onClick={() => setShowNotesDrawer(true)}
          >
            {badgeCount}
          </Badge>
        </div>
        <NotesDrawer
          onAddNote={onAddNote}
          onDeleteNote={onDeleteNote}
          data-testid="notes-drawer"
          show={showNotesDrawer}
          onClose={() => {
            setShowNotesDrawer(false);
          }}
          user={{
            id: skill.id,
            name: skill.name,
            email: skill.email,
            phone: skill.phone,
            uid: skill.uid,
          }}
          note={{ note: skill.note?.note || '', author: skill.note?.author }}
          onCancel={() => setShowNotesDrawer(false)}
        />
      </td>
      <td>
        <ActionsSection
          actionItems={[
            {
              title: intl.formatMessage({ id: translationKeys.edit_skill_drawer_tooltip_text }),
              icon: faPen,
              key: InterpretationSkillActions.EditInterpretationSkill,
              ['data-testid']: 'supplier-edit-action',
            },
            {
              title: skill.hasJobAssignment
                ? intl.formatMessage({
                    id: translationKeys.interpretation_skills_delete_skill_disabled_tooltip,
                  })
                : intl.formatMessage({ id: translationKeys.skills_delete_skill_tooltip }),
              disabled: skill.hasJobAssignment,
              icon: faTrash,
              key: InterpretationSkillActions.ConfirmDeleteSkill,
              ['data-testid']: 'supplier-edit-action',
            },
          ]}
          onActionSelected={(key) => {
            setOpenAction(key as InterpretationSkillActions);
          }}
        />

        {openAction === InterpretationSkillActions.EditInterpretationSkill && (
          <EditSkillDrawer
            show
            onClose={() => setOpenAction(null)}
            skill={skill}
            onComplete={onCompleteAction}
            skillType={ManagerSupplierType.Interpreter}
          />
        )}

        {openAction === InterpretationSkillActions.ConfirmDeleteSkill && (
          <ConfirmDeleteSkillModal
            show
            skill={skill}
            onClose={() => setOpenAction(null)}
            onComplete={onCompleteAction}
            type="interpretation"
          />
        )}
      </td>
    </tr>
  );
};

export default InterpretationSkillsItem;
