export enum ManagerOrderAttachmentStatus {
  Initial = 'initial',
  CoverLetter = 'cover_letter',
  Wip = 'wip',
  Final = 'final',
}

interface ManagerOrderAttachment {
  id: number;
  name: string;
  link: string;
  contentType: string;
  base64?: string;
  status: ManagerOrderAttachmentStatus;
  subtaskId?: number;
  archived: boolean;
  ocr: boolean;
  filePassword?: string;
  countOfWords?: number;
  countOfPages?: number;
  createdAt: string;
  updatedAt: string;
  byteSize?: number;
}

export interface ManagerOrderAttachmentResponse {
  orderAttachments: ManagerOrderAttachment[];
}

export default ManagerOrderAttachment;
