/* eslint-disable prefer-rest-params */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useEffect } from 'react';

const CLARITY_KEY = process.env.REACT_APP_CLARITY_KEY;

const useClarity = () => {
  useEffect(() => {
    if (CLARITY_KEY) {
      (function (c, l, a, r, i, t, y) {
        c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
        t = l.createElement(r); 
        t.async = 1;
        t.src = `https://www.clarity.ms/tag/${i}`;
        y = l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t, y);
      })(window, document, "clarity", "script", CLARITY_KEY);
    }
  }, [CLARITY_KEY]); // Adding CLARITY_KEY to the dependency array

  return null;
};

export default useClarity;