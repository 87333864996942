import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useParams } from 'react-router-dom';
import ChatSection from '../components/ChatSection/ChatSection';
import { useApi } from '../providers/ApiProvider';
import { ManagerJobDetails } from '../types/ManagerJob';
import JobDetails from './JobDetails/JobDetails';
import styles from './Job.module.scss';

export const Job = () => {
  const { jobId } = useParams();
  const api = useApi();
  const [job, setJob] = useState<ManagerJobDetails | null>(null);

  const getJob = async () => {
    if (!jobId) return;

    const { data } = await api.getJob(jobId);
    if (data) {
      setJob(data);
    }
  };

  useEffect(() => {
    getJob();
  }, []);

  // TODO: Show error state
  if (!job) return null;

  return (
    <Container fluid className={styles.container}>
      <Row className={styles.row}>
        <Col lg={10} className={styles.section}>
          <JobDetails job={job} />
        </Col>
        <Col lg={2} className={styles.section}>
          <ChatSection queryId={jobId} getMessage={api.getJobNotes} sendMessage={api.postJobNote} />
        </Col>
      </Row>
    </Container>
  );
};

export default Job;
