import React from 'react';
import { Placeholder } from 'react-bootstrap';
import styles from './CustomerSkeleton.module.scss';

const CustomerSkeleton = () => {
  return (
    <Placeholder as="span" animation="wave" className={styles.skeleton} data-testid="box-skeleton">
      <div className={styles.circles}>
        <Placeholder xs={2} data-testid="circle" />
      </div>
      <div className={styles.details}>
        <Placeholder xs={3} className={styles.box} />
        <Placeholder xs={2} className={styles.box} />
      </div>
    </Placeholder>
  );
};

export default CustomerSkeleton;
