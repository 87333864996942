import { IntlShape } from 'react-intl';
import translationKeys from '../../translations/translationKeys';
import { TranslationSubtaskCustomerInput } from '../../types/TranslationSubtask';

const getCustomerInputStatusName = (
  status: TranslationSubtaskCustomerInput,
  intl: IntlShape,
): string => {
  if (status === TranslationSubtaskCustomerInput.Requested) {
    return intl.formatMessage({
      id: translationKeys.translation_subtasks_status_waiting_for_customer,
    });
  }

  if (status === TranslationSubtaskCustomerInput.Updated) {
    return intl.formatMessage({
      id: translationKeys.translation_subtasks_status_updated_by_customer,
    });
  }

  return '-';
};

export default getCustomerInputStatusName;
