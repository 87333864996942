import React from 'react';
import { useIntl } from 'react-intl';
import { Tooltip } from '@skiwo/components';
import filePen from '../../../assets/icons/file-pen.svg';
import filePenTransperent from '../../../assets/icons/file-pen-transperent.svg';
import translationKeys from '../../../translations/translationKeys';
import styles from './Hint.module.scss';

interface Props {
  needsManualApproval: boolean;
}

const ManualApprovaleHint = (props: Props) => {
  const { needsManualApproval } = props;
  const intl = useIntl();

  if (needsManualApproval) {
    return (
      <Tooltip
        title={intl.formatMessage({
          id: translationKeys.job_page_needs_manual_approval_tooltip,
        })}
      >
        <img data-testid={'manual-approve-hint'} src={filePen} className={styles.icon} />
      </Tooltip>
    );
  }
  return (
    <Tooltip
      title={intl.formatMessage({
        id: translationKeys.job_page_not_needs_manual_approval_tooltip,
      })}
    >
      <img
        data-testid={'no-manual-approve-hint'}
        src={filePenTransperent}
        className={styles.icon}
      />
    </Tooltip>
  );
};

export default ManualApprovaleHint;
