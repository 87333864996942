import React from 'react';
import { useIntl } from 'react-intl';
import { Stepper } from '@skiwo/components';
import translationKeys from '../../translations/translationKeys';
import { ManagerJobDetails } from '../../types/ManagerJob';
import InterpreterCard from './InterpreterCard/InterpreterCard';
import JobFooter from './JobFooter/JobFooter';
import RequirementsSection from './RequirementsSection/RequirementsSection';
import InfoSection from './InfoSection';
import styles from './JobDetails.module.scss';

interface JobDetailsProps {
  job: ManagerJobDetails;
}

export const JobDetails = ({ job }: JobDetailsProps) => {
  const intl = useIntl();

  return (
    <section className={styles.orderSection}>
      <Stepper
        steps={[
          intl.formatMessage({ id: translationKeys.job_received_status }),
          intl.formatMessage({ id: translationKeys.job_applied_status }),
          intl.formatMessage({ id: translationKeys.job_awarded_status }),
          intl.formatMessage({ id: translationKeys.job_in_progress_status }),
          intl.formatMessage({ id: translationKeys.job_finished_status }),
          intl.formatMessage({ id: translationKeys.job_invoiced_status }),
        ]}
        currentStep={5}
      />
      <InfoSection job={job} />
      {job.acceptedInterpreter && <InterpreterCard interpreter={job.acceptedInterpreter} />}
      <RequirementsSection job={job} />
      <JobFooter job={job} />
    </section>
  );
};

export default JobDetails;
