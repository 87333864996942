enum SupplierTabs {
  Active = 'ACTIVE',
  Paused = 'PAUSED',
  Blocked = 'BLOCKED',
  Retired = 'RETIRED',
  Banned = 'BANNED',
  Deleted = 'DELETED',
  Deceased = 'DECEASED',
  All = 'ALL',
}

export default SupplierTabs;
