const formatPhoneNumber = (phoneNumber: string | undefined) => {
  if (!phoneNumber) {
    return phoneNumber;
  }

  const normalizedPhoneNumber = phoneNumber.replace(/\s+/g, '').replace(/^\++/, '+');

  if (normalizedPhoneNumber.startsWith('+47') && normalizedPhoneNumber.length === 11) {
    const numbers = normalizedPhoneNumber.slice(3);
    return ['+47', numbers.slice(0, 3), numbers.slice(3, 5), numbers.slice(5)].join(' ');
  } else if (normalizedPhoneNumber.length === 8) {
    return [
      normalizedPhoneNumber.slice(0, 3),
      normalizedPhoneNumber.slice(3, 5),
      normalizedPhoneNumber.slice(5),
    ].join(' ');
  } else {
    return normalizedPhoneNumber;
  }
};

export default formatPhoneNumber;
