import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { faPen, faTrash } from '@fortawesome/pro-solid-svg-icons';
import {
  Button,
  DetailItem,
  Drawer,
  FileSelector,
  FileState,
  SearchDropdown,
  SearchDropdownMenuOption,
  TextField,
} from '@skiwo/components';
import { formatFileSize } from '@skiwo/utils';
import { Formik } from 'formik';
import { ApiError } from '../../../Api';
import fileIllustration from '../../../assets/illustration-file.svg';
import SupplierCard from '../../../components/SupplierCard/SupplierCard';
import { useApi } from '../../../providers/ApiProvider';
import { useLanguages } from '../../../providers/LanguagesProvider';
import { useQualifications } from '../../../providers/QualificationsProvider';
import translationKeys from '../../../translations/translationKeys';
import { InterpretationSkillsStatus, ManagerSupplier, ManagerSupplierType } from '../../../types';
import getStatusName from '../../utils/getStatusName';
import QualificationAccordion from '../NewSkillDrawer/QualificationAccordion/QualificationAccordion';
import RenameFileModal from '../NewSkillDrawer/RenameFileModal/RenameFileModal';
import styles from './EditSkillDrawer.module.scss';

interface Props {
  show?: boolean;
  onClose: () => void;
  onComplete?: (hardReload?: boolean) => void;
  skill: any;
  skillType: ManagerSupplierType;
}

interface FormValues {
  dialectFrom: string;
  dialectTo: string;
  notifyInterpreter: boolean;
  nationalInterpreterId: number | string;
}

interface RenameFileValues {
  fileName: string;
  fileUid: string;
}

const EditSkillDrawer = (props: Props) => {
  const { show, onClose, skill, skillType, onComplete } = props;
  const api = useApi();

  const [selectedSupplier, setSelectedSupplier] = useState<ManagerSupplier>();
  const [selectedFromLanguage, setSelectedFromLanguage] = useState<SearchDropdownMenuOption>();
  const [selectedToLanguage, setSelectedToLanguage] = useState<SearchDropdownMenuOption>();
  const [selectedQualification, setSelectedQualification] = useState<SearchDropdownMenuOption>();
  const [showErrors, setShowErrors] = useState(false);
  const [interpretationSkillLoading, setInterpretationSkillLoading] = useState<boolean>(false);
  const [translationSkillLoading, setTranslationSkillLoading] = useState<boolean>(false);
  const [interpretationSkillError, setInterpretationSkillError] = useState<ApiError | null>();
  const [selectedSkillStatus, setSelectedSkillStatus] = useState<string>();
  const [selectedSkillStatusOption, setSelectedSkillStatusOption] =
    useState<SearchDropdownMenuOption>();
  const [notifySupplier, setNotifySupplier] = useState(false);
  const [activateInputFields, setActivateInputFields] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<FileState[]>([]);
  const [showRenameFileModal, setShowRenameFileModal] = useState<boolean>(false);
  const [renameFileUid, setRenameFileUid] = useState('');

  const { languages } = useLanguages();
  const { getQualificationById, qualifications } = useQualifications();
  const intl = useIntl();

  const skillStatusOptions =
    Object.values(InterpretationSkillsStatus).map((status, index) => {
      return { id: index, label: getStatusName(status, intl), key: status };
    }) || [];

  const getLanguageById = (id: number) => {
    const language = languages.find((language) => language.id === id);
    if (language) {
      return {
        id: language.id,
        label: language.name || '',
        key: language.id.toString(),
      };
    }
    return undefined;
  };

  const getSelectedQualification = (id: number) => {
    const qualification = getQualificationById(id);
    if (qualification) {
      return {
        id: qualification.id,
        label: qualification.name || '',
        key: qualification.id.toString(),
      };
    }
  };

  useEffect(() => {
    const statusString = skill.status;

    const matchedOption = skillStatusOptions.find((option) => option.key === statusString);

    if (matchedOption) {
      setSelectedSkillStatusOption(matchedOption);
    }
    setSelectedSkillStatus(skill.status);
  }, [skill.status]);

  const handleFileSelection = (newFiles: FileState[]) => {
    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  function handleRemoveFile(fileUid: string) {
    setSelectedFiles((prevFiles) => prevFiles.filter((file) => file.uid !== fileUid));
  }

  const interpreterQualificationOptions =
    qualifications.length > 0
      ? qualifications
          .filter((qualification) => qualification.industry === 'interpretation')
          .map((qualification) => {
            return {
              id: qualification.id,
              label: qualification.name || '',
              key: qualification.id.toString(),
            };
          })
      : [];

  const translatorQualificationOptions =
    qualifications.length > 0
      ? qualifications
          .filter((qualification) => qualification.industry === 'translation')
          .map((qualification) => {
            return {
              id: qualification.id,
              label: qualification.name || '',
              key: qualification.id.toString(),
            };
          })
      : [];

  const isFormInvalid = !selectedSupplier || !selectedQualification || !selectedSkillStatus;

  const postIntSkillRequest = async (values: FormValues) => {
    setShowErrors(false);
    if (isFormInvalid) {
      setShowErrors(true);
      return;
    }

    const certificates = selectedFiles;

    const formData = new FormData();

    if (selectedSupplier) {
      formData.append('skill[supplierUid]', selectedSupplier.uid);
    }
    if (selectedQualification) {
      formData.append('skill[qualificationId]', selectedQualification.id.toString());
    }
    if (selectedSkillStatus) {
      formData.append('skill[status]', selectedSkillStatus);
    }
    formData.append('skill[verifiedTolkId]', values.nationalInterpreterId.toString());
    formData.append('notifySupplier', notifySupplier.toString());

    certificates.forEach((fileState) => {
      const fileBlob = new Blob([fileState.data], { type: fileState.type });
      formData.append(`skill[certificates][][file]`, fileBlob, fileState.name);
    });

    const { data, error } = await api.editInterpretationSkill(
      skill.id,
      formData,
      setInterpretationSkillLoading,
    );

    if (data) {
      if (onComplete) onComplete();
      onClose();
    }

    if (error) {
      setShowErrors(true);
      setInterpretationSkillError(error);
    }
  };

  const postTransSkillRequest = async () => {
    setShowErrors(false);
    if (isFormInvalid) {
      setShowErrors(true);
      return;
    }

    const certificates = selectedFiles;

    const formData = new FormData();

    if (selectedSupplier) {
      formData.append('skill[supplierUid]', selectedSupplier.uid);
    }
    if (selectedQualification) {
      formData.append('skill[qualificationId]', selectedQualification.id.toString());
    }
    if (selectedSkillStatus) {
      formData.append('skill[status]', selectedSkillStatus);
    }
    formData.append('notifySupplier', notifySupplier.toString());

    certificates.forEach((fileState) => {
      const fileBlob = new Blob([fileState.data], { type: fileState.type });
      formData.append(`skill[certificates][][file]`, fileBlob, fileState.name);
    });

    const { data, error } = await api.editTranslationSkill(
      skill.id,
      formData,
      setTranslationSkillLoading,
    );

    if (data) {
      if (onComplete) onComplete();
      onClose();
    }

    if (error) {
      setShowErrors(true);
    }
  };

  const handleSubmit = async (values: FormValues) => {
    if (skillType === ManagerSupplierType.Interpreter) {
      postIntSkillRequest(values);
    } else {
      postTransSkillRequest();
    }
  };

  const handleRenameFileSubmit = (values: RenameFileValues) => {
    const newFileName = values.fileName;
    const fileUid = values.fileUid;
    setRenameFileUid('');

    setSelectedFiles((prevFiles) =>
      prevFiles.map((file) => {
        if (file.uid === fileUid) {
          return { ...file, name: newFileName };
        }
        return file;
      }),
    );
  };

  const getTagColorByStatus = (status: string) => {
    switch (status) {
      case InterpretationSkillsStatus.Approved:
        return 'success';
      case InterpretationSkillsStatus.Pending:
        return 'neutral';
      case InterpretationSkillsStatus.Declined:
        return 'error';
      case InterpretationSkillsStatus.Learning:
        return 'info';
      default:
        return 'neutral';
    }
  };

  useEffect(() => {
    setSelectedFromLanguage(getLanguageById(skill.langFromId));
    setSelectedToLanguage(getLanguageById(skill.langToId));
    setSelectedQualification(getSelectedQualification(skill.qualificationId));
    setSelectedSkillStatus(skill.status);
  }, []);

  const fetchSupplierById = async (personId: number) => {
    const { data } = await api.getSupplierDetails(personId.toString());

    if (data && data.supplier) {
      setSelectedSupplier(data.supplier);
    }
  };

  useEffect(() => {
    if (skillType === ManagerSupplierType.Interpreter && skill.personId) {
      fetchSupplierById(skill.personId);
    } else if (skillType === ManagerSupplierType.Translator && skill.person && skill.person.id) {
      fetchSupplierById(skill.person.id);
    }
  }, []);

  const shouldShowTolkId = (id: number) => {
    const qualification = getQualificationById(id);

    if (qualification) {
      return qualification.isInTolkeregister && qualification.priority !== 6;
    }

    return false;
  };

  const shouldShowFileUploader = (id: number) => {
    const qualification = getQualificationById(id);

    if (qualification) {
      return (
        skillType !== ManagerSupplierType.Undefined &&
        (skillType === ManagerSupplierType.Translator ||
          (skillType === ManagerSupplierType.Interpreter && qualification.priority === 6))
      );
    }

    return false;
  };

  return (
    <Drawer
      show={show}
      onClose={() => {
        onClose();
        setSelectedSupplier(undefined);
        setActivateInputFields(false);
        setSelectedFiles([]);
      }}
      title={intl.formatMessage({
        id: translationKeys.edit_skill_drawer_title,
      })}
      data-testid="edit-skill-drawer"
      tagColor={getTagColorByStatus(skill.status)}
      tagTitle={getStatusName(skill.status, intl)}
    >
      <Formik
        onSubmit={handleSubmit}
        initialValues={{
          dialectFrom: skill.dialectFrom,
          dialectTo: skill.dialectTo,
          notifyInterpreter: false,
          nationalInterpreterId: skill.verifiedTolkId ? skill.verifiedTolkId : '',
        }}
      >
        {({ handleSubmit, handleChange, handleBlur, values, setFieldValue }) => (
          <Form onSubmit={handleSubmit} className={styles.container}>
            <div>
              <div className={styles.supplierContainer}>
                <>
                  {selectedSupplier && <SupplierCard supplier={selectedSupplier} />}

                  <div>
                    <p className={styles.supplierTypesLabel}>
                      <FormattedMessage id={translationKeys.edit_skill_drawer_skill_type_label} />
                    </p>
                    <div className={styles.supplierType}>
                      <Form.Check
                        type="radio"
                        label={intl.formatMessage({
                          id: translationKeys.new_skill_drawer_supplier_type_interpreter,
                        })}
                        name="supplierType"
                        checked={skillType === ManagerSupplierType.Interpreter}
                        disabled
                        data-testid="interpreter-radio-button"
                      />
                      <Form.Check
                        type="radio"
                        label={intl.formatMessage({
                          id: translationKeys.new_skill_drawer_supplier_type_translator,
                        })}
                        checked={skillType === ManagerSupplierType.Translator}
                        name="supplierType"
                        disabled
                        data-testid="translator-radio-button"
                      />
                    </div>
                  </div>
                </>

                {skillType !== ManagerSupplierType.Undefined && (
                  <div>
                    <div className={styles.languageInput}>
                      <div data-testid="lang-from-dropdown">
                        <SearchDropdown
                          options={[]}
                          selected={selectedFromLanguage && [selectedFromLanguage]}
                          disabled
                          placeholder={intl.formatMessage({
                            id: translationKeys.interpretation_skills_new_skill_language_from_placeholder,
                          })}
                          label={intl.formatMessage({
                            id: translationKeys.interpretation_skills_new_skill_language_from_label,
                          })}
                          size="large"
                          data-testid="language-from-input"
                        />
                      </div>

                      {skillType === ManagerSupplierType.Interpreter ? (
                        <TextField
                          disabled
                          placeholder={intl.formatMessage({
                            id: translationKeys.interpretation_skills_new_skill_dialect_from_placeholder,
                          })}
                          label={intl.formatMessage({
                            id: translationKeys.interpretation_skills_new_skill_dialect_from_label,
                          })}
                          size="large"
                          name="dialectFrom"
                          data-testid="dialect-from-dropdown"
                          value={!activateInputFields ? 'Bokmål' : values.dialectFrom}
                        />
                      ) : (
                        <div data-testid="lang-to-dropdown">
                          <SearchDropdown
                            options={[]}
                            disabled
                            selected={selectedToLanguage && [selectedToLanguage]}
                            placeholder={intl.formatMessage({
                              id: translationKeys.interpretation_skills_new_skill_language_to_placeholder,
                            })}
                            label={intl.formatMessage({
                              id: translationKeys.interpretation_skills_new_skill_language_to_label,
                            })}
                            size="large"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {skillType !== ManagerSupplierType.Undefined &&
                  skillType === ManagerSupplierType.Interpreter && (
                    <div className={styles.languageInput}>
                      <div data-testid="lang-to-dropdown">
                        <SearchDropdown
                          options={[]}
                          disabled
                          selected={selectedToLanguage && [selectedToLanguage]}
                          placeholder={intl.formatMessage({
                            id: translationKeys.interpretation_skills_new_skill_language_to_placeholder,
                          })}
                          label={intl.formatMessage({
                            id: translationKeys.interpretation_skills_new_skill_language_to_label,
                          })}
                          size="large"
                          data-testid="language-to-input"
                        />
                      </div>

                      <TextField
                        placeholder={intl.formatMessage({
                          id: translationKeys.interpretation_skills_new_skill_dialect_to_placeholder,
                        })}
                        label={intl.formatMessage({
                          id: translationKeys.interpretation_skills_new_skill_dialect_to_label,
                        })}
                        disabled
                        size="large"
                        name="dialectTo"
                        data-testid="dialect-to-dropdown"
                        value={values.dialectTo || '-'}
                      />
                    </div>
                  )}

                {skillType !== ManagerSupplierType.Undefined && (
                  <>
                    <div data-testid="qualification-input">
                      <SearchDropdown
                        options={
                          skillType === ManagerSupplierType.Interpreter
                            ? interpreterQualificationOptions
                            : translatorQualificationOptions
                        }
                        placeholder={intl.formatMessage({
                          id: translationKeys.interpretation_skills_new_skill_qualification_placeholder,
                        })}
                        label={intl.formatMessage({
                          id: translationKeys.interpretation_skills_new_skill_qualification_label,
                        })}
                        size="large"
                        selected={selectedQualification ? [selectedQualification] : undefined}
                        onChange={(qualification) => {
                          if (qualification && qualification.length > 0 && qualification[0].key) {
                            setSelectedQualification(qualification[0]);
                            if (qualification[0].id === 8) {
                              setFieldValue('nationalInterpreterId', '');
                            }
                          } else {
                            setSelectedQualification(undefined);
                          }
                        }}
                      />
                      {showErrors && !selectedQualification && (
                        <span className={styles.error} data-testid="qualification-error">
                          <FormattedMessage
                            id={translationKeys.interpretation_skills_new_skill_qualification_error}
                          />
                        </span>
                      )}
                    </div>

                    <QualificationAccordion selectedSupplierType={skillType} />
                  </>
                )}
                {selectedQualification && shouldShowFileUploader(selectedQualification.id) && (
                  <div>
                    <div className={styles.docContainer}>
                      <h2>
                        <FormattedMessage
                          id={translationKeys.new_skill_drawer_documentation_title}
                        />
                      </h2>
                      <p>
                        <FormattedMessage
                          id={translationKeys.new_skill_drawer_documentation_text}
                        />
                      </p>
                    </div>

                    <div className={styles.uploadFile}>
                      <FileSelector
                        selectedFiles={selectedFiles}
                        multiple
                        onSelection={(newFiles) => {
                          handleFileSelection(newFiles as []);
                        }}
                      />
                      <div className={styles.fileList}>
                        {selectedFiles.map((file: FileState) => {
                          return (
                            <>
                              <DetailItem
                                key={file.id}
                                subtitle={<span>{formatFileSize(file.size)}</span>}
                                title={file.name}
                                size="large"
                                preview={<img src={fileIllustration} height="40" />}
                                onActionSelect={(id) => {
                                  if (id === 'Delete') {
                                    return handleRemoveFile(file.uid);
                                  } else if (id === 'Rename') {
                                    setRenameFileUid(file.uid);
                                    setShowRenameFileModal(true);
                                  }
                                }}
                                actions={[
                                  {
                                    id: 'Delete',
                                    text: intl.formatMessage({
                                      id: translationKeys.new_skill_drawer_file_selector_delete_option,
                                    }),
                                    icon: faTrash,
                                    variant: 'critical',
                                  },
                                  {
                                    id: 'Rename',
                                    text: intl.formatMessage({
                                      id: translationKeys.new_skill_drawer_file_selector_rename_option,
                                    }),
                                    icon: faPen,
                                  },
                                ]}
                              />

                              <RenameFileModal
                                show={renameFileUid === file.uid && showRenameFileModal}
                                file={file}
                                onClose={() => setShowRenameFileModal(false)}
                                handleRenameFileSubmit={handleRenameFileSubmit}
                              />
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}

                {skillType === ManagerSupplierType.Interpreter &&
                  selectedQualification &&
                  shouldShowTolkId(selectedQualification.id) && (
                    <TextField
                      placeholder={intl.formatMessage({
                        id: translationKeys.new_skill_drawer_national_id_input_label,
                      })}
                      label={intl.formatMessage({
                        id: translationKeys.new_skill_drawer_national_id_input_placeholder,
                      })}
                      size="large"
                      name="nationalInterpreterId"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.nationalInterpreterId}
                      errorText={
                        interpretationSkillError
                          ? interpretationSkillError.text.verifiedTolkId
                          : null
                      }
                    />
                  )}

                {skillType !== ManagerSupplierType.Undefined && (
                  <>
                    <div>
                      <SearchDropdown
                        options={skillStatusOptions}
                        placeholder={intl.formatMessage({
                          id: translationKeys.interpretation_skills_new_skill_status_label,
                        })}
                        label={intl.formatMessage({
                          id: translationKeys.interpretation_skills_new_skill_status_label,
                        })}
                        size="large"
                        selected={
                          selectedSkillStatusOption ? [selectedSkillStatusOption] : undefined
                        }
                        onChange={(status) => {
                          if (status && status.length > 0 && status[0].key) {
                            setSelectedSkillStatusOption(status[0]);
                            setSelectedSkillStatus(status[0].key as string);
                          } else {
                            setSelectedSkillStatusOption(undefined);
                            setSelectedSkillStatus(undefined);
                          }
                        }}
                      />
                      {showErrors && !selectedSkillStatus && (
                        <span className={styles.error} data-testid="status-error">
                          <FormattedMessage
                            id={translationKeys.interpretation_skills_new_skill_status_error}
                          />
                        </span>
                      )}
                    </div>

                    {skill.status !== selectedSkillStatus && (
                      <div className={styles.informSupplierCheckbox}>
                        <Form.Check
                          type="checkbox"
                          label={intl.formatMessage({
                            id: translationKeys.edit_skill_drawer_skill_inform_supplier_checkbox_help_text,
                          })}
                          onChange={(e) => setNotifySupplier(e.currentTarget.checked)}
                          data-testid="check-box-status-change"
                        />
                      </div>
                    )}

                    <div className={styles.actions}>
                      <Button
                        size="large"
                        variant="gray"
                        onClick={onClose}
                        data-testid="cancel-button"
                      >
                        <FormattedMessage id={translationKeys.new_skill_drawer_cancel_button} />
                      </Button>

                      <Button
                        size="large"
                        type="submit"
                        data-testid="submit-button"
                        isLoading={
                          skillType === ManagerSupplierType.Translator
                            ? translationSkillLoading
                            : interpretationSkillLoading
                        }
                      >
                        <FormattedMessage
                          id={translationKeys.edit_skill_drawer_skill_save_button}
                        />
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Drawer>
  );
};

export default EditSkillDrawer;
