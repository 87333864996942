import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  faArrowRight,
  faEnvelope,
  faGlobe,
  faPen,
  faPhone,
  faTrash,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActionsSection, Avatar, Badge, Skeleton, Tag, Tooltip } from '@skiwo/components';
import { formatPhoneNumber } from '@skiwo/utils';
import classnames from 'classnames';
import { format } from 'date-fns';
import NotesDrawer from '../../Drawers/NotesDrawer/NotesDrawer';
import EditSkillDrawer from '../../InterpretationSkills/Drawers/EditSkillDrawer/EditSkillDrawer';
import { ConfirmDeleteSkillModal } from '../../InterpretationSkills/Modals/ConfirmDeleteSkillModal/ConfirmDeleteSkillModal';
import { useLanguages } from '../../providers/LanguagesProvider';
import { useQualifications } from '../../providers/QualificationsProvider';
import translationKeys from '../../translations/translationKeys';
import {
  ManagerSupplierType,
  TranslationSkill,
  TranslationSkillAccountStatus,
  TranslationSkillsStatus,
} from '../../types';
import getAccountStatusName from '../utils/getAccountStatusName';
import getStatusName from '../utils/getStatusName';
import styles from './TranslationSkillItem.module.scss';

enum TranslationSkillActions {
  ConfirmDeleteSkill = 'CONFIRM_DELETE_SKILL',
  EditTranslationSkill = 'EDIT_TRANSLATION_SKILL',
}

interface Props {
  skill: TranslationSkill;
  onCompleteAction: (hardReload?: boolean) => void;
}

const TranslationSkillItem = (props: Props) => {
  const { skill, onCompleteAction } = props;
  const intl = useIntl();
  const { getLanguageById, isLoading: languagesLoading } = useLanguages();
  const { getQualificationById, isLoading: QualificationsLoading } = useQualifications();
  const createdAtTimeStamp = new Date(skill.createdAt);
  const [showNotesDrawer, setShowNotesDrawer] = useState(false);
  const [badgeCount, setBadgeCount] = useState(0);
  const [openAction, setOpenAction] = useState<TranslationSkillActions | null>();

  const onAddNote = () => {
    setBadgeCount(badgeCount + 1);
  };

  const onDeleteNote = () => {
    setBadgeCount(badgeCount - 1);
  };

  const handleActionComplete = () => {
    onCompleteAction();
  };

  useEffect(() => {
    setBadgeCount(skill.notesCount);
  }, []);

  const getStatusTagColor = () => {
    if (skill.status === TranslationSkillsStatus.Approved) {
      return 'success';
    }

    if (skill.status === TranslationSkillsStatus.Learning) {
      return 'info';
    }

    if (skill.status === TranslationSkillsStatus.Declined) {
      return 'error';
    }

    return 'neutral';
  };

  const getAccountStatusTagColor = () => {
    if (skill.accountStatus.name === TranslationSkillAccountStatus.Active) {
      return 'success';
    }

    if (skill.accountStatus.name === TranslationSkillAccountStatus.Paused) {
      return 'warning';
    }

    return 'error';
  };

  return (
    <tr className={styles.itemRow} data-testid="translation-skill-item">
      <td className={styles.tableTitleText}>
        <a target="_blank" href={skill.godmodeSupplierUrl} rel="noreferrer" data-testid="skills-id">
          {skill.id}
        </a>
      </td>
      <td data-testid="skills-name-or-email">
        <span className={styles.indicatorIcons}>
          <Tooltip title={skill.person.email} copyable>
            <FontAwesomeIcon
              data-testid="email-indicator"
              icon={faEnvelope}
              className={classnames(styles.icon, { [styles.active]: !!skill.person.email })}
            />
          </Tooltip>

          <Tooltip title={formatPhoneNumber(skill.person.phone)} copyable>
            <FontAwesomeIcon
              data-testid="phone-indicator"
              icon={faPhone}
              className={classnames(styles.icon, { [styles.active]: !!skill.person.phone })}
            />
          </Tooltip>

          <Avatar
            altText={skill.person.name || skill.person.email}
            data-testid="avatar"
            url={skill.person.avatarUrl}
            active={skill.accountStatus.name === TranslationSkillAccountStatus.Active}
          />
          <Tooltip title={skill.person.name || skill.person.email} copyable>
            <span className={styles.tableTitleText}>{skill.person.name || skill.person.email}</span>
          </Tooltip>
        </span>
      </td>
      <td data-testid="skill-status">
        <Tag color={getStatusTagColor()}>{getStatusName(skill.status, intl)}</Tag>
      </td>
      <td>
        <div>
          {languagesLoading ? (
            <Skeleton />
          ) : (
            <>
              <span className={styles.languageInfo}>
                {skill.nativeFrom && (
                  <Tooltip
                    title={intl.formatMessage({
                      id: translationKeys.translation_skills_native_tooltip,
                    })}
                  >
                    <FontAwesomeIcon icon={faGlobe} />
                  </Tooltip>
                )}
                <span className={styles.tableTitleText} data-testid="skills-from-language">
                  {getLanguageById(skill.langFromId)}
                </span>

                <FontAwesomeIcon icon={faArrowRight} className={styles.icon} />

                {skill.nativeTo && (
                  <Tooltip
                    title={intl.formatMessage({
                      id: translationKeys.translation_skills_native_tooltip,
                    })}
                  >
                    <FontAwesomeIcon icon={faGlobe} data-testid="native-language-icon" />
                  </Tooltip>
                )}
                <span className={styles.tableTitleText} data-testid="skills-to-language">
                  {getLanguageById(skill.langToId)}
                </span>
              </span>

              <span className={styles.details}>
                {QualificationsLoading ? (
                  <Skeleton />
                ) : (
                  <span className={styles.qualification} data-testid="skills-qualification-id">
                    {getQualificationById(skill.qualificationId)?.name || '-'}
                  </span>
                )}
              </span>
            </>
          )}
        </div>
      </td>
      <td data-testid="account-status">
        <Tag color={getAccountStatusTagColor()}>
          {getAccountStatusName(skill.accountStatus.name, intl)}
        </Tag>
      </td>
      <td data-testid="skills-created-at">
        <span className={styles.tableTitleText} data-testid="skills-created-date">
          {format(createdAtTimeStamp, 'dd.MM.yyyy')}
        </span>
        <span className={styles.details}>{format(createdAtTimeStamp, 'HH:mm')}</span>
      </td>
      <td>
        <div>
          <Badge
            variant={badgeCount > 0 ? 'active' : 'default'}
            data-testid="badge-button"
            onClick={() => setShowNotesDrawer(true)}
          >
            {badgeCount}
          </Badge>
        </div>

        <NotesDrawer
          onAddNote={onAddNote}
          onDeleteNote={onDeleteNote}
          data-testid="notes-drawer"
          show={showNotesDrawer}
          onClose={() => {
            setShowNotesDrawer(false);
          }}
          user={{
            id: skill.id,
            name: skill.person.name,
            email: skill.person.email,
            phone: skill.person.phone,
            uid: skill.person.uid,
          }}
          note={{ note: skill.note?.note || '', author: skill.note?.authorName }}
          onCancel={() => setShowNotesDrawer(false)}
        />
      </td>

      <td>
        <ActionsSection
          actionItems={[
            {
              title: intl.formatMessage({ id: translationKeys.edit_skill_drawer_tooltip_text }),
              icon: faPen,
              key: TranslationSkillActions.EditTranslationSkill,
              ['data-testid']: 'supplier-edit-action',
            },
            {
              title: skill.hasSubtaskAssignment
                ? intl.formatMessage({
                    id: translationKeys.translation_skills_delete_skill_disabled_tooltip,
                  })
                : intl.formatMessage({ id: translationKeys.skills_delete_skill_tooltip }),
              disabled: skill.hasSubtaskAssignment,
              icon: faTrash,
              key: TranslationSkillActions.ConfirmDeleteSkill,
              ['data-testid']: 'supplier-edit-action',
            },
          ]}
          onActionSelected={(key) => {
            setOpenAction(key as TranslationSkillActions);
          }}
        />

        {openAction === TranslationSkillActions.EditTranslationSkill && (
          <EditSkillDrawer
            show
            onClose={() => setOpenAction(null)}
            skill={skill}
            onComplete={handleActionComplete}
            skillType={ManagerSupplierType.Translator}
          />
        )}

        {openAction === TranslationSkillActions.ConfirmDeleteSkill && (
          <ConfirmDeleteSkillModal
            show
            skill={skill}
            onClose={() => setOpenAction(null)}
            onComplete={onCompleteAction}
            type="translation"
          />
        )}
      </td>
    </tr>
  );
};

export default TranslationSkillItem;
