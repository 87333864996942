enum TranslationSkillsFilterField {
  Id = 'ID',
  Name = 'NAME',
  Languages = 'LANGUAGES',
  Qualifications = 'QUALIFICATIONS',
  CreatedAt = 'CREATED_AT',
  AccountStatuses = 'ACCOUNT_STATUSES',
}

export default TranslationSkillsFilterField;
