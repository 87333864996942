import React from 'react';
import { useIntl } from 'react-intl';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import translationKeys from '../../translations/translationKeys';
import styles from './PaymentRegexHint.module.scss';

interface PaymentRegexHintProps {
  reference: string;
  referenceRegex?: string;
  referenceRegexHint?: string;
  icon: IconProp;
}

export default function PaymentRegexHint({
  reference,
  referenceRegex,
  referenceRegexHint,
  icon,
}: PaymentRegexHintProps) {
  const intl = useIntl();

  return (
    <div className={styles.regexHint}>
      {reference.length > 0 && referenceRegex && (
        <FontAwesomeIcon
          icon={icon}
          className={icon === faInfoCircle ? styles.warning : styles.success}
        />
      )}
      <p className={styles.hintText}>
        {referenceRegexHint ||
          intl.formatMessage({
            id: translationKeys.default_regular_expression_hint,
          })}
      </p>
    </div>
  );
}
