import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Drawer, TextField } from '@skiwo/components';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useApi } from '../../../../providers/ApiProvider';
import { useToast } from '../../../../providers/ToastProvider/ToastProvider';
import translationKeys from '../../../../translations/translationKeys';
import styles from './NewResponseDrawer.module.scss';

interface FormValues {
  name: string;
  title: string;
  message: string;
}

interface Props {
  show?: boolean;
  onClose: () => void;
  onBack: () => void;
}

const NewResponseDrawer = (props: Props) => {
  const { show, onClose, onBack } = props;
  const api = useApi();
  const intl = useIntl();
  const [createResponseLoading, setCreateResponseLoading] = useState(false);
  const { showErrorToast } = useToast();

  const schema = yup.object().shape({
    name: yup.string().required(
      intl.formatMessage(
        { id: translationKeys.form_error_required },
        {
          fieldName: intl.formatMessage({
            id: translationKeys.translation_order_new_response_name_label,
          }),
        },
      ),
    ),
    title: yup.string().required(
      intl.formatMessage(
        { id: translationKeys.form_error_required },
        {
          fieldName: intl.formatMessage({
            id: translationKeys.translation_order_new_response_title_label,
          }),
        },
      ),
    ),
    message: yup.string().required(
      intl.formatMessage(
        { id: translationKeys.form_error_required },
        {
          fieldName: intl.formatMessage({
            id: translationKeys.translation_order_new_response_message_label,
          }),
        },
      ),
    ),
  });

  const handleSubmit = async (values: FormValues) => {
    const { error } = await api.createSavedResponse(
      {
        name: values.name,
        emailTitle: values.title,
        emailContent: values.message,
      },
      setCreateResponseLoading,
    );

    if (error) {
      showErrorToast(error);
    } else {
      onBack();
    }
  };

  return (
    <Drawer
      show={show}
      onClose={onClose}
      title={intl.formatMessage({ id: translationKeys.translation_order_new_response_label })}
      data-testid="new-responses-drawer"
      onBack={onBack}
      backText={intl.formatMessage({
        id: translationKeys.translation_order_new_response_go_back_button,
      })}
    >
      <Formik<FormValues>
        initialValues={{ name: '', title: '', message: '' }}
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        {({ handleSubmit, handleChange, handleBlur, touched, errors }) => (
          <Form className={styles.sendSavedResponse} onSubmit={handleSubmit}>
            <TextField
              label={intl.formatMessage({
                id: translationKeys.translation_order_new_response_name_label,
              })}
              placeholder={intl.formatMessage({
                id: translationKeys.translation_order_new_response_name_placeholder,
              })}
              required
              size="large"
              onChange={handleChange}
              onBlur={handleBlur}
              name="name"
              errorText={touched.name ? errors.name : undefined}
            />

            <TextField
              label={intl.formatMessage({
                id: translationKeys.translation_order_new_response_title_label,
              })}
              placeholder={intl.formatMessage({
                id: translationKeys.translation_order_new_response_title_placeholder,
              })}
              required
              size="large"
              onChange={handleChange}
              onBlur={handleBlur}
              name="title"
              errorText={touched.title ? errors.title : undefined}
            />

            <TextField
              label={intl.formatMessage({
                id: translationKeys.translation_order_new_response_message_label,
              })}
              placeholder={intl.formatMessage({
                id: translationKeys.translation_order_new_response_message_placeholder,
              })}
              required
              size="large"
              textArea
              onChange={handleChange}
              onBlur={handleBlur}
              name="message"
              errorText={touched.message ? errors.message : undefined}
            />

            <div className={styles.actions}>
              <Button variant="gray" size="large" onClick={onClose}>
                <FormattedMessage
                  id={translationKeys.translation_order_new_response_cancel_button}
                />
              </Button>
              <Button size="large" type="submit" isLoading={createResponseLoading}>
                <FormattedMessage id={translationKeys.translation_order_new_response_save_button} />
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Drawer>
  );
};

export default NewResponseDrawer;
