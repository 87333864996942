import { ManagerOrderSubtaskStatus } from './ManagerOrderSubtaskDetail';

export enum ManagerOrderCustomerInput {
  Requested = 'requested',
  Updated = 'updated',
}

export enum ManagerOrderStatus {
  Draft = 'draft',
  QuoteRequested = 'quote_requested',
  QuoteSent = 'quote_sent',
  QuoteAccepted = 'quote_accepted',
  QuoteRejected = 'quote_rejected',
  InProgress = 'in_progress',
  Finished = 'finished',
  Completed = 'completed',
  Cancelled = 'cancelled',
}

export enum ManagerOrderSourceType {
  Platform = 'platform',
  ProjectEmail = 'project_email',
  OversettelseEmail = 'oversettelse_email',
  PostEmail = 'post_email',
  Post = 'post',
  PersonalEmail = 'personal_email',
  CopiedFromXtrf = 'copied_from_xtrf',
  Logout = 'logout',
}

export enum ManagerOrderDeliveryMethod {
  Email = 'email',
  Post = 'post',
}

export enum ManagerOrderPaymentStatus {
  NotReadyForFinance = 'not_ready_for_finance',
  ReadyForFinance = 'ready_for_finance',
  PaymentDownloaded = 'payment_downloaded',
}

export enum ManagerOrderCoverLetterStatus {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
}

export enum ManagerOrderAccessLevel {
  Owner = 'owner',
  Department = 'department',
  Enterprise = 'enterprise',
}

export enum ManagerOrderCalledBy {
  Customer = 'customer',
  Platform = 'platform',
}

export enum ManagerOrderInvoiceGroupStrategy {
  PerJob = 'per_job',
  PerDepartment = 'per_department',
  PerCustomer = 'per_customer',
  PerOrderReference = 'per_order_reference',
  PerBuyerReference = 'per_buyer_reference',
  PerBuyerOrderAndReference = 'per_buyer_order_and_reference',
  PerBooker = 'per_booker',
}

export enum ManagerOrderInvoiceFrequency {
  Daily = 'daily',
  Weekly = 'weekly',
  Fortnightly = 'fortnightly',
}

export enum ManagerOrderInvoiceDeliveryMechanism {
  Ehf = 'ehf',
  Email = 'email',
}

export enum ManagerOrderMethodType {
  Organization = 'organization',
  Individual = 'individual',
}

export enum ManagerOrderTaskStatus {
  Draft = 'draft',
  Published = 'published',
  Cancelled = 'cancelled',
  Finished = 'finished',
}

export enum ManagerOrderSubtaskType {
  Translation = 'translation',
  Correction = 'correction',
  Other = 'other',
  InPerson = 'in_person',
}

export interface ManagerOrderSubtask {
  id: number;
  publicId: string;
  status: ManagerOrderSubtaskStatus;
  subtaskType: ManagerOrderSubtaskType;
  seller?: {
    name: string;
    avatar?: string;
    uid: string;
  };
}

export interface ManagerOrderTask {
  id: number;
  publicId?: string;
  orderId: number;
  targetLanguageId: number;
  sourceLanguageId: number;
  status: ManagerOrderTaskStatus;
  sequenceNumber: number;
  archived: boolean;
  finishedEmailSent: boolean;
  createdAt: string;
  updatedAt: string;
  subtasks?: ManagerOrderSubtask[];
}

export interface CreateManagerOrder {
  ownerUid: string;
  departmentId?: string;
  realOwnerName?: string;
  paymentMethodId: string;
  bookingReference?: string;
  paymentBookingReference?: string;
  caseNumber?: string;
  deliveryMethod: ManagerOrderDeliveryMethod;
  sendByPostAddressIncludedInCoverLetter?: boolean;
  sendByPostAddress?: string;
  sendByPostEnterpriseName?: string;
  sendByPostName?: string;
  coverLetters?: any;
  bookedSubtaskType: ManagerOrderSubtaskType;
  sourceLanguageId?: string;
  targetLanguageIds: string[];
  alternativeTargetLanguageId?: string;
  countOfWords?: string;
  externalDeadline: string | null;
  translationMethodId?: string;
  otherSubject?: string;
  instructionsFromDemander?: string;
  attachments?: any;
  ccEmails?: string[];
  accessLevel?: ManagerOrderAccessLevel;
}

interface ManagerOrder {
  id: number;
  ownerId?: number;
  projectManagerId?: number;
  enterpriseId?: number;
  departmentId?: number;
  paymentMethodId?: number;
  sourceLanguageId: number;
  countOfWords?: number;
  status: ManagerOrderStatus;
  demanderQuoteAmount?: number;
  bookingReference?: string;
  paymentBookingReference?: string;
  caseNumber?: string;
  name: string;
  instructionsFromDemander?: string;
  instructionsFromProjectManagerToSeller?: string;
  demanderFeedback?: string;
  externalDeadline?: string;
  createdAt: string;
  updatedAt: string;
  clientId?: string;
  clientName?: string;
  closedAt?: string;
  requirementIdempotencyKey?: string;
  sentByPost: boolean;
  sourceType: ManagerOrderSourceType;
  isLoggedOut: boolean;
  isXtrf: boolean;
  loggedOutBookerEmail?: string;
  loggedOutBookerFirstName?: string;
  loggedOutBookerLastName?: string;
  loggedOutBookerPhoneCode?: string;
  loggedOutBookerPhoneNumber?: string;
  loggedOutBookerOrgNumber?: string;
  loggedOutBookerOrgName?: string;
  loggedOutBookerPaymentOrgNumber?: string;
  loggedOutBookerPaymentOrgName?: string;
  internalNote?: string;
  deliveryMethod?: ManagerOrderDeliveryMethod;
  sendByPostName?: string;
  sendByPostEnterpriseName?: string;
  sendByPostAddress?: string;
  paymentStatus: ManagerOrderPaymentStatus;
  coverLetterStatus?: ManagerOrderCoverLetterStatus;
  coverLetterRejectedEmailSent: boolean;
  showOcrAttachmentsToSeller: boolean;
  alternativeEnglishTargetLanguageAllowed?: boolean;
  alternativeTargetLanguageId?: number;
  quoteSentEmailSent: boolean;
  accessLevel: ManagerOrderAccessLevel;
  ccEmails?: string[];
  externalDeadlineChangedEmailSent: boolean;
  originalOrderId?: number;
  otherSubject?: string;
  cancelledBy?: ManagerOrderCalledBy;
  cancellationReasonText?: string;
  customerInputRequested?: boolean;
  customerInputStatus?: ManagerOrderCustomerInput | null;
  customerInputUpdatedAt?: string;
  ticketId?: number;
  copiesIds?: number[];
  adminDiscussionId?: number;
  targetLanguagesIds?: number[];
  translationMethodId?: number;
  emails?: {
    cancelledEmailSentAt: string;
    ccFinishedEmailSentAt?: string;
    finishedEmailSentAt?: string;
    inProgressEmailSentAt?: string;
    postedEmailSentAt: string;
    sentByPostEmailSentAt?: string;
  };
  owner?: {
    name?: string;
    phone?: string;
    phoneCode?: string;
    phoneNumber?: string;
    email: string;
    uid: string;
    avatar?: string;
  };
  enterprise?: {
    id?: number;
    name?: string;
    orgNumber?: string;
    isPolice?: boolean;
    logoUrl?: string;
  };
  department?: {
    id?: number;
    name?: string;
    email?: string;
  };
  paymentMethod?: {
    id?: number;
    archived?: boolean;
    orgNumber?: string;
    orgName?: string;
    email?: string;
    invoiceGroupStrategy?: ManagerOrderInvoiceGroupStrategy;
    invoiceFrequency?: ManagerOrderInvoiceFrequency;
    invoiceDeliveryMechanizm?: ManagerOrderInvoiceDeliveryMechanism;
    createdAt?: string;
    updatedAt?: string;
    methodType?: ManagerOrderMethodType;
    address?: string;
  };
  projectManager?: {
    name?: string;
    avatar?: string;
    phone?: string;
  };
  logoutEstimatedQuote?: {
    amount?: string;
    frameAgreement?: boolean;
    visibleForCustomer?: boolean;
  };
  tasks: ManagerOrderTask[];
  referenceSettings: {
    allowCaseNumber: boolean;
    requireCaseNumber: boolean;
    labelForCaseNumber: string;
    allowBookingReference: boolean;
    requireBookingReference: boolean;
    labelForBookingReference?: string;
    allowPaymentBookingReference: boolean;
    requirePaymentBookingReference: boolean;
    labelForPaymentBookingReference?: string;
    defaultBookingReference?: string;
    defaultPaymentBookingReference?: string;
    allowClientName: boolean;
    allowClientId: boolean;
  };
}

export interface ManagerOrderResponse {
  order: ManagerOrder;
}

export default ManagerOrder;
