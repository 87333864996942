import { IntlShape } from 'react-intl';
import translationKeys from '../../../../../translations/translationKeys';

export const getWaitingForCustomerTranslation = ({
  value,
  intl,
}: {
  value?: string;
  intl: IntlShape;
}) => {
  if (value === 'requested' || value === 'updated') {
    return intl.formatMessage({
      id: translationKeys.translation_order_system_logs_on_value,
    });
  }
  if (value === '-') {
    return intl.formatMessage({
      id: translationKeys.translation_order_system_logs_off_value,
    });
  }
  return '-';
};
