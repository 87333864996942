import React, { useState } from 'react';
import { faChevronDown, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './Spoiler.module.scss';

interface Props {
  title: string;
  children: React.ReactNode;
}

const Spoiler = (props: Props) => {
  const { children, title } = props;

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={styles.accordion}>
      <div className={styles.accordionHeader} onClick={() => setIsOpen(!isOpen)}>
        <span>
          <FontAwesomeIcon icon={isOpen ? faChevronDown : faChevronRight} />
        </span>
        <h1>{title}</h1>
      </div>
      {isOpen && <div className={styles.accordionBody}>{children}</div>}
    </div>
  );
};

export default Spoiler;
