import { IntlShape } from 'react-intl';
import translationKeys from '../../translations/translationKeys';
import { ManagerOrderSubtaskType } from '../../types/ManagerOrder';

const getSubtaskTypeName = (status: ManagerOrderSubtaskType, intl: IntlShape): string => {
  if (status === ManagerOrderSubtaskType.Translation) {
    return intl.formatMessage({
      id: translationKeys.translation_order_subtask_type_translation,
    });
  }

  if (status === ManagerOrderSubtaskType.Correction) {
    return intl.formatMessage({
      id: translationKeys.translation_order_subtask_type_correction,
    });
  }

  if (status === ManagerOrderSubtaskType.InPerson) {
    return intl.formatMessage({
      id: translationKeys.translation_order_subtask_type_in_person,
    });
  }

  if (status === ManagerOrderSubtaskType.Other) {
    return intl.formatMessage({
      id: translationKeys.translation_order_subtask_type_other,
    });
  }

  return '-';
};

export default getSubtaskTypeName;
