import { IntlShape } from 'react-intl';
import translationKeys from '../../translations/translationKeys';
import { ManagerOrderStatus } from '../../types';

const getOrderStatusName = (status: ManagerOrderStatus, intl: IntlShape): string => {
  if (status === ManagerOrderStatus.Draft) {
    return intl.formatMessage({
      id: translationKeys.translation_order_order_status_draft,
    });
  }

  if (status === ManagerOrderStatus.QuoteRequested) {
    return intl.formatMessage({
      id: translationKeys.translation_order_order_status_quote_requested,
    });
  }

  if (status === ManagerOrderStatus.QuoteSent) {
    return intl.formatMessage({
      id: translationKeys.translation_order_order_status_quote_sent,
    });
  }

  if (status === ManagerOrderStatus.QuoteAccepted) {
    return intl.formatMessage({
      id: translationKeys.translation_order_order_status_accepted,
    });
  }

  if (status === ManagerOrderStatus.QuoteRejected) {
    return intl.formatMessage({
      id: translationKeys.translation_order_order_status_quote_rejected,
    });
  }

  if (status === ManagerOrderStatus.InProgress) {
    return intl.formatMessage({
      id: translationKeys.translation_order_order_status_in_progress,
    });
  }

  if (status === ManagerOrderStatus.Finished) {
    return intl.formatMessage({
      id: translationKeys.translation_order_order_status_finished,
    });
  }

  if (status === ManagerOrderStatus.Completed) {
    return intl.formatMessage({
      id: translationKeys.translation_order_order_status_completed,
    });
  }

  if (status === ManagerOrderStatus.Cancelled) {
    return intl.formatMessage({
      id: translationKeys.translation_order_order_status_cancelled,
    });
  }

  return '-';
};

export default getOrderStatusName;
