import { FormikProps } from 'formik';

type UpdateField<Schema> = <T extends keyof Schema>(field: T, value: Schema[T]) => void;

export const getTypesafeSetFieldValue = <Schema>(
  formikProps: FormikProps<Schema>,
): UpdateField<Schema> => {
  return (field, value) => {
    if (typeof field !== 'string') return;

    formikProps.setFieldValue(field, value);
  };
};
