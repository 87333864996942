import React from 'react';
import { Badge as B_Badge } from 'react-bootstrap';
import classnames from 'classnames';
import styles from './Badge.module.scss';

interface Props {
  children: React.ReactNode;
  variant?: 'default' | 'disabled' | 'active';
  'data-testid'?: string;
  onClick?: () => void;
}

const Badge = (props: Props) => {
  const { children, variant = 'default', onClick } = props;

  return (
    <B_Badge
      bg=""
      className={classnames(styles.badge, styles[variant], {
        [styles['pointer-cursor']]: onClick,
      })}
      onClick={onClick}
      data-testid={props['data-testid']}
    >
      <span data-testid="badge-text">{children}</span>
    </B_Badge>
  );
};

export default Badge;
