import { IntlShape } from 'react-intl';
import translationKeys from '../../translations/translationKeys';
import { ManagerSupplierAccountStatus } from '../../types';

const getStatusName = (status: ManagerSupplierAccountStatus, intl: IntlShape): string => {
  if (status === ManagerSupplierAccountStatus.Active) {
    return intl.formatMessage({ id: translationKeys.suppliers_page_active_status });
  }

  if (status === ManagerSupplierAccountStatus.Banned) {
    return intl.formatMessage({ id: translationKeys.suppliers_page_banned_status });
  }

  if (status === ManagerSupplierAccountStatus.Blocked) {
    return intl.formatMessage({ id: translationKeys.suppliers_page_blocked_status });
  }

  if (status === ManagerSupplierAccountStatus.Deceased) {
    return intl.formatMessage({ id: translationKeys.suppliers_page_deceased_status });
  }

  if (status === ManagerSupplierAccountStatus.Deleted) {
    return intl.formatMessage({ id: translationKeys.suppliers_page_deleted_status });
  }

  if (status === ManagerSupplierAccountStatus.Paused) {
    return intl.formatMessage({ id: translationKeys.suppliers_page_paused_status });
  }

  if (status === ManagerSupplierAccountStatus.Retired) {
    return intl.formatMessage({ id: translationKeys.suppliers_page_retired_status });
  }

  return '-';
};

export default getStatusName;
