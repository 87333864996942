import { ManagerCustomerType } from '../../types';
import CustomersFilterField from '../CustomersFilterField';

const getCustomerFilters = (
  currentFilters: Record<string, string>,
  field: CustomersFilterField,
  value: string,
): Record<string, string> => {
  if (field === CustomersFilterField.Id) {
    return { ...currentFilters, ...{ 's[id_eq]': value } };
  }

  if (field === CustomersFilterField.Type) {
    if (value === ManagerCustomerType.Private) {
      return {
        ...currentFilters,
        ...{
          's[buyer_id_not_null]': '1',
          's[enterprise_membership_id_null]': '1',
          's[enterprise_membership_id_not_null]': '',
          's[buyer_id_null]': '',
        },
      };
    }

    if (value === ManagerCustomerType.Enterprise) {
      return {
        ...currentFilters,
        ...{
          's[enterprise_membership_id_not_null]': '1',
          's[buyer_id_not_null]': '',
          's[enterprise_membership_id_null]': '',
          's[buyer_id_null]': '',
        },
      };
    }

    if (value === ManagerCustomerType.Undefined) {
      return {
        ...currentFilters,
        ...{
          's[enterprise_membership_id_not_null]': '',
          's[buyer_id_not_null]': '',
          's[enterprise_membership_id_null]': '1',
          's[buyer_id_null]': '1',
        },
      };
    }

    return {
      ...currentFilters,
      ...{
        's[enterprise_membership_id_not_null]': '',
        's[buyer_id_not_null]': '',
        's[enterprise_membership_id_null]': '',
        's[buyer_id_null]': '',
      },
    };
  }

  if (field === CustomersFilterField.NameEmailPhone) {
    let filterValue = value;
    const isPhone = value.startsWith('+47');

    if (isPhone) {
      filterValue = value.replaceAll(' ', '');
    }

    return { ...currentFilters, ...{ 's[lookup_fields_cont]': filterValue } };
  }

  if (field === CustomersFilterField.OrgNumberName) {
    return {
      ...currentFilters,
      ...{ 's[enterprise_cont]': value },
    };
  }

  if (field === CustomersFilterField.Departments) {
    return { ...currentFilters, ...{ 's[departments_cont]': value } };
  }

  if (field === CustomersFilterField.Status) {
    return {
      ...currentFilters,
      ...{ 's[account_status_name_eq]': value },
    };
  }

  return currentFilters;
};

export default getCustomerFilters;
