import React, { useState } from 'react';
import { Form as B_Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { faSlidersUp, faTriangleExclamation, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  IconButton,
  SearchDropdown,
  SearchDropdownMenuOption,
  Tag,
  TextField,
} from '@skiwo/components';
import { FormikProps } from 'formik';
import { emailRegex } from '../../helpers/regexPatterns';
import getAccessLevelName from '../../InterpretationSkills/utils/getAccessLevelName';
import translationKeys from '../../translations/translationKeys';
import { ManagerOrderAccessLevel } from '../../types';
import { CreateTranslationOrderFormValues } from '../CreateTranslationOrder';
import SectionContainer from '../SectionContainer/SectionContainer';
import SettingsItem from './SettingsItem/SettingsItem';
import styles from './SettingsSection.module.scss';

interface SettingsSectionProps {
  formikProps: FormikProps<CreateTranslationOrderFormValues>;
  ccEmailsActive: boolean;
  setCcEmailsActive: (value: boolean) => void;
  accessLevelActive: boolean;
  setAccessLevelActive: (value: boolean) => void;
}

const SettingsSection = ({
  formikProps,
  ccEmailsActive,
  setCcEmailsActive,
  accessLevelActive,
  setAccessLevelActive,
}: SettingsSectionProps) => {
  const intl = useIntl();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);

  const addEmail = () => {
    if (formikProps.values.ccEmails.includes(email)) return;

    if (!email.match(emailRegex)) {
      setEmailError(true);
    } else {
      if (emailError) {
        setEmailError(false);
      }
      if (email.length) {
        formikProps.setFieldValue('ccEmails', [...formikProps.values.ccEmails, email]);
        setEmail('');
      }
    }
  };

  const accessLevelOptions = [
    {
      id: 1,
      key: ManagerOrderAccessLevel.Owner,
      label: getAccessLevelName(ManagerOrderAccessLevel.Owner, intl),
    },
    {
      id: 2,
      key: ManagerOrderAccessLevel.Department,
      label: getAccessLevelName(ManagerOrderAccessLevel.Department, intl),
    },
    {
      id: 3,
      key: ManagerOrderAccessLevel.Enterprise,
      label: getAccessLevelName(ManagerOrderAccessLevel.Enterprise, intl),
    },
  ];

  return (
    <SectionContainer
      icon={<FontAwesomeIcon icon={faSlidersUp} />}
      title={intl.formatMessage({
        id: translationKeys.create_translation_order_settings_label,
      })}
      optional
      data-testid="settings-section"
    >
      <div className={styles.settingsSection}>
        <SettingsItem
          title={intl.formatMessage({
            id: translationKeys.create_translation_order_settings_cc_label,
          })}
          description={intl.formatMessage({
            id: translationKeys.create_translation_order_settings_cc_description,
          })}
          action={
            <B_Form.Check
              type="switch"
              checked={ccEmailsActive}
              onChange={(e) => setCcEmailsActive(e.target.checked)}
              data-testid="check-cc-switch"
            />
          }
        >
          {ccEmailsActive && (
            <div className={styles.sectionItemContent}>
              <B_Form.Label>
                <FormattedMessage
                  id={translationKeys.create_translation_order_settings_cc_email_label}
                />
              </B_Form.Label>
              <div className={styles.emailInputRow}>
                <div className={styles.emailInput}>
                  <TextField
                    placeholder={intl.formatMessage({
                      id: translationKeys.create_translation_order_settings_cc_email_placeholder,
                    })}
                    size="large"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    data-testid="email"
                  />
                </div>
                <Button
                  variant="gray"
                  size="large"
                  data-testid="add-cc-button"
                  onClick={() => {
                    addEmail();
                  }}
                >
                  <FormattedMessage
                    id={translationKeys.create_translation_order_settings_cc_email_add_button}
                  />
                </Button>
              </div>
              {emailError && (
                <B_Form.Text className={styles.errorMessage}>
                  <span>
                    <FontAwesomeIcon icon={faTriangleExclamation} />
                  </span>
                  <span data-testid="textfield-error-text">
                    <FormattedMessage
                      id={translationKeys.form_error_not_valid}
                      values={{
                        fieldName: intl.formatMessage({
                          id: translationKeys.create_translation_order_settings_cc_email_label,
                        }),
                      }}
                    />
                  </span>
                </B_Form.Text>
              )}
              <div className={styles.emailList}>
                {formikProps.values.ccEmails.map((email) => {
                  return (
                    <Tag key={email} variant="square" color="neutral">
                      <div className={styles.emailItem}>
                        {email}
                        <IconButton
                          variant="link"
                          icon={<FontAwesomeIcon icon={faXmark} />}
                          size="small"
                          onClick={() => {
                            formikProps.setFieldValue(
                              'ccEmails',
                              formikProps.values.ccEmails.filter((item) => item !== email),
                            );
                          }}
                        />
                      </div>
                    </Tag>
                  );
                })}
              </div>
            </div>
          )}
        </SettingsItem>
        <SettingsItem
          title={intl.formatMessage({
            id: translationKeys.create_translation_order_settings_access_level_label,
          })}
          description={intl.formatMessage({
            id: translationKeys.create_translation_order_settings_access_level_description,
          })}
          action={
            <B_Form.Check
              type="switch"
              checked={accessLevelActive}
              data-testid="check-access-level-switch"
              onChange={(e) => {
                setAccessLevelActive(e.target.checked);
                if (!e.target.checked) {
                  formikProps.setFieldValue('accessLevel', undefined);
                } else {
                  formikProps.setFieldValue('accessLevel', ManagerOrderAccessLevel.Owner);
                }
              }}
            />
          }
        >
          {accessLevelActive && (
            <div className={styles.sectionItemContent}>
              <div className={styles.accessLevel}>
                <SearchDropdown
                  label={intl.formatMessage({
                    id: translationKeys.create_translation_order_settings_access_level_selector_label,
                  })}
                  size="large"
                  data-testid="access-level-dropdown"
                  selected={
                    formikProps.values.accessLevel
                      ? [
                          accessLevelOptions.find(
                            (item) => item.key === formikProps.values.accessLevel,
                          ) as SearchDropdownMenuOption,
                        ]
                      : undefined
                  }
                  hideClearSelected
                  options={accessLevelOptions}
                  onChange={(items: SearchDropdownMenuOption[] | null) => {
                    if (items && items.length > 0) {
                      formikProps.setFieldValue('accessLevel', items[0].key);
                    }
                  }}
                />
              </div>
            </div>
          )}
        </SettingsItem>
      </div>
    </SectionContainer>
  );
};

export default SettingsSection;
