import React from 'react';
import { Breadcrumb as B_Breadcrumb } from 'react-bootstrap';

interface BreadcrumbItemProps {
  id: string;
  title: React.ReactNode;
  active?: boolean;
  href?: string;
}

interface BreadcrumbsProps {
  items: BreadcrumbItemProps[];
}

const Breadcrumbs = (props: BreadcrumbsProps) => {
  const { items } = props;
  return (
    <B_Breadcrumb data-testid="breadcrumb">
      {items.map((breadcrumb) => {
        return (
          <B_Breadcrumb.Item key={breadcrumb.id} href={breadcrumb.href} active={breadcrumb.active}>
            {breadcrumb.title}
          </B_Breadcrumb.Item>
        );
      })}
    </B_Breadcrumb>
  );
};

export default Breadcrumbs;
