import React from 'react';
import { useIntl } from 'react-intl';
import { faBuilding, faEnvelope, faPhone } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@skiwo/components';
import translationKeys from '../../../translations/translationKeys';
import { ManagerJobDetails } from '../../../types/ManagerJob';
import Card from './Card/Card';

interface CustomerCardProps {
  job: ManagerJobDetails;
}

const CustomerCard = ({ job }: CustomerCardProps) => {
  const titleIcon = <FontAwesomeIcon icon={faBuilding} />;
  const intl = useIntl();

  return (
    <Card
      titleIcon={titleIcon}
      title={intl.formatMessage({
        id: translationKeys.job_customer_label,
      })}
      emptyText={intl.formatMessage({
        id: translationKeys.job_customer_empty_text,
      })}
    >
      {job.owner?.name && (
        <Card.BodyItem
          title={intl.formatMessage({
            id: translationKeys.job_customer_name_label,
          })}
        >
          {job.owner.name}
        </Card.BodyItem>
      )}
      {job.attendees && job.attendees.length > 0 && (
        <Card.BodyItem
          title={intl.formatMessage({
            id: translationKeys.job_customer_attendees_label,
          })}
        >
          {job.attendees.map((attendee) => (
            <Card.ItemRow key={attendee.uid}>
              {attendee.phone && (
                <Tooltip title={attendee.phone} copyable>
                  <FontAwesomeIcon icon={faPhone} />
                </Tooltip>
              )}
              {attendee.email && (
                <Tooltip title={attendee.email} copyable>
                  <FontAwesomeIcon icon={faEnvelope} />
                </Tooltip>
              )}
              {attendee.name}
            </Card.ItemRow>
          ))}
        </Card.BodyItem>
      )}
      {job.info.ccEmails && job.info.ccEmails.length > 0 && (
        <Card.BodyItem
          title={intl.formatMessage({
            id: translationKeys.job_customer_cc_label,
          })}
        >
          {job.info.ccEmails.map((email) => (
            <Card.ItemRow key={email}>{email}</Card.ItemRow>
          ))}
        </Card.BodyItem>
      )}
    </Card>
  );
};

export default CustomerCard;
