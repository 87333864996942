import { IntlShape } from 'react-intl';
import translationKeys from '../../translations/translationKeys';
import { TranslationSkillsStatus } from '../../types';

const getStatusName = (status: TranslationSkillsStatus, intl: IntlShape): string => {
  if (status === TranslationSkillsStatus.Approved) {
    return intl.formatMessage({
      id: translationKeys.translation_skills_status_approved,
    });
  }

  if (status === TranslationSkillsStatus.Declined) {
    return intl.formatMessage({
      id: translationKeys.translation_skills_status_declined,
    });
  }

  if (status === TranslationSkillsStatus.Learning) {
    return intl.formatMessage({
      id: translationKeys.translation_skills_status_learning,
    });
  }

  if (status === TranslationSkillsStatus.Pending) {
    return intl.formatMessage({
      id: translationKeys.translation_skills_status_pending,
    });
  }

  return '-';
};

export default getStatusName;
