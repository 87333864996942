import {
  Auth,
  Customers,
  Enterprises,
  Finance,
  Interpreters,
  Jobs,
  Language,
  People,
  Skills,
  Suppliers,
  Support,
  System,
  Translations,
} from './Endpoints';

export const Api = {
  ...Auth,
  ...Customers,
  ...Enterprises,
  ...Finance,
  ...Interpreters,
  ...Jobs,
  ...Language,
  ...People,
  ...Skills,
  ...Suppliers,
  ...Support,
  ...System,
  ...Translations,
};

export type ApiDefinition = typeof Api;

export { type ApiError } from './fetchApi';

export default Api;
