import React, { useEffect, useRef, useState } from 'react';
import { Menu, MenuItem, Typeahead, TypeaheadRef } from 'react-bootstrap-typeahead';
import {
  Option,
  TypeaheadInputProps,
  TypeaheadPropsAndState,
} from 'react-bootstrap-typeahead/types/types';
import { createIntl } from 'react-intl';
import {
  faClock,
  faMagnifyingGlass as faMagnifyingGlassLight,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getLanguage } from '@skiwo/utils';
import classnames from 'classnames';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import TextField from '../TextField/TextField';
import languages from '../translations/languages';
import translationKeys from '../translations/translationKeys';
import styles from './TimePicker.module.scss';

export interface TimePickerItem {
  id: number;
  label: string;
  key?: string | number;
  hour: string;
  minute: string;
}

interface Props {
  size?: 'medium' | 'large';
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  label?: string;
  selected?: TimePickerItem[];
  hint?: string;
  onChange?: (item: TimePickerItem[] | null) => void;
  errorText?: string;
  isInvalid?: boolean;
  interval?: number;
  'data-testid'?: string;
  rangeStart?: {
    hour: string;
    minute: string;
  };
}

const TimePicker = ({
  size = 'large',
  placeholder,
  onChange,
  label,
  disabled = false,
  required = false,
  selected,
  hint,
  errorText,
  isInvalid = false,
  interval = 5,
  'data-testid': dataTestId,
  rangeStart,
}: Props) => {
  const inputRef = useRef<TypeaheadRef>(null);
  const userLanguage = getLanguage();
  const [selectedItems, setSelectedItems] = useState<TimePickerItem[]>(selected || []);
  const [prevItems, setPrevItems] = useState<TimePickerItem[]>(selected || []);
  const intl = createIntl({
    locale: userLanguage,
    messages: languages[userLanguage],
  });

  const generateTimeOptions = (): TimePickerItem[] => {
    const options: TimePickerItem[] = [];

    const startTime = rangeStart ?? { hour: '00', minute: '00' };

    const startMinute = parseInt(startTime.hour) * 60 + parseInt(startTime.minute);
    const startMinuteRounded = Math.ceil(startMinute / interval) * interval;

    for (let i = startMinuteRounded; i < 24 * 60; i += interval) {
      const hours = Math.floor(i / 60);
      const minutes = i % 60;
      const label = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

      options.push({
        id: i,
        label: label,
        key: i,
        hour: hours.toString().padStart(2, '0'),
        minute: minutes.toString().padStart(2, '0'),
      });
    }

    return options;
  };

  const filterBy = (option: any, state: TypeaheadPropsAndState) => {
    return (
      option.label.toLowerCase().indexOf(state.text.toLowerCase()) !== -1 ||
      option.label.toLowerCase().indexOf(state.text.toLowerCase().replace(/\s/g, '')) !== -1
    );
  };

  const handleOnChange = (selected: TimePickerItem[]) => {
    if (onChange) {
      setPrevItems(selected);
      setSelectedItems(selected);
      onChange(selected);
      inputRef.current?.blur();
    }
  };

  useEffect(() => {
    setPrevItems(selected || []);
    setSelectedItems(selected || []);
  }, [selected]);

  const renderMenu = (results: Option[]) => {
    const options = results as TimePickerItem[];

    const menuContent = options.map((item: TimePickerItem) => (
      <div key={item.id}>
        <MenuItem
          option={item}
          position={item.id}
          className={styles.menuItem}
          data-testid="dropdown-menu-item"
        >
          <li key={item.id}>{item.label}</li>
        </MenuItem>
      </div>
    ));

    const renderEmptyState = () => {
      return (
        <div className={styles.noItems} data-testid="empty-state-section">
          <FontAwesomeIcon className={styles.icon} icon={faMagnifyingGlassLight} />
          <span className={styles.description}>
            {intl.formatMessage({ id: translationKeys.search_dropdown_empty_items_state })}
          </span>
        </div>
      );
    };

    return (
      <Menu id="menu" className={styles.dropdownMenu} data-testid="dropdown-menu">
        <div
          className={classnames(styles.dropdownContent, {
            [styles.hasSelection]: selectedItems.length > 0,
          })}
        >
          {menuContent.length ? menuContent : renderEmptyState()}
        </div>
      </Menu>
    );
  };

  const renderInput = (inputProps: TypeaheadInputProps) => {
    const inputPlaceholder = inputProps.placeholder;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { type, inputRef, referenceElementRef, ...modifiedProps } = inputProps;

    return (
      <div className={styles.textField}>
        <TextField
          size={size}
          {...modifiedProps}
          ref={inputRef}
          placeholder={inputPlaceholder}
          hint={hint}
          icon={<FontAwesomeIcon icon={faClock} />}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value;
            const regex = [
              /^([0-2])$/, // First character: 0, 1, or 2
              /^([0-1][0-9]|2[0-3])$/, // Second character: 00-19 or 20-24
              /^([0-1][0-9]|2[0-4]):$/, // Third character must be a colon after HH
              /^([0-1][0-9]|2[0-4]):[0-5]$/, // Fourth character: 0-5 after HH:
              /^([0-1][0-9]|2[0-4]):[0-5][0-9]$/, // Fifth character: 0/5 after HH:m
            ];

            const currentLength = value.length;

            if (
              value === '' ||
              (currentLength <= 5 &&
                regex[currentLength - 1] &&
                regex[currentLength - 1].test(value))
            ) {
              if (inputProps.onChange) {
                inputProps.onChange(e);
              }
            }
          }}
          value={inputProps.value ? inputProps.value.toString() : ''}
          errorText={errorText}
          isInvalid={isInvalid}
          data-testid={dataTestId}
        />
      </div>
    );
  };

  return (
    <div className={styles.timePickerDropdown}>
      <span data-testid="time-picker-dropdown-label" className={styles.label}>
        {label}
      </span>
      {required && <span>*</span>}

      <div
        data-testid="time-picker-dropdown"
        className={classnames(styles.dropdownContainer, { [styles.dropdownWithLabel]: label })}
      >
        <Typeahead
          id="time-picker-dropdown"
          labelKey="label"
          selected={selectedItems}
          options={generateTimeOptions()}
          placeholder={placeholder}
          className={classnames(styles.timePickerDropdownInput, {
            [styles.hasSelectedItems]: selectedItems.length > 0,
          })}
          ref={inputRef}
          renderInput={renderInput}
          disabled={disabled}
          maxResults={1500}
          size={size === 'large' ? 'lg' : undefined}
          onChange={(option) => handleOnChange(option as unknown as TimePickerItem[])}
          filterBy={filterBy}
          renderMenu={renderMenu}
          onFocus={() => {
            setPrevItems(selectedItems);
            setSelectedItems([]);
          }}
          onBlur={() => {
            setSelectedItems(prevItems);
          }}
        />
      </div>
    </div>
  );
};

export default TimePicker;
