import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { faEllipsis, faPen, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dropdown, TextField } from '@skiwo/components';
import { format } from 'date-fns';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { useApi } from '../../../providers/ApiProvider';
import { useAuth } from '../../../providers/AuthProvider';
import translationKeys from '../../../translations/translationKeys';
import AdminNote from '../../../types/AdminNote';
import styles from './NotesList.module.scss';

interface NotesListProps {
  userId?: string;
  notesList: AdminNote[];
  onDeleteNote?: () => void;
}

const NotesList: React.FC<NotesListProps> = (props) => {
  const { notesList, userId, onDeleteNote } = props;
  const api = useApi();
  const { userProfile } = useAuth();
  const intl = useIntl();
  const [selectedNoteId, setSelectedNoteId] = useState<string | null>(null);
  const [showEditForm, setShowEditForm] = useState<boolean>(false);
  const [notesListData, setNotesListData] = useState<any[]>(notesList);

  const schema = yup.object().shape({
    note: yup.string().required(
      intl.formatMessage(
        { id: translationKeys.form_error_required },
        {
          fieldName: intl.formatMessage({
            id: translationKeys.notes_drawer_edit_text_field_label,
          }),
        },
      ),
    ),
  });

  const updateNote = (updatedNote: string, noteId: number) => {
    if (userId) {
      api.updateNote(userId, noteId.toString(), updatedNote);
    }
  };

  const deleteNote = async (noteId: number) => {
    if (userId) {
      const response = await api.deleteNote(userId, noteId.toString());
      if (response) {
        const updatedNotesList = notesListData.filter((note) => note.id !== noteId);

        setNotesListData(updatedNotesList);
      }
    }
  };

  const handleEditNote = (updatedNote: string, noteId: number, index: number) => {
    updateNote(updatedNote, noteId);

    const updatedNotesList = [...notesList];
    updatedNotesList[index].note = updatedNote;
    setNotesListData(updatedNotesList);
  };

  const handleDeleteNote = (noteId: number) => {
    deleteNote(noteId);
    setSelectedNoteId(null);

    if (onDeleteNote) {
      onDeleteNote();
    }
  };

  const handleSubmit = () => {
    setSelectedNoteId(null);
    setShowEditForm(false);
  };

  useEffect(() => {
    setNotesListData(notesList);
  }, [notesList]);

  return (
    <div className={styles.container} data-testid="notes-modal-list">
      {notesListData?.map((note: any, index: number) => {
        return (
          <div key={note.id} className={styles.noteContainer} data-testid="note-item">
            {(selectedNoteId !== note.id || !showEditForm) && (
              <div className={styles.noteCard}>
                <div className={styles.noteContent}>
                  <span>{note.note}</span>
                  {userProfile?.uid === note.author.uid && (
                    <span data-testid="edit-options-dropdown">
                      <Dropdown
                        toggle={
                          <FontAwesomeIcon icon={faEllipsis} className={styles.noteOptions} />
                        }
                        onSelect={(selection) => {
                          if (selection === 'Edit') {
                            setSelectedNoteId(note.id);
                            setShowEditForm(true);
                          } else if (selection === 'Delete') {
                            handleDeleteNote(note.id);
                          }
                        }}
                        items={[
                          {
                            id: 'Edit',
                            text: intl.formatMessage({
                              id: translationKeys.notes_drawer_edit_button,
                            }),
                            icon: faPen,
                          },
                          {
                            id: 'Delete',
                            text: intl.formatMessage({
                              id: translationKeys.notes_drawer_delete_note_button,
                            }),
                            icon: faTrash,
                            className: styles.deleteBtn,
                          },
                        ]}
                      />
                    </span>
                  )}
                </div>
                <div className={styles.authorInfo}>
                  {note?.author && <span>{note.author.name}</span>}
                  {note?.createdAt && (
                    <span data-testid="created-at-text">
                      {format(new Date(note.createdAt), 'dd.MM.yy, HH:mm')}
                    </span>
                  )}
                </div>
              </div>
            )}

            {selectedNoteId === note.id && showEditForm && (
              <div className={styles.editNoteTextfield}>
                <Formik
                  onSubmit={handleSubmit}
                  initialValues={{
                    note: note.note as string,
                  }}
                  validationSchema={schema}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    errors,
                    dirty,
                    isValid,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <TextField
                        as="textarea"
                        type="text"
                        placeholder="Write here..."
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="note"
                        value={values.note}
                        errorText={touched.note ? errors.note : undefined}
                        data-testid="edit-textfield"
                      />
                      <div className={styles.editButtons}>
                        <Button
                          onClick={() => setSelectedNoteId(null)}
                          variant="gray"
                          size="large"
                          className={styles.cancelButton}
                          data-testid="cancel-button"
                        >
                          <FormattedMessage id={translationKeys.notes_drawer_edit_cancel_button} />
                        </Button>

                        <Button
                          iconPosition="left"
                          size="large"
                          variant="primary"
                          disabled={!(isValid && dirty)}
                          onClick={() => handleEditNote(values.note, note.id, index)}
                          type="submit"
                          data-testid="edit-submit-button"
                        >
                          <FormattedMessage id={translationKeys.notes_drawer_edit_save_button} />
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default NotesList;
