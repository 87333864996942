import { InterpretationSkillSex, InterpretationSkillStandBy } from '../../types';
import InterpretationSkillsFilterField from '../InterpretationSkillsFilterField';

const getInterpretationSkillsFilters = (
  currentFilters: Record<string, string | string[]>,
  field: InterpretationSkillsFilterField,
  value: string,
): Record<string, string | string[]> => {
  if (field === InterpretationSkillsFilterField.Id) {
    return { ...currentFilters, ...{ 's[id_eq]': value } };
  }

  if (field === InterpretationSkillsFilterField.Name) {
    let filterValue = value;
    const isPhone = value.startsWith('+47');

    if (isPhone) {
      filterValue = value.replaceAll(' ', '');
    }

    return { ...currentFilters, ...{ 's[lookup_fields_cont]': filterValue } };
  }

  if (field === InterpretationSkillsFilterField.Languages) {
    const languages = value.split(',');
    if (languages.length > 0) {
      return { ...currentFilters, ...{ 's[lang_id_in]': languages } };
    }

    return currentFilters;
  }

  if (field === InterpretationSkillsFilterField.Qualifications) {
    const qualifications = value.split(',');
    if (qualifications.length > 0) {
      return { ...currentFilters, ...{ 's[qualification_id_in]': qualifications } };
    }

    return currentFilters;
  }

  if (field === InterpretationSkillsFilterField.CreatedAt) {
    const dates = value.split(',');

    return {
      ...currentFilters,
      ...{ 's[created_at_gteq]': dates[0], 's[created_at_lteq]': dates[1] },
    };
  }

  if (field === InterpretationSkillsFilterField.Gender) {
    let sexFilter = '';

    if (value === InterpretationSkillSex.Male) {
      sexFilter = '0';
    }

    if (value === InterpretationSkillSex.Female) {
      sexFilter = '1';
    }

    return {
      ...currentFilters,
      ...{ 's[sex_in][]': sexFilter },
    };
  }

  if (field === InterpretationSkillsFilterField.Standby) {
    let standByFilter = '';

    if (value === InterpretationSkillStandBy.Yes) {
      standByFilter = 'true';
    }

    if (value === InterpretationSkillStandBy.No) {
      standByFilter = 'false';
    }

    return {
      ...currentFilters,
      ...{ 's[can_standby_eq]': standByFilter },
    };
  }

  if (field === InterpretationSkillsFilterField.Location) {
    return { ...currentFilters, ...{ 's[location_cont]': value } };
  }

  if (field === InterpretationSkillsFilterField.AccountStatuses) {
    return {
      ...currentFilters,
      ...{ 's[account_status_name_in][]': value.split(',') },
    };
  }

  return currentFilters;
};

export default getInterpretationSkillsFilters;
