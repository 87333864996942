import React from 'react';
import B_Toast from 'react-bootstrap/Toast';
import { faCheckCircle, faTriangleExclamation, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './Toast.module.scss';

export interface ToastProps {
  message: string;
  variant?: 'success' | 'error';
  onClose?: () => void;
}

const Toast = ({ message, variant = 'success', onClose }: ToastProps) => {
  return (
    <B_Toast className={styles.toast} onClose={onClose} animation={true}>
      <B_Toast.Body className={styles.body}>
        <div className={styles.content}>
          {variant === 'success' && (
            <FontAwesomeIcon data-testid="success-icon" className={styles.success} icon={faCheckCircle} />
          )}
          {variant === 'error' && (
            <FontAwesomeIcon data-testid="error-icon" className={styles.error} icon={faTriangleExclamation} />
          )}
          <span className={styles.message}>{message}</span>
        </div>
        <div>
          <button className={styles.button} onClick={onClose} data-testid="close-button">
            <FontAwesomeIcon className={styles.closeButton} icon={faXmark} />
          </button>
        </div>
      </B_Toast.Body>
    </B_Toast>
  );
};

export default Toast
