import { TimePickerItem } from '@skiwo/components/src/TimePicker/TimePicker';
import { differenceInHours } from 'date-fns';
import { addTimeToDate } from '../CreateInterpretationOrder/utils';

const LONG_BOOKING_THRESHOLD_IN_HOURS = 12;

export default function isBookedOverThreshold(
  dates: {
    date: Date;
    startTime?: TimePickerItem;
    finishTime?: TimePickerItem;
  }[],
) {
  return dates.some((date) => {
    const start = addTimeToDate(date.date, date.startTime);
    const finish = addTimeToDate(date.date, date.finishTime);
    return differenceInHours(finish, start) >= LONG_BOOKING_THRESHOLD_IN_HOURS;
  });
}
