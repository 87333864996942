import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { faLocationDot, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, DetailItem, Drawer, FileSelector, FileState, TextField } from '@skiwo/components';
import { formatFileSize } from '@skiwo/utils';
import { Formik } from 'formik';
import * as yup from 'yup';
import fileIllustration from '../../../../assets/illustration-file.svg';
import { useApi } from '../../../../providers/ApiProvider';
import { useToast } from '../../../../providers/ToastProvider/ToastProvider';
import translationKeys from '../../../../translations/translationKeys';
import {
  ManagerOrder,
  ManagerOrderAttachment,
  ManagerOrderAttachmentStatus,
  ManagerOrderDeliveryMethod,
} from '../../../../types';
import GroupedFileList from '../../../GroupedFileList/GroupedFileList';
import { translationOrderErrorKeys } from '../../../utils/translationOrderErrorKeys';
import styles from './SendByPostDrawer.module.scss';

interface FormValues {
  recipientName: string;
  enterpriseName: string;
  address: string;
}

interface Props {
  show?: boolean;
  onClose: () => void;
  order: ManagerOrder;
}

const SendByPostDrawer = (props: Props) => {
  const { show, order, onClose } = props;
  const [sendByPost, setSendByPost] = useState(
    order.deliveryMethod === ManagerOrderDeliveryMethod.Post,
  );
  const [markAsSent, setMarkAsSent] = useState(order.sentByPost);
  const [updateOrderLoading, setUpdateOrderLoading] = useState(false);
  const [orderAttachments, setOrderAttachments] = useState<ManagerOrderAttachment[]>();
  const [selectedFiles, setSelectedFiles] = useState<FileState[]>([]);
  const api = useApi();
  const intl = useIntl();
  const { showErrorToast } = useToast();

  const schema = yup.object().shape({
    recipientName: yup.string().required(
      intl.formatMessage(
        { id: translationKeys.form_error_required },
        {
          fieldName: intl.formatMessage({
            id: translationKeys.translation_order_send_by_post_recipient_name_label,
          }),
        },
      ),
    ),
    enterpriseName: yup.string().required(
      intl.formatMessage(
        { id: translationKeys.form_error_required },
        {
          fieldName: intl.formatMessage({
            id: translationKeys.translation_order_send_by_post_enterprise_label,
          }),
        },
      ),
    ),
    address: yup.string(),
  });

  const resetSelection = () => {
    setMarkAsSent(order.sentByPost);
    setSelectedFiles([]);
  };

  const handleOnSubmit = async (values: FormValues) => {
    const payload: Record<string, any> = {
      requirementIdempotencyKey: order.requirementIdempotencyKey,
      deliveryMethod: sendByPost
        ? ManagerOrderDeliveryMethod.Post
        : ManagerOrderDeliveryMethod.Email,
    };

    if (sendByPost) {
      payload.sendByPostName = values.recipientName;
      payload.sendByPostEnterpriseName = values.enterpriseName;
      payload.sendByPostAddress = values.address;
      payload.sentByPost = markAsSent;
    }

    const { data, error } = await api.updateOrder(
      order.id.toString(),
      payload,
      setUpdateOrderLoading,
    );

    if (error) {
      showErrorToast(error, translationOrderErrorKeys);
      return;
    }

    if (selectedFiles.length > 0) {
      const coverLetterPayload = new FormData();

      selectedFiles.forEach((file, index) => {
        const fileBlob = new Blob([file.data], {
          type: file.type,
        });

        coverLetterPayload.append(
          `orderAttachments[${index}][status]`,
          ManagerOrderAttachmentStatus.CoverLetter,
        );
        coverLetterPayload.append(`orderAttachments[${index}][file]`, fileBlob, file.name);
      });

      const { error: uploadError } = await api.uploadOrderAttachments(
        order.id.toString(),
        coverLetterPayload,
        setUpdateOrderLoading,
      );

      if (uploadError) {
        showErrorToast(uploadError);
      }
    }

    if (data) {
      onClose();
    }
  };

  const getOrderAttachments = async () => {
    const { data, error } = await api.getOrderAttachments(order.id.toString(), {
      status: ManagerOrderAttachmentStatus.CoverLetter,
    });

    if (data?.orderAttachments) {
      const currentOrderAttachments = data.orderAttachments.filter(
        (attachment) => !attachment.archived,
      );
      setOrderAttachments(currentOrderAttachments);
    }

    if (error) {
      showErrorToast(error);
    }
  };

  const downloadAttachment = async (attachment: ManagerOrderAttachment) => {
    const downloadUrl = `${process.env.REACT_APP_API_BASE_URL}${attachment.link}?disposition=attachment`;

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = attachment.name;
    link.click();
  };

  const deleteAttachment = async (attachment: ManagerOrderAttachment) => {
    const { error } = await api.updateOrderAttachments(
      order.id.toString(),
      attachment.id.toString(),
      {
        orderAttachment: {
          archived: true,
        },
      },
    );

    if (error) {
      showErrorToast(error);
    } else {
      getOrderAttachments();
    }
  };

  const handleFileSelection = (newFiles: FileState[]) => {
    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  useEffect(() => {
    resetSelection();

    if (show) {
      getOrderAttachments();
    }
  }, [show]);

  return (
    <Drawer
      show={show}
      onClose={onClose}
      title={intl.formatMessage({ id: translationKeys.translation_order_send_by_post_title })}
    >
      <Formik
        validationSchema={schema}
        onSubmit={handleOnSubmit}
        initialValues={{
          recipientName: order.sendByPostName || '',
          enterpriseName: order.sendByPostEnterpriseName || '',
          address: order.sendByPostAddress || '',
        }}
      >
        {({ handleSubmit, handleChange, handleBlur, values, touched, errors }) => (
          <Form onSubmit={handleSubmit} className={styles.sendByPost}>
            <Form.Check
              type="switch"
              label={intl.formatMessage({
                id: translationKeys.translation_order_send_by_post_send_documents_switch,
              })}
              checked={sendByPost}
              onChange={(event) => setSendByPost(event.currentTarget.checked)}
            />

            {sendByPost && (
              <>
                <TextField
                  label={intl.formatMessage({
                    id: translationKeys.translation_order_send_by_post_recipient_name_label,
                  })}
                  placeholder={intl.formatMessage({
                    id: translationKeys.translation_order_send_by_post_recipient_name_placeholder,
                  })}
                  type="text"
                  size="large"
                  name="recipientName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.recipientName}
                  errorText={touched.recipientName ? errors.recipientName : undefined}
                />

                <TextField
                  label={intl.formatMessage({
                    id: translationKeys.translation_order_send_by_post_enterprise_label,
                  })}
                  placeholder={intl.formatMessage({
                    id: translationKeys.translation_order_send_by_post_enterprise_placeholder,
                  })}
                  type="text"
                  size="large"
                  name="enterpriseName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.enterpriseName}
                  errorText={touched.enterpriseName ? errors.enterpriseName : undefined}
                />

                <TextField
                  name="address"
                  size="large"
                  icon={<FontAwesomeIcon icon={faLocationDot} />}
                  label={intl.formatMessage({
                    id: translationKeys.translation_order_send_by_post_address_label,
                  })}
                  placeholder={intl.formatMessage({
                    id: translationKeys.translation_order_send_by_post_address_placeholder,
                  })}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.address}
                  errorText={touched.address ? errors.address : undefined}
                />

                {orderAttachments && orderAttachments.length == 0 && (
                  <div>
                    <span className={styles.coverLetterLabel}>
                      <FormattedMessage
                        id={
                          translationKeys.translation_order_details_send_by_post_cover_letter_label
                        }
                      />
                    </span>
                    <FileSelector
                      selectedFiles={selectedFiles}
                      multiple
                      onSelection={(newFiles) => {
                        handleFileSelection(newFiles);
                      }}
                    />

                    <div className={styles.fileList}>
                      {selectedFiles.map((file: FileState) => {
                        return (
                          <>
                            <DetailItem
                              key={file.id}
                              subtitle={<span>{formatFileSize(file.size)}</span>}
                              title={file.name}
                              size="large"
                              preview={
                                <img
                                  src={file.preview ? file.preview : fileIllustration}
                                  height="40"
                                />
                              }
                              onActionSelect={(action) => {
                                if (action === 'Delete') {
                                  const updatedFiles = selectedFiles.filter(
                                    (currentFiles) => currentFiles.uid !== file.uid,
                                  );

                                  setSelectedFiles(updatedFiles);
                                }
                              }}
                              actions={[
                                {
                                  id: 'Delete',
                                  text: 'Delete',
                                  icon: faTrash,
                                  variant: 'critical',
                                },
                              ]}
                            />
                          </>
                        );
                      })}
                    </div>
                  </div>
                )}

                {orderAttachments && orderAttachments.length > 0 && (
                  <GroupedFileList
                    files={orderAttachments}
                    onDownload={downloadAttachment}
                    onArchive={deleteAttachment}
                  />
                )}

                <Form.Check
                  type="checkbox"
                  label={intl.formatMessage({
                    id: translationKeys.translation_order_send_by_post_mark_as_sent_label,
                  })}
                  checked={markAsSent}
                  onChange={(event) => setMarkAsSent(event.currentTarget.checked)}
                />
              </>
            )}

            <div className={styles.actions}>
              <Button size="large" variant="gray" onClick={onClose}>
                <FormattedMessage
                  id={translationKeys.translation_order_send_by_post_cancel_button}
                />
              </Button>
              <Button size="large" type="submit" isLoading={updateOrderLoading}>
                <FormattedMessage id={translationKeys.translation_order_send_by_post_save_button} />
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Drawer>
  );
};

export default SendByPostDrawer;
