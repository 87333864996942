import React from 'react';
import classnames from 'classnames';
import styles from './Chip.module.scss';

interface Props {
  children: React.ReactNode;
  variant?: 'default' | 'selected';
  'data-testid'?: string;
  onClick?: () => void;
}

const Chip = (props: Props) => {
  const { children, variant = 'default', onClick } = props;

  return (
    <div
      className={classnames(styles.chip, styles[variant])}
      onClick={onClick}
      data-testid={props['data-testid']}
    >
      <span data-testid="chip-text">{children}</span>
    </div>
  );
};

export default Chip;
