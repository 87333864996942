import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { Button, DetailItem, Drawer, FileSelector, FileState, TextField } from '@skiwo/components';
import { formatFileSize } from '@skiwo/utils';
import fileIllustration from '../../assets/illustration-file.svg';
import { useApi } from '../../providers/ApiProvider';
import { useToast } from '../../providers/ToastProvider/ToastProvider';
import translationKeys from '../../translations/translationKeys';
import { ManagerOrderAttachmentStatus } from '../../types';
import styles from './UploadFileDrawer.module.scss';

interface Props {
  show?: boolean;
  attachmentStatus: ManagerOrderAttachmentStatus;
  onClose: () => void;
  orderId: string;
  subtaskId?: string;
}

const UploadFileDrawer = (props: Props) => {
  const { show, orderId, subtaskId, attachmentStatus, onClose } = props;
  const api = useApi();
  const [selectedFiles, setSelectedFiles] = useState<FileState[]>([]);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [passwordProtected, setPasswordProtected] = useState(false);
  const [filePasswords, setFilePasswords] = useState<Record<string, string>>({});
  const intl = useIntl();
  const { showErrorToast } = useToast();

  const showPasswordProtection = [
    ManagerOrderAttachmentStatus.Initial,
    ManagerOrderAttachmentStatus.Final,
  ].includes(attachmentStatus);

  const handleFileSelection = (newFiles: FileState[]) => {
    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleSubmit = async () => {
    const payload = new FormData();

    selectedFiles.forEach((file, index) => {
      const fileBlob = new Blob([file.data], {
        type: file.type,
      });

      const subtaskStatuses = [
        ManagerOrderAttachmentStatus.Wip,
        ManagerOrderAttachmentStatus.Final,
      ];
      payload.append(`orderAttachments[${index}][status]`, attachmentStatus);
      payload.append(`orderAttachments[${index}][file]`, fileBlob, file.name);

      if (subtaskStatuses.includes(attachmentStatus) && subtaskId) {
        payload.append(`orderAttachments[${index}][subtaskId]`, subtaskId);
      }

      if (passwordProtected) {
        if (attachmentStatus === ManagerOrderAttachmentStatus.Initial && filePasswords[file.uid]) {
          payload.append(`orderAttachments[${index}][filePassword]`, filePasswords[file.uid]);
        }

        if (attachmentStatus === ManagerOrderAttachmentStatus.Final) {
          payload.append(`orderAttachments[${index}][protectWithPassword]`, 'true');
        }
      }
    });

    const { error } = await api.uploadOrderAttachments(orderId, payload, setUploadLoading);

    if (error) {
      showErrorToast(error);
    } else {
      onClose();
    }
  };
  const getStatusText = (status: ManagerOrderAttachmentStatus) => {
    if (status === ManagerOrderAttachmentStatus.Initial) {
      return intl.formatMessage({
        id: translationKeys.translation_order_details_source_files_header,
      });
    }
    if (status === ManagerOrderAttachmentStatus.Wip) {
      return intl.formatMessage({
        id: translationKeys.translation_subtask_details_in_progress_header,
      });
    }
    if (status === ManagerOrderAttachmentStatus.Final) {
      return intl.formatMessage({
        id: translationKeys.translation_subtask_details_delivery_header,
      });
    }
  };

  const handlePasswordToggle = (checked: boolean) => {
    setPasswordProtected(checked);
  };

  const setFilePassword = (fileId: string, password: string) => {
    const passwords = { ...filePasswords, [fileId]: password };

    setFilePasswords(passwords);
  };

  useEffect(() => {
    if (show) {
      setSelectedFiles([]);
      setFilePasswords({});
    }
  }, [show]);

  return (
    <Drawer
      show={show}
      onClose={onClose}
      title={intl.formatMessage(
        { id: translationKeys.translation_order_upload_files_title },
        {
          status: getStatusText(attachmentStatus),
        },
      )}
      data-testid="upload-file-drawer"
    >
      <div className={styles.uploadFile}>
        {showPasswordProtection && (
          <Form.Check
            type="switch"
            onChange={(e) => handlePasswordToggle(e.currentTarget.checked)}
            checked={passwordProtected}
            label={intl.formatMessage({
              id: translationKeys.translation_order_upload_files_password_protection_label,
            })}
          />
        )}

        <FileSelector
          selectedFiles={selectedFiles}
          multiple
          onSelection={(newFiles) => {
            handleFileSelection(newFiles);
          }}
        />

        <div className={styles.fileList}>
          {selectedFiles.map((file: FileState) => {
            return (
              <>
                <DetailItem
                  key={file.id}
                  subtitle={<span>{formatFileSize(file.size)}</span>}
                  title={file.name}
                  size="large"
                  preview={<img src={file.preview ? file.preview : fileIllustration} height="40" />}
                  onActionSelect={(action) => {
                    if (action === 'Delete') {
                      const updatedFiles = selectedFiles.filter(
                        (currentFiles) => currentFiles.uid !== file.uid,
                      );

                      setSelectedFiles(updatedFiles);
                    }
                  }}
                  actions={[
                    {
                      id: 'Delete',
                      text: 'Delete',
                      icon: faTrash,
                      variant: 'critical',
                    },
                  ]}
                >
                  {passwordProtected &&
                    attachmentStatus === ManagerOrderAttachmentStatus.Initial && (
                      <div className={styles.filePassword}>
                        <TextField
                          placeholder={intl.formatMessage({
                            id: translationKeys.translation_order_upload_files_password_placeholder,
                          })}
                          type="text"
                          size="large"
                          name="username"
                          onChange={(e) => setFilePassword(file.uid, e.currentTarget.value)}
                        />
                      </div>
                    )}
                </DetailItem>
              </>
            );
          })}
        </div>

        <div className={styles.actions}>
          <Button size="large" variant="gray" onClick={onClose}>
            <FormattedMessage id={translationKeys.translation_order_upload_files_cancel_button} />
          </Button>
          <Button
            size="large"
            onClick={handleSubmit}
            disabled={selectedFiles.length === 0}
            isLoading={uploadLoading}
          >
            <FormattedMessage id={translationKeys.translation_order_upload_files_upload_button} />
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default UploadFileDrawer;
