import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { ApiError } from '../Api';
import { JobCategory } from '../types';
import { useApi } from './ApiProvider';
import { useAuth } from './AuthProvider';

interface Props {
  children: JSX.Element | JSX.Element[];
}

interface JobCategoriesContext {
  jobCategories: JobCategory[];
  isLoading: boolean;
  error: ApiError | null;
}

export const initialState: JobCategoriesContext = {
  jobCategories: [],
  isLoading: false,
  error: null,
};

export const JobCategoriesContext = createContext(initialState);

const JobCategoriesProvider: React.FC<Props> = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const api = useApi();
  const [jobCategories, setJobCategories] = useState<JobCategory[]>([]);
  const [jobCategoriesLoading, setJobCategoriesLoading] = useState(false);
  const [jobCategoriesError, setJobCategoriesError] = useState<ApiError | null>(null);

  const getJobCategories = async () => {
    const { data, error } = await api.getCategories(setJobCategoriesLoading);

    if (data && data.jobCategories) {
      setJobCategories(data.jobCategories);
    }
    if (error) {
      setJobCategoriesError(error);
    }
  };

  useEffect(() => {
    if (!isAuthenticated() || jobCategories.length > 0) return;
    getJobCategories();
  }, []);

  const JobCategoriesContextValue = useMemo(
    () => ({
      jobCategories,
      isLoading: jobCategoriesLoading,
      error: jobCategoriesError,
    }),
    [jobCategories, jobCategoriesLoading, jobCategoriesError],
  );

  return (
    <JobCategoriesContext.Provider value={JobCategoriesContextValue}>
      {children}
    </JobCategoriesContext.Provider>
  );
};

export const useJobCategories = () => useContext(JobCategoriesContext);

export default JobCategoriesProvider;
