import { ManagerOrderStatus } from '../../types';

const getOrderStatusColour = (
  status: ManagerOrderStatus,
): 'success' | 'error' | 'info' | 'warning' | 'primary' | 'neutral' => {
  if (status === ManagerOrderStatus.QuoteAccepted) {
    return 'success';
  }

  if (status === ManagerOrderStatus.QuoteRejected || status === ManagerOrderStatus.Cancelled) {
    return 'error';
  }

  if (status === ManagerOrderStatus.QuoteSent || status === ManagerOrderStatus.Finished) {
    return 'info';
  }

  if (status === ManagerOrderStatus.InProgress || status === ManagerOrderStatus.QuoteRequested) {
    return 'warning';
  }

  return 'neutral';
};

export default getOrderStatusColour;
