import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Banner,
  Button,
  Drawer,
  SearchDropdown,
  SearchDropdownMenuOption,
  Tag,
  TextField,
} from '@skiwo/components';
import { Formik } from 'formik';
import * as yup from 'yup';
import CreatePaymentDrawer from '../../../components/CreatePaymentDrawer/CreatePaymentDrawer';
import { getInvoiceStrategies } from '../../../components/CreatePaymentDrawer/getInvoiceStrategies';
import EnterpriseCard from '../../../components/EnterpriseCard/EnterpriseCard';
import { getInvoiceStrategyName } from '../../../CreateTranslationOrder/PaymentSection/getInvoiceStrategyName';
import useDebounce from '../../../hooks/useDebounce';
import { useApi } from '../../../providers/ApiProvider';
import { useToast } from '../../../providers/ToastProvider/ToastProvider';
import translationKeys from '../../../translations/translationKeys';
import {
  BrregSearchResult,
  CustomerPaymentMethod,
  CustomerPaymentMethodInvoiceGroupStrategy,
  Enterprise,
  EnterpriseDepartment,
  ManagerCustomer,
} from '../../../types';
import { CustomerPaymentMethodInvoiceDeliveryMechanizm } from '../../../types/CustomerPaymentMethod';
import CustomerToEnterpriseSuccess from './CustomerToEnterpriseSuccess/CustomerToEnterpriseSuccess';
import styles from './CustomerToEnterpriseDrawer.module.scss';

interface FormValues {
  enterprise?: SearchDropdownMenuOption<Enterprise> | SearchDropdownMenuOption<BrregSearchResult>;
  departments?: SearchDropdownMenuOption<EnterpriseDepartment>[];
  paymentMethod?: SearchDropdownMenuOption<CustomerPaymentMethod>;
  paymentEnterprise?:
    | SearchDropdownMenuOption<Enterprise>
    | SearchDropdownMenuOption<BrregSearchResult>;
  paymentInvoiceEmail?: string;
  paymentInvoiceGroupStrategy?: CustomerPaymentMethodInvoiceGroupStrategy;
  paymentDefault: boolean;
}

interface Props {
  customer: ManagerCustomer;
  show?: boolean;
  onClose: () => void;
  onComplete: (hardReload?: boolean) => void;
}

enum EnterpriseType {
  ExistingEnterprises = 'Existing Enterprises',
  BrregEnterprise = 'Results From Brreg.no',
}

const CustomerToEnterpriseDrawer = (props: Props) => {
  const { show, onClose, customer, onComplete } = props;
  const intl = useIntl();
  const [departmentsLoading, setDepartmentsLoading] = useState(false);
  const [upgradeCustomerLoading, setUpgradeCustomerLoading] = useState(false);
  const [departmentOptions, setDepartmentOptions] = useState<SearchDropdownMenuOption[]>([]);
  const [enterpriseOptions, setEnterpriseOptions] = useState<SearchDropdownMenuOption[]>([]);
  const [selectedEnterprise, setSelectedEnterprise] = useState<Enterprise>();
  const [showSuccess, setShowSuccess] = useState(false);
  const debounceEnterpriseSearch = useDebounce(300);
  const api = useApi();
  const { showErrorToast } = useToast();

  const schema = yup.object().shape({
    enterprise: yup.object().required(
      intl.formatMessage(
        { id: translationKeys.form_error_required },
        {
          fieldName: intl.formatMessage({
            id: translationKeys.create_payment_method_drawer_enterprise_label,
          }),
        },
      ),
    ),
    departments: yup.array().notRequired(),
    paymentMethodId: yup.string().notRequired(),
    paymentMethod: yup.object().notRequired(),
  });

  const handleSubmit = async (values: FormValues) => {
    if (!values.enterprise || !values.enterprise.customData?.orgNumber) return;
    const departmentIds =
      values.departments?.map((department) => department.key?.toString() || '') || [];

    const { data, error } = await api.upgradeCustomerToEnterprise(
      customer.uid,
      values.enterprise.customData.orgNumber,
      {
        paymentMethodId:
          values.enterprise.group === EnterpriseType.ExistingEnterprises
            ? values.paymentMethod?.id
            : undefined,
        paymentMethod:
          values.enterprise.group === EnterpriseType.BrregEnterprise &&
          values.paymentEnterprise?.customData?.orgNumber &&
          values.paymentInvoiceGroupStrategy
            ? {
                orgNumber: values.paymentEnterprise.customData.orgNumber,
                email: values.paymentInvoiceEmail,
                invoiceGroupStrategy: values.paymentInvoiceGroupStrategy,
                default: values.paymentDefault,
                invoiceDeliveryMechanizm: CustomerPaymentMethodInvoiceDeliveryMechanizm.EHF,
              }
            : undefined,
        departmentIds,
      },
      setUpgradeCustomerLoading,
    );

    if (error) {
      showErrorToast(error);
    }
    if (data) {
      setShowSuccess(true);
    }
  };

  const getEnterpriseList = async (query: string) => {
    const { data } = await api.searchEnterprises({
      query,
      search_brreg: true,
    });

    if (data) {
      const enterpriseOptions =
        data.enterprises.map((enterprise) => {
          return {
            id: enterprise.id,
            label: enterprise.name,
            key: enterprise.orgNumber,
            subtitle: `Org. nr. ${enterprise.orgNumber}`,
            group: EnterpriseType.ExistingEnterprises,
            customData: { ...enterprise },
          };
        }) || [];

      const brregOptions =
        data.brregSearch.map((enterprise) => {
          return {
            id: parseInt(enterprise.orgNumber),
            label: enterprise.name,
            key: enterprise.orgNumber,
            subtitle: `Org. nr. ${enterprise.orgNumber}`,
            group: EnterpriseType.BrregEnterprise,
            customData: { ...enterprise },
          };
        }) || [];

      setEnterpriseOptions([...enterpriseOptions, ...brregOptions]);
    }
  };

  const getEnterpriseDetails = async (
    enterpriseId: number,
    setDefault: (value: SearchDropdownMenuOption<CustomerPaymentMethod>) => void,
  ) => {
    const { data } = await api.getEnterpriseDetails(enterpriseId.toString());

    if (data) {
      setSelectedEnterprise(data.enterprise);
      getPaymentMethods({
        customerUid: data.enterprise.owner.uid,
        defaultPaymentMethodId: data.enterprise.defaultPaymentMethodId,
        setDefault,
      });
    }
  };

  const resetSelection = () => {
    setSelectedEnterprise(undefined);
    setShowSuccess(false);
  };

  const getFormattedAddress = (address: {
    line1: string;
    line2: string;
    postCode: string;
    city: string;
    country: string;
    county: string;
  }) => {
    if (!address) return '-';
    const postalAddress = [address.postCode, address.city].filter((component) => !!component);
    const addressComponents = [address.line1, postalAddress.join(' '), address.country].filter(
      (component) => !!component,
    );

    if (addressComponents.length === 0) return null;

    return addressComponents.join(', ');
  };

  const getEnterpriseDepartments = async (enterpriseId: string) => {
    const { data } = await api.getEnterpriseDepartments(enterpriseId, true, setDepartmentsLoading);

    if (data?.departments) {
      const dropdownOptions =
        data?.departments.map((department) => {
          return { id: department.id, label: department.name, key: department.id.toString() };
        }) || [];

      setDepartmentOptions(dropdownOptions);
    }
  };

  const [paymentMethodDropdownOptions, setPaymentMethodDropdownOptions] = useState<
    SearchDropdownMenuOption<CustomerPaymentMethod>[]
  >([]);
  const [showCreateDrawer, setShowCreateDrawer] = useState(false);

  const getPaymentMethods = async ({
    customerUid,
    defaultPaymentMethodId,
    setDefault,
  }: {
    customerUid?: string;
    defaultPaymentMethodId?: number | null;
    setDefault?: (value: SearchDropdownMenuOption<CustomerPaymentMethod>) => void;
  }) => {
    if (!customerUid) return;

    const { data, error } = await api.getPaymentMethods({
      customerUid,
    });

    if (data) {
      const activePaymentMethods = data.filter((item) => !item.archived);

      const paymentDropdownOptions = activePaymentMethods.map((option) => {
        const title = option.orgNumber
          ? `${option.orgName || '-'} / ${option.orgNumber} (${getInvoiceStrategyName(
              option.invoiceGroupStrategy,
              intl,
            )})`
          : `${option.email || '-'} / ${intl.formatMessage({
              id: translationKeys.translation_order_edit_invoicing_payment_methods_email_label,
            })} (${getInvoiceStrategyName(option.invoiceGroupStrategy, intl)})`;
        return {
          id: option.id,
          label: title,
          key: option.id.toString(),
          customData: option,
        };
      });

      setPaymentMethodDropdownOptions(paymentDropdownOptions);

      if (paymentDropdownOptions.length && setDefault) {
        const defaultPaymentMethod = paymentDropdownOptions.find(
          (option) => option.id === defaultPaymentMethodId,
        );

        if (defaultPaymentMethod) {
          setDefault(defaultPaymentMethod);
        }
      }
    }

    if (error) {
      showErrorToast(error);
    }
  };

  const shouldShowWarningBanner = (values: FormValues) => {
    if (!values.enterprise) return false;

    const isExistingEnterprise = values.enterprise.group === EnterpriseType.ExistingEnterprises;
    const isBrregEnterprise = values.enterprise.group === EnterpriseType.BrregEnterprise;

    if (isExistingEnterprise && !values.paymentMethod) {
      return true;
    }

    if (isBrregEnterprise && !(values.paymentEnterprise && values.paymentInvoiceGroupStrategy)) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (!show) return;

    resetSelection();
  }, [show]);

  const handleComplete = () => {
    resetSelection();
    onComplete();
    onClose();
  };

  return (
    <>
      <Drawer
        show={show}
        onClose={onClose}
        title={
          showSuccess
            ? intl.formatMessage({ id: translationKeys.customers_page_to_enterprise_success_title })
            : intl.formatMessage({ id: translationKeys.customers_page_to_enterprise_title })
        }
        data-testid="customer-to-enterprise-drawer"
      >
        {showSuccess ? (
          <CustomerToEnterpriseSuccess
            customerName={customer.name}
            openEnterprisePage={() => {
              window.open(customer.godmodeDemanderUrl, '_blank');
              handleComplete();
            }}
            onClose={handleComplete}
            resetSelection={resetSelection}
            resetShowSuccess={() => setShowSuccess(false)}
          />
        ) : (
          <Formik<FormValues>
            initialValues={{
              enterprise: undefined,
              departments: [],
              paymentMethod: undefined,
              paymentEnterprise: undefined,
              paymentInvoiceEmail: undefined,
              paymentInvoiceGroupStrategy: undefined,
              paymentDefault: false,
            }}
            onSubmit={handleSubmit}
            validationSchema={schema}
          >
            {({
              handleSubmit,
              handleBlur,
              handleChange,
              values,
              setFieldValue,
              touched,
              errors,
              resetForm,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className={styles.container}>
                  <div className={styles.infoCard}>
                    <div>
                      <span className={styles.id}>
                        <FormattedMessage
                          id={translationKeys.customers_page_move_id_label}
                          values={{ id: customer.id }}
                        />
                      </span>
                      <h5>{customer.name}</h5>
                    </div>

                    <div className={styles.contactInfo}>
                      <span>{customer.email}</span>
                      {customer.phone && <span>{customer.phone}</span>}
                    </div>
                  </div>

                  <Banner
                    text={intl.formatMessage({
                      id: translationKeys.customers_page_to_enterprise_warning,
                    })}
                    variant={
                      values.enterprise?.group === EnterpriseType.BrregEnterprise
                        ? 'warning'
                        : 'error'
                    }
                  />

                  <div data-testid="enterprise-dropdown">
                    <SearchDropdown
                      options={enterpriseOptions}
                      label={intl.formatMessage({
                        id: translationKeys.customers_page_to_enterprise_organisation_search_label,
                      })}
                      placeholder={intl.formatMessage({
                        id: translationKeys.customers_page_to_enterprise_organisation_search_placeholder,
                      })}
                      size="large"
                      search
                      grouped
                      onSearch={(query: string) => {
                        debounceEnterpriseSearch(() => {
                          getEnterpriseList(query);
                        });
                      }}
                      onChange={(enterprise) => {
                        if (enterprise && enterprise.length > 0 && enterprise[0].key) {
                          resetSelection();
                          resetForm();

                          const selectedEnterprise = enterprise[0];
                          setFieldValue('enterprise', selectedEnterprise);

                          if (selectedEnterprise.group === EnterpriseType.ExistingEnterprises) {
                            getEnterpriseDepartments(selectedEnterprise.id.toString());
                            getEnterpriseDetails(enterprise[0].id, (value) =>
                              setFieldValue('paymentMethod', value),
                            );
                          }
                        }
                      }}
                    />
                  </div>

                  {selectedEnterprise && <EnterpriseCard enterprise={selectedEnterprise} />}

                  {selectedEnterprise && departmentOptions.length > 0 && (
                    <div data-testid="department-dropdown">
                      <SearchDropdown
                        options={departmentOptions}
                        label={intl.formatMessage({
                          id: translationKeys.customers_page_to_enterprise_department_label,
                        })}
                        placeholder={intl.formatMessage({
                          id: translationKeys.customers_page_to_enterprise_department_placeholder,
                        })}
                        multiple
                        disabled={!selectedEnterprise || departmentsLoading}
                        size="large"
                        selected={values.departments}
                        onChange={(departments) => {
                          setFieldValue('departments', departments);
                        }}
                      />
                    </div>
                  )}

                  {selectedEnterprise && (
                    <div className={styles.dropdownPaymentMethods} data-testid="dropdown-options">
                      <Form.Label>
                        <FormattedMessage
                          id={translationKeys.customers_page_to_enterprise_payment_method_label}
                        />
                      </Form.Label>
                      <div className={styles.selectorWithButton}>
                        {!!paymentMethodDropdownOptions.length && (
                          <div className={styles.selector}>
                            <SearchDropdown
                              options={paymentMethodDropdownOptions}
                              selected={values.paymentMethod ? [values.paymentMethod] : []}
                              onChange={(option) => {
                                if (option && option.length > 0 && option[0].key) {
                                  setFieldValue('paymentMethod', option[0]);
                                }
                              }}
                              size="large"
                              hideClearSelected
                            />
                          </div>
                        )}
                        <Button
                          variant="gray"
                          icon={<FontAwesomeIcon icon={faPlus} />}
                          onClick={() => setShowCreateDrawer(true)}
                          size="x-large"
                          className={styles.button}
                          data-testid="add-payment-button"
                        >
                          <FormattedMessage
                            id={translationKeys.customers_page_to_enterprise_add_payment_button}
                          />
                        </Button>
                      </div>
                    </div>
                  )}

                  {values.enterprise?.group === EnterpriseType.BrregEnterprise &&
                    values.enterprise && (
                      <>
                        <div className={styles.companyInfoWrapper}>
                          <span className={styles.companyInfoLabel} data-testid="brreg-card-label">
                            <FormattedMessage
                              id={translationKeys.customers_page_to_enterprise_brreg_label}
                            />
                          </span>

                          <div className={styles.companyInfo}>
                            <span className={styles.infoLabel}>
                              <FormattedMessage
                                id={translationKeys.customers_page_to_enterprise_org_number_label}
                              />
                            </span>
                            <span className={styles.infoValue} data-testid="brreg-card-org-number">
                              {(values.enterprise.customData as BrregSearchResult)?.orgNumber ||
                                '-'}
                            </span>
                          </div>
                          <div className={styles.companyInfo}>
                            <span className={styles.infoLabel}>
                              <FormattedMessage
                                id={translationKeys.customers_page_to_enterprise_name_label}
                              />
                            </span>
                            <span className={styles.infoValue}>
                              {(values.enterprise.customData as BrregSearchResult)?.name || '-'}
                            </span>
                          </div>
                          <div className={styles.companyInfo}>
                            <span className={styles.infoLabel}>
                              <FormattedMessage
                                id={translationKeys.customers_page_to_enterprise_org_form_label}
                              />
                            </span>
                            <span className={styles.infoValue}>
                              {(values.enterprise.customData as BrregSearchResult)
                                ?.organizationForm || '-'}
                            </span>
                          </div>
                          <div className={styles.companyInfo}>
                            <span className={styles.infoLabel}>
                              <FormattedMessage
                                id={translationKeys.customers_page_to_enterprise_address_label}
                              />
                            </span>
                            <span className={styles.infoValue}>
                              {getFormattedAddress(
                                (values.enterprise.customData as BrregSearchResult)?.address,
                              ) || '-'}
                            </span>
                          </div>
                          <div className={styles.companyInfo}>
                            <span className={styles.infoLabel}>
                              <FormattedMessage
                                id={translationKeys.customers_page_to_enterprise_municipality_label}
                              />
                            </span>
                            <span className={styles.infoValue}>
                              {(values.enterprise.customData as BrregSearchResult)?.address
                                ?.county || '-'}
                            </span>
                          </div>
                          <div className={styles.companyInfo}>
                            <span className={styles.infoLabel}>
                              <FormattedMessage
                                id={translationKeys.customers_page_to_enterprise_website_label}
                              />
                            </span>
                            <span className={styles.infoValue}>
                              {(values.enterprise.customData as BrregSearchResult)?.website || '-'}
                            </span>
                          </div>
                        </div>

                        <div className={styles.createPaymentMethod}>
                          <div className={styles.label}>
                            <span>
                              <FormattedMessage
                                id={
                                  translationKeys.customers_page_to_enterprise_payment_method_label
                                }
                              />
                            </span>
                            <Tag variant="square" color="neutral" size="medium">
                              <FormattedMessage
                                id={
                                  translationKeys.customers_page_to_enterprise_payment_method_optional_label
                                }
                              />
                            </Tag>
                          </div>
                          <div data-testid="enterprise-dropdown">
                            <SearchDropdown
                              options={enterpriseOptions}
                              label={intl.formatMessage({
                                id: translationKeys.customers_page_to_enterprise_organisation_search_label,
                              })}
                              placeholder={intl.formatMessage({
                                id: translationKeys.customers_page_to_enterprise_organisation_search_placeholder,
                              })}
                              size="large"
                              search
                              grouped
                              onSearch={(query: string) => {
                                debounceEnterpriseSearch(() => {
                                  getEnterpriseList(query);
                                });
                              }}
                              onChange={(enterprise) => {
                                if (enterprise && enterprise.length > 0 && enterprise[0].key) {
                                  setFieldValue('paymentEnterprise', enterprise[0]);
                                } else {
                                  setFieldValue('paymentEnterprise', undefined);
                                }
                              }}
                              errorText={
                                touched.paymentEnterprise ? errors.paymentEnterprise : undefined
                              }
                            />
                          </div>
                          <Banner
                            text={intl.formatMessage({
                              id: translationKeys.create_payment_method_drawer_info_banner,
                            })}
                            variant="information"
                          />
                          <TextField
                            name="paymentInvoiceEmail"
                            label={intl.formatMessage({
                              id: translationKeys.create_payment_method_drawer_invoice_email_label,
                            })}
                            placeholder={intl.formatMessage({
                              id: translationKeys.create_payment_method_drawer_invoice_email_placeholder,
                            })}
                            size="large"
                            value={values.paymentInvoiceEmail}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            errorText={
                              touched.paymentInvoiceEmail ? errors.paymentInvoiceEmail : undefined
                            }
                          />
                          <div data-testid="enterprise-dropdown">
                            <SearchDropdown
                              options={getInvoiceStrategies(intl)}
                              label={intl.formatMessage({
                                id: translationKeys.create_payment_method_drawer_group_invoices_label,
                              })}
                              placeholder={intl.formatMessage({
                                id: translationKeys.create_payment_method_drawer_group_invoices_label,
                              })}
                              size="large"
                              required
                              onSearch={(query: string) => {
                                debounceEnterpriseSearch(() => {
                                  getEnterpriseList(query);
                                });
                              }}
                              onChange={(strategy) => {
                                if (strategy && strategy.length > 0 && strategy[0].key) {
                                  setFieldValue('paymentInvoiceGroupStrategy', strategy[0].key);
                                } else {
                                  setFieldValue('paymentInvoiceGroupStrategy', undefined);
                                }
                              }}
                              errorText={
                                touched.paymentInvoiceGroupStrategy
                                  ? errors.paymentInvoiceGroupStrategy
                                  : undefined
                              }
                            />
                          </div>
                          <Form.Check
                            type="checkbox"
                            onChange={(e) => {
                              setFieldValue('paymentDefault', e.target.checked);
                            }}
                            checked={values.paymentDefault}
                            label={intl.formatMessage(
                              {
                                id: translationKeys.create_payment_method_drawer_set_default_for_enterprise_checkbox,
                              },
                              { enterpriseName: values.enterprise.label },
                            )}
                            className={styles.noSourceLanguageCheckbox}
                          />
                        </div>
                      </>
                    )}

                  {shouldShowWarningBanner(values) && (
                    <Banner
                      variant="warning"
                      text={
                        "This conversion may cause problems with invoicing. Please, select payment method or make sure that all customer's jobs have proper payment info."
                      }
                    />
                  )}

                  <div className={styles.actions}>
                    <Button
                      size="large"
                      variant="gray"
                      onClick={onClose}
                      data-testid="cancel-button"
                    >
                      <FormattedMessage
                        id={translationKeys.customers_page_to_enterprise_cancel_button}
                      />
                    </Button>

                    <Button
                      size="large"
                      type="submit"
                      isLoading={upgradeCustomerLoading}
                      data-testid="submit-button"
                      disabled={!values.enterprise}
                    >
                      <FormattedMessage
                        id={translationKeys.customers_page_to_enterprise_upgrade_button}
                      />
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </Drawer>

      <CreatePaymentDrawer
        show={showCreateDrawer}
        onClose={() => {
          setShowCreateDrawer(false);
          getPaymentMethods({ customerUid: selectedEnterprise?.owner.uid });
        }}
        customerName={customer?.name}
        enterpriseName={selectedEnterprise?.name}
        enterpriseId={selectedEnterprise?.id?.toString()}
      />
    </>
  );
};

export default CustomerToEnterpriseDrawer;
