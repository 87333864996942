import { IntlShape } from 'react-intl';
import translationKeys from '../../translations/translationKeys';
import { CustomerPaymentMethodInvoiceGroupStrategy } from '../../types/CustomerPaymentMethod';

export const getInvoiceStrategyName = (
  strategy: CustomerPaymentMethodInvoiceGroupStrategy,
  intl: IntlShape,
) => {
  switch (strategy) {
    case CustomerPaymentMethodInvoiceGroupStrategy.PerBooker:
      return intl.formatMessage({
        id: translationKeys.create_translation_order_payment_per_booker,
      });
    case CustomerPaymentMethodInvoiceGroupStrategy.PerBuyerOrderAndReference:
      return intl.formatMessage({
        id: translationKeys.create_translation_order_payment_per_buyer_order_and_reference,
      });
    case CustomerPaymentMethodInvoiceGroupStrategy.PerBuyerReference:
      return intl.formatMessage({
        id: translationKeys.create_translation_order_payment_per_buyer_reference,
      });
    case CustomerPaymentMethodInvoiceGroupStrategy.PerCustomer:
      return intl.formatMessage({
        id: translationKeys.create_translation_order_payment_per_customer,
      });
    case CustomerPaymentMethodInvoiceGroupStrategy.PerDepartment:
      return intl.formatMessage({
        id: translationKeys.create_translation_order_payment_per_department,
      });
    case CustomerPaymentMethodInvoiceGroupStrategy.PerJob:
      return intl.formatMessage({
        id: translationKeys.create_translation_order_payment_per_job,
      });
    case CustomerPaymentMethodInvoiceGroupStrategy.PerOrderReference:
      return intl.formatMessage({
        id: translationKeys.create_translation_order_payment_per_order_reference,
      });
  }
};
