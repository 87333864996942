import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { faNoteSticky } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Drawer, TextField } from '@skiwo/components';
import { Formik } from 'formik';
import { useApi } from '../../providers/ApiProvider';
import translationKeys from '../../translations/translationKeys';
import AdminNote from '../../types/AdminNote';
import NotesList from './NotesList/NotesList';
import styles from './NotesDrawer.module.scss';

interface NotesDrawerProps {
  show: boolean;
  onClose: () => void;
  onCancel: () => void;
  onSubmit?: () => void;
  onAddNote?: () => void;
  onDeleteNote?: () => void;
  user: {
    id: number;
    name: string | undefined;
    email: string | undefined;
    phone?: string;
    uid?: string;
  };
  note?: {
    note: string;
    author?: string;
    noteCreatedAt?: string;
  };
}

const NotesDrawer: React.FC<NotesDrawerProps> = (props) => {
  const { show, onClose, user, onAddNote, onDeleteNote } = props;
  const api = useApi();
  const [notes, setNotes] = useState<AdminNote[]>([]);
  const intl = useIntl();

  const addNewNote = (newNote: string) => {
    if (user.uid) {
      api.createNote(user.uid, newNote);
    }
  };

  const handleCreateNote = (newNote: string) => {
    addNewNote(newNote);

    if (onAddNote) {
      onAddNote();
    }
  };

  const handleSubmit = () => {
    onClose();
  };

  const getNotes = async () => {
    if (user.uid) {
      const { data } = await api.getNotes(user.uid);

      if (data) {
        setNotes(data.notes);
      }
    }
  };

  useEffect(() => {
    if (!show) return;

    getNotes();
  }, [show]);

  return (
    <Drawer
      show={show}
      title={intl.formatMessage({ id: translationKeys.notes_drawer_header })}
      data-testid="notes-drawer"
      onClose={onClose}
    >
      <div className={styles.container}>
        <div className={styles.infoCard}>
          <div>
            <span className={styles.id}>ID: {user.id}</span>
            <h5 className={styles.name}>{user.name}</h5>
          </div>

          <div className={styles.contactInfo}>
            <span>{user.email}</span>
            {user.phone && <span>{user.phone}</span>}
          </div>
        </div>

        {notes && notes.length === 0 ? (
          <div className={styles.emptyState}>
            <FontAwesomeIcon icon={faNoteSticky} />
            <p>
              <FormattedMessage id={translationKeys.notes_drawer_empty_state_text} />
            </p>
          </div>
        ) : (
          <NotesList notesList={notes} userId={user.uid} onDeleteNote={onDeleteNote} />
        )}

        <div className={styles.noteTextfield}>
          <Formik
            onSubmit={handleSubmit}
            initialValues={{
              note: '',
            }}
          >
            {({ handleSubmit, handleChange, values }) => (
              <Form onSubmit={handleSubmit}>
                <TextField
                  as="textarea"
                  type="text"
                  placeholder={intl.formatMessage({
                    id: translationKeys.notes_drawer_new_post_placeholder,
                  })}
                  label={intl.formatMessage({ id: translationKeys.notes_drawer_text_area_label })}
                  onChange={handleChange}
                  name="note"
                  value={values.note}
                  data-testid="note-textfield"
                />
                <div className={styles.postButtons}>
                  <Button onClick={onClose} variant="gray" size="large">
                    <FormattedMessage id={translationKeys.notes_drawer_close_button} />
                  </Button>

                  <Button
                    data-testid="submit-button"
                    iconPosition="left"
                    size="large"
                    variant="primary"
                    type="submit"
                    onClick={() => handleCreateNote(values.note)}
                    disabled={!values.note}
                  >
                    <FormattedMessage id={translationKeys.notes_drawer_post_button} />
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Drawer>
  );
};

export default NotesDrawer;
