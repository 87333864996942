import translationKeys from '../../translations/translationKeys';

export const translationOrderErrorKeys = {
  paymentMethodId: translationKeys.translation_order_edit_invoicing_payment_methods_label,
  sourceLanguageId: translationKeys.edit_order_drawer_source_language_label,
  countOfWords: translationKeys.translation_order_details_word_count_label,
  translationMethodId: translationKeys.translation_order_order_details_subject_label,
  caseNumber: translationKeys.translation_order_order_details_case_number_label,
  instructionsFromDemander: translationKeys.translation_order_order_details_description_label,
  sendByPostName: translationKeys.translation_order_send_by_post_recipient_name_label,
  sendByPostAddress: translationKeys.translation_order_send_by_post_address_label,
  sendByPostEnterpriseName: translationKeys.translation_order_send_by_post_enterprise_label,
  departmentId: translationKeys.edit_booker_drawer_department_label,
};
