import React, { PropsWithChildren } from 'react';
import styles from './SummarySectionContainer.module.scss';

interface SummarySectionContainerProps {
  icon: React.ReactNode;
  title: string;
  description?: string;
}

const SummarySectionContainer = ({
  icon,
  title,
  description,
  children,
}: PropsWithChildren<SummarySectionContainerProps>) => {
  return (
    <div>
      <div className={styles.headerWrapper}>
        <div className={styles.header}>
          <div className={styles.iconTitleWrapper}>
            {icon}
            <span className={styles.title}>{title}</span>
          </div>
        </div>
        <span className={styles.description}>{description}</span>
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default SummarySectionContainer;
