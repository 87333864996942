import React from 'react';
import classnames from 'classnames';
import styles from './TrafficLightIndicator.module.scss';

interface Props {
  variant?: 'green' | 'yellow' | 'red';
  'data-testid'?: string;
}

const TrafficLightIndicator: React.FC<Props> = (props: Props) => {
  const { variant = 'green' } = props;

  return (
    <div
      data-testid={props['data-testid']}
      className={classnames(styles.trafficLightIndicator, styles[variant])}
    ></div>
  );
};

export default TrafficLightIndicator;
