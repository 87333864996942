import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { faSearch, faUser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextField } from '@skiwo/components';
import useDebounce from '../../../../hooks/useDebounce';
import translationKeys from '../../../../translations/translationKeys';
import { ManagerOrderSubtaskStatus } from '../../../../types';
import { ManagerRejectedSeller } from '../../../../types/ManagerRejectedSeller';
import SellerItem from '../../SellerItem/SellerItem';
import styles from './AssignSellerDrawer.module.scss';

interface RejectedSellersListProps {
  rejectedSellers: ManagerRejectedSeller[];
  onSearch: (query: string) => void;
  onDelete: (id: string) => void;
}

const RejectedSellersList = ({ rejectedSellers, onSearch, onDelete }: RejectedSellersListProps) => {
  const intl = useIntl();
  const debounceSearch = useDebounce(300);

  return (
    <>
      <TextField
        size="large"
        icon={<FontAwesomeIcon icon={faSearch} />}
        placeholder={intl.formatMessage({
          id: translationKeys.translation_subtask_details_assign_translator_drawer_search_placeholder,
        })}
        onChange={(e) =>
          debounceSearch(() => {
            onSearch(e.target.value);
          })
        }
      />

      {!!rejectedSellers.length && (
        <div className={styles.detailItemList}>
          {rejectedSellers.map((item) => {
            return (
              <SellerItem
                key={item.id}
                name={item.seller.person.name}
                qualificationName={item.seller.suitableSkill?.qualification.name}
                assignedSubtasksCount={item.seller.assignedSubtasksCount}
                isProactive={item.seller.isProactive}
                hasContract={item.seller.suitableSkill?.hasContract}
                avatarUrl={item.seller.person?.avatar}
                status={ManagerOrderSubtaskStatus.Rejected}
                onDelete={() => {
                  onDelete(item.seller.id.toString());
                }}
                rejectionReason={item.reason}
                deadlineChangeRequestedAt={item.createdAt}
                originalInternalDeadline={item.originalInternalDeadline}
                requestedInternalDeadline={item.requestedInternalDeadline}
              />
            );
          })}
        </div>
      )}

      {!rejectedSellers.length && (
        <div className={styles.emptyState}>
          <FontAwesomeIcon className={styles.emptyStateIcon} icon={faUser} />
          <span>
            <FormattedMessage
              id={translationKeys.translation_subtask_details_assign_translator_drawer_empty_state}
            />
          </span>
        </div>
      )}
    </>
  );
};

export default RejectedSellersList;
