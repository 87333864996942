import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DatePicker,
  EmptyState,
  SearchDropdown,
  SearchDropdownMenuOption,
  Table,
  TextField,
} from '@skiwo/components';
import { format } from 'date-fns';
import { ApiError } from '../../Api';
import errorStateIllustration from '../../assets/empty-table-error.svg';
import useDebounce from '../../hooks/useDebounce';
import { useLanguages } from '../../providers/LanguagesProvider';
import { useQualifications } from '../../providers/QualificationsProvider';
import getStandByName from '../../Suppliers/utils/getStandByName';
import translationKeys from '../../translations/translationKeys';
import {
  InterpretationSkill,
  InterpretationSkillsAccountStatus,
  InterpretationSkillSex,
} from '../../types';
import { InterpretationSkillStandBy } from '../../types/InterpretationSkill';
import InterpretationSkillsFilterField from '../InterpretationSkillsFilterField';
import InterpretationSkillsItem from '../InterpretationSkillsItem/InterpretationSkillsItem';
import InterpretationSkillsItemSkeleton from '../InterpretationSkillsItemSkeleton/InterpretationSkillsItemSkeleton';
import getAccountStatusName from '../utils/getAccountStatusName';
import getGenderName from '../utils/getGenderName';
import styles from './InterpretationSkillsTable.module.scss';

interface Props {
  skills: InterpretationSkill[];
  isLoading: boolean;
  error: ApiError | null;
  onFilterChange: (field: InterpretationSkillsFilterField, value: string) => void;
  onCompleteAction: (hardReload: boolean) => void;
  className?: string;
}

const InterpretationSkillsTable = (props: Props) => {
  const { skills, isLoading, error, onFilterChange, onCompleteAction } = props;
  const intl = useIntl();
  const { languages } = useLanguages();
  const { qualifications } = useQualifications();
  const [selectedLanguages, setSelectedLanguages] = useState<SearchDropdownMenuOption[]>();
  const [selectedQualifications, setSelectedQualifications] =
    useState<SearchDropdownMenuOption[]>();
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [formattedCreatedDate, setFormattedCreatedDate] = useState('');
  const [selectedAccountStatuses, setSelectedAccountStatuses] =
    useState<SearchDropdownMenuOption[]>();

  const debounceFilterChange = useDebounce(300);

  const handleFilterChange = (field: InterpretationSkillsFilterField, value: string) => {
    debounceFilterChange(() => {
      onFilterChange(field, value);
    });
  };

  const handleCompleteAction = (hardReload = false) => {
    onCompleteAction(hardReload);
  };

  const handleDateChange = (field: InterpretationSkillsFilterField, e: React.ChangeEvent<any>) => {
    const newValue = e.currentTarget.value;
    if (!newValue) {
      if (field === InterpretationSkillsFilterField.CreatedAt) {
        setFormattedCreatedDate('');
      }

      onFilterChange(field, '');
      setDatePickerOpen(false);
    }
  };

  const accountStatusOptions =
    Object.values(InterpretationSkillsAccountStatus)
      .filter((status) => status !== InterpretationSkillsAccountStatus.Inactive)
      .map((status, index) => {
        return { id: index, label: getAccountStatusName(status, intl), key: status };
      }) || [];

  const handleDatePickerChange = (
    field: InterpretationSkillsFilterField,
    start: Date,
    end?: Date,
  ) => {
    const formattedStartDate = format(start, 'dd.MM.yy');
    const formattedEndDate = end ? format(end, 'dd.MM.yy') : null;

    if (field === InterpretationSkillsFilterField.CreatedAt && end) {
      onFilterChange(
        InterpretationSkillsFilterField.CreatedAt,
        `${start.toISOString()},${end.toISOString()}`,
      );
      setFormattedCreatedDate(`${formattedStartDate} - ${formattedEndDate}`);
    }

    setDatePickerOpen(false);
  };

  const languageOptions =
    languages.map((language) => {
      return { id: language.id, label: language.name || '', key: language.id.toString() };
    }) || [];

  const qualificationOptions =
    qualifications
      .filter((qualification) => qualification.industry === 'interpretation')
      .map((qualification) => {
        return {
          id: qualification.id,
          label: qualification.name || '',
          key: qualification.id.toString(),
        };
      }) || [];

  const sexOptions =
    Object.values(InterpretationSkillSex)
      .filter((gender) => gender !== InterpretationSkillSex.Transgender)
      .map((sex, index) => {
        return { id: index, label: getGenderName(sex, intl), key: sex };
      }) || [];

  const standByOptions =
    Object.values(InterpretationSkillStandBy).map((standby, index) => {
      return { id: index, label: getStandByName(standby, intl), key: standby };
    }) || [];

  useEffect(() => {
    if (!selectedLanguages) return;
    if (selectedLanguages.length) {
      const languageIds = selectedLanguages.map((language) => language.id);
      handleFilterChange(InterpretationSkillsFilterField.Languages, languageIds.join(','));
    } else {
      handleFilterChange(InterpretationSkillsFilterField.Languages, '');
    }
  }, [selectedLanguages]);

  useEffect(() => {
    if (!selectedQualifications) return;
    if (selectedQualifications.length) {
      const qualificationIds = selectedQualifications.map((qualification) => qualification.id);
      handleFilterChange(
        InterpretationSkillsFilterField.Qualifications,
        qualificationIds.join(','),
      );
    } else {
      handleFilterChange(InterpretationSkillsFilterField.Qualifications, '');
    }
  }, [selectedQualifications]);

  useEffect(() => {
    if (!selectedAccountStatuses) return;

    const accountStatusValues: Record<string, number> = {
      [InterpretationSkillsAccountStatus.Active]: 0,
      [InterpretationSkillsAccountStatus.Paused]: 1,
      [InterpretationSkillsAccountStatus.Blocked]: 2,
      [InterpretationSkillsAccountStatus.Retired]: 3,
      [InterpretationSkillsAccountStatus.Banned]: 4,
      [InterpretationSkillsAccountStatus.Deleted]: 5,
      [InterpretationSkillsAccountStatus.Deceased]: 6,
    };

    const selectedStatusValues = selectedAccountStatuses.map(
      (status) => accountStatusValues[status.key as string],
    );

    if (selectedStatusValues.length) {
      handleFilterChange(
        InterpretationSkillsFilterField.AccountStatuses,
        selectedStatusValues.join(','),
      );
    } else {
      handleFilterChange(InterpretationSkillsFilterField.AccountStatuses, '');
    }
  }, [selectedAccountStatuses]);

  if (error) {
    return (
      <div className={styles.emptyStateContainer} data-testid="page-error-empty-state">
        <EmptyState
          image={errorStateIllustration}
          text="Unfortunately we were unable to load any skills"
          description="Please refresh this page."
          refreshable
        />
      </div>
    );
  }

  return (
    <>
      <Table striped data-testid="interpretation-skills-table">
        <thead className={styles.tableHeader}>
          <tr className={styles.filters}>
            <th className={styles.idFilter}>
              <TextField
                placeholder={intl.formatMessage({
                  id: translationKeys.interpretation_skills_page_id_filter_placeholder,
                })}
                data-testid="id-filter"
                onChange={(e) =>
                  handleFilterChange(InterpretationSkillsFilterField.Id, e.currentTarget.value)
                }
              />
            </th>
            <th className={styles.nameFilter}>
              <TextField
                placeholder={intl.formatMessage({
                  id: translationKeys.interpretation_skills_page_name_filter_placeholder,
                })}
                data-testid="name-filter"
                onChange={(e) =>
                  handleFilterChange(InterpretationSkillsFilterField.Name, e.currentTarget.value)
                }
              />
            </th>
            <th className={styles.statusFilter}></th>
            <th
              className={styles.languageQualificationFilter}
              data-testid="language-and-qualification-filter"
            >
              <div className={styles.multipleColumnHeader}>
                <SearchDropdown
                  maxResults={languageOptions.length}
                  options={languageOptions}
                  placeholder={intl.formatMessage({
                    id: translationKeys.interpretation_skills_page_languages_filter_placeholder,
                  })}
                  multiple
                  selected={selectedLanguages}
                  onChange={(languages) => {
                    setSelectedLanguages([...(languages || [])]);
                  }}
                />
                <SearchDropdown
                  options={qualificationOptions}
                  placeholder={intl.formatMessage({
                    id: translationKeys.interpretation_skills_page_qualifications_filter_placeholder,
                  })}
                  multiple
                  selected={selectedQualifications}
                  onChange={(qualifications) => {
                    setSelectedQualifications([...(qualifications || [])]);
                  }}
                />
              </div>
            </th>
            <th className={styles.statusFilter}>
              <SearchDropdown
                options={accountStatusOptions}
                placeholder={intl.formatMessage({
                  id: translationKeys.translation_skills_account_status_filter_placeholder,
                })}
                multiple
                selected={selectedAccountStatuses}
                onChange={(statuses) => {
                  setSelectedAccountStatuses([...(statuses || [])]);
                }}
              />
            </th>
            <th className={styles.dateFilter}>
              <TextField
                type="search"
                placeholder={intl.formatMessage({
                  id: translationKeys.interpretation_skills_page_created_at_filter_placeholder,
                })}
                icon={<FontAwesomeIcon icon={faCalendar} />}
                onFocus={() => setDatePickerOpen(true)}
                value={formattedCreatedDate}
                data-testid="created-date-filter"
                onChange={(e) => handleDateChange(InterpretationSkillsFilterField.CreatedAt, e)}
              />
              {datePickerOpen && (
                <DatePicker
                  className={styles.datePicker}
                  onClose={() => setDatePickerOpen(false)}
                  monthCount={1}
                  onChange={(start: Date, end?: Date) =>
                    handleDatePickerChange(InterpretationSkillsFilterField.CreatedAt, start, end)
                  }
                />
              )}
            </th>
            <th className={styles.typeFilter}></th>
            <th className={styles.genderFilter} data-testid="gender-filter">
              <SearchDropdown
                options={sexOptions}
                placeholder={intl.formatMessage({
                  id: translationKeys.interpretation_skills_page_gender_filter_placeholder,
                })}
                onChange={(gender) => {
                  if (gender && gender.length > 0 && gender[0].key) {
                    handleFilterChange(
                      InterpretationSkillsFilterField.Gender,
                      gender[0].key.toString(),
                    );
                  } else {
                    handleFilterChange(InterpretationSkillsFilterField.Gender, '');
                  }
                }}
              />
            </th>
            <th data-testid="standby-filter" className={styles.standByFilter}>
              <SearchDropdown
                options={standByOptions}
                placeholder="Standby"
                onChange={(standby) => {
                  if (standby && standby.length > 0 && standby[0].key) {
                    handleFilterChange(
                      InterpretationSkillsFilterField.Standby,
                      standby[0].key.toString(),
                    );
                  } else {
                    handleFilterChange(InterpretationSkillsFilterField.Standby, '');
                  }
                }}
              />
            </th>
            <th className={styles.locationFilter}>
              <TextField
                placeholder={intl.formatMessage({
                  id: translationKeys.interpretation_skills_page_location_filter_placeholder,
                })}
                data-testid="location-filter"
                onChange={(e) =>
                  handleFilterChange(
                    InterpretationSkillsFilterField.Location,
                    e.currentTarget.value,
                  )
                }
              />
            </th>
            <th className={styles.statsHeader}></th>
            <th></th>
            <th></th>
          </tr>

          <tr className={styles.headers}>
            <th>
              <FormattedMessage id={translationKeys.interpretation_skills_page_id_header} />
            </th>
            <th>
              <FormattedMessage id={translationKeys.interpretation_skills_page_name_header} />
            </th>
            <th>
              <FormattedMessage id={translationKeys.interpretation_skills_page_status_header} />
            </th>
            <th>
              <FormattedMessage id={translationKeys.interpretation_skills_page_language_header} />
            </th>
            <th>
              <FormattedMessage id={translationKeys.interpretation_skills_account_status_header} />
            </th>
            <th>
              <FormattedMessage id={translationKeys.interpretation_skills_page_created_at_header} />
            </th>
            <th>
              <FormattedMessage
                id={translationKeys.interpretation_skills_page_assignment_type_header}
              />
            </th>
            <th>
              <FormattedMessage id={translationKeys.interpretation_skills_page_gender_header} />
            </th>
            <th>Standby</th>
            <th>
              <FormattedMessage id={translationKeys.interpretation_skills_page_location_header} />
            </th>
            <th>
              <FormattedMessage id={translationKeys.interpretation_skills_page_statistic_header} />
            </th>
            <th>
              <FormattedMessage id={translationKeys.interpretation_skills_page_notes_header} />
            </th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          {skills.map((skill) => (
            <InterpretationSkillsItem
              skill={skill}
              key={skill.id}
              onCompleteAction={handleCompleteAction}
            />
          ))}

          {isLoading &&
            [...Array(3)].map((_, index) => (
              <InterpretationSkillsItemSkeleton
                key={index}
                data-testid="interpretation-skill-skeleton"
              />
            ))}
        </tbody>
      </Table>

      {!isLoading && skills.length === 0 && (
        <div className={styles.emptyStateContainer}>
          <EmptyState
            image={errorStateIllustration}
            text="No Skill"
            description="Change your filters to see skills"
          />
        </div>
      )}
    </>
  );
};

export default InterpretationSkillsTable;
