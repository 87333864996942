import React, { createContext, useContext, useState } from 'react';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { useIntl } from 'react-intl';
import { Toast } from '@skiwo/components';
import { ApiError } from '../../Api';
import translationKeys from '../../translations/translationKeys';
import styles from './ToastProvider.module.scss';

interface Props {
  children: JSX.Element | JSX.Element[];
}

interface ToastProps {
  message: string;
  variant?: 'success' | 'error';
}

interface ToastContext {
  showErrorToast: (error: ApiError, translations?: { [key: string]: string }) => void;
  showToast: (props: ToastProps) => void;
}

export const ToastContext = createContext<ToastContext | undefined>(undefined);

const ToastProvider: React.FC<Props> = ({ children }) => {
  const [toasts, setToasts] = useState<ToastProps[]>([]);
  const intl = useIntl();


  // TODO: Refactor error handling. We need a new error structure to handle errors in a more consistent way.
  const showErrorToast = (error: ApiError, translations?: { [key: string]: string }) => {
    // Declare generic error
    let errorMessages: string[] = [
      intl.formatMessage({ id: translationKeys.global_generic_error }),
    ];

    // Make sure if errors is an object
    if (typeof error.text === 'object') {
      // Make sure if error object values are string or an array.
      // If error object value is an object - do nothing == show generic error declared above
      const messages = Object.entries(error.text).reduce<string[]>((acc, [key, value]) => {
        if (!(typeof value === 'object' && !Array.isArray(value))) {
          // Check if translations object contains the error key
          if (translations && translations[key]) {
            const translatedKey = intl.formatMessage({ id: translations[key] });
            acc.push(`${translatedKey}: ${value}`);
          } else {
            acc.push(value as string);
          }
        }

        return acc;
      }, []);

      if (messages.length) {
        errorMessages = messages;
      }
    }

    const errorToasts: ToastProps[] = errorMessages.map((message) => ({
      variant: 'error',
      // converting to string and adding space after commas for handling old way errors with an array
      message: message.toString().replaceAll(',', ', '),
    }));

    setToasts([...errorToasts, ...toasts]);
  };

  const showToast = (toast: ToastProps) => {
    setToasts([toast, ...toasts]);
  };

  const closeToast = (index: number) => {
    setToasts(toasts.filter((t, i) => i !== index));
  };

  return (
    <ToastContext.Provider value={{ showToast, showErrorToast }}>
      {children}
      <ToastContainer className={styles.toastContainer}>
        {toasts.map((toast, index) => (
          <Toast
            message={toast.message}
            variant={toast.variant}
            key={index}
            onClose={() => closeToast(index)}
          />
        ))}
      </ToastContainer>
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};

export default ToastProvider;
