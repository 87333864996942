import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { faList } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  PhoneCodeDropdown,
  RadioOptions,
  SearchDropdown,
  SearchDropdownMenuOption,
  TextField,
} from '@skiwo/components';
import { getFormattedAddress } from '@skiwo/utils';
import classNames from 'classnames';
import { FormikProps, getIn } from 'formik';
import { getTypesafeSetFieldValue } from '../../../helpers/getTypesafeSetFieldValue';
import useDebounce from '../../../hooks/useDebounce';
import { useApi } from '../../../providers/ApiProvider';
import translationKeys from '../../../translations/translationKeys';
import { Enterprise } from '../../../types';
import { BaseContactAddress } from '../../../types/EnterpriseSearchResult';
import { AddressType, CreateInterpretationOrderFormValues } from '../../schema';
import { getAddressObjectFromGeocode } from '../../utils';
import { AssignmentTypeVideoSection } from '../AssignmentTypeVideoSection/AssignmentTypeVideoSection';
import styles from './AssignmentTypeInPersonSection.module.scss';

interface AssignmentTypeInPersonSectionProps {
  formikProps: FormikProps<CreateInterpretationOrderFormValues>;
  enterpriseId?: Enterprise['id'];
}

export enum AssignmentVideoSolution {
  SalitaVideo = 'salita-video',
  OwnVideo = 'own-video',
}

export enum AssignmentInPersonAlternative {
  PhoneInterpreter = 'phone-interpreter',
  VideoInterpreter = 'video-interpreter',
  CancelAssignment = 'cancel-assignment',
}

export const AssignmentTypeInPersonSection = ({
  formikProps,
  enterpriseId,
}: AssignmentTypeInPersonSectionProps) => {
  const intl = useIntl();
  const api = useApi();
  const debounceAddressSearch = useDebounce(300);
  const [enterpriseAddressesOptions, setEnterpriseAddressesOptions] = useState<
    SearchDropdownMenuOption<BaseContactAddress>[]
  >([]);
  const [googleAddressesOptions, setGoogleAddressesOptions] = useState<
    SearchDropdownMenuOption<BaseContactAddress>[]
  >([]);

  const setFieldValue = getTypesafeSetFieldValue(formikProps);

  const getEnterpriseAddressesList = async () => {
    if (!enterpriseId) return;

    const { data } = await api.getEnterpriseAddresses(
      enterpriseId.toString(),
      formikProps.values.departmentId,
    );

    if (data) {
      const addressOptions =
        data.contact_addresses?.map((address) => {
          return {
            id: address.id,
            label: getFormattedAddress(address) ?? '',
            key: address.id.toString(),
            customData: address,
          };
        }) || [];

      if (data.contact_addresses?.length && !formikProps.values.contactPersonAddress) {
        setFieldValue('contactPersonAddressType', AddressType.Presaved);
        setFieldValue('contactPersonAddress', addressOptions[0]);
      }

      const hasInitialPreSavedAddress =
        !!formikProps.values.contactPersonAddress?.key &&
        !addressOptions.find(
          ({ label }) => formikProps.values.contactPersonAddress?.label === label,
        );

      if (hasInitialPreSavedAddress) {
        setFieldValue('contactPersonAddressType', AddressType.Google);
      }

      setEnterpriseAddressesOptions(addressOptions || []);
    }
  };

  const getGoogleAddressList = async (query: string) => {
    const { data } = await api.getAddressList({ query, region: 'NO' });

    if (data) {
      const addressOptions =
        data.map((address, index) => {
          return {
            id: index,
            label: address.data.formatted_address,
            key: address.data.place_id,
            customData: getAddressObjectFromGeocode(address.data),
          };
        }) || [];

      setGoogleAddressesOptions(addressOptions);
    }
  };

  useEffect(() => {
    getEnterpriseAddressesList();
  }, [formikProps.values.departmentId]);

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!formikProps.values.contactPersonAddress?.customData) return;

    const customData: BaseContactAddress = {
      ...formikProps.values.contactPersonAddress.customData,
      [e.target.name]: e.target.value,
    };

    setFieldValue('contactPersonAddress', {
      ...formikProps.values.contactPersonAddress,
      label: getFormattedAddress(customData) ?? '',
      customData,
    });
  };

  return (
    <>
      <span className={styles.hint}>
        <FormattedMessage
          id={translationKeys.create_interpretation_order_assignment_type_in_person_hint}
        />
      </span>
      <div
        className={classNames(styles.gridRow, styles.contactRow)}
        data-testid="in-person-assignment-body"
      >
        <TextField
          data-testid="contact-person-name-input"
          label={intl.formatMessage({
            id: translationKeys.create_interpretation_order_assignment_type_in_person_contact_person_label,
          })}
          placeholder={intl.formatMessage({
            id: translationKeys.create_interpretation_order_assignment_type_in_person_contact_person_placeholder,
          })}
          name="contactPersonName"
          value={formikProps.values.contactPersonName}
          onChange={formikProps.handleChange}
          onBlur={formikProps.handleBlur}
          errorText={
            formikProps.touched.contactPersonName ? formikProps.errors.contactPersonName : undefined
          }
          size="large"
        />
        <PhoneCodeDropdown
          data-testid="phone-code-dropdown"
          selected={formikProps.values.contactPersonCountryCode}
          placeholder="+47"
          label={intl.formatMessage({
            id: translationKeys.create_interpretation_order_assignment_type_in_person_contact_country_code_label,
          })}
          onChange={(phoneCodes) => {
            setFieldValue('contactPersonCountryCode', phoneCodes?.[0] ?? undefined);
          }}
        />
        <TextField
          data-testid="phone-number-input"
          placeholder={intl.formatMessage({
            id: translationKeys.create_interpretation_order_assignment_type_in_person_contact_phone_number_placeholder,
          })}
          type="text"
          label={intl.formatMessage({
            id: translationKeys.create_interpretation_order_assignment_type_in_person_contact_phone_number_label,
          })}
          size="large"
          name="contactPersonPhoneNumber"
          onChange={formikProps.handleChange}
          onBlur={formikProps.handleBlur}
          value={formikProps.values.contactPersonPhoneNumber}
          errorText={
            formikProps.touched.contactPersonPhoneNumber
              ? formikProps.errors.contactPersonPhoneNumber
              : undefined
          }
        />
      </div>
      {formikProps.values.contactPersonAddressType === AddressType.Presaved ? (
        <>
          <div>
            <Form.Label>
              <FormattedMessage
                id={
                  translationKeys.create_interpretation_order_assignment_type_in_person_contact_address_label
                }
              />
            </Form.Label>
            <div className={classNames(styles.gridRow, styles.addressRow)}>
              <SearchDropdown
                data-testid="enterprise-address-dropdown"
                options={enterpriseAddressesOptions}
                size="large"
                placeholder={intl.formatMessage({
                  id: translationKeys.create_interpretation_order_assignment_type_in_person_contact_address_placeholder,
                })}
                selected={
                  formikProps.values.contactPersonAddress
                    ? [formikProps.values.contactPersonAddress]
                    : []
                }
                onChange={(address) => {
                  setFieldValue('contactPersonAddress', address?.[0] ?? undefined);
                }}
                errorText={
                  formikProps.touched.contactPersonAddress
                    ? getIn(formikProps.errors, 'contactPersonAddress.label')
                    : undefined
                }
              />
              <Button
                data-testid="use-other-address-button"
                variant="gray"
                size="x-large"
                className={styles.addAddress}
                onClick={() => {
                  setFieldValue('contactPersonAddressType', AddressType.Google);
                  setFieldValue('contactPersonAddress', enterpriseAddressesOptions[0]);
                }}
              >
                <FormattedMessage
                  id={
                    translationKeys.create_interpretation_order_assignment_type_in_person_use_other_address_button
                  }
                />
              </Button>
            </div>
          </div>
        </>
      ) : (
        <>
          {!!enterpriseAddressesOptions.length && (
            <div>
              <Button
                data-testid="show-saved-addresses-button"
                className={styles.savedAddresses}
                variant="link"
                onClick={() => {
                  setFieldValue('contactPersonAddressType', AddressType.Presaved);
                  setFieldValue('contactPersonAddress', enterpriseAddressesOptions[0]);
                }}
                icon={<FontAwesomeIcon icon={faList} />}
              >
                <FormattedMessage
                  id={
                    translationKeys.create_interpretation_order_assignment_type_in_person_show_saved_addresses_button
                  }
                />
              </Button>
            </div>
          )}
          <SearchDropdown
            data-testid="google-address-dropdown"
            options={googleAddressesOptions}
            search
            searchAddress
            size="large"
            label={intl.formatMessage({
              id: translationKeys.create_interpretation_order_assignment_type_in_person_contact_address_label,
            })}
            placeholder={intl.formatMessage({
              id: translationKeys.create_interpretation_order_assignment_type_in_person_contact_address_search_placeholder,
            })}
            selected={
              formikProps.values.contactPersonAddress
                ? [formikProps.values.contactPersonAddress]
                : []
            }
            onSearch={(query: string) => {
              debounceAddressSearch(() => {
                getGoogleAddressList(query);
              });
            }}
            onChange={(address) => {
              setFieldValue('contactPersonAddress', address?.[0] ?? undefined);
            }}
            errorText={
              formikProps.touched.contactPersonAddress
                ? getIn(formikProps.errors, 'contactPersonAddress.label')
                : undefined
            }
          />
          {formikProps.values.contactPersonAddress && (
            <>
              <TextField
                data-testid="address-line-1"
                size="large"
                name="line1"
                label={intl.formatMessage({
                  id: translationKeys.create_interpretation_order_assignment_type_in_person_contact_address_line_1_label,
                })}
                placeholder={intl.formatMessage({
                  id: translationKeys.create_interpretation_order_assignment_type_in_person_contact_address_line_1_placeholder,
                })}
                value={formikProps.values.contactPersonAddress.customData?.line1 ?? ''}
                onChange={handleAddressChange}
                errorText={
                  formikProps.touched.contactPersonAddress
                    ? getIn(formikProps.errors, 'contactPersonAddress.customData.line1')
                    : undefined
                }
              />
              <TextField
                data-testid="address-line-2"
                name="line2"
                size="large"
                label={intl.formatMessage({
                  id: translationKeys.create_interpretation_order_assignment_type_in_person_contact_address_line_2_label,
                })}
                placeholder={intl.formatMessage({
                  id: translationKeys.create_interpretation_order_assignment_type_in_person_contact_address_line_2_placeholder,
                })}
                value={formikProps.values.contactPersonAddress.customData?.line2 ?? ''}
                onChange={handleAddressChange}
                errorText={
                  formikProps.touched.contactPersonAddress
                    ? getIn(formikProps.errors, 'contactPersonAddress.customData.line2')
                    : undefined
                }
              />
              <div className={classNames(styles.gridRow, styles.postcodeRow)}>
                <TextField
                  data-testid="postcode"
                  size="large"
                  name="postcode"
                  label={intl.formatMessage({
                    id: translationKeys.create_interpretation_order_assignment_type_in_person_contact_address_postcode_label,
                  })}
                  placeholder={intl.formatMessage({
                    id: translationKeys.create_interpretation_order_assignment_type_in_person_contact_address_postcode_placeholder,
                  })}
                  value={formikProps.values.contactPersonAddress.customData?.postcode ?? ''}
                  onChange={handleAddressChange}
                  errorText={
                    formikProps.touched.contactPersonAddress
                      ? getIn(formikProps.errors, 'contactPersonAddress.customData.postcode')
                      : undefined
                  }
                />
                <TextField
                  data-testid="city"
                  size="large"
                  name="city"
                  label={intl.formatMessage({
                    id: translationKeys.create_interpretation_order_assignment_type_in_person_contact_address_city_label,
                  })}
                  placeholder={intl.formatMessage({
                    id: translationKeys.create_interpretation_order_assignment_type_in_person_contact_address_city_placeholder,
                  })}
                  value={formikProps.values.contactPersonAddress.customData?.city ?? ''}
                  onChange={handleAddressChange}
                  errorText={
                    formikProps.touched.contactPersonAddress
                      ? getIn(formikProps.errors, 'contactPersonAddress.customData.city')
                      : undefined
                  }
                />
              </div>
              <div className={classNames(styles.gridRow, styles.evenRow)}>
                <TextField
                  data-testid="county"
                  size="large"
                  name="county"
                  label={intl.formatMessage({
                    id: translationKeys.create_interpretation_order_assignment_type_in_person_contact_address_county_label,
                  })}
                  placeholder={intl.formatMessage({
                    id: translationKeys.create_interpretation_order_assignment_type_in_person_contact_address_county_placeholder,
                  })}
                  value={formikProps.values.contactPersonAddress.customData?.county ?? ''}
                  onChange={handleAddressChange}
                  errorText={
                    formikProps.touched.contactPersonAddress
                      ? getIn(formikProps.errors, 'contactPersonAddress.customData.county')
                      : undefined
                  }
                />
                <TextField
                  data-testid="country"
                  size="large"
                  name="country"
                  label={intl.formatMessage({
                    id: translationKeys.create_interpretation_order_assignment_type_in_person_contact_address_country_label,
                  })}
                  placeholder={intl.formatMessage({
                    id: translationKeys.create_interpretation_order_assignment_type_in_person_contact_address_country_placeholder,
                  })}
                  value={formikProps.values.contactPersonAddress.customData?.country ?? ''}
                  onChange={handleAddressChange}
                  errorText={
                    formikProps.touched.contactPersonAddress
                      ? getIn(formikProps.errors, 'contactPersonAddress.customData.country')
                      : undefined
                  }
                />
              </div>
            </>
          )}
          <TextField
            data-testid="contact-person-directions"
            label={intl.formatMessage({
              id: translationKeys.create_interpretation_order_assignment_type_in_person_contact_address_directions_label,
            })}
            placeholder={intl.formatMessage({
              id: translationKeys.create_interpretation_order_assignment_type_in_person_contact_address_directions_placeholder,
            })}
            name="contactPersonDirections"
            value={formikProps.values.contactPersonDirections}
            onChange={formikProps.handleChange}
            onBlur={formikProps.handleBlur}
            errorText={
              formikProps.touched.contactPersonDirections
                ? formikProps.errors.contactPersonDirections
                : undefined
            }
            size="large"
            textArea
          />

          <Form.Check
            data-testid="save-address-checkbox"
            type="checkbox"
            label={intl.formatMessage({
              id: translationKeys.create_interpretation_order_assignment_type_in_person_save_address_checkbox,
            })}
            checked={formikProps.values.saveAddress}
            onChange={(e) => setFieldValue('saveAddress', e.target.checked)}
          />
        </>
      )}
      <>
        <span className={styles.title}>
          <FormattedMessage
            id={translationKeys.create_interpretation_order_assignment_type_alternative_type_label}
          />
        </span>
        <RadioOptions
          label={intl.formatMessage({
            id: translationKeys.create_interpretation_order_assignment_type_if_no_interpreter_available,
          })}
          options={[
            {
              id: AssignmentInPersonAlternative.PhoneInterpreter,
              title: intl.formatMessage({
                id: translationKeys.create_interpretation_order_assignment_type_alternative_type_phone,
              }),
              dataTestId: 'phone-interpreter-option',
            },
            {
              id: AssignmentInPersonAlternative.VideoInterpreter,
              title: intl.formatMessage({
                id: translationKeys.create_interpretation_order_assignment_type_alternative_type_video,
              }),
              dataTestId: 'video-interpreter-option',
            },
            {
              id: AssignmentInPersonAlternative.CancelAssignment,
              title: intl.formatMessage({
                id: translationKeys.create_interpretation_order_assignment_type_alternative_type_cancel,
              }),
              dataTestId: 'cancel-assignment-option',
            },
          ]}
          selected={formikProps.values.assignmentInPersonAlternative}
          onSelect={(option) =>
            setFieldValue('assignmentInPersonAlternative', option as AssignmentInPersonAlternative)
          }
          errorText={
            formikProps.touched.assignmentInPersonAlternative || formikProps.touched.sessionType
              ? formikProps.errors.assignmentInPersonAlternative
              : undefined
          }
        />
      </>
      {formikProps.values.assignmentInPersonAlternative ===
        AssignmentInPersonAlternative.VideoInterpreter && (
        <div className={classNames(styles.gridRow, styles.videoWrapper)}>
          <AssignmentTypeVideoSection formikProps={formikProps} inPersonMode />
        </div>
      )}
    </>
  );
};
