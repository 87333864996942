import React from 'react';
import {
  faAddressCard,
  faClone,
  faEnvelope,
  faMailbox,
  faRightFromBracket,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ManagerOrderSourceType } from '../../types/ManagerOrder';

const getOrderSourceIcon = (status: ManagerOrderSourceType) => {
  switch (status) {
    case ManagerOrderSourceType.ProjectEmail:
    case ManagerOrderSourceType.OversettelseEmail:
    case ManagerOrderSourceType.PersonalEmail:
    case ManagerOrderSourceType.PostEmail:
      return <FontAwesomeIcon icon={faEnvelope} />;
    case ManagerOrderSourceType.Post:
      return <FontAwesomeIcon icon={faMailbox} />;
    case ManagerOrderSourceType.CopiedFromXtrf:
      return <FontAwesomeIcon icon={faClone} />;
    case ManagerOrderSourceType.Logout:
      return <FontAwesomeIcon icon={faRightFromBracket} />;
    case ManagerOrderSourceType.Platform:
      return <FontAwesomeIcon icon={faAddressCard} />;
  }
};

export default getOrderSourceIcon;
