import {
  CustomerPaymentMethodInvoiceFrequency,
  CustomerPaymentMethodInvoiceGroupStrategy,
  CustomerPaymentMethodType,
} from './CustomerPaymentMethod';

export enum ManagerJobStatus {
  Published = 'published',
  Accepted = 'accepted',
  InProgress = 'in_progress',
  Finished = 'finished',
  Closed = 'closed',
  Cancelled = 'cancelled',
}

export enum ManagerJobSessionType {
  Video = 'video',
  Phone = 'phone',
  InPerson = 'in_person',
  VideoRoom = 'videoroom',
}

export enum ManagerJobBookingMechanism {
  Platform = 'platform',
  Phone = 'phone',
  Email = 'email',
  Chat = 'chat',
  SalitaConnect = 'salita_connect',
  PunchOut = 'punch_out',
  PriceCheck = 'price_check',
}

export enum ManagerJobGender {
  Male = 'male',
  Female = 'female',
  Both = 'both',
  Transgender = 'transgender',
}

export enum ManagerJobVideoPlatformName {
  Other = 'other',
  Salita = 'Salita',
  Whereby = 'Whereby',
  GoogleMeet = 'Google meet',
  AppleFacetime = 'Apple Facetime',
  Cisco = 'Cisco',
  MicrosoftTeams = 'Microsoft Teams',
  Pexip = 'Pexip',
  Skype = 'Skype',
  Zoom = 'Zoom',
}

export enum ManagerJobInviteStatus {
  Ongoing = 'ongoing',
  Paused = 'paused',
  Disabled = 'disabled',
  Finished = 'finished',
  NoSuitableInterpreters = 'no_suitable_interpreters',
}

export enum ManagerJobAdminAutoInviteProcessing {
  AdminAutoInviteNoAction = 'admin_auto_invite_no_action',
  AdminAutoInviteStart = 'admin_auto_invite_start',
  AdminAutoInviteStop = 'admin_auto_invite_stop',
}

export enum ManagerJobStandbyProcessing {
  TurnedOff = 'turned_off',
  TurnedOn = 'turned_on',
}

export enum ManagerJobAutoUpdateToSessionType {
  Video = 'video',
  Phone = 'phone',
  InPerson = 'in_person',
  VideoRoom = 'videoroom',
}

export enum ManagerJobSessionTypeProcessing {
  SessionTypeNoAction = 'session_type_no_action',
  SessionTypeAutoUpdate = 'session_type_auto_update',
  SessionTypeCancelNotFound = 'session_type_cancel_not_found',
}

export enum ManagerJobGenderProcessing {
  GenderNoAction = 'gender_no_action',
  GenderAutoUpdate = 'gender_auto_update',
  GenderCancelNotFound = 'gender_cancel_not_found',
}

export enum ManagerJobQualificationProcessing {
  qualification_no_action = 'qualification_no_action',
  qualification_auto_update = 'qualification_auto_update',
  qualification_cancel_not_found = 'qualification_cancel_not_found',
}

export enum ManagerJobAlternativeLanguageProcessing {
  AlternativeLanguageNoAction = 'alternative_language_no_action',
  AlternativeLanguageAutoUpdate = 'alternative_language_auto_update',
}

export enum ManagerJobDirectProcessing {
  DirectNoAction = 'direct_no_action',
  DirectFindAnother = 'direct_find_another',
  DirectCancelSaidNo = 'direct_cancel_said_no',
}

export enum ManagerJobLastActivityType {
  JobUpdated = 'job_updated',
  JobUpdatedFeesAndOrder = 'job_updated_fees_and_order',
  JobUpdatedExpenses = 'job_updated_expenses',
  JobUpdatedInvoicing = 'job_updated_invoicing',
  JobUpdatedAssignmentType = 'job_updated_assignment_type',
  JobUpdatedReferences = 'job_updated_references',
  ApplicationInvited = 'application_invited',
  ApplicationWithdrawn = 'application_withdrawn',
  JobCreated = 'job_created',
}

export enum ManagerJobBookingReferenceFrom {
  Owner = 'owner',
  Department = 'department',
  Enterprise = 'enterprise',
  User = 'user',
}

export enum ManagerJobType {
  Default = 'default',
  DrivingLicense = 'driving_license',
  NavJob = 'nav_job',
  Secretary = 'secretary',
}

export interface ManagerJobAddress {
  line1: string | null;
  line2: string | null;
  postcode: string | null;
  city: string | null;
  county: string | null;
  country: string | null;
  latitude: number | null;
  longitude: number | null;
}

export interface CreateManagerJob {
  ownerUid: string;
  departmentId?: string;
  realOwnerName?: string;
  paymentMethodId: string;
  bookingReference?: string;
  paymentBookingReference?: string;
  caseNumber?: string;
}

export interface ManagerJobAcceptedInterpreter {
  id: number;
  city: string;
  country: string;
  hasStandbyForJob: boolean;
  notesCount: number;
  person: {
    avatar: string;
    email: string;
    gender: ManagerJobGender;
    name: string;
    phone: string;
    phoneCode: string;
    phoneNumber: string;
    uid: string;
  };
  skills: {
    id: number;
    languageFromId: number;
    languageToId: number;
    qualificationId: number;
  }[];
}

export interface ManagerJobPerson {
  uid: string;
  name: string;
  avatar: string | null;
  email: string;
  phone: string | null;
  phoneCode: string | null;
  phoneNumber: string | null;
  gender: ManagerJobGender;
}

export interface ManagerJobDetails {
  id: number;
  createdAt: string;
  actualCreatedAt: string;
  status: ManagerJobStatus;
  acceptedInterpreter: ManagerJobAcceptedInterpreter | null;
  agreementName: string | null;
  bookingMechanism: ManagerJobBookingMechanism;
  interpretationRequirement: {
    languageFromId: number;
    languageToId: number;
    dialect: string | null;
    alternativeLanguageToId: number | null;
    startTime: string;
    finishTime: string;
    confirmationBy: string | null;
    qualificationId: number;
    specificQualificationRequested: boolean;
    jobType: ManagerJobType;
    sex: ManagerJobGender;
    sessionType: ManagerJobSessionType;
    videoPlatformId: number | null;
    videoPlatformInstructions: string | null;
    otherVideoPlatform: boolean | null;
    videoPlatformUrl: string | null;
  };
  processRequirement: {
    sessionTypeProcessing: ManagerJobSessionTypeProcessing;
    autoUpdateToSessionType: ManagerJobAutoUpdateToSessionType | null;
    genderProcessing: ManagerJobGenderProcessing;
    directProcessing: ManagerJobDirectProcessing;
    qualificationProcessing: ManagerJobQualificationProcessing;
    alternativeLanguageProcessing: ManagerJobAlternativeLanguageProcessing;
    alternativeLanguageActivated: boolean;
    autoUpdateSessionTypeAt: string | null;
    autoUpdateGenderAt: string | null;
    autoUpdateAlternativeLanguageAt: string | null;
    qualificationAutoUpdated: boolean;
  };
  info: {
    subject: string | null;
    jobCategoryId: number | null;
    jobCategoryName: string | null;
    jobCategoryItemId: number | null;
    jobCategoryItemName: string | null;
    realPersonName: string | null;
    caseNumber: string | null;
    bookingReference: string | null;
    paymentBookingReference: string | null;
    ccEmails: string[] | null;
    description: string | null;
    manualBookingFeeApplied: boolean;
    manualEditingFeeApplied: boolean;
    manualTravelBookingFeeApplied: boolean;
  };
  owner: ManagerJobPerson | null;
  enterprise: {
    id: number;
    name: string;
    orgNumber: string;
    logoUrl: string | null;
    adminEmail: string | null;
    ownerFullName: string | null;
    fullPhoneNumber: string | null;
    isPolice: boolean;
    defaultBookingReference: string | null;
    defaultPaymentBookingReference: string | null;
    defaultPaymentMethodId: number | null;
    departmentsCount: number;
    membersCount: number;
    address: ManagerJobAddress | null;
    owner: {
      fullName: string | null;
      uid: string | null;
    } | null;
  } | null;
  department: {
    id: number;
    name: string;
    phoneCode: string;
    phoneNumber: string;
    email: string | null;
    defaultBookingReference: string | null;
    defaultPaymentBookingReference: string | null;
  } | null;
  paymentMethod: {
    id: number;
    archived: boolean;
    orgNumber: string;
    orgName: string;
    email: null | string;
    phoneCode: null | string;
    phoneNumber: null | string;
    invoiceGroupStrategy: CustomerPaymentMethodInvoiceGroupStrategy;
    invoiceFrequency: null | string;
    invoiceDeliveryMechanizm: CustomerPaymentMethodInvoiceFrequency | null;
    methodType: CustomerPaymentMethodType;
    address: null | ManagerJobAddress;
    isBlacklisted: boolean;
    blacklistMessage: null | string;
    createdAt: string;
    updatedAt: string;
    default: boolean;
  };
  contact: {
    name: string | null;
    phone: string | null;
    extra: string | null;
    line1: string | null;
    line2: string | null;
    postcode: string | null;
    city: string | null;
    county: string | null;
    country: string | null;
    latitude: number | null;
    longitude: number | null;
  } | null;
  attendees: ManagerJobPerson[] | null;
  attachments:
    | {
        fileName: string | null;
        fileUrl: string | null;
      }[]
    | null;
  blockedInterpreters: ManagerJobPerson[] | null;
  blockedCities: ManagerJobBlockedCity[] | null;
  referenceSettings: {
    allowBookingReference: boolean;
    allowCaseNumber: boolean;
    allowPaymentBookingReference: boolean;
    labelForBookingReference: string;
    labelForCaseNumber: string;
    labelForPaymentBookingReference: string;
    requireBookingReference: boolean;
    requireCaseNumber: boolean;
    requirePaymentBookingReference: boolean;
  };
  jobLockScheduledAt: string | null;
  isLocked: boolean;
}

interface ManagerJob {
  id: number;
  status: ManagerJobStatus;
  startTime: string;
  confirmationBy?: string;
  confirmationDeadline?: string;
  finishTime: string;
  langFromId: number;
  langToId: number;
  alternativeLangToId?: number;
  sessionType: ManagerJobSessionType;
  bookingMechanism: ManagerJobBookingMechanism;
  owner: string;
  ownerName: string;
  ownerEmail: string;
  ownerPhone?: string;
  ownerUID: string;
  enterprise?: string;
  enterpriseId?: number;
  department?: string;
  departmentId?: number;
  godmodeSupplierUrl?: string;
  godmodeDemanderUrl: string;
  actualCreatedAt: string;
  createdAt: string;
  gender?: ManagerJobGender;
  assignedManagerUid?: string;
  videoPlatformName?: ManagerJobVideoPlatformName;
  autoAward: boolean;
  autoInvite: boolean;
  inviteStatus?: ManagerJobInviteStatus;
  infiniteAutoInvite: boolean;
  nextInvitationTime?: string;
  invitationDelay?: number;
  standbyProcessing?: ManagerJobStandbyProcessing;
  autoUpdateToSessionType?: ManagerJobAutoUpdateToSessionType;
  sessionTypeProcessing?: ManagerJobSessionTypeProcessing;
  genderProcessing?: ManagerJobGenderProcessing;
  qualificationName?: string;
  qualificationPriority?: number;
  specificQualificationRequested?: boolean;
  qualificationProcessing?: ManagerJobQualificationProcessing;
  alternativeLanguageProcessing?: ManagerJobAlternativeLanguageProcessing;
  alternativeLanguageActivated?: boolean;
  directProcessing?: ManagerJobDirectProcessing;
  autoUpdateTime?: string;
  invitationsCount: number;
  applicationsCount: number;
  participantsCount: number;
  extraParticipantsCount: number;
  lastWithdrawalAt?: string;
  manualApprovalNeeded: boolean;
  directlyInvitedInterpreterName?: string;
  directlyInvitedInterpreterAvatarUrl?: string;
  directlyInvitedInterpreterAbandonedAt?: string;
  lastAdminDiscussionAuthorName?: string;
  lastAdminDiscussionCreatedAt?: string;
  lastAdminDiscussionText?: string;
  subject?: string;
  categoryName?: string;
  categoryItemName?: string;
  caseNumber?: string;
  bookingReference?: string;
  paymentBookingReference?: string;
  lastActivityType?: ManagerJobLastActivityType;
  lastActivityCreatedAt?: string;
  lastActivityActorName?: string;
  dangerLevel: 1 | 2 | 3 | 4 | 5 | 6 | 100;
  panicPercentage: number;
  pricingTemplateUiColor?: string;
  pricingTemplateSystemDefault?: Record<string, never>;
  interpreter?: {
    uid: string;
    name: string;
    qualification?: string;
    phone?: string;
    email: string;
  };
  adminDiscussionMessagesCount: number;
}

export interface ManagerJobsResponse {
  jobs: ManagerJob[];
  count: number;
  page: number;
  pages: number;
  category_item_ids: any;
}

export interface ManagerJobBlockedCity {
  country: string;
  county: string;
  name: string;
  latitude: number;
  longitude: number;
}

export interface ManagerJobResponse {
  id: string;
  godmodeDemanderUrl: string;
}

export default ManagerJob;
