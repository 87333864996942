import {
  InterpretationSkillsResponse,
  Qualification,
  TranslationSkillsResponse,
} from '../../types';
import fetchApi, { UrlParams } from '../fetchApi';

export default {
  getInterpretationSkills: (
    urlParams: UrlParams,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi<InterpretationSkillsResponse>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/interpretation_skills`,
      urlParams,
      loadingCallback,
    });
  },
  createInterpretationSkill: (body: any, loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi({
      method: 'POST',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/interpretation_skills`,
      body,
      loadingCallback,
    });
  },
  editInterpretationSkill: (
    skillId: string,
    body: any,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi({
      method: 'PATCH',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/interpretation_skills/${skillId}`,
      body,
      loadingCallback,
    });
  },
  deleteInterpretationSkill: (skillId: string, loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi({
      method: 'DELETE',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/interpretation_skills/${skillId}`,
      loadingCallback,
    });
  },
  getTranslationSkills: (urlParams: UrlParams, loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi<TranslationSkillsResponse>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/translation_skills`,
      urlParams,
      loadingCallback,
    });
  },
  createTranslationSkill: (body: any, loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi({
      method: 'POST',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/translation_skills`,
      body,
      loadingCallback,
    });
  },
  editTranslationSkill: (
    skillId: string,
    body: any,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi({
      method: 'PATCH',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/translation_skills/${skillId}`,
      body,
      loadingCallback,
    });
  },
  deleteTranslationSkill: (skillId: string, loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi({
      method: 'DELETE',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/translation_skills/${skillId}`,
      loadingCallback,
    });
  },
  getQualifications: (loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi<Qualification[]>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/qualifications`,
      loadingCallback,
    });
  },
};
