import React from 'react';
import Avatar from '../Avatar/Avatar';
import Tooltip from '../Tooltip/Tooltip';
import DetailsCardType from './DetailsCardType';
import styles from './DetailsCard.module.scss';

const DetailsCard = ({ avatarUrl, title, subtitleItems, mainItems }: DetailsCardType) => {
  return (
    <div className={styles.detailsCard} data-testid="details-card">
      <div className={styles.header}>
        <Avatar altText={title || 'logo'} url={avatarUrl} size="large" />

        <div className={styles.headerContent}>
          <div>
            <span data-testid="details-card-title" className={styles.detailsTitle}>
              {title}
            </span>
          </div>

          <div className={styles.headerValues}>
            {subtitleItems.map((item, i) => {
              return (
                item.value &&
                (item.tooltip ? (
                  <Tooltip key={i} title={item.tooltip}>
                    <span data-testid={item['data-itemid'] || `details-card-subtitle-item-${i}`}>
                      {item.icon}
                      {item.value}
                    </span>
                  </Tooltip>
                ) : (
                  <span
                    key={i}
                    data-testid={item['data-itemid'] || `details-card-subtitle-item-${i}`}
                  >
                    {item.icon}
                    {item.value}
                  </span>
                ))
              );
            })}
          </div>
        </div>
      </div>

      {mainItems &&
        !!mainItems.length &&
        !!mainItems.filter((item) => item.value !== undefined).length && (
          <>
            <hr data-testid="separator" />
            <div className={styles.footer}>
              {mainItems.map((item, i) => {
                return (
                  item.value && (
                    <div key={i}>
                      <span
                        className={styles.label}
                        data-testid={`details-card-main-item-label-${i}`}
                      >
                        {item.label}
                      </span>
                      <span
                        className={styles.value}
                        data-testid={item['data-itemid'] || `details-card-main-item-value-${i}`}
                      >
                        {item.icon}
                        {item.value}
                      </span>
                    </div>
                  )
                );
              })}
            </div>
          </>
        )}
    </div>
  );
};

export default DetailsCard;
