import { IntlShape } from 'react-intl';
import translationKeys from '../../translations/translationKeys';
import { ManagerSupplierSex } from '../../types';

const getGenderName = (gender: ManagerSupplierSex, intl: IntlShape): string => {
  if (gender === ManagerSupplierSex.Male) {
    return intl.formatMessage({ id: translationKeys.suppliers_page_supplier_gender_male });
  }

  if (gender === ManagerSupplierSex.Female) {
    return intl.formatMessage({ id: translationKeys.suppliers_page_supplier_gender_female });
  }

  return '-';
};

export default getGenderName;
