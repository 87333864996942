enum TranslationSubtasksFilterField {
  Id = 'ID',
  OrderId = 'ORDER_ID',
  Document = 'DOCUMENT',
  Status = 'STATUS',
  CustomerInput = 'CUSTOMER_INPUT',
  Owner = 'OWNER',
  Languages = 'LANGUAGES',
  ExternalDeadline = 'EXTERNAL_DEADLINE',
  InternalDeadline = 'INTERNAL_DEADLINE',
  CreatedAt = 'CREATED_AT',
  Translator = 'TRANSLATOR',
  WordCount = 'WORD_COUNT',
}

export default TranslationSubtasksFilterField;
