import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import jwtDecode from 'jwt-decode';
import { useApi } from './ApiProvider';

interface Props {
  children: JSX.Element | JSX.Element[];
}

interface AuthContext {
  setUserNameCache: (username: string) => any;
  setToken: (token: string) => any;
  isAuthenticated: () => boolean;
  username: string;
  userProfile?: any;
}

export const initialState: AuthContext = {
  setUserNameCache: () => null,
  setToken: () => false,
  isAuthenticated: () => false,
  username: '',
  userProfile: null,
};

export const AuthContext = createContext(initialState);

const LOCAL_STORAGE_TOKEN_KEY = 'token';

const AuthProvider: React.FC<Props> = ({ children }) => {
  const [username, setUsername] = useState('');
  // TODO: Need to update this with the type when the endpoint is documented
  const [userProfile, setUserProfile] = useState<any>();
  const api = useApi();

  const setUserNameCache = (username: string) => {
    setUsername(username);
  };

  const getUserProfile = async () => {
    const token = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
    if (!token) return;

    const decoded = jwtDecode(token) as Record<string, string>;
    const userUid = decoded.uid;

    const { data } = await api.getProfile(userUid);
    if (data) {
      const person = data.person;
      if (person) {
        setUserProfile(person);
      }
    }
  };

  useEffect(() => {
    const token = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
    if (!token) return;

    getUserProfile();
  }, []);

  const setToken = (newToken: string) => {
    localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, newToken);
    getUserProfile();
  };

  const isAuthenticated = () => {
    const token = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
    return !!token;
  };

  const AuthContextValue = useMemo(
    () => ({
      isAuthenticated,
      setUserNameCache,
      setToken,
      username,
      userProfile,
    }),
    [isAuthenticated, setUserNameCache, setToken, username, userProfile],
  );

  return <AuthContext.Provider value={AuthContextValue}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;
