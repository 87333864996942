import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { ApiError } from '../Api/fetchApi';
import { Language } from '../types';
import { useApi } from './ApiProvider';
import { useAuth } from './AuthProvider';

interface Props {
  children: JSX.Element | JSX.Element[];
}

interface LanguagesContext {
  languages: Language[];
  isLoading: boolean;
  error: ApiError | null;
  getLanguageById: (languageId: number) => string | undefined;
  getDefaultLanguage: () => Language | undefined;
}

export const initialState: LanguagesContext = {
  languages: [],
  isLoading: false,
  error: null,
  getLanguageById: () => undefined,
  getDefaultLanguage: () => undefined,
};

export const LanguagesContext = createContext(initialState);

const LanguagesProvider: React.FC<Props> = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);
  const [languages, setLanguages] = useState<Language[]>([]);
  const api = useApi();

  const getLanguages = async () => {
    const { data, error } = await api.getLanguages(setIsLoading);

    if (data) {
      setLanguages(data);
    }

    setError(error);
  };

  const getDefaultLanguage = () => {
    return languages.find((language) => language.default_source);
  };

  useEffect(() => {
    if (!isAuthenticated() || languages.length > 0) return;
    getLanguages();
  }, [isAuthenticated]);

  const getLanguageById = (languageId: number) => {
    if (languages) {
      const languageObject = languages.find((language: any) => language.id === languageId);

      if (languageObject) {
        return languageObject.name;
      }
    }

    return '-';
  };

  const LanguagesContextValue = useMemo(
    () => ({
      languages,
      isLoading,
      error,
      getLanguageById,
      getDefaultLanguage
    }),
    [languages, isLoading, error],
  );

  return (
    <LanguagesContext.Provider value={LanguagesContextValue}>{children}</LanguagesContext.Provider>
  );
};

export const useLanguages = () => useContext(LanguagesContext);

export default LanguagesProvider;
