export enum InterpretationSkillsAccountStatus {
  Active = 'active',
  Paused = 'paused',
  Blocked = 'blocked',
  Retired = 'retired',
  Banned = 'banned',
  Deleted = 'deleted',
  Deceased = 'deceased',
  Inactive = 'inactive',
}

export enum InterpretationSkillsStatus {
  Approved = 'approved',
  Pending = 'pending',
  Learning = 'learning',
  Declined = 'declined',
}

export enum InterpretationSkillSex {
  Male = 'male',
  Female = 'female',
  Transgender = 'transgender',
}

export enum InterpretationSkillStandBy {
  Plus = 'plus',
  Yes = 'yes',
  No = 'no',
}

interface InterpretationSkill {
  id: number;
  uid: string;
  godmodeSupplierUrl: string;
  langFromId: number;
  langToId: number;
  dialectFrom: string;
  dialectTo: string;
  qualificationId: number;
  qualificationIsInTolkeregister: boolean;
  verifiedTolkId: string;
  nativeFrom: boolean;
  nativeTo: boolean;
  status: InterpretationSkillsStatus;
  createdAt: string;
  name: string;
  email: string;
  phone?: string;
  personId: number;
  alternativePhone?: string;
  avatarUrl?: string;
  sex?: InterpretationSkillSex;
  notifySupplier: boolean;
  standby: InterpretationSkillStandBy;
  hasJobAssignment: boolean;
  accountStatus: {
    name: InterpretationSkillsAccountStatus;
    expiresAt?: string;
    comment?: string;
  };
  sessionTypesStatuses?: {
    phone: {
      name?: 'active' | 'paused' | 'blocked' | 'disabled';
      expiresAt?: string;
      comment?: string;
    };
    video: {
      name?: 'active' | 'paused' | 'blocked' | 'disabled';
      expiresAt?: string;
      comment?: string;
    };
    in_person: {
      name?: 'active' | 'paused' | 'blocked' | 'disabled';
      expiresAt?: string;
      comment?: string;
    };
    videoroom: {
      name?: 'active' | 'paused' | 'blocked' | 'disabled';
      expiresAt?: string;
      comment?: string;
    };
  };
  statistics: {
    invited: number;
    withdrawn: number;
    showedUp: number;
    late: number;
    noShow: number;
  };
  anniversary: {
    birth?: string;
    birthAnniversary: boolean;
    accountCreated?: string;
    accountCreatedAnniversary: boolean;
  };
  location?: {
    line1?: string;
    line2?: string;
    postcode?: string;
    city?: string;
    country?: string;
    county?: string;
  };
  note?: {
    author?: string;
    note: string;
    source: 'manual' | 'import' | 'platform';
  };
  notesCount: number;
}

export interface InterpretationSkillsStatistics {
  all: number;
  anniversary: number;
  approved: number;
  declined: number;
  learning: number;
  pending: number;
}

export interface InterpretationSkillsResponse {
  skills: InterpretationSkill[];
  statistics: InterpretationSkillsStatistics;
  count: number;
  page: number;
  pages: number;
}

export default InterpretationSkill;
