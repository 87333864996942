import React from 'react';
import { useIntl } from 'react-intl';
import {
  faBuilding,
  faEnvelope,
  faHashtag,
  faLocationDot,
  faPhone,
  faUser,
  faUsers,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DetailsCard } from '@skiwo/components';
import { formatOrgNumber, formatPhoneNumber } from '@skiwo/utils';
import translationKeys from '../../translations/translationKeys';
import Enterprise from '../../types/Enterprise';

interface EnterpriseCardProps {
  enterprise: Enterprise;
}

const EnterpriseCard = ({ enterprise }: EnterpriseCardProps) => {
  const intl = useIntl();

  const getAddress = () => {
    const address = enterprise.address;
    const postalAddress = [address?.postcode, address?.city].filter((component) => !!component);
    const addressComponents = [address?.line1, postalAddress.join(' '), address?.country].filter(
      (component) => !!component,
    );

    if (addressComponents.length === 0) return;

    return addressComponents.join(', ');
  };

  return (
    <DetailsCard
      avatarUrl={enterprise.logoUrl}
      title={enterprise.name || enterprise.orgNumber || '-'}
      subtitleItems={[
        {
          icon: <FontAwesomeIcon icon={faLocationDot} />,
          value: getAddress(),
          'data-itemid': 'enterprise-address',
        },
        {
          icon: <FontAwesomeIcon icon={faEnvelope} />,
          value: enterprise.adminEmail,
          'data-itemid': 'enterprise-email',
        },
        {
          icon: <FontAwesomeIcon icon={faPhone} />,
          value: formatPhoneNumber(enterprise.fullPhoneNumber),
          'data-itemid': 'enterprise-phone',
        },
      ]}
      mainItems={[
        {
          label: intl.formatMessage({
            id: translationKeys.create_department_drawer_org_info_owner_label,
          }),
          icon: <FontAwesomeIcon icon={faUser} />,
          value: enterprise.ownerFullName,
          'data-itemid': 'enterprise-owner',
        },
        {
          label: intl.formatMessage({
            id: translationKeys.create_department_drawer_org_info_org_number_label,
          }),
          icon: <FontAwesomeIcon icon={faHashtag} />,
          value: formatOrgNumber(enterprise.orgNumber),
          'data-itemid': 'enterprise-org-number',
        },
        {
          label: intl.formatMessage({
            id: translationKeys.create_department_drawer_org_info_department_label,
          }),
          icon: <FontAwesomeIcon icon={faBuilding} />,
          value: enterprise.departmentsCount?.toString(),
          'data-itemid': 'enterprise-departments',
        },
        {
          label: intl.formatMessage({
            id: translationKeys.create_department_drawer_org_info_members_label,
          }),
          icon: <FontAwesomeIcon icon={faUsers} />,
          value: enterprise.membersCount?.toString(),
          'data-itemid': 'enterprise-members',
        },
      ]}
    />
  );
};

export default EnterpriseCard;
