import translationKeys from '../../translations/translationKeys';

export const createTranslationOrderErrorKeys = {
  customerId: translationKeys.create_translation_order_customer_label,
  bookerName: translationKeys.create_translation_order_customer_booker_name_placeholder,
  departmentId: translationKeys.create_translation_order_customer_department_label,
  paymentMethodId: translationKeys.create_translation_order_payment_label,
  sendByPostAddressIncludedInCoverLetter:
    translationKeys.create_translation_order_postage_cover_letter_includes_return_address_checkbox,
  sendByPostAddress:
    translationKeys.create_translation_order_postage_add_return_address_address_label,
  sendByPostEnterpriseName:
    translationKeys.create_translation_order_postage_add_return_address_company_label,
  sendByPostName: translationKeys.create_translation_order_postage_add_return_address_name_label,
  coverLetters: translationKeys.create_translation_order_postage_cover_letter_label,
  bookedSubtaskType: translationKeys.create_translation_order_information_type_label,
  sourceLanguageId: translationKeys.create_translation_order_information_from_label,
  targetLanguageIds: translationKeys.create_translation_order_information_to_label,
  alternativeTargetLanguageId:
    translationKeys.create_translation_order_information_alternative_language_label,
  filePasswords: translationKeys.create_translation_order_information_password_protection_toggle,
  countOfWords: translationKeys.create_translation_order_information_word_count_label,
  externalDeadline: translationKeys.create_translation_order_information_deadline_label,
  translationMethodId: translationKeys.create_translation_order_information_subject_other_option,
  otherSubject: translationKeys.create_translation_order_information_subject_other_option,
  instructionsFromDemander: translationKeys.create_translation_order_information_description_label,
  ccEmails: translationKeys.create_translation_order_settings_cc_label,
  accessLevel: translationKeys.create_translation_order_settings_access_level_label,
};
