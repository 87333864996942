import React, { useEffect, useState } from 'react';
import { Form as B_Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import { faLanguage, faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Banner,
  DatePicker,
  SearchDropdown,
  SearchDropdownMenuOption,
  TextField,
  TimePicker,
} from '@skiwo/components';
import { TimePickerItem } from '@skiwo/components/src/TimePicker/TimePicker';
import { format, isToday } from 'date-fns';
import { FormikErrors, FormikProps } from 'formik';
import { getTypesafeSetFieldValue } from '../../helpers/getTypesafeSetFieldValue';
import isBookedOverThreshold from '../../helpers/isBookedOverThreshold';
import { useApi } from '../../providers/ApiProvider';
import { useLanguages } from '../../providers/LanguagesProvider';
import { useToast } from '../../providers/ToastProvider/ToastProvider';
import translationKeys from '../../translations/translationKeys';
import { Enterprise } from '../../types';
import { EnterpriseJobCategory } from '../../types/EnterpriseJobCategories';
import { AssignmentTypeSection } from '../AssignmentTypeSection/AssignmentTypeSection';
import { CreateInterpretationOrderFormValues } from '../schema';
import SectionContainer from '../SectionContainer/SectionContainer';
import { getAdjustedFinishTime } from '../utils';
import styles from './InformationSection.module.scss';

interface InformationSectionProps {
  formikProps: FormikProps<CreateInterpretationOrderFormValues>;
  enterpriseId?: Enterprise['id'];
  setIsCategoryRequired: (isCategoryRequired: boolean) => void;
  setIsCategorySubjectRequired: (isCategorySubjectRequired: boolean) => void;
  isEditForm?: boolean;
}

const TIMEPICKER_INTERVAL = 5;

export const InformationSection = ({
  formikProps,
  enterpriseId,
  setIsCategoryRequired,
  setIsCategorySubjectRequired,
  isEditForm = false,
}: InformationSectionProps) => {
  const api = useApi();
  const intl = useIntl();
  const { languages } = useLanguages();
  const languageOptions =
    languages
      .map((language) => {
        return { id: language.id, label: language.name || '', key: language.id.toString() };
      })
      .filter((language) => language.id !== formikProps.values.alternativeLanguageTo?.id) || [];
  const [jobCategoryOptions, setJobCategoryOptions] = useState<SearchDropdownMenuOption[]>([]);
  const [jobCategoriesSubjectRequired, setJobCategoriesSubjectRequired] = useState<{
    [id: number]: boolean;
  }>({});
  const { showErrorToast } = useToast();
  const [showDatePicker, setShowDatePicker] = useState<number>();
  const setFieldValue = getTypesafeSetFieldValue(formikProps);

  const getEnterpriseCategories = async () => {
    if (!enterpriseId) return;

    const { data, error } = await api.getEnterpriseCategories(
      enterpriseId.toString(),
      formikProps.values.departmentId,
    );

    if (data) {
      if (data.categories.length) {
        setIsCategoryRequired?.(true);
      }

      const options = data.categories.reduce(
        (acc: SearchDropdownMenuOption[], group: EnterpriseJobCategory) => {
          if (group.items) {
            const itemsWithGroup = group.items?.map((item) => {
              setJobCategoriesSubjectRequired((prev) => {
                return { ...prev, [item.id]: item.subjectrequired };
              });
              return {
                id: item.id,
                label: item.name || '',
                group: group.name || '',
                key: item.id.toString(),
              };
            });
            return [...acc, ...itemsWithGroup];
          }
          return acc;
        },
        [],
      );

      setJobCategoryOptions(options);
    }

    if (error) {
      showErrorToast(error);
    }
  };

  const handleDatePicker = (date: Date, index: number) => {
    if (date) {
      setFieldValue(
        'dates',
        formikProps.values.dates.map((item, i) => {
          if (i !== index) return item;

          const timeframe = isToday(date)
            ? {
                startTime: formikProps.initialValues.dates[index].startTime,
                finishTime: formikProps.initialValues.dates[index].finishTime,
              }
            : {};

          return {
            ...item,
            date,
            ...timeframe,
          };
        }),
      );
    }

    setShowDatePicker(undefined);
  };

  // TODO: There is a bug here and the app crashes when the date is invalid, need to create a ticket for it.
  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const date = e.target.value === '' ? new Date() : new Date(e.target.value);

    handleDatePicker(date, index);
  };

  const handleTimeChange = (
    item: TimePickerItem[] | null,
    index: number,
    fieldName: 'startTime' | 'finishTime',
  ): void => {
    if (!item || !item.length) return;

    const dates = formikProps.values.dates.map((date, i) => {
      if (i !== index) return date;

      const { date: currentDate, startTime } = date;

      const updatedTime = item[0];
      const updatedStartTime = fieldName === 'startTime' ? updatedTime : startTime;
      const updatedFinishTime =
        fieldName === 'finishTime'
          ? updatedTime
          : getAdjustedFinishTime(currentDate, updatedStartTime, TIMEPICKER_INTERVAL);

      return { ...date, finishTime: updatedFinishTime, [fieldName]: updatedTime };
    });

    setFieldValue('dates', dates);
    setFieldValue('isBookingThresholdConfirmationRequired', isBookedOverThreshold(dates));
    setFieldValue('bookingThresholdConfirmation', false);
  };

  const getDateValidationError = (index: number) => {
    if (formikProps.touched.dates && formikProps.errors.dates) {
      const error = formikProps.errors.dates as FormikErrors<{
        date: Date;
        startTime: string;
        finishTime: string;
      }>[];
      return error[index];
    }
    return undefined;
  };

  const handleDialectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const dialect = e.target.value;
    const regex = /^[A-Za-zÆØÅæøå\s]*$/;
    if (regex.test(dialect)) {
      setFieldValue('dialect', dialect);
    }
  };

  useEffect(() => {
    getEnterpriseCategories();
  }, [formikProps.values.departmentId]);

  const sectionContainerProps = isEditForm
    ? {}
    : {
        icon: <FontAwesomeIcon icon={faLanguage} />,
        title: intl.formatMessage({
          id: translationKeys.create_interpretation_order_information_label,
        }),
      };

  return (
    <SectionContainer {...sectionContainerProps} data-testid="information-section">
      <div className={styles.informationSection}>
        <div>
          <div className={styles.flexRow}>
            <div className={styles.languageSelector} data-testid="source-language-dropdown">
              <SearchDropdown
                options={languageOptions}
                data-testid="source-language-dropdown-input"
                selected={
                  formikProps.values.languageId
                    ? [
                        languageOptions.find((item) => {
                          return item.id.toString() === formikProps.values.languageId;
                        }) as SearchDropdownMenuOption,
                      ]
                    : []
                }
                label={intl.formatMessage({
                  id: translationKeys.create_interpretation_order_information_language_label,
                })}
                maxResults={200}
                placeholder={intl.formatMessage({
                  id: translationKeys.create_interpretation_order_information_language_placeholder,
                })}
                errorText={
                  formikProps.touched.languageId ? formikProps.errors.languageId : undefined
                }
                size="large"
                onChange={(language) => {
                  if (language && language.length > 0 && language[0].key) {
                    setFieldValue('languageId', language[0].id.toString());

                    // Clear alternative language if it's the same as the selected language
                    if (formikProps.values.alternativeLanguageTo?.id === language[0].id) {
                      setFieldValue('alternativeLanguageTo', undefined);
                    }
                  } else {
                    setFieldValue('languageId', '');
                  }
                  // Always clear specific qualification when changing source language
                  setFieldValue('specificQualification', undefined);
                }}
              />
            </div>

            <div className={styles.languageSelector} data-testid="target-languages-dropdown">
              <TextField
                name="dialect"
                label={intl.formatMessage({
                  id: translationKeys.create_interpretation_order_information_dialect_label,
                })}
                placeholder={intl.formatMessage({
                  id: translationKeys.create_interpretation_order_information_dialect_placeholder,
                })}
                maxLength={15}
                size="large"
                data-testid="dialect-input"
                onChange={handleDialectChange}
                onBlur={formikProps.handleBlur}
                value={formikProps.values.dialect}
                hint={intl.formatMessage(
                  {
                    id: translationKeys.create_interpretation_order_information_dialect_hint,
                  },
                  { charactersLeft: 15 - formikProps.values.dialect.length },
                )}
                errorText={formikProps.touched.dialect ? formikProps.errors.dialect : undefined}
              />
            </div>
          </div>
        </div>

        <div className={styles.dates}>
          {formikProps.values.dates.map((item, index) => (
            <div key={index}>
              <div className={styles.date}>
                <div className={styles.dateItemWrapper}>
                  <div>
                    <TextField
                      label={intl.formatMessage({
                        id: translationKeys.create_interpretation_order_information_date_label,
                      })}
                      icon={<FontAwesomeIcon icon={faCalendar} />}
                      value={format(item.date, 'dd.MM.yyyy')}
                      size="large"
                      data-testid={`assignment-date-input-${index}`}
                      onFocus={() => setShowDatePicker(index)}
                      onChange={(e) => {
                        handleDateChange(e as React.ChangeEvent<HTMLInputElement>, index);
                      }}
                      type="search"
                      errorText={(getDateValidationError(index)?.date as string) || ''}
                    />
                    {showDatePicker === index && (
                      <DatePicker
                        monthCount={1}
                        onClose={() => setShowDatePicker(undefined)}
                        singleDate
                        onChange={(date) => handleDatePicker(date, index)}
                        selected={formikProps.values.dates[index].date}
                      />
                    )}
                  </div>

                  <div className={styles.timePicker}>
                    <TimePicker
                      label={intl.formatMessage({
                        id: translationKeys.create_interpretation_order_information_start_time_label,
                      })}
                      placeholder="00:00"
                      data-testid={`assignment-start-time-timepicker-${index}`}
                      selected={item.startTime ? [item.startTime] : undefined}
                      onChange={(item) => handleTimeChange(item, index, 'startTime')}
                      errorText={getDateValidationError(index)?.startTime || ''}
                      rangeStart={
                        isToday(formikProps.values.dates[index].date)
                          ? formikProps.initialValues.dates[index].startTime
                          : undefined
                      }
                      interval={TIMEPICKER_INTERVAL}
                    />
                  </div>
                  <div className={styles.timePicker}>
                    <TimePicker
                      label={intl.formatMessage({
                        id: translationKeys.create_interpretation_order_information_finish_time_label,
                      })}
                      placeholder="00:00"
                      data-testid={`assignment-finish-time-timepicker-${index}`}
                      selected={item.finishTime ? [item.finishTime] : undefined}
                      onChange={(item) => handleTimeChange(item, index, 'finishTime')}
                      errorText={(getDateValidationError(index)?.finishTime as string) || ''}
                      rangeStart={formikProps.values.dates[index].startTime}
                      interval={TIMEPICKER_INTERVAL}
                    />
                  </div>
                  {/* TODO: WAITS FOR BE */}
                  {/* {index !== 0 && (
                    <IconButton
                      className={styles.button}
                      icon={<FontAwesomeIcon icon={faTrash} />}
                      onClick={() => {
                        setFieldValue(
                          'dates',
                          formikProps.values.dates.filter((_, i) => i !== index),
                        );
                      }}
                    />
                  )}
                  {formikProps.values.dates.length - 1 === index && customer.seriesOrderEnabled && (
                    <Button
                      className={styles.button}
                      variant="gray"
                      icon={<FontAwesomeIcon icon={faPlus} />}
                      size="x-large"
                      onClick={() =>
                        setFieldValue('dates', [
                          ...formikProps.values.dates,
                          {
                            date: new Date(),
                            startTime: getTimeOption(
                              roundToNearestMinutes(new Date(), {
                                roundingMethod: 'ceil',
                                nearestTo: 30,
                              }),
                            ),
                            finishTime: getTimeOption(
                              roundToNearestMinutes(addHours(new Date(), 1), {
                                roundingMethod: 'ceil',
                                nearestTo: 30,
                              }),
                            ),
                          },
                        ])
                      }
                      data-testid="add-payment-button"
                    >
                      <FormattedMessage
                        id={translationKeys.create_interpretation_order_information_add_time_button}
                      />
                    )}
                    {formikProps.values.dates.length - 1 === index && customer.seriesOrderEnabled && (
                      <Button
                        className={styles.button}
                        variant="gray"
                        icon={<FontAwesomeIcon icon={faPlus} />}
                        size="x-large"
                        onClick={() =>
                          setFieldValue('dates', [
                            ...formikProps.values.dates,
                            {
                              date: new Date(),
                              startTime: getTimeOption(
                                roundToNearestMinutes(new Date(), {
                                  roundingMethod: 'ceil',
                                  nearestTo: 30,
                                }),
                              ),
                              finishTime: getTimeOption(
                                roundToNearestMinutes(addHours(new Date(), 1), {
                                  roundingMethod: 'ceil',
                                  nearestTo: 30,
                                }),
                              ),
                            },
                          ])
                        }
                        data-testid="add-payment-button"
                      >
                        <FormattedMessage
                          id={translationKeys.create_interpretation_order_information_add_time_button}
                        />
                      </Button>
                    )} */}
                </div>
              </div>
              {item.startTime && item.finishTime && item.finishTime.id - item.startTime.id < 15 && (
                <span className={styles.errorMessage} data-testid="timepicker-error">
                  <span className={styles.errorMessageIcon}>
                    <FontAwesomeIcon icon={faTriangleExclamation} />
                  </span>
                  <FormattedMessage
                    id={translationKeys.create_interpretation_order_information_min_time_error}
                  />
                </span>
              )}
            </div>
          ))}
        </div>

        {formikProps.values.isBookingThresholdConfirmationRequired && (
          <B_Form.Check
            className={styles.checkbox}
            type="checkbox"
            label={intl.formatMessage({
              id: translationKeys.create_interpretation_order_information_booking_threshold_info_label,
            })}
            isInvalid={
              formikProps.touched.bookingThresholdConfirmation
                ? Boolean(formikProps.errors.bookingThresholdConfirmation)
                : false
            }
            checked={formikProps.values.bookingThresholdConfirmation}
            onChange={formikProps.handleChange}
            name="bookingThresholdConfirmation"
            data-testid="checkbox-booking-confirmation"
          />
        )}

        {formikProps.values.dates.length > 1 && (
          <Banner
            variant="warning"
            text={
              <span>
                <FormattedMessage
                  id={
                    translationKeys.create_interpretation_order_information_series_assignment_banner
                  }
                />
              </span>
            }
          />
        )}

        <div className={styles.flexRow}>
          {!!jobCategoryOptions.length && (
            <div className={styles.category} data-testid="subject-dropdown">
              <SearchDropdown
                options={jobCategoryOptions}
                size="large"
                data-testid="category-dropdown-input"
                label={intl.formatMessage({
                  id: translationKeys.create_interpretation_order_information_category_label,
                })}
                placeholder={intl.formatMessage({
                  id: translationKeys.create_interpretation_order_information_category_placeholder,
                })}
                grouped
                selected={formikProps.values.jobCategory ? [formikProps.values.jobCategory] : []}
                onChange={(items: SearchDropdownMenuOption[] | null) => {
                  if (items && items.length > 0) {
                    setIsCategorySubjectRequired?.(jobCategoriesSubjectRequired[items[0].id]);
                    setFieldValue('jobCategory', items[0].id === 0 ? undefined : items[0]);
                  } else {
                    setIsCategorySubjectRequired?.(false);
                    setFieldValue('jobCategory', undefined);
                  }
                }}
                errorText={
                  formikProps.touched.jobCategory ? formikProps.errors.jobCategory : undefined
                }
              />
            </div>
          )}

          <div className={styles.subject}>
            <TextField
              name="subject"
              label={intl.formatMessage({
                id: translationKeys.create_interpretation_order_information_subject_label,
              })}
              placeholder={intl.formatMessage({
                id: translationKeys.create_interpretation_order_information_subject_placeholder,
              })}
              value={formikProps.values.subject}
              size="large"
              data-testid="subject-input"
              onChange={formikProps.handleChange}
              onBlur={formikProps.handleBlur}
              errorText={formikProps.touched.subject ? formikProps.errors.subject : undefined}
            />
          </div>
        </div>

        <Banner
          variant="warning"
          text={
            <span>
              <FormattedMessage
                id={translationKeys.create_interpretation_order_information_banner}
              />
            </span>
          }
        />
        <AssignmentTypeSection formikProps={formikProps} enterpriseId={enterpriseId} />
      </div>
    </SectionContainer>
  );
};
