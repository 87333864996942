import { addDays, endOfDay, isWeekend } from 'date-fns';

const getEndOfNextWorkingDay = () => {
  const today = new Date();
  let nextWorkingDay = addDays(today, 1);

  while (isWeekend(nextWorkingDay)) {
    nextWorkingDay = addDays(nextWorkingDay, 1);
  }

  return endOfDay(nextWorkingDay);
};

export default getEndOfNextWorkingDay;
