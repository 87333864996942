import React, { useEffect, useState } from 'react';
import { Form as B_Form } from 'react-bootstrap';
import { createIntl } from 'react-intl';
import { faCheck, faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getLanguage } from '@skiwo/utils';
import classnames from 'classnames';
import Button from '../Button/Button';
import languages from '../translations/languages';
import translationKeys from '../translations/translationKeys';
import styles from './RadioOptions.module.scss';

export interface RadioOption {
  id: string;
  title: React.ReactNode;
  default?: boolean;
  description?: React.ReactNode;
  subtitle?: React.ReactNode;
  icon?: JSX.Element;
  dataTestId?: string;
}

interface Props {
  options: RadioOption[];
  onSelect: (selectedId: string) => void;
  selected?: string;
  label?: React.ReactNode;
  block?: boolean;
  errorText?: string;
}

const RadioOptions = (props: Props) => {
  const { options, selected, label, block, onSelect, errorText } = props;

  const userLanguage = getLanguage();
  const intl = createIntl({
    locale: userLanguage,
    messages: languages[userLanguage],
  });

  const [selectedId, setSelectedId] = useState<string | undefined>(selected);

  const handleClick = (value: string) => {
    setSelectedId(value);
    onSelect(value);
  };

  useEffect(() => {
    if (!selected) {
      const defaultOption = options.find((option) => option.default);
      if (defaultOption) {
        setSelectedId(defaultOption.id);
        onSelect(defaultOption.id);
      }
    }
    if (selectedId !== selected) {
      setSelectedId(selected);
    }
  }, [options, selected]);

  return (
    <div>
      {label && <span className={styles.label}>{label}</span>}
      <div className={classnames(styles.radioOptions)}>
        {options.map((option) => (
          <Button
            key={option.id}
            className={classnames(styles.option, {
              [styles.block]: block,
              [styles.selected]: selectedId === option.id,
            })}
            size="x-large"
            data-testid={option.dataTestId}
            variant="gray"
            icon={option.icon}
            onClick={() => handleClick(option.id)}
            title={typeof option.title === 'string' ? option.title : undefined}
          >
            <div
              className={classnames(styles.optionContent, {
                [styles.block]: block,
              })}
            >
              <div className={styles.optionText}>
                <div className={styles.titleContainer}>
                  <span className={styles.title}>{option.title}</span>
                  {option.default && (
                    <span className={styles.defaultBadge}>
                      {intl.formatMessage({ id: translationKeys.radio_options_default_label })}
                    </span>
                  )}
                </div>
                {option.subtitle && <span className={styles.subtitle}>{option.subtitle}</span>}
                {block && option.description && (
                  <span className={styles.description}>{option.description}</span>
                )}
              </div>

              <div
                className={classnames(styles.selectedIndicator, {
                  [styles.selected]: selectedId === option.id,
                })}
              >
                {selectedId === option.id && (
                  <FontAwesomeIcon icon={faCheck} className={styles.selectedIcon} />
                )}
              </div>
            </div>
          </Button>
        ))}
      </div>

      {errorText && (
        <B_Form.Text className={styles.errorMessage}>
          <span>
            <FontAwesomeIcon icon={faTriangleExclamation} />
          </span>
          <span data-testid="radio-options-error-text">{errorText}</span>
        </B_Form.Text>
      )}
    </div>
  );
};

export default RadioOptions;
