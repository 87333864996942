import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { faLocationDot, faPhone, faVideo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RadioOptions } from '@skiwo/components';
import { FormikProps } from 'formik';
import { getTypesafeSetFieldValue } from '../../helpers/getTypesafeSetFieldValue';
import translationKeys from '../../translations/translationKeys';
import { Enterprise, ManagerJobSessionType } from '../../types';
import { CreateInterpretationOrderFormValues } from '../schema';
import { AssignmentTypeInPersonSection } from './AssignmentTypeInPersonSection/AssignmentTypeInPersonSection';
import { AssignmentTypeVideoSection } from './AssignmentTypeVideoSection/AssignmentTypeVideoSection';
import styles from './AssignmentTypeSection.module.scss';

interface AssignmentTypeSectionProps {
  formikProps: FormikProps<CreateInterpretationOrderFormValues>;
  enterpriseId?: Enterprise['id'];
}

export const AssignmentTypeSection = ({
  formikProps,
  enterpriseId,
}: AssignmentTypeSectionProps) => {
  const setFieldValue = getTypesafeSetFieldValue(formikProps);
  const intl = useIntl();

  const getAssignmentTypeComponent = () => {
    switch (formikProps.values.sessionType) {
      case ManagerJobSessionType.Phone:
        return (
          <span className={styles.hint} data-testid="phone-assignment-body">
            <FormattedMessage
              id={translationKeys.create_interpretation_order_assignment_type_phone_hint}
            />
          </span>
        );
      case ManagerJobSessionType.Video:
        return <AssignmentTypeVideoSection formikProps={formikProps} />;
      case ManagerJobSessionType.InPerson:
        return (
          <AssignmentTypeInPersonSection formikProps={formikProps} enterpriseId={enterpriseId} />
        );
      default:
        return null;
    }
  };

  const resetAssignmentType = () => {
    setFieldValue('videoPlatformUrl', '');
    setFieldValue('videoPlatformInstructions', '');
    setFieldValue('videoPlatformDataRequired', true);
    setFieldValue('videoPlatform', undefined);

    setFieldValue('contactPersonName', '');
    setFieldValue('contactPersonPhoneNumber', '');
    setFieldValue('contactPersonAddress', undefined);
  };

  return (
    <div className={styles.assignmentTypeSection} data-testid="assignment-section">
      <span className={styles.title}>
        {intl.formatMessage({
          id: translationKeys.create_interpretation_order_assignment_type_label,
        })}
      </span>
      <RadioOptions
        options={[
          {
            id: ManagerJobSessionType.Phone,
            title: intl.formatMessage({
              id: translationKeys.create_interpretation_order_assignment_type_phone_label,
            }),
            icon: <FontAwesomeIcon icon={faPhone} />,
            dataTestId: 'phone-radio-option',
          },
          {
            id: ManagerJobSessionType.Video,
            title: intl.formatMessage({
              id: translationKeys.create_interpretation_order_assignment_type_video_label,
            }),
            icon: <FontAwesomeIcon icon={faVideo} />,
            dataTestId: 'video-radio-option',
          },
          {
            id: ManagerJobSessionType.InPerson,
            title: intl.formatMessage({
              id: translationKeys.create_interpretation_order_assignment_type_in_person_label,
            }),
            icon: <FontAwesomeIcon icon={faLocationDot} />,
            dataTestId: 'in-person-radio-option',
          },
        ]}
        selected={formikProps.values.sessionType}
        onSelect={(value) => {
          formikProps.setFieldValue('sessionType', value);
          resetAssignmentType();
        }}
      />
      {getAssignmentTypeComponent()}
    </div>
  );
};
