import { AdminChatResponse } from '../../types';
import fetchApi from '../fetchApi';

export default {
  getAdminChat: (orderId: string, loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi<AdminChatResponse>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/orders/${orderId}/manager_chat/messages`,
      loadingCallback,
    });
  },
  createAdminChatMessage: (
    orderId: string,
    message: string,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi({
      method: 'POST',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/orders/${orderId}/manager_chat/messages`,
      body: {
        message: { text: message },
      },
      loadingCallback,
    });
  },
};
