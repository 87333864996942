import { CustomerPaymentMethod } from '../../types';
import CreatePaymentMethod from '../../types/CreatePaymentMethod';
import fetchApi, { UrlParams } from '../fetchApi';

export default {
  getPaymentMethods: (urlParams: UrlParams, loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi<CustomerPaymentMethod[]>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/payment_methods`,
      urlParams,
      loadingCallback,
    });
  },
  createPaymentMethod: (
    enterpriseId: string,
    body: CreatePaymentMethod,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi({
      method: 'POST',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/enterprises/${enterpriseId}/payment_methods`,
      body,
      loadingCallback,
    });
  },
};
