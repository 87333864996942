enum JobsFilterField {
  BuyerAndOrderRef = 'BUYER_AND_ORDER_REF',
  CategoryAndSubject = 'CATEGORY_AND_SUBJECT',
  CreatedDate = 'CREATED_DATE',
  Id = 'ID',
  Interpreter = 'INTERPRETER',
  Language = 'LANGUAGE',
  OwnerAndParticipants = 'OWNER_AND_PARTICIPANTS',
  Status = 'STATUS',
  StartDate = 'START_DATE',
  SessionType = 'SESSION_TYPE',
  Withdrawal = 'WITHDRAWAL',
  QualificationRequested = 'QUALIFICATION_REQUESTED',
  InviteStatus = 'INVITE_STATUS',
  NeedsManualApproval = 'NEEDS_MANUAL_APPROVAL'
}

export default JobsFilterField;
