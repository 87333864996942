import React from 'react';
import { Skeleton } from '@skiwo/components';
import styles from './PaymentSection.module.scss';

export const PaymentSectionSkeleton = () => {
  return (
    <>
      <div className={styles.inputSkeleton}>
        <Skeleton variant="details" />
      </div>

      <hr />

      <div className={styles.inputDetailsSkeleton}>
        <div className={styles.inputSkeleton}>
          <Skeleton variant="details" />
        </div>
        <div className={styles.inputSkeleton}>
          <Skeleton variant="details" />
        </div>
        <div className={styles.inputSkeleton}>
          <Skeleton variant="details" />
        </div>
      </div>
    </>
  );
};
