import { IntlShape } from 'react-intl';
import translationKeys from '../../../../../translations/translationKeys';

export const getAlternativeLanguageTranslation = ({
  value,
  intl,
}: {
  value?: string;
  intl: IntlShape;
}) => {
  if (value === 'no_preference') {
    return intl.formatMessage({
      id: translationKeys.translation_order_alternative_language_no_preference,
    });
  }
  return value || '-';
};
