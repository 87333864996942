import React from 'react';
import ReactDOM from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
import { getLanguage } from '@skiwo/utils';
import ApiProvider from './providers/ApiProvider';
import AuthProvider from './providers/AuthProvider';
import FeatureToggleProvider from './providers/FeatureToggleProvider';
import languages from './translations/languages';
import { Api } from './Api';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@skiwo/styles/global/base.scss';

const language = getLanguage();
const supportedLanguages = Object.keys(languages);
let translations = languages[supportedLanguages[0]];

if (supportedLanguages.includes(language)) {
  translations = languages[language];
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <IntlProvider locale={language} messages={translations}>
      <ApiProvider value={Api}>
        <FeatureToggleProvider>
          <AuthProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </AuthProvider>
        </FeatureToggleProvider>
      </ApiProvider>
    </IntlProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
