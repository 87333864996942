import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import { useApi } from '../../providers/ApiProvider';
import { useLanguages } from '../../providers/LanguagesProvider';
import { useQualifications } from '../../providers/QualificationsProvider';
import translationKeys from '../../translations/translationKeys';
import { Qualification } from '../../types';
import { QualificationIndustry } from '../../types/Qualification';
import { CreateInterpretationOrderFormValues } from '../schema';

interface QualificationOption {
  id: number;
  label: string;
  subtitle?: string;
  key: string;
  disabled: boolean;
}

export default function useQueryInterpreterQualificationsPresence() {
  const { qualifications } = useQualifications();
  const api = useApi();
  const intl = useIntl();
  const formik = useFormikContext<CreateInterpretationOrderFormValues>();
  const { getLanguageById } = useLanguages();
  const [interpreterQualificationOptions, setInterpreterQualificationOptions] = useState<
    QualificationOption[]
  >([]);

  const queryInterpreterQualificationsPresence = async (
    languageId: string,
    qualifications: Qualification[],
  ) => {
    const { data } = languageId ? await api.getQualificationsPresence(languageId) : { data: null };

    function getSubtitle(interpretersCount: number) {
      switch (interpretersCount) {
        case 0:
          return intl.formatMessage({
            id: translationKeys.create_interpretation_order_settings_qualification_subtitle_empty,
          });
        case 1:
          return intl.formatMessage(
            {
              id: translationKeys.create_interpretation_order_settings_qualification_subtitle_singular,
            },
            { count: interpretersCount, language: getLanguageById(parseInt(languageId)) },
          );
        default:
          return intl.formatMessage(
            {
              id: translationKeys.create_interpretation_order_settings_qualification_subtitle_plural,
            },
            { count: interpretersCount, language: getLanguageById(parseInt(languageId)) },
          );
      }
    }

    const internalCategoryMessage = intl.formatMessage({
      id: translationKeys.create_interpretation_order_settings_qualification_subtitle_internal,
    });

    const interpretationQualifications = qualifications.filter(
      (qualification) => qualification.industry === QualificationIndustry.Interpretation,
    );

    setInterpreterQualificationOptions(
      interpretationQualifications.map((qualification) => {
        const interpretersCount =
          data?.[0].qualifications.find(
            ({ qualificationId }) => qualificationId === qualification.id,
          )?.interpretersCount ?? 0;

        const interpreterQualificationOption = {
          id: qualification.id,
          label: qualification.name || '',
          subtitle: qualification.isInTolkeregister
            ? getSubtitle(interpretersCount)
            : internalCategoryMessage,
          disabled: qualification.isInTolkeregister && interpretersCount === 0,
          key: qualification.id.toString(),
        };

        return interpreterQualificationOption;
      }),
    );
  };

  useEffect(() => {
    queryInterpreterQualificationsPresence(formik.values.languageId, qualifications);
  }, [formik.values.languageId, qualifications]);

  return { interpreterQualificationOptions };
}
