import React from 'react';
import { Skeleton } from '@skiwo/components';
import JobsTab from '../JobsTab';
import styles from './JobItemSkeleton.module.scss';

interface Props {
  activeTab?: string;
}

const JobItemSkeleton = (props: Props) => {
  const { activeTab } = props;

  return (
    <tr className={styles.skeleton} data-testid="job-item-skeleton">
      <td className={styles.indicatorSkeleton}>
        <Skeleton />
      </td>
      <td>
        <Skeleton variant="details" />
      </td>
      {activeTab && activeTab === JobsTab.All && (
        <td>
          <Skeleton />
        </td>
      )}
      <td>
        <Skeleton variant="details" />
      </td>
      <td>
        <Skeleton variant="circles" circleCount={5} />
      </td>
      <td>
        <Skeleton variant="details" />
      </td>
      <td>
        <Skeleton variant="details" />
      </td>
      <td>
        <Skeleton variant="details" />
      </td>
      <td>
        <Skeleton variant="details" />
      </td>
      <td>
        <Skeleton variant="details" />
      </td>
      <td>
        <Skeleton />
      </td>
    </tr>
  );
};

export default JobItemSkeleton;
