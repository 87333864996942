import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Spoiler } from '@skiwo/components';
import translationKeys from '../../../../translations/translationKeys';
import { ManagerSupplierType } from '../../../../types';
import styles from './QualificationAccordion.module.scss';

interface QualificationAccordionProps {
  selectedSupplierType: ManagerSupplierType;
}

const QualificationAccordion: React.FC<QualificationAccordionProps> = ({
  selectedSupplierType,
}) => {
  const intl = useIntl();

  return (
    <div className={styles.accordion}>
      {' '}
      <Spoiler
        title={intl.formatMessage({
          id: translationKeys.new_skill_drawer_qualification_spoiler_title,
        })}
      >
        {selectedSupplierType === ManagerSupplierType.Interpreter ? (
          <ul>
            <li>
              <span>
                <FormattedMessage
                  id={translationKeys.new_skill_drawer_interpreter_spoiler_category_A}
                />
              </span>{' '}
              <FormattedMessage
                id={translationKeys.new_skill_drawer_interpreter_spoiler_category_A_text}
              />
            </li>
            <li>
              <span>
                <FormattedMessage
                  id={translationKeys.new_skill_drawer_interpreter_spoiler_category_B}
                />
              </span>{' '}
              <FormattedMessage
                id={translationKeys.new_skill_drawer_interpreter_spoiler_category_B_text}
              />
            </li>
            <li>
              <span>
                <FormattedMessage
                  id={translationKeys.new_skill_drawer_interpreter_spoiler_category_C}
                />
              </span>{' '}
              <FormattedMessage
                id={translationKeys.new_skill_drawer_interpreter_spoiler_category_C_text}
              />
            </li>
            <li>
              <span>
                <FormattedMessage
                  id={translationKeys.new_skill_drawer_interpreter_spoiler_category_D}
                />
              </span>{' '}
              <FormattedMessage
                id={translationKeys.new_skill_drawer_interpreter_spoiler_category_D_text}
              />
            </li>
            <li>
              <span>
                <FormattedMessage
                  id={translationKeys.new_skill_drawer_interpreter_spoiler_category_E}
                />
              </span>{' '}
              <FormattedMessage
                id={translationKeys.new_skill_drawer_interpreter_spoiler_category_E_text}
              />
            </li>
            <li>
              <FormattedMessage
                id={translationKeys.new_skill_drawer_interpreter_spoiler_not_registered_text}
              />
            </li>
          </ul>
        ) : (
          <ul>
            <li>
              <span>
                <FormattedMessage
                  id={translationKeys.new_skill_drawer_translator_spoiler_state_authorized_title}
                />
              </span>{' '}
              <FormattedMessage
                id={translationKeys.new_skill_drawer_translator_spoiler_state_authorized_text}
              />
            </li>
            <li>
              <span>
                <FormattedMessage
                  id={
                    translationKeys.new_skill_drawer_translator_spoiler_professional_translator_title
                  }
                />
              </span>{' '}
              <FormattedMessage
                id={
                  translationKeys.new_skill_drawer_translator_spoiler_professional_translator_text
                }
              />
            </li>
          </ul>
        )}
      </Spoiler>
    </div>
  );
};

export default QualificationAccordion;
