import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  faArrowDownToLine,
  faFile,
  faFilePen,
  faLocationDot,
  faMailbox,
  faTrash,
  faTriangleExclamation,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DetailItem, FileSelector, FileState, RadioOptions, TextField } from '@skiwo/components';
import { formatFileSize } from '@skiwo/utils';
import { FormikProps } from 'formik';
import fileIllustration from '../../assets/default-file-preview.svg';
import translationKeys from '../../translations/translationKeys';
import { ManagerOrderDeliveryMethod } from '../../types';
import { CreateTranslationOrderFormValues } from '../CreateTranslationOrder';
import SectionContainer from '../SectionContainer/SectionContainer';
import styles from './PostageSection.module.scss';

interface PostageSectionProps {
  coverLetterFiles: FileState[];
  setCoverLetterFiles: (file: FileState[]) => void;
  postageAddressRequired: boolean;
  setPostageAddressRequired: (value: boolean) => void;
  postageType: PostageType;
  setPostageType: (value: PostageType) => void;
  formikProps: FormikProps<CreateTranslationOrderFormValues>;
}

export enum PostageType {
  CoverLetter = 'coverLetter',
  Manual = 'manual',
}

export const PostageSection = ({
  coverLetterFiles,
  setCoverLetterFiles,
  postageAddressRequired,
  setPostageAddressRequired,
  postageType,
  setPostageType,
  formikProps,
}: PostageSectionProps) => {
  const intl = useIntl();
  const postageActive = formikProps.values.deliveryMethod === ManagerOrderDeliveryMethod.Post;
  const [coverLetterHasAddress, setCoverLetterHasAddress] = useState(true);

  const handleDownload = (file: FileState) => {
    const blob = new Blob([file.data], { type: file.type });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = file.name;
    link.click();

    URL.revokeObjectURL(url);
  };

  const clearAddress = () => {
    formikProps.setFieldValue('sendByPostName', '');
    formikProps.setFieldValue('sendByPostEnterpriseName', '');
    formikProps.setFieldValue('sendByPostAddress', '');
  };

  return (
    <SectionContainer
      icon={<FontAwesomeIcon icon={faMailbox} />}
      title={intl.formatMessage({ id: translationKeys.create_translation_order_postage_label })}
      description={intl.formatMessage({
        id: translationKeys.create_translation_order_postage_description,
      })}
      action={
        <Form.Check
          type="switch"
          data-testid="postage-toggle"
          checked={postageActive}
          onChange={(e) => {
            formikProps.setFieldValue(
              'deliveryMethod',
              e.target.checked ? ManagerOrderDeliveryMethod.Post : ManagerOrderDeliveryMethod.Email,
            );

            if (!e.target.checked) {
              setCoverLetterFiles([]);
              setCoverLetterHasAddress(true);
              clearAddress();
              setPostageAddressRequired(false);
            }
          }}
        />
      }
      data-testid='postage-section'
    >
      {postageActive && (
        <>
          <RadioOptions
            selected={postageType}
            options={[
              {
                id: PostageType.CoverLetter,
                title: intl.formatMessage({
                  id: translationKeys.create_translation_order_postage_use_cover_letter_radio_option,
                }),
                icon: <FontAwesomeIcon icon={faFile} />,
                dataTestId: 'cover-letter-radiobutton-option',
              },
              {
                id: PostageType.Manual,
                title: intl.formatMessage({
                  id: translationKeys.create_translation_order_postage_add_return_address_manually_radio_option,
                }),
                icon: <FontAwesomeIcon icon={faFilePen} />,
                dataTestId: 'manual-address-return-radio-option',
              },
            ]}
            onSelect={(option) => {
              setPostageType(option as PostageType);
              setPostageAddressRequired(
                (option === PostageType.CoverLetter && !coverLetterHasAddress) ||
                  option === PostageType.Manual,
              );
            }}
          />

          {postageType === PostageType.CoverLetter && (
            <>
              <div className={styles.fileUploader}>
                {!coverLetterFiles.length && (
                  <>
                    <span className={styles.coverLetterLabel}>
                      <FormattedMessage
                        id={translationKeys.create_translation_order_postage_cover_letter_label}
                      />
                    </span>
                    <FileSelector
                      selectedFiles={coverLetterFiles}
                      onSelection={(newFiles) => {
                        setCoverLetterFiles([newFiles[0]]);
                        formikProps.setFieldValue('coverLetters', [newFiles[0]]);
                      }}
                    />
                    {formikProps.touched.coverLetters && formikProps.errors.coverLetters && (
                      <span className={styles.coverLetterErrorMessage}>
                        <span>
                          <FontAwesomeIcon icon={faTriangleExclamation} />
                        </span>
                        {formikProps.errors.coverLetters as string}
                      </span>
                    )}
                  </>
                )}

                <div>
                  {coverLetterFiles.map((file: FileState) => (
                    <DetailItem
                      key={file.id}
                      subtitle={<span>{formatFileSize(file.size)}</span>}
                      title={file.name}
                      preview={
                        file.type.includes('image') ? (
                          <img src={file.preview} height="40" />
                        ) : (
                          <img src={fileIllustration} height="40" />
                        )
                      }
                      onActionSelect={(action) => {
                        if (action === 'Download') {
                          handleDownload(coverLetterFiles[0]);
                        }
                        if (action === 'Delete') {
                          const updatedFiles = coverLetterFiles.filter(
                            (currentFiles) => currentFiles.uid !== file.uid,
                          );
                          setCoverLetterFiles(updatedFiles);
                          formikProps.setFieldValue('coverLetters', []);
                        }
                      }}
                      actions={[
                        {
                          id: 'Download',
                          text: intl.formatMessage({
                            id: translationKeys.create_translation_order_postage_file_download_label,
                          }),
                          icon: faArrowDownToLine,
                        },
                        {
                          id: 'Delete',
                          text: intl.formatMessage({
                            id: translationKeys.create_translation_order_postage_file_delete_label,
                          }),
                          icon: faTrash,
                          variant: 'critical',
                        },
                      ]}
                    />
                  ))}
                </div>
              </div>

              <Form.Check
                type="checkbox"
                label={intl.formatMessage({
                  id: translationKeys.create_translation_order_postage_cover_letter_includes_return_address_checkbox,
                })}
                checked={coverLetterHasAddress}
                onChange={(e) => {
                  formikProps.setFieldValue(
                    'sendByPostAddressIncludedInCoverLetter',
                    e.target.checked,
                  );
                  setCoverLetterHasAddress(e.target.checked);
                  setPostageAddressRequired(!e.target.checked);
                  clearAddress();
                }}
                className={styles.coverLetterHasAddressCheckbox}
                data-testid='cover-letter-checkbox'
              />

              {!coverLetterHasAddress && (
                <span className={styles.returnAddressLabel}>
                  <FormattedMessage
                    id={translationKeys.create_translation_order_postage_return_address_label}
                  />
                </span>
              )}
            </>
          )}

          {postageAddressRequired && (
            <div className={styles.returnAddressFormWrapper}>
              <div className={styles.returnAddressFormFirstRow}>
                <div className={styles.recipientName}>
                  <TextField
                    name="sendByPostName"
                    size="large"
                    label={intl.formatMessage({
                      id: translationKeys.create_translation_order_postage_add_return_address_name_label,
                    })}
                    placeholder={intl.formatMessage({
                      id: translationKeys.create_translation_order_postage_add_return_address_name_placeholder,
                    })}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    value={formikProps.values.sendByPostName}
                    errorText={
                      formikProps.touched.sendByPostName
                        ? formikProps.errors.sendByPostName
                        : undefined
                    }
                  />
                </div>
                <div className={styles.enterpriseName}>
                  <TextField
                    name="sendByPostEnterpriseName"
                    size="large"
                    label={intl.formatMessage({
                      id: translationKeys.create_translation_order_postage_add_return_address_company_label,
                    })}
                    placeholder={intl.formatMessage({
                      id: translationKeys.create_translation_order_postage_add_return_address_company_placeholder,
                    })}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    value={formikProps.values.sendByPostEnterpriseName}
                    errorText={
                      formikProps.touched.sendByPostEnterpriseName
                        ? formikProps.errors.sendByPostEnterpriseName
                        : undefined
                    }
                  />
                </div>
              </div>

              <TextField
                name="sendByPostAddress"
                size="large"
                icon={<FontAwesomeIcon icon={faLocationDot} />}
                label={intl.formatMessage({
                  id: translationKeys.create_translation_order_postage_add_return_address_address_label,
                })}
                placeholder={intl.formatMessage({
                  id: translationKeys.create_translation_order_postage_add_return_address_address_placeholder,
                })}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                value={formikProps.values.sendByPostAddress}
                errorText={
                  formikProps.touched.sendByPostAddress
                    ? formikProps.errors.sendByPostAddress
                    : undefined
                }
              />
            </div>
          )}
        </>
      )}
    </SectionContainer>
  );
};
