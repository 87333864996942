import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  faClock,
  faExclamationCircle,
  faGraduationCap,
  faLanguage,
  faVenusMars,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tag, Tooltip } from '@skiwo/components';
import { format } from 'date-fns';
import {
  getAssignmentTypeIcon,
  getAssignmentTypeLabel,
  getGenderLabel,
} from '../../../CreateInterpretationOrder/utils';
import { useLanguages } from '../../../providers/LanguagesProvider';
import { useQualifications } from '../../../providers/QualificationsProvider';
import translationKeys from '../../../translations/translationKeys';
import {
  ManagerJobAlternativeLanguageProcessing,
  ManagerJobDetails,
  ManagerJobGenderProcessing,
  ManagerJobQualificationProcessing,
  ManagerJobSessionTypeProcessing,
} from '../../../types/ManagerJob';
import getAlternativeSessionTypeName from '../../utils/getAlternativeSessionTypeName';
import RequirementCard from './RequirementCard/RequirementCard';
import styles from './RequirementsSection.module.scss';

interface RequirementsSectionProps {
  job: ManagerJobDetails;
}

export const RequirementsSection = ({ job }: RequirementsSectionProps) => {
  const intl = useIntl();
  const { getLanguageById } = useLanguages();
  const { getQualificationById } = useQualifications();
  const qualification = getQualificationById(job.interpretationRequirement.qualificationId);

  const getQualificationValue = () => {
    if (qualification?.isInTolkeregister && !job.processRequirement.qualificationAutoUpdated) {
      return (
        <div className={styles.flexRow}>
          <span>
            <FormattedMessage id={translationKeys.job_requirements_card_qualification_category} />
          </span>
          <Tag variant="square" color={'success'} size="small">
            {getQualificationById(job.interpretationRequirement.qualificationId)?.tolkeregisterName}
          </Tag>
        </div>
      );
    } else {
      return (
        <span>
          <FormattedMessage id={translationKeys.job_requirements_card_qualification_all} />
        </span>
      );
    }
  };

  return (
    <div className={styles.requirementsSection}>
      <RequirementCard
        label={intl.formatMessage({ id: translationKeys.job_requirements_card_type_label })}
        icons={
          <FontAwesomeIcon
            icon={getAssignmentTypeIcon(job.interpretationRequirement.sessionType)}
          />
        }
        value={getAssignmentTypeLabel(job.interpretationRequirement.sessionType, intl)}
      >
        {job.processRequirement.sessionTypeProcessing ===
          ManagerJobSessionTypeProcessing.SessionTypeAutoUpdate &&
          job.processRequirement.autoUpdateSessionTypeAt &&
          job.processRequirement.autoUpdateToSessionType && (
            <Tooltip
              title={intl.formatMessage(
                { id: translationKeys.job_requirements_card_type_tooltip },
                {
                  type: getAlternativeSessionTypeName(
                    job.processRequirement.autoUpdateToSessionType,
                    intl,
                  ),
                  date: format(
                    new Date(job.processRequirement.autoUpdateSessionTypeAt),
                    'dd.MM.yyyy HH:mm',
                  ),
                },
              )}
            >
              <div className={styles.flexRow}>
                <FontAwesomeIcon className={styles.clockIcon} icon={faClock} />
                <span>
                  {format(new Date(job.processRequirement.autoUpdateSessionTypeAt), 'dd.MM.yyyy')}
                </span>
              </div>
            </Tooltip>
          )}
        {job.processRequirement.sessionTypeProcessing ===
          ManagerJobSessionTypeProcessing.SessionTypeCancelNotFound && (
          <div className={styles.flexRow}>
            <FontAwesomeIcon className={styles.exclamationMarkIcon} icon={faExclamationCircle} />
            <span>
              <FormattedMessage
                id={translationKeys.job_requirements_card_cancel_if_no_interpreter}
              />
            </span>
          </div>
        )}
      </RequirementCard>
      <RequirementCard
        label={intl.formatMessage({ id: translationKeys.job_requirements_card_language_label })}
        icons={<FontAwesomeIcon icon={faLanguage} />}
        value={
          <span>
            {getLanguageById(job.interpretationRequirement.languageToId)}
            {job.interpretationRequirement.dialect && `, ${job.interpretationRequirement.dialect}`}
          </span>
        }
      >
        {job.processRequirement.alternativeLanguageProcessing ===
          ManagerJobAlternativeLanguageProcessing.AlternativeLanguageAutoUpdate &&
          job.interpretationRequirement.alternativeLanguageToId &&
          job.processRequirement.autoUpdateAlternativeLanguageAt && (
            <Tooltip
              title={intl.formatMessage(
                { id: translationKeys.job_requirements_card_language_tooltip },
                {
                  language: getLanguageById(job.interpretationRequirement.alternativeLanguageToId),
                  date: format(
                    new Date(job.processRequirement.autoUpdateAlternativeLanguageAt),
                    'dd.MM.yyyy HH:mm',
                  ),
                },
              )}
            >
              <div className={styles.flexRow}>
                <FontAwesomeIcon className={styles.clockIcon} icon={faClock} />
                <span>
                  {format(
                    new Date(job.processRequirement.autoUpdateAlternativeLanguageAt),
                    'dd.MM.yyyy',
                  )}
                </span>
              </div>
            </Tooltip>
          )}
      </RequirementCard>
      <RequirementCard
        label={intl.formatMessage({ id: translationKeys.job_requirements_card_gender_label })}
        icons={<FontAwesomeIcon icon={faVenusMars} />}
        value={getGenderLabel(job.interpretationRequirement.sex, intl)}
      >
        {job.processRequirement.genderProcessing === ManagerJobGenderProcessing.GenderAutoUpdate &&
          job.processRequirement.autoUpdateGenderAt && (
            <Tooltip
              title={intl.formatMessage(
                { id: translationKeys.job_requirements_card_gender_tooltip },
                {
                  date: format(
                    new Date(job.processRequirement.autoUpdateGenderAt),
                    'dd.MM.yyyy HH:mm',
                  ),
                },
              )}
            >
              <div className={styles.flexRow}>
                <FontAwesomeIcon className={styles.clockIcon} icon={faClock} />
                <span>
                  {format(new Date(job.processRequirement.autoUpdateGenderAt), 'dd.MM.yyyy')}
                </span>
              </div>
            </Tooltip>
          )}
        {job.processRequirement.genderProcessing ===
          ManagerJobGenderProcessing.GenderCancelNotFound && (
          <div className={styles.flexRow}>
            <FontAwesomeIcon className={styles.exclamationMarkIcon} icon={faExclamationCircle} />
            <span>
              <FormattedMessage
                id={translationKeys.job_requirements_card_cancel_if_no_interpreter}
              />
            </span>
          </div>
        )}
      </RequirementCard>
      <RequirementCard
        label={intl.formatMessage({
          id: translationKeys.job_requirements_card_qualification_label,
        })}
        icons={<FontAwesomeIcon icon={faGraduationCap} />}
        value={getQualificationValue()}
      >
        {job.processRequirement.qualificationProcessing ===
          ManagerJobQualificationProcessing.qualification_auto_update &&
          !job.processRequirement.qualificationAutoUpdated && (
            <Tooltip
              title={intl.formatMessage({
                id: translationKeys.job_requirements_card_qualification_tooltip,
              })}
            >
              <div className={styles.flexRow}>
                <FontAwesomeIcon className={styles.clockIcon} icon={faExclamationCircle} />
                <span>
                  <FormattedMessage
                    id={translationKeys.job_requirements_card_reset_if_no_interpreter}
                  />
                </span>
              </div>
            </Tooltip>
          )}
        {job.processRequirement.qualificationProcessing ===
          ManagerJobQualificationProcessing.qualification_cancel_not_found && (
          <div className={styles.flexRow}>
            <FontAwesomeIcon className={styles.exclamationMarkIcon} icon={faExclamationCircle} />
            <span>
              <FormattedMessage
                id={translationKeys.job_requirements_card_cancel_if_no_interpreter}
              />
            </span>
          </div>
        )}
      </RequirementCard>
    </div>
  );
};

export default RequirementsSection;
