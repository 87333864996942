import { IntlShape } from 'react-intl';
import { format } from 'date-fns';
import getAccessLevelName from '../../../../../InterpretationSkills/utils/getAccessLevelName';
import {
  ManagerOrderAccessLevel,
  ManagerOrderSourceType,
  ManagerOrderStatus,
  ManagerOrderSubtaskStatus,
  ManagerOrderSubtaskType,
  ManagerOrderTaskStatus,
} from '../../../../../types';
import SystemLog, { SystemLogType } from '../../../../../types/SystemLog';
import getOrderSourceName from '../../../../utils/getOrderSourceName';
import getOrderStatusColour from '../../../../utils/getOrderStatusColour';
import getOrderStatusName from '../../../../utils/getOrderStatusName';
import getSubtaskStatusColour from '../../../../utils/getSubtaskStatusColour';
import getSubtaskStatusName from '../../../../utils/getSubtaskStatusName';
import getSubtaskTypeName from '../../../../utils/getSubtaskTypeName';
import getTaskStatusColour from '../../../../utils/getTaskStatusColour';
import getTaskStatusName from '../../../../utils/getTaskStatusName';
import { getAlternativeLanguageTranslation } from './getAlternativeLanguageTranslations';
import { getBooleanTranslation } from './getBooleanTranslations';
import { getDeliveryMethodTranslation } from './getDeliveryMethodTranslations';
import { getValuesComponent } from './getValuesComponent';
import { getValuesDescriptionComponent } from './getValuesDescriptionComponent';
import { getWaitingForCustomerTranslation } from './getWaitingForCustomerTranslations';

export const getValuesByType = (logItem: SystemLog, intl: IntlShape) => {
  switch (logItem.logType) {
    case SystemLogType.OrderUpdatedStatus:
      return (
        logItem.value &&
        getValuesComponent({
          oldValue: getOrderStatusName(logItem.oldValue as ManagerOrderStatus, intl),
          value: getOrderStatusName(logItem.value as ManagerOrderStatus, intl),
          oldValueTagColor: getOrderStatusColour(logItem.oldValue as ManagerOrderStatus),
          valueTagColor: getOrderStatusColour(logItem.value as ManagerOrderStatus),
        })
      );
    case SystemLogType.TaskUpdatedStatus:
      return (
        logItem.value &&
        getValuesComponent({
          oldValue: getTaskStatusName(logItem.oldValue as ManagerOrderTaskStatus, intl),
          value: getTaskStatusName(logItem.value as ManagerOrderTaskStatus, intl),
          oldValueTagColor: getTaskStatusColour(logItem.oldValue as ManagerOrderTaskStatus),
          valueTagColor: getTaskStatusColour(logItem.value as ManagerOrderTaskStatus),
        })
      );
    case SystemLogType.SubtaskUpdatedStatus:
      return (
        logItem.value &&
        getValuesComponent({
          oldValue: getSubtaskStatusName(logItem.oldValue as ManagerOrderSubtaskStatus, intl),
          value: getSubtaskStatusName(logItem.value as ManagerOrderSubtaskStatus, intl),
          oldValueTagColor: getSubtaskStatusColour(logItem.oldValue as ManagerOrderSubtaskStatus),
          valueTagColor: getSubtaskStatusColour(logItem.value as ManagerOrderSubtaskStatus),
        })
      );
    case SystemLogType.OrderAttachmentDownloaded:
    case SystemLogType.OrderUpdatedWordCount:
    case SystemLogType.OrderUpdatedCaseNumber:
    case SystemLogType.OrderUpdatedBookerReference:
    case SystemLogType.OrderUpdatedBookerDepartment:
    case SystemLogType.OrderUpdatedClientName:
    case SystemLogType.OrderUpdatedSendByPostRecipientName:
    case SystemLogType.OrderUpdatedSendByPostAddress:
    case SystemLogType.OrderUpdatedSendByPostEnterprise:
    case SystemLogType.OrderUpdatedSendByPostCoverLetter:
    case SystemLogType.OrderUpdatedSubject:
    case SystemLogType.OrderUpdatedOrderReference:
    case SystemLogType.OrderUpdatedClientNumber:
    case SystemLogType.TaskUpdatedTargetLanguage:
    case SystemLogType.SubtaskUpdatedSellerWordCount:
    case SystemLogType.OrderUpdatedPaymentMethod:
    case SystemLogType.OrderUpdatedOtherSubject:
    case SystemLogType.OrderUpdatedLoggedOutPhoneNumber:
    case SystemLogType.OrderUpdatedLoggedOutLastName:
    case SystemLogType.OrderUpdatedLoggedOutBookerOrgNumber:
    case SystemLogType.OrderUpdatedLoggedOutPaymentOrgNumber:
    case SystemLogType.OrderUpdatedLoggedOutBookerOrgName:
    case SystemLogType.OrderUpdatedLoggedOutBookerFirstName:
    case SystemLogType.OrderUpdatedLoggedOutBookerEmail:
    case SystemLogType.SubtaskUpdatedAssignedSeller:
    case SystemLogType.SubtaskUpdatedAssignedQA:
    case SystemLogType.OrderUpdatedBookerEnterprise:
    case SystemLogType.SubtaskUpdatedTargetLanguage:
    case SystemLogType.OrderUpdatedLoggedOutPhoneCode:
    case SystemLogType.OrderUpdatedSourceLanguage:
    case SystemLogType.SubtaskUpdatedSourceLanguage:
    case SystemLogType.OrderUpdatedName:
      return (
        logItem.value &&
        getValuesComponent({
          oldValue: logItem.oldValue,
          value: logItem.value,
        })
      );
    case SystemLogType.OrderUpdatedAlternativeLanguage:
      return (
        logItem.value &&
        getValuesComponent({
          oldValue: getAlternativeLanguageTranslation({ value: logItem.oldValue, intl }),
          value: getAlternativeLanguageTranslation({ value: logItem.value, intl }),
        })
      );
    case SystemLogType.OrderUpdatedDescription:
    case SystemLogType.SubtaskUpdatedSalitaInstructions:
    case SystemLogType.SubtaskUpdatedReviewComments:
    case SystemLogType.OrderUpdatedInternalNote:
    case SystemLogType.SubtaskUpdatedTranslatorComments:
    case SystemLogType.OrderUpdatedDemanderFeedback:
      return (
        logItem.value &&
        getValuesDescriptionComponent({
          oldValue: logItem.oldValue,
          value: logItem.value,
        })
      );
    case SystemLogType.SubtaskUpdatedSellerQuote:
    case SystemLogType.SubtaskUpdatedSalitaQuote:
      return (
        logItem.value &&
        getValuesComponent({
          oldValue: logItem.oldValue || '-',
          value: logItem.value || '-',
        })
      );
    case SystemLogType.OrderUpdatedAccessLevel:
      return (
        logItem.value &&
        getValuesComponent({
          oldValue: getAccessLevelName(logItem.oldValue as ManagerOrderAccessLevel, intl),
          value: getAccessLevelName(logItem.value as ManagerOrderAccessLevel, intl),
        })
      );
    case SystemLogType.OrderUpdatedSource:
      return (
        logItem.value &&
        getValuesComponent({
          oldValue: getOrderSourceName(logItem.oldValue as ManagerOrderSourceType, intl),
          value: getOrderSourceName(logItem.value as ManagerOrderSourceType, intl),
        })
      );
    case SystemLogType.OrderUpdatedWaitingForCustomer:
      if (logItem.oldValue === 'requested' && logItem.value === 'updated') return null;
      return (
        logItem.value &&
        getValuesComponent({
          oldValue: getWaitingForCustomerTranslation({ value: logItem.oldValue, intl }),
          value: getWaitingForCustomerTranslation({ value: logItem.value, intl }),
        })
      );
    case SystemLogType.OrderUpdatedSendByPost:
    case SystemLogType.SubtaskUpdatedDeliveredShortNotice:
    case SystemLogType.OrderUpdatedIsLoggedOut:
      return (
        logItem.value &&
        getValuesComponent({
          oldValue: getBooleanTranslation({ value: logItem.oldValue, intl }),
          value: getBooleanTranslation({ value: logItem.value, intl }),
        })
      );
    case SystemLogType.OrderUpdatedExternalDeadline:
    case SystemLogType.SubtaskUpdatedInternalDeadline:
    case SystemLogType.SubtaskUpdatedDeliveryDate:
      return (
        logItem.value &&
        getValuesComponent({
          oldValue:
            logItem.oldValue && logItem.oldValue !== '-'
              ? format(new Date(logItem.oldValue), 'dd.MM.yyyy')
              : '-',
          value: logItem.value !== '-' ? format(new Date(logItem.value), 'dd.MM.yyyy') : '-',
        })
      );
    case SystemLogType.SubtaskUpdatedType:
      return (
        logItem.value &&
        getValuesComponent({
          oldValue: getSubtaskTypeName(logItem.oldValue as ManagerOrderSubtaskType, intl),
          value: getSubtaskTypeName(logItem.value as ManagerOrderSubtaskType, intl),
        })
      );
    case SystemLogType.OrderUpdatedDeliveryMethod:
      return (
        logItem.value &&
        getValuesComponent({
          oldValue: getDeliveryMethodTranslation({ value: logItem.oldValue, intl }),
          value: getDeliveryMethodTranslation({ value: logItem.value, intl }),
        })
      );
    
    // TODO: handle these logs values after BE fixes
    case SystemLogType.OrderUpdatedCCEmails:
    case SystemLogType.OrderUpdatedCoverLetters:
    case SystemLogType.OrderUpdatedRequestedShortNotice:
    default:
      return null;
  }
};
