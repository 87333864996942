import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Button, DetailItem, Drawer } from '@skiwo/components';
import { useApi } from '../../../../providers/ApiProvider';
import { useLanguages } from '../../../../providers/LanguagesProvider';
import { useToast } from '../../../../providers/ToastProvider/ToastProvider';
import translationKeys from '../../../../translations/translationKeys';
import { ManagerOrderSubtaskDetail } from '../../../../types';
import { Manager, ManagerRole } from '../../../../types/Manager';
import getSubtaskTypeName from '../../../utils/getSubtaskTypeName';
import QAList from './QAsList';
import styles from './AssignQADrawer.module.scss';

interface Props {
  show?: boolean;
  onClose: () => void;
  onSubtaskUpdate: () => void;
  subtask: ManagerOrderSubtaskDetail;
}

const AssignQADrawer = (props: Props) => {
  const { show, onClose, onSubtaskUpdate, subtask } = props;
  const api = useApi();
  const intl = useIntl();
  const { getLanguageById } = useLanguages();
  const [selectedQAUid, setSelectedQAUid] = useState<string | null>(subtask.qualityAssurerUid);
  const [assignLoading, setAssignLoading] = useState<boolean>();
  const [qualityAssurers, setQualityAssurers] = useState<Manager[]>([]);
  const { showErrorToast } = useToast();

  const getQualityAssurers = async (query?: string) => {
    const { data, error } = await api.getManagers(query || '', ManagerRole.Translation);

    if (data) {
      setQualityAssurers(data.managers);
    }
    if (error) {
      showErrorToast(error);
    }
  };

  useEffect(() => {
    if (show) getQualityAssurers();
  }, [show]);

  const assignSeller = async () => {
    if (!selectedQAUid) return;

    const { data, error } = await api.updateOrderSubtask(
      subtask.id.toString(),
      {
        qualityAssurerUid: selectedQAUid,
      },
      setAssignLoading,
    );

    if (error) {
      showErrorToast(error);
    }

    if (data) {
      onSubtaskUpdate();
      onClose();
    }
  };

  return (
    <Drawer
      show={show}
      onClose={onClose}
      title={intl.formatMessage({
        id: translationKeys.translation_subtask_details_assign_qa_drawer_title,
      })}
      data-testid="edit-subtask-drawer"
    >
      <Form className={styles.editSubtask}>
        <div className={styles.overview}>
          <span className={styles.id}>#{subtask.publicId}</span>
          <div className={styles.title}>
            <span>{getLanguageById(subtask.sourceLanguageId)}</span>
            <FontAwesomeIcon icon={faArrowRight} />
            <span>{getLanguageById(subtask.targetLanguageId)}</span>
          </div>
          <span className={styles.description}>
            {getSubtaskTypeName(subtask.subtaskType, intl)}
          </span>
        </div>

        {subtask.qualityAssurer && !!Object.keys(subtask.qualityAssurer).length && (
          <DetailItem
            preview={
              <Avatar
                size="medium"
                altText={subtask.qualityAssurer.name}
                url={subtask.qualityAssurer.avatar}
              />
            }
            title={subtask.qualityAssurer.name}
            size="large"
          />
        )}

        <QAList
          qualityAssurers={qualityAssurers}
          onSearch={(query) => getQualityAssurers(query)}
          selectedQAUid={selectedQAUid}
          setSelectedQAUid={(value) => setSelectedQAUid(value)}
        />
      </Form>
      <div className={styles.actions}>
        <Button size="large" variant="gray" onClick={onClose} data-testid="cancel-button">
          <FormattedMessage
            id={translationKeys.translation_subtask_details_assign_qa_drawer_cancel_button}
          />
        </Button>
        <Button
          onClick={() => assignSeller()}
          disabled={!selectedQAUid}
          size="large"
          isLoading={assignLoading}
        >
          <FormattedMessage
            id={translationKeys.translation_subtask_details_assign_qa_drawer_select_button}
          />
        </Button>
      </div>
    </Drawer>
  );
};

export default AssignQADrawer;
