import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useParams } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import ChatSection from '../components/ChatSection/ChatSection';
import { useApi } from '../providers/ApiProvider';
import { useToast } from '../providers/ToastProvider/ToastProvider';
import { ManagerOrder } from '../types';
import OrderDetails from './OrderDetails/OrderDetails';
import OrderMenu from './OrderMenu/OrderMenu';
import SubtaskDetails from './SubtaskDetails/SubtaskDetails';
import TaskDetails from './TaskDetails/TaskDetails';
import styles from './TranslationOrder.module.scss';

const TranslationOrder = () => {
  const { orderId } = useParams();
  const [searchParams] = useSearchParams();
  const activeItem = searchParams.get('active') || orderId || '';
  const [order, setOrder] = useState<ManagerOrder>();
  const api = useApi();
  const editable = !!order?.ownerId;
  const { showErrorToast } = useToast();

  const getOrder = async () => {
    if (!orderId) return;

    const { data, error } = await api.getOrder(orderId?.toString());

    if (data?.order) {
      setOrder(data.order);
    }
    if (error) {
      showErrorToast(error);
    }
  };

  const onOrderUpdate = async () => {
    await getOrder();
  };

  useEffect(() => {
    getOrder();
  }, []);

  const getTask = (id: string) => {
    if (!order) return;

    return order.tasks.find((task) => task.publicId === id);
  };

  const getSubtask = (id: string) => {
    if (!order) return;
    const allSubtasks = order.tasks.map((task) => task.subtasks).flat();

    return allSubtasks.find((subtask) => subtask?.publicId === id);
  };

  // TODO: Show error state
  if (!order) return null;

  return (
    <Container fluid className={styles.container}>
      <Row className={styles.row}>
        <Col lg={2} className={styles.section}>
          <OrderMenu order={order} onOrderUpdate={onOrderUpdate} editable={editable} />
        </Col>
        <Col lg={8} className={styles.section}>
          {activeItem.split('-').length === 1 && (
            <OrderDetails order={order} onOrderUpdate={onOrderUpdate} editable={editable} />
          )}
          {activeItem.split('-').length === 2 && (
            <TaskDetails task={getTask(activeItem)} onUpdate={onOrderUpdate} editable={editable} />
          )}
          {/* TODO: Checking the subtask is not necessary */}
          {activeItem.split('-').length === 3 && (
            <SubtaskDetails
              subtaskId={getSubtask(activeItem)?.id}
              orderId={order.id}
              editable={editable}
              onOrderUpdate={onOrderUpdate}
            />
          )}
        </Col>
        <Col lg={2} className={styles.section}>
          <ChatSection queryId={orderId} getMessage={api.getAdminChat} sendMessage={api.createAdminChatMessage} />
        </Col>
      </Row>
    </Container>
  );
};

export default TranslationOrder;
