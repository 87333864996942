import { IntlShape } from 'react-intl';
import translationKeys from '../../translations/translationKeys';
import { ManagerJobLastActivityType } from '../../types';

const getLastActivityName = (lastActivity: ManagerJobLastActivityType, intl: IntlShape): string => {
  if (lastActivity === ManagerJobLastActivityType.ApplicationInvited) {
    return intl.formatMessage({
      id: translationKeys.jobs_page_last_activity_application_invited,
    });
  }

  if (lastActivity === ManagerJobLastActivityType.ApplicationWithdrawn) {
    return intl.formatMessage({
      id: translationKeys.jobs_page_last_activity_application_withdrawn,
    });
  }

  if (lastActivity === ManagerJobLastActivityType.JobCreated) {
    return intl.formatMessage({
      id: translationKeys.jobs_page_last_activity_job_created,
    });
  }

  if (lastActivity === ManagerJobLastActivityType.JobUpdated) {
    return intl.formatMessage({
      id: translationKeys.jobs_page_last_activity_job_updated,
    });
  }

  if (lastActivity === ManagerJobLastActivityType.JobUpdatedAssignmentType) {
    return intl.formatMessage({
      id: translationKeys.jobs_page_last_activity_job_updated_assignment_type,
    });
  }

  if (lastActivity === ManagerJobLastActivityType.JobUpdatedExpenses) {
    return intl.formatMessage({
      id: translationKeys.jobs_page_last_activity_job_updated_expenses,
    });
  }

  if (lastActivity === ManagerJobLastActivityType.JobUpdatedFeesAndOrder) {
    return intl.formatMessage({
      id: translationKeys.jobs_page_last_activity_job_updated_fees_and_order,
    });
  }

  if (lastActivity === ManagerJobLastActivityType.JobUpdatedInvoicing) {
    return intl.formatMessage({
      id: translationKeys.jobs_page_last_activity_job_updated_invoicing,
    });
  }

  if (lastActivity === ManagerJobLastActivityType.JobUpdatedReferences) {
    return intl.formatMessage({
      id: translationKeys.jobs_page_last_activity_job_updated_references,
    });
  }

  return '';
};

export default getLastActivityName;
