import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import {
  faBuilding,
  faCog as faLightCog,
  faRightFromBracket,
} from '@fortawesome/pro-light-svg-icons';
import { faCog } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, IconButton } from '@skiwo/components';
import { useAuth } from '../../providers/AuthProvider';
import translationKeys from '../../translations/translationKeys';
import styles from './SettingsDropdown.module.scss';

const SettingsDropdown = () => {
  const { userProfile } = useAuth();

  const handleLogOut = () => {
    localStorage.removeItem('token');
    location.reload();
  };

  return (
    <NavDropdown
      title={<IconButton size="medium" icon={<FontAwesomeIcon icon={faCog} />} />}
      align="end"
      className={styles.settingsMenu}
      data-testid="settings-dropdown"
    >
      <NavDropdown.Item
        href={`${process.env.REACT_APP_OLD_APP_BASE_URL}/nb/no/m/profile/${userProfile?.uid}/edit`}
        disabled={!userProfile?.uid}
        target="_blank"
      >
        <Avatar altText="Profile pic" url={userProfile?.avatar || undefined} />
        <FormattedMessage id={translationKeys.menu_settings_my_profile} />
      </NavDropdown.Item>
      <NavDropdown.Item
        href={`${process.env.REACT_APP_OLD_APP_BASE_URL}/nb/no/m/myorg/info?view=org-info`}
        target="_blank"
      >
        <FontAwesomeIcon icon={faBuilding} />
        <FormattedMessage id={translationKeys.menu_settings_my_organisation} />
      </NavDropdown.Item>
      <NavDropdown.Item
        href={`${process.env.REACT_APP_OLD_CORE_BASE_URL}/management_console/managers`}
        target="_blank"
      >
        <FontAwesomeIcon icon={faLightCog} />
        <FormattedMessage id={translationKeys.menu_settings_settings_option} />
      </NavDropdown.Item>
      <NavDropdown.Item onClick={handleLogOut} data-testid="sign-out-button">
        <FontAwesomeIcon icon={faRightFromBracket} />
        <FormattedMessage id={translationKeys.menu_settings_sign_out} />
      </NavDropdown.Item>
    </NavDropdown>
  );
};

export default SettingsDropdown;
