import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Button, SearchDropdownMenuOption } from '@skiwo/components';
import { Form, Formik } from 'formik';
import { CustomerSearchDropdownMenuOption } from '../components/CustomerSearchDropdown/CustomerSearchDropdown';
import SearchCustomerSection from '../components/SearchCustomerSection/SearchCustomerSection';
import appendFormAttachmentsBlob from '../helpers/appendFormAttachmentsBlob';
import appendNestedFormDataObject from '../helpers/appendNestedFormDataObject';
import getDefaultReferences from '../helpers/getDefaultReferences';
import { useApi } from '../providers/ApiProvider';
import { useLanguages } from '../providers/LanguagesProvider';
import { useQualifications } from '../providers/QualificationsProvider';
import { useToast } from '../providers/ToastProvider/ToastProvider';
import translationKeys from '../translations/translationKeys';
import { CustomerPaymentMethod, EnterpriseDepartment, ManagerCustomer } from '../types';
import { ManagerJobBookingReferenceFrom } from '../types/ManagerJob';
import {
  ManagerJobAdminAutoInviteProcessing,
  ManagerJobStandbyProcessing,
} from '../types/ManagerJob';
import CustomerSection from './CustomerSection/CustomerSection';
import { InformationSection } from './InformationSection/InformationSection';
import { ManualBookingSection } from './ManualBookingSection/ManualBookingSection';
import { PaymentSection } from './PaymentSection/PaymentSection';
import SettingsSection from './SettingsSection/SettingsSection';
import { SummaryView } from './SummaryView/SummaryView';
import { CreateInterpretationOrderFormValues, createInterpretationOrderSchema } from './schema';
import {
  addTimeToDate,
  getCreateInterpretationFormInitialValues,
  getInformationOrderData,
} from './utils';
import styles from './CreateInterpretationOrder.module.scss';

const CreateInterpretationOrder = () => {
  const intl = useIntl();
  const api = useApi();
  const navigate = useNavigate();
  const { getDefaultLanguage, languages } = useLanguages();
  const { showToast, showErrorToast } = useToast();
  const { qualifications } = useQualifications();
  const [confirmationPage, setConfirmationPage] = useState(false);
  const [customer, setCustomer] = useState<ManagerCustomer>();
  const [selectedDepartment, setSelectedDepartment] = useState<SearchDropdownMenuOption>();
  const [additionalCustomers, setAdditionalCustomers] = useState<
    CustomerSearchDropdownMenuOption[]
  >([]);
  const [departmentList, setDepartmentList] = useState<EnterpriseDepartment[]>();
  const [paymentMethod, setPaymentMethod] = useState<CustomerPaymentMethod>();
  const [isCategoryRequired, setIsCategoryRequired] = useState<boolean>(false);
  const [isCategorySubjectRequired, setIsCategorySubjectRequired] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const defaultLanguageId = getDefaultLanguage()?.id;

  // Requirement for the old application (the VUE one)
  const generateRandomString = (length: number): string => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;
  };

  const schema = createInterpretationOrderSchema({
    customer,
    intl,
    paymentMethod,
    isCategoryRequired,
    isCategorySubjectRequired,
  });

  const resetSelections = () => {
    setCustomer(undefined);
    setSelectedDepartment(undefined);
    setDepartmentList(undefined);
    setAdditionalCustomers([]);
  };

  const handleOnSubmit = async (values: CreateInterpretationOrderFormValues) => {
    if (!customer) return;
    if (confirmationPage) {
      const {
        defaultBookingReference,
        defaultBookingReferenceFrom,
        defaultPaymentBookingReference,
        defaultPaymentBookingReferenceFrom,
      } = getDefaultReferences({
        customer,
        departmentList,
        paymentMethod,
        selectedDepartment,
      });

      const informationOrderData = getInformationOrderData(values, qualifications);

      const createOrderData = {
        ...informationOrderData,
        ownerUid: customer.uid,
        bookingMechanism: values.bookingMechanism,
        // TODO: Probably not necessary for Connect app
        creationIdempotencyKey: generateRandomString(64),
        allowAutoAward: values.manualBookingAutoAward,
        actualCreatedAt: addTimeToDate(
          values.actualCreatedAtDate,
          values.actualCreatedAtTime,
        ).toString(),
        interpretationRequirement: {
          ...informationOrderData.interpretationRequirement,
          departmentId: values.departmentId,
          languageFromId: defaultLanguageId,
          confirmationBy:
            values.deadlineConfirmationActive &&
            values.confirmationByDate &&
            values.confirmationByTime
              ? addTimeToDate(values.confirmationByDate, values.confirmationByTime).toString()
              : undefined,
        },
        info: {
          ...informationOrderData.info,
          realPersonName: values.bookerName,
          bookingReference: values.bookingReference,
          bookingReferenceFrom:
            !!values.bookingReference && values.bookingReference !== defaultBookingReference
              ? ManagerJobBookingReferenceFrom.User
              : defaultBookingReferenceFrom,
          paymentBookingReference: values.paymentBookingReference,
          paymentBookingReferenceFrom:
            !!values.paymentBookingReference &&
            values.paymentBookingReference !== defaultPaymentBookingReference
              ? ManagerJobBookingReferenceFrom.User
              : defaultPaymentBookingReferenceFrom,
          caseNumber: values.caseNumber,
          confirmationPhones: values.ccActive ? values.confirmationPhones : undefined,
          ccEmails: values.ccActive ? values.ccEmails : undefined,
          manualBookingFeeApplied: values.manualBookingFeeApplied,
          manualEditingFeeApplied: values.manualEditingFeeApplied,
          manualTravelBookingFeeApplied: values.manualTravelBookingFeeApplied,
        },
        processRequirement: {
          ...informationOrderData.processRequirement,
          adminAutoInviteProcessing: values.specificInterpreterActive
            ? ManagerJobAdminAutoInviteProcessing.AdminAutoInviteNoAction
            : values.manualBookingAutoInvite
            ? ManagerJobAdminAutoInviteProcessing.AdminAutoInviteStart
            : ManagerJobAdminAutoInviteProcessing.AdminAutoInviteStop,
          infiniteAutoInvite: values.specificInterpreterActive
            ? false
            : values.manualBookingForever,
          standbyProcessing: values.manualBookingStandBy
            ? ManagerJobStandbyProcessing.TurnedOn
            : ManagerJobStandbyProcessing.TurnedOff,
          directProcessing:
            values.specificInterpreterActive && values.specificInterpreterProcessing
              ? values.specificInterpreterProcessing
              : undefined,
        },
        participants: values.additionalCustomerUids?.map((customerUid) => ({ uid: customerUid })),
        paymentMethodId: values.paymentMethodId,
        directInterpreterUid: values.specificInterpreterActive
          ? values.specificInterpreter?.customData?.person.uid
          : undefined,
      };

      const createOrderFormData = new FormData();

      appendNestedFormDataObject(createOrderFormData, createOrderData);

      values.uploadFilesActive &&
        values.attachments &&
        appendFormAttachmentsBlob(createOrderFormData, values.attachments);

      const { data, error } = await api.createJob(createOrderFormData, setIsLoading);

      if (error) {
        showErrorToast(error);
      } else {
        data && window.open(data.godmodeDemanderUrl, '_blank');
        navigate('/jobs');
        showToast({
          variant: 'success',
          message: intl.formatMessage({
            id: translationKeys.create_interpretation_order_assignment_created_successfully,
          }),
        });
      }
    } else {
      setConfirmationPage(true);
    }
  };

  return (
    <Container fluid>
      <Row className={styles.row}>
        <Col lg={6} data-testid="interpretation-booking-form">
          <div className={styles.header}>
            <span>
              {!confirmationPage ? (
                <FormattedMessage id={translationKeys.create_interpretation_order_label} />
              ) : (
                <FormattedMessage id={translationKeys.create_interpretation_order_summary_label} />
              )}
            </span>
          </div>
          <Formik<CreateInterpretationOrderFormValues>
            validationSchema={schema}
            initialValues={getCreateInterpretationFormInitialValues(languages)}
            onSubmit={handleOnSubmit}
          >
            {(formikProps) => (
              <Form onSubmit={formikProps.handleSubmit} className={styles.column}>
                {!customer ? (
                  <SearchCustomerSection setCustomer={setCustomer} formikProps={formikProps} />
                ) : !confirmationPage ? (
                  <>
                    <CustomerSection
                      customer={customer}
                      changeCustomer={() => resetSelections()}
                      formikProps={formikProps}
                      selectedDepartment={selectedDepartment}
                      setSelectedDepartment={setSelectedDepartment}
                      setDepartmentList={setDepartmentList}
                      additionalCustomers={additionalCustomers}
                      setAdditionalCustomers={setAdditionalCustomers}
                    />

                    <PaymentSection
                      customer={customer}
                      selectedPaymentMethod={paymentMethod}
                      setSelectedPaymentMethod={setPaymentMethod}
                      selectedDepartment={selectedDepartment}
                      departmentList={departmentList}
                      formikProps={formikProps}
                    />

                    <InformationSection
                      formikProps={formikProps}
                      enterpriseId={customer.enterprise?.id}
                      setIsCategoryRequired={setIsCategoryRequired}
                      setIsCategorySubjectRequired={setIsCategorySubjectRequired}
                    />

                    <SettingsSection formikProps={formikProps} customer={customer} />

                    <ManualBookingSection formikProps={formikProps} />

                    <div className={styles.buttonsWrapper}>
                      <Button type="submit" size="x-large">
                        <FormattedMessage
                          id={translationKeys.create_interpretation_order_preview_booking_button}
                        />
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <SummaryView
                      customer={customer}
                      selectedDepartment={selectedDepartment?.label}
                      additionalCustomers={additionalCustomers}
                      paymentMethod={paymentMethod}
                      formikProps={formikProps}
                    />
                    <div className={styles.buttonsWrapper}>
                      <Button
                        variant="gray"
                        size="x-large"
                        onClick={() => setConfirmationPage(false)}
                      >
                        <FormattedMessage
                          id={translationKeys.create_translation_order_summary_back_button}
                        />
                      </Button>
                      <Button type="submit" size="x-large" isLoading={isLoading}>
                        <FormattedMessage
                          id={translationKeys.create_translation_order_summary_place_order_button}
                        />
                      </Button>
                    </div>
                  </>
                )}
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </Container>
  );
};

export default CreateInterpretationOrder;
