export default function appendNestedFormDataObject(formData: FormData, data: any, prefix?: string) {
  Object.keys(data).forEach((key) => {
    const value = data[key];
    const newKey = prefix ? `${prefix}[${key}]` : key;
    if (value !== undefined && value !== null && value !== '') {
      if (Array.isArray(value)) {
        value.forEach((item) => {
          if (typeof item === 'object' && item !== null) {
            appendNestedFormDataObject(formData, item, `${newKey}[]`);
          } else {
            formData.append(`${newKey}[]`, item);
          }
        });
      } else if (typeof value === 'object' && value !== null) {
        appendNestedFormDataObject(formData, value, newKey);
      } else {
        formData.append(newKey, value);
      }
    }
  });
}
