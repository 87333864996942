import { IntlShape } from 'react-intl';
import translationKeys from '../../translations/translationKeys';
import { ManagerCustomerType } from '../../types';

const getTypeName = (type: ManagerCustomerType, intl: IntlShape): string => {
  if (type === ManagerCustomerType.Private) {
    return intl.formatMessage({ id: translationKeys.customers_page_customer_type_private });
  }

  if (type === ManagerCustomerType.Enterprise) {
    return intl.formatMessage({ id: translationKeys.customers_page_customer_type_company });
  }

  return intl.formatMessage({ id: translationKeys.customers_page_customer_type_undefined });
};

export default getTypeName;
