import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Modal } from '@skiwo/components';
import translationKeys from '../../../translations/translationKeys';
import styles from './ConfimChangeCustomerModal.module.scss';

interface ConfimChangeCustomerModalProps {
  show: boolean;
  onClose: () => void;
  changeCustomer: () => void;
}

export const ConfimChangeCustomerModal = ({
  show,
  onClose,
  changeCustomer,
}: ConfimChangeCustomerModalProps) => {
  const intl = useIntl();

  return (
    <Modal
      show={show}
      title={intl.formatMessage({
        id: translationKeys.create_translation_order_change_customer_modal_label,
      })}
      description={intl.formatMessage({
        id: translationKeys.create_translation_order_change_customer_modal_description,
      })}
      onHide={onClose}
    >
      <div className={styles.actionButtons}>
        <Button
          onClick={onClose}
          variant="gray"
          size="large"
          className={styles.cancelButton}
          data-testid="cancel-button"
        >
          <FormattedMessage
            id={translationKeys.create_translation_order_change_customer_modal_back_button}
          />
        </Button>

        <Button
          iconPosition="left"
          size="large"
          variant="danger"
          className={styles.renameFileButton}
          onClick={() => changeCustomer()}
        >
          <FormattedMessage
            id={translationKeys.create_translation_order_change_customer_modal_change_button}
          />
        </Button>
      </div>
    </Modal>
  );
};
