 import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { Feature } from '../types';
import { useApi } from './ApiProvider';

interface Props {
  children: JSX.Element | JSX.Element[];
}

interface FeatureToggleContext {
  features: Record<Feature, boolean> | null;
  isFeatureActive: (feature: Feature) => boolean;
}

export const initialState: FeatureToggleContext = {
  features: null,
  isFeatureActive: () => false,
};

export const FeatureToggleContext = createContext(initialState);

const FeatureToggleProvider: React.FC<Props> = ({ children }) => {
  const [features, setFeatures] = useState<Record<Feature, boolean> | null>(null);
  const api = useApi();

  const getFeatures = async () => {
    const { data } = await api.getFeatures();

    if (data?.features) {
      setFeatures(data.features);
    }
  };

  const isFeatureActive = (feature: Feature) => {
    if (features && features[feature]) {
      return features[feature];
    }

    return false;
  };

  useEffect(() => {
    getFeatures();
  }, []);

  const FeatureToggleContextValue = useMemo(
    () => ({
      features,
      isFeatureActive,
    }),
    [features],
  );

  return (
    <FeatureToggleContext.Provider value={FeatureToggleContextValue}>
      {children}
    </FeatureToggleContext.Provider>
  );
};

export const useFeatureToggle = () => useContext(FeatureToggleContext);

export default FeatureToggleProvider;
