import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@skiwo/components';
import SuccessBanner from '../../../../components/SuccessBanner/SuccessBanner';
import translationKeys from '../../../../translations/translationKeys';
import styles from './CustomerToEnterpriseSuccess.module.scss';

interface Props {
  customerName: string;
  resetShowSuccess: () => void;
  resetSelection: () => void;
  openEnterprisePage: () => void;
  onClose: () => void;
}

const CustomerToEnterpriseSuccess = ({
  customerName,
  resetShowSuccess,
  resetSelection,
  openEnterprisePage,
  onClose,
}: Props) => {
  return (
    <div className={styles.successContainer} data-testid="customer-to-enterprise-success-container">
      <SuccessBanner />
      <p className={styles.message} data-testid="customer-to-enterprise-success-text">
        <FormattedMessage
          id={translationKeys.customers_page_to_enterprise_success_text}
          values={{ name: <span className={styles.messageName}>{customerName}</span> }}
        />
      </p>
      <div className={styles.actions}>
        <Button
          size="large"
          variant="gray"
          data-testid="close-button"
          onClick={() => {
            onClose();

            setTimeout(() => {
              resetShowSuccess();
              resetSelection();
            }, 300);
          }}
        >
          <FormattedMessage
            id={translationKeys.customers_page_to_enterprise_success_close_button}
          />
        </Button>

        <Button
          size="large"
          onClick={() => {
            openEnterprisePage();
          }}
          data-testid="open-enterprise-page-button"
        >
          <FormattedMessage
            id={translationKeys.customers_page_to_enterprise_success_open_enterprise_page_button}
          />
        </Button>
      </div>
    </div>
  );
};

export default CustomerToEnterpriseSuccess;
