import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { faPen, faTrash } from '@fortawesome/pro-solid-svg-icons';
import {
  Banner,
  Button,
  DetailItem,
  Drawer,
  FileSelector,
  FileState,
  SearchDropdown,
  SearchDropdownMenuOption,
  TextField,
} from '@skiwo/components';
import { formatFileSize } from '@skiwo/utils';
import { Formik } from 'formik';
import * as yup from 'yup';
import { ApiError } from '../../../Api';
import fileIllustration from '../../../assets/illustration-file.svg';
import SupplierCard from '../../../components/SupplierCard/SupplierCard';
import { dialectRegex } from '../../../helpers/regexPatterns';
import useDebounce from '../../../hooks/useDebounce';
import { useApi } from '../../../providers/ApiProvider';
import { useLanguages } from '../../../providers/LanguagesProvider';
import { useQualifications } from '../../../providers/QualificationsProvider';
import translationKeys from '../../../translations/translationKeys';
import { ManagerSupplier, ManagerSupplierType } from '../../../types';
import NewSkillSuccess from './NewSkillSuccess/NewSkillSuccess';
import QualificationAccordion from './QualificationAccordion/QualificationAccordion';
import RenameFileModal from './RenameFileModal/RenameFileModal';
import styles from './NewSkillDrawer.module.scss';

interface Props {
  show?: boolean;
  onClose: () => void;
  onComplete?: (hardReload?: boolean) => void;
  supplierId?: number | null;
}

interface FormValues {
  dialectFrom: string;
  dialectTo: string;
  nativeFrom: boolean;
  nativeTo: boolean;
  notifyInterpreter: boolean;
  nationalInterpreterId: number | string;
}

interface RenameFileValues {
  fileName: string;
  fileUid: string;
}

const NewSkillDrawer = (props: Props) => {
  const { show, onClose, supplierId } = props;
  const [createSkillError, setCreateSkillError] = useState<ApiError | null>(null);
  const [createSkillLoading, setCreateSkillLoading] = useState(false);
  const [supplierResults, setSupplierResults] = useState<SearchDropdownMenuOption[]>([]);
  const [selectedSupplier, setSelectedSupplier] = useState<ManagerSupplier>();
  const [selectedFromLanguage, setSelectedFromLanguage] = useState<SearchDropdownMenuOption>();
  const [selectedToLanguage, setSelectedToLanguage] = useState<SearchDropdownMenuOption>();
  const [selectedQualification, setSelectedQualification] = useState<SearchDropdownMenuOption>();
  const [showErrors, setShowErrors] = useState(false);
  const [selectedSupplierType, setSelectedSupplierType] = useState(ManagerSupplierType.Undefined);
  const [selectedSkillStatus, setSelectedSkillStatus] = useState<string>();
  const [notifySupplier, setNotifySupplier] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [activateInputFields, setActivateInputFields] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<FileState[]>([]);
  const [showRenameFileModal, setShowRenameFileModal] = useState<boolean>(false);
  const [renameFileUid, setRenameFileUid] = useState('');

  const api = useApi();
  const { languages } = useLanguages();
  const { qualifications, getQualificationById } = useQualifications();
  const intl = useIntl();
  const debounceSupplierSearch = useDebounce(300);

  const getLanguageById = (id: number) => {
    const language = languages.find((language) => language.id === id);
    if (language) {
      return {
        id: language.id,
        label: language.name || '',
        key: language.id.toString(),
      };
    }
    return undefined;
  };

  const resetShowSuccess = () => setShowSuccess(false);

  const resetSelectedSupplier = () => {
    setSelectedSupplier(undefined);
    setSelectedSupplierType(ManagerSupplierType.Undefined);
  };

  const handleRadioButtonChange = (
    supplierType: ManagerSupplierType,
    fromLanguageId: number | undefined,
  ) => {
    setSelectedSupplierType(supplierType);
    setSelectedQualification(undefined);
    setSelectedFromLanguage(fromLanguageId ? getLanguageById(fromLanguageId) : undefined);
    setSelectedToLanguage(undefined);
    setActivateInputFields(false);
    setSelectedSkillStatus(undefined);
    setSelectedFiles([]);
    setShowErrors(false);
  };

  const handleFileSelection = (newFiles: FileState[]) => {
    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const schema = yup.object().shape({
    dialectFrom: yup.string().matches(
      dialectRegex,
      intl.formatMessage({
        id: translationKeys.interpretation_skill_new_skill_dialect_input_error,
      }),
    ),
    dialectTo: yup.string().matches(
      dialectRegex,
      intl.formatMessage({
        id: translationKeys.interpretation_skill_new_skill_dialect_input_error,
      }),
    ),
    nationalInterpreterId: yup.string().when([], () => {
      return selectedQualification &&
        shouldShowAndRequireTolkId(selectedQualification.id) &&
        selectedSupplierType === ManagerSupplierType.Interpreter
        ? yup
            .string()
            .required(
              intl.formatMessage({
                id: translationKeys.new_skill_drawer_national_id_input_error_message,
              }),
            )
            .matches(
              /^\d+$/,
              intl.formatMessage({
                id: translationKeys.new_skill_drawer_national_id_input_not_valid_error,
              }),
            )
            .min(
              4,
              intl.formatMessage(
                { id: translationKeys.form_error_min_length },
                {
                  fieldName: intl.formatMessage({
                    id: translationKeys.new_skill_drawer_national_id_input_label,
                  }),
                  minLength: 4,
                },
              ),
            )
        : yup.string().notRequired();
    }),
  });

  const getSupplierList = async (query: string) => {
    const { data } = await api.getSuppliers({
      's[lookup_fields_cont]': query,
    });

    if (data?.suppliers) {
      const suppliersOptions =
        data.suppliers.map((supplier) => {
          return {
            id: supplier.id,
            label: supplier.name,
            key: supplier.email,
            phoneCode: supplier.phoneCode,
            phone: supplier.phoneNumber,
            avatarUrl: supplier.avatarUrl,
            name: supplier.name,
            email: supplier.email,
            sex: supplier.sex,
            uid: supplier.uid,
            interpretation: {
              approvedSkills: supplier.interpretation.approvedSkills,
              statistics: {
                showedUp: supplier.interpretation.statistics.showedUp,
              },
            },
            translation: {
              approvedSkills: supplier.translation.approvedSkills,
              statistics: {
                finished: supplier.translation.statistics.finished,
              },
            },
            address: {
              city: supplier.address?.city,
              country: supplier.address?.country,
              county: supplier.address?.county,
              latitude: supplier.address?.latitude,
              line1: supplier.address?.line1,
              line2: supplier.address?.line2,
              longitude: supplier.address?.longitude,
              postcode: supplier.address?.postcode,
            },
          };
        }) || [];

      setSupplierResults(suppliersOptions);
    }
  };

  const fetchSupplierById = async (id: number) => {
    const { data } = await api.getSupplierDetails(id.toString());

    if (data && data.supplier) {
      setSelectedSupplier(data.supplier);
    }
  };

  useEffect(() => {
    if (supplierId) {
      fetchSupplierById(supplierId);
    }
  }, []);

  const activateInputs = () => {
    setActivateInputFields(true);
    setSelectedFromLanguage(undefined);
  };

  function handleRemoveFile(fileUid: string) {
    setSelectedFiles((prevFiles) => prevFiles.filter((file) => file.uid !== fileUid));
  }

  const languageOptions =
    languages.map((language) => {
      return { id: language.id, label: language.name || '', key: language.id.toString() };
    }) || [];

  const interpreterQualificationOptions =
    qualifications.length > 0
      ? qualifications
          .filter((qualification) => qualification.industry === 'interpretation')
          .map((qualification) => {
            return {
              id: qualification.id,
              label: qualification.name || '',
              key: qualification.id.toString(),
            };
          })
      : [];

  const translatorQualificationOptions =
    qualifications.length > 0
      ? qualifications
          .filter((qualification) => qualification.industry === 'translation')
          .map((qualification) => {
            return {
              id: qualification.id,
              label: qualification.name || '',
              key: qualification.id.toString(),
            };
          })
      : [];

  useEffect(() => {
    const preselectedLanguage = languageOptions.find((language) => language.id === 3);
    if (preselectedLanguage) {
      setSelectedFromLanguage(preselectedLanguage);
    }
  }, []);

  useEffect(() => {
    if (show) {
      getSupplierList('');
    }
  }, [show]);

  const isFormInValid =
    !selectedSupplier ||
    !selectedFromLanguage ||
    !selectedToLanguage ||
    !selectedQualification ||
    !selectedSkillStatus;

  const postIntSkillRequest = async (values: FormValues) => {
    setShowErrors(false);
    if (isFormInValid) {
      setShowErrors(true);
      return;
    }

    const certificates = selectedFiles;

    const formData = new FormData();

    formData.append('skill[supplierUid]', selectedSupplier.uid);
    formData.append('skill[languageFromId]', selectedFromLanguage.id.toString());
    formData.append('skill[languageToId]', selectedToLanguage.id.toString());
    formData.append('skill[dialectFrom]', values.dialectFrom);
    formData.append('skill[dialectTo]', values.dialectTo);
    formData.append('skill[qualificationId]', selectedQualification.id.toString());
    formData.append('skill[status]', selectedSkillStatus);
    formData.append('skill[verifiedTolkId]', values.nationalInterpreterId.toString());
    formData.append('notifySupplier', notifySupplier.toString());

    certificates.forEach((fileState) => {
      const fileBlob = new Blob([fileState.data], { type: fileState.type });
      formData.append(`skill[certificates][][file]`, fileBlob, fileState.name);
    });

    const { data, error } = await api.createInterpretationSkill(formData, setCreateSkillLoading);

    setCreateSkillError(error);
    if (data) {
      setShowSuccess(true);
    }
  };

  const postTransSkillRequest = async () => {
    setShowErrors(false);

    if (isFormInValid) {
      setShowErrors(true);
      return;
    }

    const certificates = selectedFiles;

    const formData = new FormData();

    formData.append('skill[supplierUid]', selectedSupplier.uid);
    formData.append('skill[languageFromId]', selectedFromLanguage.id.toString());
    formData.append('skill[languageToId]', selectedToLanguage.id.toString());
    formData.append('skill[qualificationId]', selectedQualification.id.toString());
    formData.append('skill[status]', selectedSkillStatus);
    formData.append('notifySupplier', notifySupplier.toString());

    certificates.forEach((fileState) => {
      const fileBlob = new Blob([fileState.data], { type: fileState.type });
      formData.append(`skill[certificates][][file]`, fileBlob, fileState.name);
    });

    const { data, error } = await api.createTranslationSkill(formData, setCreateSkillLoading);

    setCreateSkillError(error);
    if (data) {
      setShowSuccess(true);
    }
  };

  const shouldShowAndRequireTolkId = (id: number) => {
    const qualification = getQualificationById(id);

    if (qualification) {
      return qualification.isInTolkeregister && qualification.priority !== 6;
    }

    return false;
  };

  const shouldShowFileUploader = (id: number) => {
    const qualification = getQualificationById(id);

    if (qualification) {
      return (
        selectedSupplierType !== ManagerSupplierType.Undefined &&
        (selectedSupplierType === ManagerSupplierType.Translator ||
          (selectedSupplierType === ManagerSupplierType.Interpreter &&
            qualification.priority === 6))
      );
    }

    return false;
  };

  const handleSubmit = async (values: FormValues) => {
    if (selectedSupplierType === ManagerSupplierType.Interpreter) {
      postIntSkillRequest(values);
    } else {
      postTransSkillRequest();
    }
  };

  const handleRenameFileSubmit = (values: RenameFileValues) => {
    const newFileName = values.fileName;
    const fileUid = values.fileUid;
    setRenameFileUid('');

    setSelectedFiles((prevFiles) =>
      prevFiles.map((file) => {
        if (file.uid === fileUid) {
          return { ...file, name: newFileName };
        }
        return file;
      }),
    );
  };

  return (
    <Drawer
      show={show}
      onClose={() => {
        onClose();
        setSelectedSupplier(undefined);
        setSelectedSupplierType(ManagerSupplierType.Undefined);
        setActivateInputFields(false);
        resetShowSuccess();
        setSelectedFiles([]);
      }}
      title={
        showSuccess && !createSkillError
          ? intl.formatMessage({ id: translationKeys.new_skill_success_drawer_title })
          : intl.formatMessage({ id: translationKeys.new_skill_drawer_title })
      }
      data-testid="new-skill-drawer"
    >
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={{
          dialectFrom: '',
          dialectTo: '',
          nativeFrom: false,
          nativeTo: false,
          notifyInterpreter: false,
          nationalInterpreterId: '',
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
          resetForm,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit} className={styles.container}>
            {showSuccess && !createSkillLoading && !createSkillError ? (
              <NewSkillSuccess
                supplierType={selectedSupplierType}
                resetShowSuccess={resetShowSuccess}
                resetSelectedSupplier={resetSelectedSupplier}
                onClose={onClose}
              />
            ) : (
              <div>
                <div data-testid="supplier-dropdown">
                  <SearchDropdown
                    options={supplierResults}
                    placeholder={
                      selectedSupplier
                        ? selectedSupplier.name
                        : intl.formatMessage({
                            id: translationKeys.new_skill_drawer_supplier_input_label_and_placeholder,
                          })
                    }
                    label={intl.formatMessage({
                      id: translationKeys.new_skill_drawer_supplier_input_label_and_placeholder,
                    })}
                    size="large"
                    search
                    onSearch={(query: string) => {
                      debounceSupplierSearch(() => {
                        getSupplierList(query);
                      });
                    }}
                    onChange={(supplier) => {
                      if (supplier && supplier.length > 0 && supplier[0].key) {
                        setSelectedSupplier(supplier[0] as unknown as ManagerSupplier);
                      } else {
                        setSelectedSupplier(undefined);
                      }
                    }}
                  />
                  {showErrors && !selectedSupplier && (
                    <span className={styles.error} data-testid="supplier-error">
                      <FormattedMessage
                        id={translationKeys.interpretation_skills_new_skill_supplier_error}
                      />
                    </span>
                  )}
                </div>
                <div className={styles.supplierContainer}>
                  {selectedSupplier && (
                    <>
                      <SupplierCard supplier={selectedSupplier} />
                      <div>
                        <p className={styles.supplierTypesLabel}>
                          <FormattedMessage
                            id={translationKeys.new_skill_drawer_supplier_type_label}
                          />
                        </p>
                        <div className={styles.supplierType}>
                          <Form.Check
                            type="radio"
                            label={intl.formatMessage({
                              id: translationKeys.new_skill_drawer_supplier_type_interpreter,
                            })}
                            name="supplierType"
                            onChange={() => {
                              resetForm();
                              handleRadioButtonChange(ManagerSupplierType.Interpreter, 3);
                            }}
                            data-testid="interpreter-radio-button"
                          />
                          <Form.Check
                            type="radio"
                            label={intl.formatMessage({
                              id: translationKeys.new_skill_drawer_supplier_type_translator,
                            })}
                            name="supplierType"
                            onChange={() => {
                              resetForm();
                              handleRadioButtonChange(ManagerSupplierType.Translator, undefined);
                            }}
                            data-testid="translator-radio-button"
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {selectedSupplierType !== ManagerSupplierType.Undefined && (
                    <div>
                      <div className={styles.languageInput}>
                        <div data-testid="lang-from-dropdown">
                          <SearchDropdown
                            options={languageOptions}
                            selected={selectedFromLanguage && [selectedFromLanguage]}
                            disabled={
                              !activateInputFields &&
                              selectedSupplierType === ManagerSupplierType.Interpreter
                            }
                            placeholder={intl.formatMessage({
                              id: translationKeys.interpretation_skills_new_skill_language_from_placeholder,
                            })}
                            label={intl.formatMessage({
                              id: translationKeys.interpretation_skills_new_skill_language_from_label,
                            })}
                            size="large"
                            data-testid="language-from-input"
                            onChange={(language) => {
                              if (language && language.length > 0 && language[0].key) {
                                setSelectedFromLanguage(language[0]);
                              }
                            }}
                          />
                          {showErrors && !selectedFromLanguage && (
                            <span className={styles.error} data-testid="lang-from-error">
                              <FormattedMessage
                                id={
                                  translationKeys.interpretation_skills_new_skill_language_from_error
                                }
                              />
                            </span>
                          )}
                          {selectedSupplierType === ManagerSupplierType.Interpreter &&
                            !activateInputFields && (
                              <Button
                                variant="link"
                                className={styles.newLanguageBtn}
                                onClick={activateInputs}
                                data-testid="activate-lang-input-button"
                              >
                                <FormattedMessage
                                  id={
                                    translationKeys.new_skill_drawer_choose_another_language_button
                                  }
                                />
                              </Button>
                            )}
                        </div>

                        {selectedSupplierType === ManagerSupplierType.Interpreter ? (
                          <TextField
                            disabled={!activateInputFields}
                            placeholder={intl.formatMessage({
                              id: translationKeys.interpretation_skills_new_skill_dialect_from_placeholder,
                            })}
                            label={intl.formatMessage({
                              id: translationKeys.interpretation_skills_new_skill_dialect_from_label,
                            })}
                            size="large"
                            name="dialectFrom"
                            data-testid="dialect-from-dropdown"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={!activateInputFields ? 'Bokmål' : values.dialectFrom}
                            errorText={touched.dialectFrom ? errors.dialectFrom : undefined}
                          />
                        ) : (
                          <div data-testid="lang-to-dropdown">
                            <SearchDropdown
                              options={languageOptions}
                              placeholder={intl.formatMessage({
                                id: translationKeys.interpretation_skills_new_skill_language_to_placeholder,
                              })}
                              label={intl.formatMessage({
                                id: translationKeys.interpretation_skills_new_skill_language_to_label,
                              })}
                              size="large"
                              data-testid="lang-to-input"
                              onChange={(language) => {
                                if (language && language.length > 0 && language[0].key) {
                                  setSelectedToLanguage(language[0]);
                                } else {
                                  setSelectedToLanguage(undefined);
                                }
                              }}
                            />
                            {showErrors && !selectedToLanguage && (
                              <span className={styles.error} data-testid="lang-to-error">
                                <FormattedMessage
                                  id={
                                    translationKeys.interpretation_skills_new_skill_language_to_error
                                  }
                                />
                              </span>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {selectedSupplierType !== ManagerSupplierType.Undefined &&
                    selectedSupplierType === ManagerSupplierType.Interpreter && (
                      <div className={styles.languageInput}>
                        <div data-testid="lang-to-dropdown">
                          <SearchDropdown
                            options={languageOptions}
                            placeholder={intl.formatMessage({
                              id: translationKeys.interpretation_skills_new_skill_language_to_placeholder,
                            })}
                            label={intl.formatMessage({
                              id: translationKeys.interpretation_skills_new_skill_language_to_label,
                            })}
                            size="large"
                            data-testid="language-to-input"
                            onChange={(language) => {
                              if (language && language.length > 0 && language[0].key) {
                                setSelectedToLanguage(language[0]);
                              } else {
                                setSelectedToLanguage(undefined);
                              }
                            }}
                          />
                          {showErrors && !selectedToLanguage && (
                            <span className={styles.error} data-testid="lang-to-error">
                              <FormattedMessage
                                id={
                                  translationKeys.interpretation_skills_new_skill_language_to_error
                                }
                              />
                            </span>
                          )}
                        </div>

                        <TextField
                          placeholder={intl.formatMessage({
                            id: translationKeys.interpretation_skills_new_skill_dialect_to_placeholder,
                          })}
                          label={intl.formatMessage({
                            id: translationKeys.interpretation_skills_new_skill_dialect_to_label,
                          })}
                          size="large"
                          name="dialectTo"
                          data-testid="dialect-to-dropdown"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.dialectTo}
                          errorText={touched.dialectTo ? errors.dialectTo : undefined}
                        />
                      </div>
                    )}

                  {selectedSupplierType !== ManagerSupplierType.Undefined && (
                    <>
                      <div data-testid="qualification-input">
                        <SearchDropdown
                          options={
                            selectedSupplierType === ManagerSupplierType.Interpreter
                              ? interpreterQualificationOptions
                              : translatorQualificationOptions
                          }
                          placeholder={intl.formatMessage({
                            id: translationKeys.interpretation_skills_new_skill_qualification_placeholder,
                          })}
                          label={intl.formatMessage({
                            id: translationKeys.interpretation_skills_new_skill_qualification_label,
                          })}
                          size="large"
                          selected={selectedQualification ? [selectedQualification] : undefined}
                          onChange={(qualification) => {
                            if (qualification && qualification.length > 0 && qualification[0].key) {
                              setSelectedQualification(qualification[0]);
                              if (qualification[0].id === 8) {
                                setFieldValue('nationalInterpreterId', '');
                              }
                            } else {
                              setSelectedQualification(undefined);
                            }
                          }}
                        />
                        {showErrors && !selectedQualification && (
                          <span className={styles.error} data-testid="qualification-error">
                            <FormattedMessage
                              id={
                                translationKeys.interpretation_skills_new_skill_qualification_error
                              }
                            />
                          </span>
                        )}
                      </div>

                      <QualificationAccordion selectedSupplierType={selectedSupplierType} />
                    </>
                  )}
                  {selectedQualification && shouldShowFileUploader(selectedQualification.id) && (
                    <div>
                      {selectedSupplierType === ManagerSupplierType.Interpreter && (
                        <div className={styles.infoBanner}>
                          <Banner
                            text={intl.formatMessage({
                              id: translationKeys.new_skill_drawer_interpreter_banner_text,
                            })}
                            variant="information"
                          />
                        </div>
                      )}

                      <div className={styles.docContainer}>
                        <h2>
                          <FormattedMessage
                            id={translationKeys.new_skill_drawer_documentation_title}
                          />
                        </h2>
                        <p>
                          <FormattedMessage
                            id={translationKeys.new_skill_drawer_documentation_text}
                          />
                        </p>
                      </div>

                      <div className={styles.uploadFile}>
                        <FileSelector
                          selectedFiles={selectedFiles}
                          multiple
                          onSelection={(newFiles) => {
                            handleFileSelection(newFiles as []);
                          }}
                        />
                        <div className={styles.fileList}>
                          {selectedFiles.map((file) => {
                            return (
                              <>
                                <DetailItem
                                  key={file.id}
                                  subtitle={<span>{formatFileSize(file.size)}</span>}
                                  title={file.name}
                                  size="large"
                                  preview={<img src={fileIllustration} height="40" />}
                                  onActionSelect={(id) => {
                                    if (id === 'Delete') {
                                      return handleRemoveFile(file.uid);
                                    } else if (id === 'Rename') {
                                      setRenameFileUid(file.uid);
                                      setShowRenameFileModal(true);
                                    }
                                  }}
                                  actions={[
                                    {
                                      id: 'Delete',
                                      text: intl.formatMessage({
                                        id: translationKeys.new_skill_drawer_file_selector_delete_option,
                                      }),
                                      icon: faTrash,
                                      variant: 'critical',
                                    },
                                    {
                                      id: 'Rename',
                                      text: intl.formatMessage({
                                        id: translationKeys.new_skill_drawer_file_selector_rename_option,
                                      }),
                                      icon: faPen,
                                    },
                                  ]}
                                />

                                <RenameFileModal
                                  show={renameFileUid === file.uid && showRenameFileModal}
                                  file={file}
                                  onClose={() => setShowRenameFileModal(false)}
                                  handleRenameFileSubmit={handleRenameFileSubmit}
                                />
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  )}

                  {selectedSupplierType === ManagerSupplierType.Interpreter &&
                    selectedQualification &&
                    shouldShowAndRequireTolkId(selectedQualification.id) && (
                      <TextField
                        placeholder={intl.formatMessage({
                          id: translationKeys.new_skill_drawer_national_id_input_label,
                        })}
                        label={intl.formatMessage({
                          id: translationKeys.new_skill_drawer_national_id_input_placeholder,
                        })}
                        size="large"
                        name="nationalInterpreterId"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.nationalInterpreterId}
                        errorText={
                          touched.nationalInterpreterId || createSkillError?.text.verifiedTolkId
                            ? errors.nationalInterpreterId || createSkillError?.text.verifiedTolkId
                            : undefined
                        }
                      />
                    )}

                  {selectedSupplierType !== ManagerSupplierType.Undefined && (
                    <>
                      <div className={styles.skillStatus}>
                        <Form.Check
                          type="radio"
                          label={intl.formatMessage({
                            id: translationKeys.new_skill_drawer_skills_status_pending_label,
                          })}
                          name="skillStatus"
                          checked={selectedSkillStatus === 'pending'}
                          onChange={() => {
                            setSelectedSkillStatus('pending');
                            setNotifySupplier(false);
                          }}
                          data-testid="pending-radio-button"
                        />
                        <Form.Check
                          type="radio"
                          label={intl.formatMessage({
                            id: translationKeys.new_skill_drawer_skills_status_approved_with_notification_label,
                          })}
                          name="skillStatus"
                          checked={selectedSkillStatus === 'approved' && notifySupplier === true}
                          onChange={() => {
                            setSelectedSkillStatus('approved');
                            setNotifySupplier(true);
                          }}
                          data-testid="approved-radio-button"
                        />
                        <Form.Check
                          type="radio"
                          label={intl.formatMessage({
                            id: translationKeys.new_skill_drawer_skills_status_approved_silently_label,
                          })}
                          name="skillStatus"
                          checked={selectedSkillStatus === 'approved' && notifySupplier === false}
                          onChange={() => {
                            setSelectedSkillStatus('approved');
                            setNotifySupplier(false);
                          }}
                          data-testid="approved-silently-radio-button"
                        />
                      </div>

                      {createSkillError &&
                        (createSkillError.text?.languageFromId ||
                          createSkillError.text?.languageToId) && (
                          <Banner
                            variant="error"
                            text={
                              createSkillError.text?.languageFromId ||
                              createSkillError.text?.languageToId
                            }
                          />
                        )}

                      <div className={styles.actions}>
                        <Button
                          size="large"
                          variant="gray"
                          onClick={onClose}
                          data-testid="cancel-button"
                        >
                          <FormattedMessage id={translationKeys.new_skill_drawer_cancel_button} />
                        </Button>

                        <Button
                          size="large"
                          type="submit"
                          data-testid="submit-button"
                          isLoading={createSkillLoading}
                        >
                          <FormattedMessage
                            id={translationKeys.new_skill_drawer_create_skill_button}
                          />
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </Drawer>
  );
};

export default NewSkillDrawer;
