import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Drawer, SearchDropdown, SearchDropdownMenuOption } from '@skiwo/components';
import { useApi } from '../../../../providers/ApiProvider';
import { useLanguages } from '../../../../providers/LanguagesProvider';
import { useToast } from '../../../../providers/ToastProvider/ToastProvider';
import translationKeys from '../../../../translations/translationKeys';
import { ManagerOrderTask } from '../../../../types';
import styles from './EditTaskDrawer.module.scss';

interface Props {
  show?: boolean;
  onClose: () => void;
  task: ManagerOrderTask;
}

const EditTaskDrawer = (props: Props) => {
  const { show, onClose, task } = props;
  const { languages, getLanguageById } = useLanguages();
  const [selectedLanguage, setSelectedLanguage] = useState<SearchDropdownMenuOption>();
  const [updateLoading, setUpdateLoading] = useState(false);
  const api = useApi();
  const { showErrorToast } = useToast();

  const intl = useIntl();

  const handleSubmit = async () => {
    if (!selectedLanguage) return;

    const { data, error } = await api.updateTask(
      task.orderId.toString(),
      task.id.toString(),
      { targetLanguageId: selectedLanguage.id },
      setUpdateLoading,
    );

    if (data) {
      onClose();
    }
    if (error) {
      showErrorToast(error);
    }
  };

  const languageOptions =
    languages.map((language) => {
      return { id: language.id, label: language.name || '', key: language.id.toString() };
    }) || [];

  useEffect(() => {
    if (!show) return;

    const currentLanguage = languageOptions.find(
      (language) => language.id === task.targetLanguageId,
    );

    if (currentLanguage) {
      setSelectedLanguage(currentLanguage);
    }
  }, [show]);

  return (
    <Drawer
      show={show}
      onClose={onClose}
      title={intl.formatMessage({ id: translationKeys.translation_order_edit_task_title })}
      data-testid="edit-task-drawer"
    >
      <div className={styles.editTask}>
        <div className={styles.overview}>
          <span className={styles.id}>#{task.id}</span>
          <div className={styles.title}>
            <span>{getLanguageById(task.sourceLanguageId)}</span>
            <FontAwesomeIcon icon={faArrowRight} />
            <span>{getLanguageById(task.targetLanguageId)}</span>
          </div>
        </div>

        <SearchDropdown
          options={languageOptions}
          selected={selectedLanguage && [selectedLanguage]}
          placeholder={intl.formatMessage({
            id: translationKeys.translation_order_edit_task_target_language_label,
          })}
          label={intl.formatMessage({
            id: translationKeys.translation_order_edit_task_target_language_placeholder,
          })}
          size="large"
          hint={intl.formatMessage({
            id: translationKeys.translation_order_edit_task_target_language_hint,
          })}
          errorText={
            (!selectedLanguage &&
              intl.formatMessage(
                { id: translationKeys.form_error_required },
                {
                  fieldName: intl.formatMessage({
                    id: translationKeys.translation_order_edit_task_target_language_label,
                  }),
                },
              )) ||
            undefined
          }
          onChange={(language) => {
            if (language && language.length > 0 && language[0].key) {
              setSelectedLanguage(language[0]);
            } else {
              setSelectedLanguage(undefined);
            }
          }}
        />

        <div className={styles.actions}>
          <Button variant="gray" onClick={onClose} size="large">
            <FormattedMessage id={translationKeys.translation_order_edit_task_cancel_button} />
          </Button>
          <Button
            type="submit"
            size="large"
            isLoading={updateLoading}
            disabled={!selectedLanguage}
            onClick={handleSubmit}
            data-testid="change-owner-submit-button"
          >
            <FormattedMessage id={translationKeys.translation_order_edit_task_save_button} />
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default EditTaskDrawer;
