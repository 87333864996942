import { IntlShape } from 'react-intl';
import getAssignments from './Assignments';
import getCustomers from './Customers';
import getFinance from './Finance';
import getSuppliers from './Suppliers';

const getMenuTabs = (intl: IntlShape) => {
  return {
    assignments: getAssignments(intl),
    customers: getCustomers(intl),
    suppliers: getSuppliers(intl),
    finance: getFinance(intl),
  };
};

export default getMenuTabs;
