import React, { useState } from 'react';
import { Form as B_Form } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import { faArrowRight, faBuilding, faFile, faFilePen } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, DatePicker, Drawer, RadioOptions, Tag, TextField } from '@skiwo/components';
import { differenceInHours, format, getDay, startOfMonth } from 'date-fns';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { useApi } from '../../../../providers/ApiProvider';
import { useLanguages } from '../../../../providers/LanguagesProvider';
import { useToast } from '../../../../providers/ToastProvider/ToastProvider';
import translationKeys from '../../../../translations/translationKeys';
import { ManagerOrderSubtaskDetail, ManagerOrderSubtaskType } from '../../../../types';
import styles from './EditSubtaskDrawer.module.scss';

interface FormValues {
  type: ManagerOrderSubtaskType;
  internalDeadline?: string;
  externalDeadline?: string;
  deliveredOn?: string;
  countOfWords?: number;
  platformQuoteAmount?: number;
  sellerQuoteAmount?: number;
  deliveredShortNotice?: boolean;
}

interface Props {
  show?: boolean;
  subtask: ManagerOrderSubtaskDetail;
  onClose: () => void;
}

const EditSubtaskDrawer = (props: Props) => {
  const { show, subtask, onClose } = props;
  const api = useApi();
  const intl = useIntl();
  const { showErrorToast } = useToast();
  const { getLanguageById } = useLanguages();
  const [showExternalDeadlineDatePicker, setShowExternalDeadlineDatePicker] = useState(false);
  const [showInternalDeadlineDatePicker, setShowInternalDeadlineDatePicker] = useState(false);
  const [showReviewRequestedAtDatePicker, setShowReviewRequestedAtDatePicker] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);

  const schema = yup.object().shape({
    type: yup.string().required(
      intl.formatMessage(
        { id: translationKeys.form_error_required },
        {
          fieldName: intl.formatMessage({
            id: translationKeys.translation_subtask_edit_drawer_type_label,
          }),
        },
      ),
    ),
    internalDeadline: yup.string().nullable(),
    externalDeadline: yup.string().nullable(),
    deliveredOn: yup.string().nullable(),
    countOfWords: yup.string().when('type', ([type]: ManagerOrderSubtaskType[], sch) =>
      type === ManagerOrderSubtaskType.Translation || type === ManagerOrderSubtaskType.Correction
        ? sch.required(
            intl.formatMessage(
              { id: translationKeys.form_error_required },
              {
                fieldName: intl.formatMessage({
                  id: translationKeys.translation_subtask_edit_drawer_seller_word_count_label,
                }),
              },
            ),
          )
        : sch.notRequired(),
    ),
    platformQuoteAmount: yup.string(),
    sellerQuoteAmount: yup.string(),
    deliveredShortNotice: yup.boolean(),
  });

  const showShortNoticeTag = (externalDeadline?: string) => {
    if (!externalDeadline) return false;

    const currentDate = new Date();
    const shortNoticeTimeHours = subtask.enterprise?.isPolice ? 24 : 48;

    if (differenceInHours(new Date(externalDeadline), currentDate) < shortNoticeTimeHours)
      return true;
    return false;
  };

  const handleSubmit = async (values: FormValues) => {
    const { data, error } = await api.updateOrderSubtask(
      subtask.id.toString(),
      {
        subtaskType: values.type,
        internalDeadline: values.internalDeadline,
        externalDeadline: values.externalDeadline,
        deliveredOn: values.deliveredOn,
        countOfWords: values.countOfWords,
        platformQuoteAmount: values.platformQuoteAmount,
        sellerQuoteAmount: values.sellerQuoteAmount,
        deliveredShortNotice: values.deliveredShortNotice,
      },
      setUpdateLoading,
    );

    if (data) {
      onClose();
    }
    if (error) {
      showErrorToast(error);
    }
  };

  return (
    <Drawer
      show={show}
      onClose={onClose}
      title={intl.formatMessage({ id: translationKeys.translation_subtask_edit_drawer_title })}
      data-testid="edit-subtask-drawer"
    >
      <Formik<FormValues>
        validationSchema={schema}
        initialValues={{
          type: subtask.subtaskType as ManagerOrderSubtaskType,
          internalDeadline: subtask.internalDeadline,
          externalDeadline: subtask.externalDeadline,
          deliveredOn: subtask.deliveredOn,
          countOfWords: subtask.countOfWords,
          platformQuoteAmount: subtask.platformQuoteAmount,
          sellerQuoteAmount: subtask.sellerQuoteAmount,
          deliveredShortNotice: subtask.deliveredShortNotice,
        }}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, handleChange, handleBlur, setFieldValue, values, touched, errors }) => (
          <Form onSubmit={handleSubmit} className={styles.editSubtask}>
            <div className={styles.overview}>
              <span className={styles.id}>#{subtask.publicId}</span>
              <div className={styles.title}>
                <span>{getLanguageById(subtask.sourceLanguageId)}</span>
                <FontAwesomeIcon icon={faArrowRight} />
                <span>{getLanguageById(subtask.targetLanguageId)}</span>
              </div>
            </div>

            <RadioOptions
              label={intl.formatMessage({
                id: translationKeys.translation_subtask_edit_drawer_type_label,
              })}
              selected={values.type}
              options={[
                {
                  id: ManagerOrderSubtaskType.Translation,
                  title: intl.formatMessage({
                    id: translationKeys.translation_subtask_edit_drawer_type_translation,
                  }),
                  icon: <FontAwesomeIcon icon={faFile} />,
                },
                {
                  id: ManagerOrderSubtaskType.Correction,
                  title: intl.formatMessage({
                    id: translationKeys.translation_subtask_edit_drawer_type_correction,
                  }),
                  icon: <FontAwesomeIcon icon={faFilePen} />,
                },
                {
                  id: ManagerOrderSubtaskType.InPerson,
                  title: intl.formatMessage({
                    id: translationKeys.translation_subtask_edit_drawer_type_in_person,
                  }),
                  icon: <FontAwesomeIcon icon={faBuilding} />,
                },
              ]}
              onSelect={(option) => setFieldValue('type', option)}
            />

            <div className={styles.inputRow}>
              <div className={styles.inputRowElement}>
                <TextField
                  label={intl.formatMessage({
                    id: translationKeys.translation_subtask_edit_drawer_internal_deadline_label,
                  })}
                  placeholder={intl.formatMessage({
                    id: translationKeys.translation_subtask_edit_drawer_internal_deadline_label,
                  })}
                  icon={<FontAwesomeIcon icon={faCalendar} />}
                  value={
                    values.internalDeadline
                      ? format(new Date(values.internalDeadline), 'dd.MM.yy')
                      : undefined
                  }
                  size="large"
                  onFocus={() => setShowInternalDeadlineDatePicker(true)}
                  onChange={() => setFieldValue('internalDeadline', null)}
                  type="search"
                  errorText={touched.internalDeadline ? errors.internalDeadline : undefined}
                />
                {showInternalDeadlineDatePicker && (
                  <DatePicker
                    monthCount={1}
                    onClose={() => setShowInternalDeadlineDatePicker(false)}
                    singleDate
                    selected={
                      values.internalDeadline ? new Date(values.internalDeadline) : undefined
                    }
                    onChange={(date: Date) => {
                      setFieldValue('internalDeadline', date);
                      setShowInternalDeadlineDatePicker(false);
                    }}
                  />
                )}
              </div>

              <div className={styles.inputRowElement}>
                <TextField
                  label={intl.formatMessage({
                    id: translationKeys.translation_subtask_edit_drawer_external_deadline_label,
                  })}
                  placeholder={intl.formatMessage({
                    id: translationKeys.translation_subtask_edit_drawer_external_deadline_label,
                  })}
                  icon={<FontAwesomeIcon icon={faCalendar} />}
                  value={
                    values.externalDeadline
                      ? format(new Date(values.externalDeadline), 'dd.MM.yy')
                      : undefined
                  }
                  size="large"
                  onFocus={() => setShowExternalDeadlineDatePicker(true)}
                  onChange={() => setFieldValue('externalDeadline', null)}
                  type="search"
                  decorationView={
                    showShortNoticeTag(values.externalDeadline) && (
                      <Tag variant="square" color="neutral">
                        {intl.formatMessage({
                          id: translationKeys.translation_subtask_edit_drawer_short_notice_tag,
                        })}
                      </Tag>
                    )
                  }
                  errorText={touched.externalDeadline ? errors.externalDeadline : undefined}
                  disabled
                />
                {showExternalDeadlineDatePicker && (
                  <DatePicker
                    monthCount={1}
                    onClose={() => setShowExternalDeadlineDatePicker(false)}
                    singleDate
                    selected={
                      values.externalDeadline ? new Date(values.externalDeadline) : undefined
                    }
                    onChange={(date: Date) => {
                      setFieldValue('externalDeadline', date);
                      setShowExternalDeadlineDatePicker(false);
                    }}
                  />
                )}
              </div>
            </div>

            <div className={styles.inputRow}>
              <div className={styles.inputRowElement}>
                <TextField
                  label={intl.formatMessage({
                    id: translationKeys.translation_subtask_edit_drawer_delivery_date_label,
                  })}
                  placeholder={intl.formatMessage({
                    id: translationKeys.translation_subtask_edit_drawer_delivery_date_placeholder,
                  })}
                  icon={<FontAwesomeIcon icon={faCalendar} />}
                  value={
                    values.deliveredOn
                      ? format(new Date(values.deliveredOn), 'dd.MM.yy')
                      : undefined
                  }
                  size="large"
                  onFocus={() => setShowReviewRequestedAtDatePicker(true)}
                  onChange={() => {
                    setFieldValue('deliveredOn', null);
                  }}
                  type="search"
                  errorText={touched.deliveredOn ? errors.deliveredOn : undefined}
                />
                {showReviewRequestedAtDatePicker && (
                  <DatePicker
                    monthCount={1}
                    onClose={() => setShowReviewRequestedAtDatePicker(false)}
                    singleDate
                    minDate={getDay(new Date()) < 5 ? startOfMonth(new Date()) : undefined}
                    selected={values.deliveredOn ? new Date(values.deliveredOn) : undefined}
                    onChange={(date: Date) => {
                      setFieldValue('deliveredOn', date);
                      setShowReviewRequestedAtDatePicker(false);
                    }}
                  />
                )}
              </div>
              <div className={styles.inputRowElement}>
                {values.type !== ManagerOrderSubtaskType.InPerson && (
                  <div className={styles.wordCountElement}>
                    <TextField
                      label={intl.formatMessage({
                        id: translationKeys.translation_subtask_edit_drawer_seller_word_count_label,
                      })}
                      placeholder={intl.formatMessage({
                        id: translationKeys.translation_subtask_edit_drawer_seller_word_count_placeholder,
                      })}
                      value={values.countOfWords}
                      size="large"
                      type="number"
                      name="countOfWords"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errorText={touched.countOfWords ? errors.countOfWords : undefined}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className={styles.inputRow}>
              <div className={styles.inputRowElement}>
                <TextField
                  label={intl.formatMessage({
                    id: translationKeys.translation_subtask_edit_drawer_salita_quote_label,
                  })}
                  placeholder={intl.formatMessage({
                    id: translationKeys.translation_subtask_edit_drawer_salita_quote_label,
                  })}
                  value={values.platformQuoteAmount}
                  size="large"
                  type="number"
                  decorationView="NOK"
                  name="platformQuoteAmount"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errorText={touched.platformQuoteAmount ? errors.platformQuoteAmount : undefined}
                />
              </div>

              <div className={styles.inputRowElement}>
                <TextField
                  label={intl.formatMessage({
                    id: translationKeys.translation_subtask_edit_drawer_seller_quote_label,
                  })}
                  placeholder={intl.formatMessage({
                    id: translationKeys.translation_subtask_edit_drawer_seller_quote_label,
                  })}
                  value={values.sellerQuoteAmount}
                  size="large"
                  type="number"
                  decorationView="NOK"
                  name="sellerQuoteAmount"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errorText={touched.sellerQuoteAmount ? errors.sellerQuoteAmount : undefined}
                />
              </div>
            </div>

            <B_Form.Check
              type="checkbox"
              label={intl.formatMessage({
                id: translationKeys.translation_subtask_edit_drawer_short_notice_checkbox,
              })}
              name="deliveredShortNotice"
              onChange={handleChange}
              checked={values.deliveredShortNotice}
            />

            <div className={styles.actions}>
              <Button size="large" variant="gray" onClick={onClose} data-testid="cancel-button">
                {intl.formatMessage({
                  id: translationKeys.translation_subtask_edit_drawer_cancel_button,
                })}
              </Button>
              <Button type="submit" size="large" isLoading={updateLoading}>
                {intl.formatMessage({
                  id: translationKeys.translation_subtask_edit_drawer_save_button,
                })}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Drawer>
  );
};

export default EditSubtaskDrawer;
