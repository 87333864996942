import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { faSearch, faUser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, TextField } from '@skiwo/components';
import classnames from 'classnames';
import useDebounce from '../../../../hooks/useDebounce';
import translationKeys from '../../../../translations/translationKeys';
import { Manager } from '../../../../types/Manager';
import styles from './AssignQADrawer.module.scss';

interface QAsListProps {
  qualityAssurers: Manager[];
  onSearch: (query: string) => void;
  selectedQAUid: string | null;
  setSelectedQAUid: (id: string) => void;
}

const AvailableQAsList = ({
  qualityAssurers,
  onSearch,
  selectedQAUid,
  setSelectedQAUid,
}: QAsListProps) => {
  const intl = useIntl();
  const debounceSearch = useDebounce(300);

  return (
    <>
      <TextField
        size="large"
        icon={<FontAwesomeIcon icon={faSearch} />}
        placeholder={intl.formatMessage({
          id: translationKeys.translation_subtask_details_assign_qa_drawer_search_placeholder,
        })}
        onChange={(e) =>
          debounceSearch(() => {
            onSearch(e.target.value);
          })
        }
      />

      {!!qualityAssurers.length && (
        <div className={styles.detailItemList}>
          {qualityAssurers.map((item) => {
            return (
              <div
                key={item.uid}
                className={classnames(styles.detailItem, {
                  [styles.selected]: selectedQAUid === item.uid,
                })}
                onClick={() => setSelectedQAUid(item.uid)}
              >
                <div data-testid="detail-item-preview">
                  <Avatar size="medium" altText="" url={item.avatarPath} />
                </div>
                <div className={styles.titleContainer}>
                  <span data-testid="detail-item-title" className={styles.title}>
                    {item.name}
                  </span>
                </div>

                <div className={styles.rightSection}>
                  <div data-testid="detail-item-auxiliary-view">
                    <div
                      className={classnames(styles.selectedIndicator, {
                        [styles.selected]: selectedQAUid === item.uid,
                      })}
                    >
                      <FontAwesomeIcon icon={faCheck} className={styles.selectedIcon} />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}

      {!qualityAssurers.length && (
        <div className={styles.emptyState}>
          <FontAwesomeIcon className={styles.emptyStateIcon} icon={faUser} />
          <span>
            <FormattedMessage
              id={translationKeys.translation_subtask_details_assign_qa_drawer_empty_state}
            />
          </span>
        </div>
      )}
    </>
  );
};

export default AvailableQAsList;
