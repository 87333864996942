import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { faNoteSticky } from '@fortawesome/pro-light-svg-icons';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Drawer, TextField } from '@skiwo/components';
import { format } from 'date-fns';
import { Form, Formik } from 'formik';
import { useApi } from '../../providers/ApiProvider';
import { useLanguages } from '../../providers/LanguagesProvider';
import translationKeys from '../../translations/translationKeys';
import { JobNoteResponse, ManagerJob } from '../../types';
import JobNotesList from './JobNotesList/JobNotesList';
import styles from './JobNotes.Drawer.module.scss';

interface JobNotesDrawerProps {
  title: string;
  onClose: () => void;
  onAddNote?: () => void;
  onDeleteNote?: () => void;
  show: boolean;
  job: ManagerJob;
}

const JobNotesDrawer: React.FC<JobNotesDrawerProps> = (props) => {
  const { show, onClose, title, job, onDeleteNote, onAddNote } = props;
  const { getLanguageById } = useLanguages();
  const intl = useIntl();
  const api = useApi();
  const [jobNotes, setJobNotes] = useState<JobNoteResponse>();

  const handleCreateNote = async (newNote: string) => {
    await api.postJobNote(job.id.toString(), newNote);

    if (onAddNote) {
      onAddNote();
    }
  };

  const handleSubmit = () => {
    onClose();
  };

  const getJobNotes = async () => {
    const { data } = await api.getJobNotes(job.id.toString());
    if (data) setJobNotes(data);
  };

  useEffect(() => {
    if (!show) return;

    getJobNotes();
  }, [show]);

  return (
    <Drawer show={show} title={title} onClose={onClose} data-testid="notes-drawer">
      <div className={styles.container}>
        <div className={styles.infoCard}>
          <div>
            <span className={styles.id}>ID: {job.id}</span>
            <h5 className={styles.language}>
              {getLanguageById(job.langFromId)} <FontAwesomeIcon icon={faArrowRight} />
              {getLanguageById(job.langToId)}
            </h5>
          </div>

          <div className={styles.contactInfo}>
            <span className={styles.name}>{job.owner}</span>
            <span className={styles.createdDate}>
              {format(new Date(job.createdAt), 'dd.MM.yyyy, HH:mm')}
            </span>
          </div>
        </div>
        {(jobNotes?.messages?.length ?? 0) === 0 ||
        jobNotes?.messages.every((message) => message.text === null) ? (
          <div className={styles.emptyState}>
            <FontAwesomeIcon icon={faNoteSticky} />
            <p>
              <FormattedMessage id={translationKeys.job_notes_drawer_empty_state_text} />
            </p>
          </div>
        ) : (
          <JobNotesList
            job={job}
            jobNotesList={jobNotes?.messages || []}
            onDeleteNote={onDeleteNote}
          />
        )}
      </div>

      <div className={styles.noteTextfield}>
        <Formik
          onSubmit={handleSubmit}
          initialValues={{
            message: '',
          }}
        >
          {({ handleSubmit, handleChange, values }) => (
            <Form onSubmit={handleSubmit}>
              <TextField
                as="textarea"
                type="text"
                placeholder={intl.formatMessage({
                  id: translationKeys.job_notes_drawer_input_placeholder,
                })}
                label={intl.formatMessage({ id: translationKeys.job_notes_drawer_input_title })}
                onChange={handleChange}
                name="message"
                value={values.message}
                data-testid="note-textfield"
              />
              <div className={styles.postButtons}>
                <Button onClick={onClose} variant="gray" size="large" data-testid="close-button">
                  <FormattedMessage id={translationKeys.job_notes_drawer_close_button} />
                </Button>

                <Button
                  iconPosition="left"
                  size="large"
                  variant="primary"
                  type="submit"
                  onClick={() => handleCreateNote(values.message)}
                  disabled={!values.message}
                  data-testid="submit-button"
                >
                  <FormattedMessage id={translationKeys.job_notes_drawer_submit_button} />
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Drawer>
  );
};

export default JobNotesDrawer;
