import { IntlShape } from 'react-intl';
import translationKeys from '../../translations/translationKeys';
import { TranslationSkillAccountStatus } from '../../types';

const getAccountStatusName = (status: TranslationSkillAccountStatus, intl: IntlShape): string => {
  if (status === TranslationSkillAccountStatus.Active) {
    return intl.formatMessage({ id: translationKeys.suppliers_page_active_status });
  }

  if (status === TranslationSkillAccountStatus.Banned) {
    return intl.formatMessage({ id: translationKeys.suppliers_page_banned_status });
  }

  if (status === TranslationSkillAccountStatus.Blocked) {
    return intl.formatMessage({ id: translationKeys.suppliers_page_blocked_status });
  }

  if (status === TranslationSkillAccountStatus.Deceased) {
    return intl.formatMessage({ id: translationKeys.suppliers_page_deceased_status });
  }

  if (status === TranslationSkillAccountStatus.Deleted) {
    return intl.formatMessage({ id: translationKeys.suppliers_page_deleted_status });
  }

  if (status === TranslationSkillAccountStatus.Paused) {
    return intl.formatMessage({ id: translationKeys.suppliers_page_paused_status });
  }

  if (status === TranslationSkillAccountStatus.Retired) {
    return intl.formatMessage({ id: translationKeys.suppliers_page_retired_status });
  }

  return '-';
};

export default getAccountStatusName;
