import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  faComment,
  faEnvelope,
  faLocationDot,
  faPhone,
  faVenusMars,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Button, Tag, Tooltip } from '@skiwo/components';
import { getGenderLabel } from '../../../CreateInterpretationOrder/utils';
import NotesDrawer from '../../../Drawers/NotesDrawer/NotesDrawer';
import { useLanguages } from '../../../providers/LanguagesProvider';
import { useQualifications } from '../../../providers/QualificationsProvider';
import translationKeys from '../../../translations/translationKeys';
import { ManagerJobAcceptedInterpreter } from '../../../types/ManagerJob';
import styles from './InterpreterCard.module.scss';

interface InterpreterCardProps {
  interpreter: ManagerJobAcceptedInterpreter;
}

const InterpreterCard = ({ interpreter }: InterpreterCardProps) => {
  const intl = useIntl();
  const { getLanguageById } = useLanguages();
  const { getQualificationById } = useQualifications();
  const [showNotesDrawer, setShowNotesDrawer] = useState(false);
  const subtitleItems = [
    {
      icon: <FontAwesomeIcon icon={faLocationDot} />,
      value: `${interpreter.city}, ${interpreter.country}`,
    },
    // V2
    // { icon: <FontAwesomeIcon icon={faArrowsLeftRight} />, value: '75.67 km' },
    {
      icon: <FontAwesomeIcon icon={faVenusMars} />,
      value: getGenderLabel(interpreter.person.gender, intl),
    },
  ];

  const mainItems = interpreter.skills.map((skill) => {
    const qualification = getQualificationById(skill.qualificationId);
    return {
      icon: (
        <Tag
          variant="square"
          color={qualification?.isInTolkeregister ? 'success' : 'neutral'}
          size="small"
        >
          {qualification?.isInTolkeregister && qualification.tolkeregisterName
            ? qualification.tolkeregisterName
            : 'N/A'}
        </Tag>
      ),
      value: getLanguageById(skill.languageToId),
    };
  });

  return (
    <>
      <div className={styles.interpreterCard} data-testid="details-card">
        <div className={styles.header}>
          <Avatar
            altText={interpreter.person.name || 'logo'}
            url={interpreter.person.avatar}
            size="large"
          />

          <div className={styles.headerContent}>
            <div className={styles.detailsTitle}>
              <Tooltip title={interpreter.person.phone} copyable>
                <FontAwesomeIcon icon={faPhone} size="xs" />
              </Tooltip>
              <Tooltip title={interpreter.person.email} copyable>
                <FontAwesomeIcon icon={faEnvelope} size="xs" />
              </Tooltip>
              <span data-testid="details-card-title">{interpreter.person.name}</span>
              <Tag color="success" size="medium">
                <FormattedMessage id={translationKeys.job_interpreter_awared} />
              </Tag>
              {interpreter.hasStandbyForJob && (
                <Tag color="neutral" size="medium">
                  <FormattedMessage id={translationKeys.job_interpreter_standby} />
                </Tag>
              )}
            </div>

            <div className={styles.headerValues}>
              {subtitleItems.map((item, i) => {
                return (
                  item.value && (
                    <span key={i}>
                      {item.icon}
                      {item.value}
                    </span>
                  )
                );
              })}
            </div>
          </div>
          <Button
            size="large"
            variant="white"
            icon={<FontAwesomeIcon icon={faComment} />}
            onClick={() => setShowNotesDrawer(true)}
          >
            <FormattedMessage
              id={translationKeys.job_interpreter_notes}
              values={{ notesCount: interpreter.notesCount ? `(${interpreter.notesCount})` : '' }}
            />
          </Button>
        </div>

        {!!mainItems.filter((item) => item.value !== undefined).length && (
          <>
            <hr className={styles.separator} data-testid="separator" />
            <div className={styles.footer}>
              {mainItems.map((item, i) => {
                return (
                  item.value && (
                    <div key={i}>
                      <span className={styles.value}>
                        {item.icon}
                        {item.value}
                      </span>
                    </div>
                  )
                );
              })}
            </div>
          </>
        )}
      </div>
      <NotesDrawer
        show={showNotesDrawer}
        onClose={() => setShowNotesDrawer(false)}
        onCancel={() => setShowNotesDrawer(false)}
        user={{
          id: interpreter.id,
          name: interpreter.person.name,
          email: interpreter.person.email,
          phone: interpreter.person.phone,
          uid: interpreter.person.uid,
        }}
      />
    </>
  );
};

export default InterpreterCard;
