import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Banner,
  Button,
  Drawer,
  SearchDropdown,
  SearchDropdownMenuOption,
} from '@skiwo/components';
import useDebounce from '../../../hooks/useDebounce';
import { useApi } from '../../../providers/ApiProvider';
import translationKeys from '../../../translations/translationKeys';
import { ManagerCustomer } from '../../../types';
import styles from './MergeCustomersDrawer.module.scss';

interface Props {
  show?: boolean;
  customer: ManagerCustomer;
  onClose: () => void;
  onComplete: (hardReload?: boolean) => void;
}

const MergeCustomersDrawer = (props: Props) => {
  const { show, customer, onClose, onComplete } = props;
  const intl = useIntl();
  const [customerResults, setCustomerResults] = useState<SearchDropdownMenuOption[]>([]);
  const [selectedCustomers, setSelectedCustomers] = useState<SearchDropdownMenuOption[]>();
  const debounceCustomerSearch = useDebounce(300);
  const api = useApi();

  const handleSubmit = async () => {
    if (!selectedCustomers || selectedCustomers.length === 0) return;
    const { error } = await api.mergeCustomerList(
      customer.uid,
      selectedCustomers.map((selectedCustomer) => selectedCustomer.key?.toString() || ''),
    );

    if (!error) {
      onComplete(true);
      onClose();
    }
  };

  const handleCustomerSelect = (customers: SearchDropdownMenuOption[]) => {
    setSelectedCustomers([...customers]);
  };

  const getCustomerList = async (query: string) => {
    const { data } = await api.getCustomers({
      's[name_cont]': query,
      's[enterprise_membership_id_not_null]': 1,
    });

    if (data?.customers) {
      const customerOptions =
        data.customers
          .filter((customers) => customers.id !== customer.id)
          .map((customer) => {
            return { id: customer.id, label: customer.name, key: customer.uid };
          }) || [];

      setCustomerResults(customerOptions);
    }
  };

  useEffect(() => {
    if (!show) return;

    getCustomerList('');
  }, [show]);

  return (
    <Drawer
      show={show}
      onClose={onClose}
      title={intl.formatMessage({ id: translationKeys.customers_page_merge_title })}
      subtitle={intl.formatMessage(
        { id: translationKeys.customers_page_merge_subtitle },
        { name: customer.name },
      )}
      data-testid="merge-customer-drawer"
    >
      <div className={styles.container}>
        <div className={styles.infoCard}>
          <div>
            <span className={styles.id}>
              <FormattedMessage
                id={translationKeys.customers_page_merge_id_label}
                values={{ id: customer.id }}
              />
            </span>
            <h5>{customer.name}</h5>
          </div>

          <div className={styles.contactInfo}>
            {customer.enterprise?.name && <span>{customer.enterprise.name}</span>}
            <span>{customer.email}</span>
            {customer.phone && <span>{customer.phone}</span>}
          </div>
        </div>

        <div className={styles.textInfo}>
          <span className={styles.heading}>
            <FormattedMessage id={translationKeys.customers_page_merge_duplicate_accounts_title} />
          </span>
          <span className={styles.description}>
            <FormattedMessage
              id={translationKeys.customers_page_merge_duplicate_accounts_description}
              values={{ name: customer.name }}
            />
          </span>
        </div>

        <div data-testid="customer-dropdown">
          <SearchDropdown
            options={customerResults}
            placeholder={intl.formatMessage({
              id: translationKeys.customers_page_merge_customers_placeholder,
            })}
            label={intl.formatMessage({
              id: translationKeys.customers_page_merge_customers_label,
            })}
            size="large"
            multiple
            onSearch={(query: string) => {
              debounceCustomerSearch(() => {
                getCustomerList(query);
              });
            }}
            onChange={(customers) => {
              handleCustomerSelect(customers || []);
            }}
          />
        </div>

        <Banner
          variant="warning"
          text={intl.formatMessage(
            { id: translationKeys.customers_page_merge_info_text },
            { name: customer.name },
          )}
        />

        <div className={styles.actions}>
          <Button size="large" variant="gray" onClick={onClose} data-testid="cancel-button">
            <FormattedMessage id={translationKeys.customers_page_merge_cancel_button} />
          </Button>

          <Button
            size="large"
            type="submit"
            disabled={!selectedCustomers || selectedCustomers.length === 0}
            data-testid="submit-button"
            onClick={handleSubmit}
          >
            <FormattedMessage id={translationKeys.customers_page_merge_merge_button} />
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default MergeCustomersDrawer;
