import React from 'react';
import { Placeholder } from 'react-bootstrap';
import { UsePlaceholderProps } from 'react-bootstrap/esm/usePlaceholder';
import classnames from 'classnames';
import styles from './Skeleton.module.scss';

interface Props {
  variant?: 'box' | 'details' | 'circles';
  circleCount?: number;
}

const Skeleton = (props: Props & UsePlaceholderProps) => {
  const { variant = 'box', circleCount = 1, ...placeholderProps } = props;

  if (variant === 'details') {
    return (
      <Placeholder
        {...placeholderProps}
        as="span"
        animation="wave"
        className={styles.skeleton}
        data-testid="details-skeleton"
      >
        <Placeholder xs={12} className={styles.box} />
        <Placeholder xs={12} className={styles.details} />
      </Placeholder>
    );
  }

  if (variant === 'circles') {
    return (
      <Placeholder
        as="span"
        animation="wave"
        className={classnames(styles.skeleton, styles.circlesContainer)}
        data-testid="circles-skeleton"
      >
        <div className={styles.circles}>
          {[...Array(circleCount)].map((_, index) => (
            <Placeholder xs={12} key={index} data-testid="circle" />
          ))}
        </div>
      </Placeholder>
    );
  }
  return (
    <Placeholder
      {...placeholderProps}
      as="span"
      animation="wave"
      className={styles.skeleton}
      data-testid="box-skeleton"
    >
      <Placeholder xs={12} className={styles.box} />
    </Placeholder>
  );
};

export default Skeleton;
