import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Modal, TextField } from '@skiwo/components';
import { Form, Formik } from 'formik';
import translationKeys from '../../../../translations/translationKeys';
import styles from './RenameFileModal.module.scss';

interface File {
  name: string;
  uid: string;
  id?: number;
}

interface Props {
  file: File;
  show: boolean;
  onClose: () => void;
  handleRenameFileSubmit: (values: { fileName: string; fileUid: string }) => void;
}

const RenameFileModal = (props: Props) => {
  const { file, show, onClose, handleRenameFileSubmit } = props;
  const intl = useIntl();

  const [fileExtension, setFileExtension] = useState<string>('');

  useEffect(() => {
    const extension = file.name.split('.').pop();
    setFileExtension(extension ? `.${extension}` : '');
  }, [file.name]);

  return (
    <Modal
      show={show}
      title={intl.formatMessage({ id: translationKeys.new_skill_drawer_rename_file_modal_title })}
      description={
        <span>
          <FormattedMessage id={translationKeys.new_skill_drawer_rename_file_modal_description} />{' '}
          <span className={styles.descriptionFileName}>{file.name}</span>
        </span>
      }
      onHide={onClose}
      data-testid="rename-file-modal"
    >
      <Formik
        initialValues={{
          fileName: file.name.split('.').slice(0, -1).join('.'),
          fileUid: file.uid,
        }}
        onSubmit={(values) => {
          handleRenameFileSubmit({
            fileName: values.fileName + fileExtension,
            fileUid: values.fileUid,
          });

          onClose();
        }}
      >
        {({ handleSubmit, handleChange, handleBlur, values }) => (
          <Form onSubmit={handleSubmit}>
            <TextField
              label={intl.formatMessage({
                id: translationKeys.new_skill_drawer_rename_file_modal_text_label,
              })}
              as="textarea"
              type="text"
              placeholder={intl.formatMessage({
                id: translationKeys.new_skill_drawer_rename_file_modal_text_placeholder,
              })}
              onChange={handleChange}
              onBlur={handleBlur}
              name="fileName"
              value={values.fileName}
              data-testid="rename-file-textarea"
            />
            <div className={styles.editButtons}>
              <Button
                onClick={onClose}
                variant="gray"
                size="large"
                className={styles.cancelButton}
                data-testid="cancel-button"
              >
                <FormattedMessage
                  id={translationKeys.new_skill_drawer_rename_file_modal_cancel_button}
                />
              </Button>

              <Button
                iconPosition="left"
                size="large"
                variant="primary"
                type="submit"
                className={styles.renameFileButton}
                data-testid="submit-button"
              >
                <FormattedMessage
                  id={translationKeys.new_skill_drawer_rename_file_modal_save_button}
                />
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default RenameFileModal;
