import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Banner, Button, Modal, RadioOptions, TextField } from '@skiwo/components';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import translationKeys from '../../../../translations/translationKeys';
import { ManagerOrderCalledBy } from '../../../../types';
import styles from './CancelOrderModal.module.scss';

interface FormValues {
  cancelledBy: ManagerOrderCalledBy;
  cancellationReasonText: string;
}

interface CancelOrderModalProps {
  show: boolean;
  onClose: () => void;
  onSubmit: (formData: FormValues) => void;
}

export const CancelOrderModal = ({ show, onClose, onSubmit }: CancelOrderModalProps) => {
  const intl = useIntl();
  const schema = yup.object().shape({
    cancelledBy: yup.string().required(
      intl.formatMessage(
        { id: translationKeys.form_error_required },
        {
          fieldName: intl.formatMessage({
            id: translationKeys.translation_order_cancel_order_modal_cancelled_by_label,
          }),
        },
      ),
    ),
    cancellationReasonText: yup.string().required(
      intl.formatMessage(
        { id: translationKeys.form_error_required },
        {
          fieldName: intl.formatMessage({
            id: translationKeys.translation_order_cancel_order_modal_cancellation_reason_label,
          }),
        },
      ),
    ),
  });

  const handleSubmission = (data: FormValues) => {
    onSubmit(data);
    onClose();
  };

  return (
    <Modal
      show={show}
      title={intl.formatMessage({
        id: translationKeys.translation_order_cancel_order_modal_title,
      })}
      description={intl.formatMessage({
        id: translationKeys.translation_order_cancel_order_modal_description,
      })}
      onHide={onClose}
    >
      <Banner
        variant="error"
        text={intl.formatMessage({
          id: translationKeys.translation_order_cancel_order_modal_warning,
        })}
      />
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmission}
        initialValues={{
          cancelledBy: '' as ManagerOrderCalledBy,
          cancellationReasonText: '',
        }}
      >
        {({ handleSubmit, handleChange, handleBlur, setFieldValue, errors, touched, values }) => (
          <Form onSubmit={handleSubmit}>
            <div className={styles.cancelledBy}>
              <RadioOptions
                label={intl.formatMessage({
                  id: translationKeys.translation_order_cancel_order_modal_cancelled_by_label,
                })}
                options={[
                  {
                    id: ManagerOrderCalledBy.Customer,
                    title: intl.formatMessage({
                      id: translationKeys.translation_order_cancel_order_modal_cancelled_by_customer_option,
                    }),
                  },
                  {
                    id: ManagerOrderCalledBy.Platform,
                    title: intl.formatMessage({
                      id: translationKeys.translation_order_cancel_order_modal_cancelled_by_platform_option,
                    }),
                  },
                ]}
                selected={values.cancelledBy}
                onSelect={(value) => {
                  setFieldValue('cancelledBy', value);
                }}
                errorText={touched.cancelledBy ? errors.cancelledBy : undefined}
              />
            </div>

            <TextField
              textArea
              label={intl.formatMessage({
                id: translationKeys.translation_order_cancel_order_modal_cancellation_reason_label,
              })}
              placeholder={intl.formatMessage({
                id: translationKeys.translation_order_cancel_order_modal_cancellation_reason_placeholder,
              })}
              onChange={handleChange}
              onBlur={handleBlur}
              name="cancellationReasonText"
              errorText={touched.cancellationReasonText ? errors.cancellationReasonText : undefined}
            />

            <div className={styles.actionButtons}>
              <Button
                onClick={onClose}
                variant="gray"
                size="large"
                className={styles.cancelButton}
                data-testid="cancel-button"
              >
                <FormattedMessage
                  id={translationKeys.translation_order_cancel_order_modal_back_button}
                />
              </Button>

              <Button
                iconPosition="left"
                size="large"
                variant="danger"
                type="submit"
                className={styles.renameFileButton}
                data-testid="submit-button"
              >
                <FormattedMessage
                  id={translationKeys.translation_order_cancel_order_modal_submit_button}
                />
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
