enum InterpretationSkillsFilterField {
  Id = 'ID',
  Name = 'NAME',
  Languages = 'LANGUAGES',
  Qualifications = 'QUALIFICATIONS',
  CreatedAt = 'CREATED_AT',
  Gender = 'GENDER',
  Location = 'LOCATION',
  Standby = 'STANDBY',
  AccountStatuses = 'ACCOUNT_STATUSES',
}

export default InterpretationSkillsFilterField;
