import React from 'react';
import { useIntl } from 'react-intl';
import { faCalendar, faEnvelope, faFileInvoice, faPhone } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tag, Tooltip } from '@skiwo/components';
import classNames from 'classnames';
import { format, formatDuration, intervalToDuration } from 'date-fns';
import { getAssignmentTypeIcon } from '../../CreateInterpretationOrder/utils';
import { getPaymentMethodName } from '../../helpers/getPaymentMethodName';
import { useLanguages } from '../../providers/LanguagesProvider';
import translationKeys from '../../translations/translationKeys';
import { ManagerJobDetails } from '../../types/ManagerJob';
import InfoItem from './InfoItem/InfoItem';
import styles from './JobDetails.module.scss';

interface InfoSectionProps {
  job: ManagerJobDetails;
}

export const InfoSection = ({ job }: InfoSectionProps) => {
  const intl = useIntl();
  const { getLanguageById } = useLanguages();

  return (
    <>
      <div className={styles.mainInfo}>
        <span className={classNames(styles.flexRow, styles.date)}>
          {format(new Date(job.actualCreatedAt), 'dd.MM.yyyy HH:mm')}
        </span>
        <div className={classNames(styles.flexRow, styles.header)}>
          <span>{job.id}</span>
          <FontAwesomeIcon
            icon={getAssignmentTypeIcon(job.interpretationRequirement.sessionType)}
            size="xs"
          />
          <span>{getLanguageById(job.interpretationRequirement.languageToId)}</span>
        </div>
        <span className={styles.category}>
          {job.info.jobCategoryName}
          {` - ${job.info.jobCategoryItemName}`}
        </span>
      </div>
      <div className={styles.infoItems}>
        <InfoItem
          label={intl.formatMessage({ id: translationKeys.job_date_and_time_label })}
          icons={<FontAwesomeIcon icon={faCalendar} />}
        >
          {format(new Date(job.interpretationRequirement.startTime), 'dd.MM.yyyy HH:mm')} -
          {format(new Date(job.interpretationRequirement.finishTime), 'HH:mm')}
          <Tag variant="square" color="neutral" size="small">
            <span className={styles.timeInterval}>
              {formatDuration(
                intervalToDuration({
                  start: new Date(job.interpretationRequirement.startTime),
                  end: new Date(job.interpretationRequirement.finishTime),
                }),
                {
                  format: ['hours', 'minutes'],
                  locale: {
                    formatDistance: (token: 'xHours' | 'xMinutes', count: number) => {
                      const formatMap = {
                        xHours: `${count}h`,
                        xMinutes: `${count}m`,
                      };
                      return formatMap[token];
                    },
                  },
                },
              )}
            </span>
          </Tag>
        </InfoItem>
        <InfoItem
          label={intl.formatMessage({ id: translationKeys.job_confirm_by_label })}
          icons={<FontAwesomeIcon icon={faCalendar} />}
        >
          {job.interpretationRequirement.confirmationBy &&
            format(new Date(job.interpretationRequirement.confirmationBy), 'dd.MM.yyyy HH:mm')}
        </InfoItem>
        <InfoItem
          label={intl.formatMessage({ id: translationKeys.job_booker_label })}
          icons={
            <>
              {job.owner?.phone && (
                <Tooltip title={job.owner.phone} copyable>
                  <FontAwesomeIcon icon={faPhone} />
                </Tooltip>
              )}
              {job.owner?.email && (
                <Tooltip title={job.owner.email} copyable>
                  <FontAwesomeIcon icon={faEnvelope} />
                </Tooltip>
              )}
            </>
          }
        >
          {`${job.owner?.name}, ${job.enterprise?.name}`}
        </InfoItem>
        <InfoItem
          label={intl.formatMessage({ id: translationKeys.job_payment_method_label })}
          icons={<FontAwesomeIcon icon={faFileInvoice} />}
        >
          {getPaymentMethodName(
            {
              orgName: job.paymentMethod.orgName,
              orgNumber: job.paymentMethod.orgNumber,
              email: job.paymentMethod.email,
              invoiceGroupStrategy: job.paymentMethod.invoiceGroupStrategy,
            },
            intl,
          )}
        </InfoItem>
      </div>
    </>
  );
};

export default InfoSection;
